import React, { useState } from "react";
import styles from "./AddSocialMediaPostModal.module.scss";
import Modal from "../../../Modal/Modal";
import Button from "../../../Button/Button";
import Input from "../../../MarketingBriefFormCard/components/Input/Input";
import TextElement from "../../../TextElement/TextElement";
import Select from "../../../Select/Select";
import { SocialMediaPostData } from "../../types";
import classNames from "classnames";

interface AddSocialMediaPostModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: SocialMediaPostData, label: string) => void;
}

const AddSocialMediaPostModal: React.FC<AddSocialMediaPostModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const handleOk = () => {};
  const [data, setData] = useState<SocialMediaPostData>({
    postType: "",
    text: "",
    imgSrc: "/common/post.png",
  });
  const [label, setLabel] = useState<string>("");

  return (
    <Modal
      isOpen={open}
      closeModal={onClose}
      className={styles["container"]}
      contentClassName={styles["content"]}
    >
      <TextElement className={styles["title"]}>
        {data?.postType ? "Add Social Media Post" : "Select channels"}
      </TextElement>
      {!data?.postType && (
        <div className={styles["social-media-buttons"]}>
          <button
            onClick={() => {
              setData({
                ...data,
                postType: "facebook",
              });
            }}
            className={classNames(styles["social-media"], styles["facebook"])}
          >
            <div className={styles["icon"]}>
              <svg
                width="27"
                height="50"
                viewBox="0 0 27 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.7875 8.30006H26.4875V0.350074C24.2119 0.113442 21.9254 -0.0033857 19.6375 7.46602e-05C12.8376 7.46602e-05 8.18756 4.15007 8.18756 11.7501V18.3H0.512573V27.2H8.18756V50H17.3875V27.2H25.0375L26.1875 18.3H17.3875V12.6251C17.3875 10.0001 18.0875 8.30006 21.7875 8.30006Z"
                  fill="white"
                />
              </svg>
            </div>
            <TextElement className={styles["title"]}>Facebook Post</TextElement>
            <TextElement className={styles["description"]}>
              Post from your page
            </TextElement>
          </button>
          <button
            onClick={() => {
              setData({
                ...data,
                postType: "linkedIn",
              });
            }}
            className={classNames(styles["social-media"], styles["linkedIn"])}
          >
            <div className={styles["icon"]}>
              <svg
                width="51"
                height="50"
                viewBox="0 0 51 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M46.6748 0.000349572H4.32534C3.84929 -0.00626189 3.37661 0.0809672 2.93427 0.257055C2.49194 0.433144 2.08863 0.694641 1.74737 1.02662C1.40611 1.35859 1.13359 1.75454 0.945366 2.19184C0.757145 2.62915 0.656912 3.09925 0.650391 3.5753V46.4247C0.656912 46.9007 0.757145 47.3708 0.945366 47.8082C1.13359 48.2455 1.40611 48.6414 1.74737 48.9734C2.08863 49.3054 2.49194 49.5669 2.93427 49.7429C3.37661 49.919 3.84929 50.0063 4.32534 49.9996H46.6748C47.1508 50.0063 47.6235 49.919 48.0658 49.7429C48.5081 49.5669 48.9115 49.3054 49.2527 48.9734C49.594 48.6414 49.8665 48.2455 50.0547 47.8082C50.2429 47.3708 50.3432 46.9007 50.3497 46.4247V3.5753C50.3432 3.09925 50.2429 2.62915 50.0547 2.19184C49.8665 1.75454 49.594 1.35859 49.2527 1.02662C48.9115 0.694641 48.5081 0.433144 48.0658 0.257055C47.6235 0.0809672 47.1508 -0.00626189 46.6748 0.000349572ZM15.7252 41.8498H8.22529V19.3501H15.7252V41.8498ZM11.9752 16.2001C10.9409 16.2001 9.94894 15.7892 9.21755 15.0579C8.48617 14.3265 8.07529 13.3345 8.07529 12.3002C8.07529 11.2658 8.48617 10.2739 9.21755 9.5425C9.94894 8.81112 10.9409 8.40023 11.9752 8.40023C12.5245 8.33794 13.0807 8.39237 13.6074 8.55994C14.1341 8.72751 14.6195 9.00445 15.0318 9.37262C15.4441 9.7408 15.774 10.1919 15.9999 10.6964C16.2257 11.2009 16.3425 11.7474 16.3425 12.3002C16.3425 12.8529 16.2257 13.3994 15.9999 13.9039C15.774 14.4085 15.4441 14.8596 15.0318 15.2277C14.6195 15.5959 14.1341 15.8728 13.6074 16.0404C13.0807 16.208 12.5245 16.2624 11.9752 16.2001ZM42.7748 41.8498H35.2749V29.7749C35.2749 26.75 34.1999 24.775 31.475 24.775C30.6316 24.7812 29.8105 25.0457 29.1221 25.5329C28.4337 26.0201 27.9112 26.7067 27.625 27.5C27.4294 28.0876 27.3446 28.7064 27.375 29.3249V41.8248H19.8751C19.8751 41.8248 19.8751 21.375 19.8751 19.3251H27.375V22.5C28.0563 21.3178 29.0473 20.3439 30.2411 19.6831C31.4349 19.0223 32.7864 18.6997 34.1499 18.7501C39.1499 18.7501 42.7748 21.975 42.7748 28.8999V41.8498Z"
                  fill="white"
                />
              </svg>
            </div>
            <TextElement className={styles["title"]}>LinkedIn Post</TextElement>
            <TextElement className={styles["description"]}>
              Post from your LinkedIn Business Page
            </TextElement>
          </button>
        </div>
      )}

      {data?.postType && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(data, label);
          }}
        >
          <div className={styles["image-container"]}>
            <img
              src={data.imgSrc}
              alt="post"
              className={styles["post-image"]}
            />
            <Button className={styles["change-media"]}>Change Media</Button>
          </div>
          <input
            value={label}
            required
            placeholder="Add a Label e.g Reintroduction Post"
            onChange={(e) => {
              setLabel(e.target.value);
            }}
          />
          <textarea
            className={styles["textarea"]}
            rows={6}
            cols={10}
            required
            placeholder="Write your post here"
            onChange={(e) => {
              setData({
                ...data,
                text: e.target.value,
              });
            }}
          ></textarea>

          <div className={styles["modal-footer"]}>
            <Button type={"submit"} onClick={handleOk}>
              Save
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AddSocialMediaPostModal;
