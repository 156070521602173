import HttpService from "../../lib/api";
import { toast } from "react-toastify";

import {
  CustomNode,
  EmailData,
  TriggerData,
  EntryData,
  CheckSetupData,
  DelayData,
  PushNotificationsData,
  SocialMediaPostData,
  CustomEdge,
  CustomNodeTypeVariants,
} from "./types";
import { randomUUID } from "../../lib/helpers";
import { useEdgesState, useNodesState } from "@xyflow/react";

const httpService = new HttpService();

const useNodeComponents = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState<CustomNode>([]);

  const [edges, setEdges, onEdgesChange] = useEdgesState<CustomEdge>([]);
  const executeRawQuery = async <T = any>(query: string): Promise<T> => {
    try {
      const response = await httpService.post<T, { query: string }>(
        "/api/execute-raw-sql/",
        { query }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to execute raw query", error);
      toast.error("Failed to execute raw query");
      return [] as T;
    }
  };

  const fetchJourneyNodesAndEdges = async (journeyId: number) => {
    try {
      // Fetch nodes related to the journey
      const nodesQuery = `
        SELECT id, type, position_x as position_x, position_y as position_y, payload_id, label
        FROM nodes
        WHERE journey_id = ${journeyId};
      `;

      const nodes = await executeRawQuery<
        {
          id: string;
          type: CustomNodeTypeVariants;
          positionX: number;
          positionY: number;
          payloadId: number;
          label: string;
        }[]
      >(nodesQuery); // Execute raw query and fetch nodes

      // Fetch transitions related to the journey
      const transitionsQuery = `
        SELECT id, source, target, label, type
        FROM transitions
        WHERE journey_id = ${journeyId};
      `;

      const transitions = await executeRawQuery<
        {
          id: string;
          source: string;
          target: string;
          label: string;
          selected: boolean;
          type: string;
        }[]
      >(transitionsQuery);

      // Map transitions to React Flow edges format
      const edges: CustomEdge[] = transitions.map((transition) => ({
        id: transition?.id, // Generate unique edge id
        source: transition.source,
        target: transition.target,
        label: transition.label || "",
        selected: false,
        type: transition?.type,
      }));

      // Map nodes to React Flow nodes format
      const formattedNodes = nodes.map((node) => ({
        id: node.id,
        type: node.type,
        position: { x: node.positionX, y: node.positionY },
        data: {
          payloadId: node.payloadId,
          label: node.label,
        },
      }));
      setNodes(formattedNodes); // Set nodes in React Flow state
      setEdges(edges); // Set edges in React Flow state
    } catch (error) {
      console.error("Error fetching nodes and transitions", error);
      toast.error("Failed to fetch nodes and transitions");
      return { nodes: [], edges: [] }; // Return empty nodes and edges on error
    }
  };

  const createTransition = async (data: {
    id: string;
    source: string;
    target: string;
    label: string;
    journeyId: number;
    type?: string;
  }) => {
    const { id, source, target, label, journeyId, type } = data;

    const transitionQuery = `
      INSERT INTO transitions (id, source, target, label, journey_id, type)
      VALUES ('${id}', '${source}', '${target}', '${label}', ${journeyId}, '${type}')
    `;

    console.log("Transition Query", transitionQuery);

    try {
      await executeRawQuery<
        {
          id: string;
          source: string;
          target: string;
          label: string;
          journeyId: number;
          type: string;
        }[]
      >(transitionQuery);
    } catch (error) {
      console.error("Error creating transition", error);
      throw new Error("Failed to create transition");
    }
  };

  // Save Email Node
  const createEmailNode = async (input: {
    journeyId: number;
    nodeData: EmailData;
    metaData: {
      label: string;
      position: { x: number; y: number };
      type: CustomNodeTypeVariants;
    };
  }) => {
    const { journeyId, nodeData, metaData } = input;
    const { emailName, subject, previewText, fromName, fromEmail, emailBody } =
      nodeData;
    const { label, position, type } = metaData;

    // Insert email data first
    const emailDataQuery = `
      INSERT INTO email_data (email_name, subject, preview_text, from_name, from_email, email_body)
      VALUES ('${emailName}', '${subject}', '${previewText}', '${fromName}', '${fromEmail}', '${emailBody}')
      RETURNING id;
    `;

    const emailData = await executeRawQuery<{ id: number }[]>(emailDataQuery);

    const nodeId = randomUUID();

    const nodeQuery = `
      INSERT INTO nodes (id, journey_id, type, position_x, position_y, payload_id, label)
      VALUES ('${nodeId}', ${journeyId}, '${type}', ${position.x}, ${position.y}, ${emailData[0].id}, '${label}');
    `;

    await executeRawQuery(nodeQuery);

    setNodes((prevNodes) => [
      ...prevNodes,
      {
        id: nodeId,
        type: type,
        position: { x: position.x, y: position.y },
        data: {
          payloadId: emailData[0].id,
          label: label,
        },
      },
    ]);

    toast.success("Email Node saved successfully!");
  };

  // Save Trigger Node
  const createTriggerNode = async (input: {
    journeyId: number;
    nodeData: TriggerData;
    metaData: {
      label: string;
      position: { x: number; y: number };
      type: CustomNodeTypeVariants;
    };
  }) => {
    const { journeyId, nodeData, metaData } = input;
    const { title } = nodeData;
    const { label, position, type } = metaData;

    // Insert trigger data first
    const triggerDataQuery = `
      INSERT INTO trigger_data (title)
      VALUES ('${title}')
      RETURNING id;
    `;

    const triggerData = await executeRawQuery<{ id: number }[]>(
      triggerDataQuery
    );

    const nodeId = randomUUID();

    const nodeQuery = `
      INSERT INTO nodes (id, journey_id, type, position_x, position_y, payload_id, label)
      VALUES ('${nodeId}', ${journeyId}, '${type}', ${position.x}, ${position.y}, ${triggerData[0].id}, '${label}');
    `;

    await executeRawQuery(nodeQuery);

    setNodes((prevNodes) => [
      ...prevNodes,
      {
        id: nodeId,
        type: type,
        position: { x: position.x, y: position.y },
        data: {
          payloadId: triggerData[0].id,
          label: label,
        },
      },
    ]);

    toast.success("Trigger Node saved successfully!");
  };

  // Save Push Notifications Node
  const createPushNotificationsNode = async (input: {
    journeyId: number;
    nodeData: PushNotificationsData;
    metaData: {
      label: string;
      position: { x: number; y: number };
      type: CustomNodeTypeVariants;
    };
  }) => {
    const { journeyId, nodeData, metaData } = input;
    const { message, screenType, selectedScreen } = nodeData;
    const { label, position, type } = metaData;

    // Insert push notifications data first
    const pushNotificationsQuery = `
      INSERT INTO push_notifications_data (message, screen_type, selected_screen)
      VALUES ('${message}', '${screenType}', '${selectedScreen}')
      RETURNING id;
    `;

    const pushNotificationsData = await executeRawQuery<{ id: number }[]>(
      pushNotificationsQuery
    );

    const nodeId = randomUUID();

    const nodeQuery = `
      INSERT INTO nodes (id, journey_id, type, position_x, position_y, payload_id, label)
      VALUES ('${nodeId}', ${journeyId}, '${type}', ${position.x}, ${position.y}, ${pushNotificationsData[0].id}, '${label}');
    `;

    await executeRawQuery(nodeQuery);

    setNodes((prevNodes) => [
      ...prevNodes,
      {
        id: nodeId,
        type: type,
        position: { x: position.x, y: position.y },
        data: {
          payloadId: pushNotificationsData[0].id,
          label: label,
        },
      },
    ]);

    toast.success("Push Notifications Node saved successfully!");
  };

  const createDelayNode = async (input: {
    journeyId: number;
    nodeData: DelayData;
    metaData: {
      label: string;
      position: { x: number; y: number };
      type: CustomNodeTypeVariants;
    };
  }) => {
    const { journeyId, nodeData, metaData } = input;
    const { delayInterval, date } = nodeData;
    const { label, position, type } = metaData;

    const delayDataQuery = `
        INSERT INTO delay_data (delay_interval, date)
        VALUES ('${delayInterval}', ${date === "" ? "NULL" : `'${date}'`})
        RETURNING id;
    `;

    const delayData = await executeRawQuery<{ id: number }[]>(delayDataQuery);

    const nodeId = randomUUID();

    const nodeQuery = `
      INSERT INTO nodes (id, journey_id, type, position_x, position_y, payload_id, label)
      VALUES ('${nodeId}', ${journeyId}, '${type}', ${position.x}, ${position.y}, ${delayData[0].id}, '${label}');
    `;

    await executeRawQuery(nodeQuery);

    setNodes((prevNodes) => [
      ...prevNodes,
      {
        id: nodeId,
        type: type,
        position: { x: position.x, y: position.y },
        data: {
          payloadId: delayData[0].id,
          label: label,
        },
      },
    ]);

    toast.success("Delay Node saved successfully!");
  };

  // Save Social Media Post Node
  const createSocialMediaPostNode = async (input: {
    journeyId: number;
    nodeData: SocialMediaPostData;
    metaData: {
      label: string;
      position: { x: number; y: number };
      type: CustomNodeTypeVariants;
    };
  }) => {
    const { journeyId, nodeData, metaData } = input;
    const { postType, imgSrc, text } = nodeData; // Extract relevant data for social media post
    const { label, position, type } = metaData;

    // Insert social media post data first
    const socialMediaQuery = `
      INSERT INTO social_media_post_data (post_type, img_src, text)
      VALUES ('${postType}', '${imgSrc}', '${text}')
      RETURNING id;
    `;

    const socialMediaData = await executeRawQuery<{ id: number }[]>(
      socialMediaQuery
    );

    const nodeId = randomUUID();

    const nodeQuery = `
      INSERT INTO nodes (id, journey_id, type, position_x, position_y, payload_id, label)
      VALUES ('${nodeId}', ${journeyId}, '${type}', ${position.x}, ${position.y}, ${socialMediaData[0].id}, '${label}');
    `;

    await executeRawQuery(nodeQuery);

    setNodes((prevNodes) => [
      ...prevNodes,
      {
        id: nodeId,
        type: type,
        position: { x: position.x, y: position.y },
        data: {
          payloadId: socialMediaData[0].id,
          label: label,
        },
      },
    ]);

    toast.success("Social Media Post Node saved successfully!");
  };

  // Save Entry Node
  const createEntryNode = async (input: {
    journeyId: number;
    nodeData: EntryData;
    metaData: {
      label: string;
      position: { x: number; y: number };
      type: CustomNodeTypeVariants;
    };
  }) => {
    const { journeyId, nodeData, metaData } = input;
    const { title } = nodeData;
    const { label, position, type } = metaData;

    // Insert entry data first
    const entryDataQuery = `
      INSERT INTO entry_data (title)
      VALUES ('${title}')
      RETURNING id;
    `;

    const entryData = await executeRawQuery<{ id: number }[]>(entryDataQuery);

    const entryNodeId = randomUUID();
    // Insert node into nodes table
    const nodeQuery = `
      INSERT INTO nodes (id,journey_id, type, position_x, position_y, payload_id, label)
      VALUES ('${entryNodeId}',${journeyId}, '${type}', ${position.x}, ${position.y}, ${entryData[0].id}, '${label}');
    `;

    await executeRawQuery(nodeQuery);

    setNodes((prevNodes) => [
      ...prevNodes,
      {
        id: entryNodeId,
        type: type,
        position: { x: position.x, y: position.y },
        data: {
          payloadId: entryData[0].id,
          label: label,
        },
      },
    ]);
    toast.success("Entry Node saved successfully!");
  };

  // Save End Node
  const createEndNode = async (input: {
    journeyId: number;
    nodeData: { label: string }; // Just a label for End Node
    metaData: {
      label: string;
      position: { x: number; y: number };
      type: CustomNodeTypeVariants;
    };
  }) => {
    const { journeyId, nodeData, metaData } = input;
    const { label } = nodeData;
    const { position, type } = metaData;

    const endNodeId = randomUUID();

    const nodeQuery = `
      INSERT INTO nodes (id, journey_id, type, position_x, position_y, label)
      VALUES ('${endNodeId}', ${journeyId}, '${type}', ${position.x}, ${position.y}, '${label}');
    `;

    await executeRawQuery(nodeQuery);

    setNodes((prevNodes) => [
      ...prevNodes,
      {
        id: endNodeId,
        type: type,
        position: { x: position.x, y: position.y },
        data: { label, payloadId: -1 },
      },
    ]);

    toast.success("End Node saved successfully!");
  };

  const createCheckNode = async (input: {
    journeyId: number;
    nodeData: CheckSetupData;
    metaData: {
      label: string;
      position: { x: number; y: number };
      type: CustomNodeTypeVariants;
    };
  }) => {
    const { journeyId, nodeData, metaData } = input;
    const { condition } = nodeData; // Condition or check data
    const { label, position, type } = metaData;

    // Insert check data first (if you want to store the condition in a separate table, update accordingly)
    const checkDataQuery = `
      INSERT INTO check_data (condition)
      VALUES ('${condition}')
      RETURNING id;
    `;

    const checkData = await executeRawQuery<{ id: number }[]>(checkDataQuery);

    const checkNodeId = randomUUID();

    const nodeQuery = `
      INSERT INTO nodes (id, journey_id, type, position_x, position_y, payload_id, label)
      VALUES ('${checkNodeId}', ${journeyId}, '${type}', ${position.x}, ${position.y}, ${checkData[0].id}, '${label}');
    `;

    await executeRawQuery(nodeQuery);

    setNodes((prevNodes) => [
      ...prevNodes,
      {
        id: checkNodeId,
        type: type,
        position: { x: position.x, y: position.y },
        data: { payloadId: checkData[0].id, label },
      },
    ]);

    toast.success("Check Node saved successfully!");
  };

  const updateJourneyNodes = async (
    nodesData: {
      id: string; // UUID of the node to update
      type?: string; // Node type (optional, if you want to update it)
      position?: { x: number; y: number }; // Position (optional, if you want to update it)
      label?: string; // Label (optional, if you want to update it)
      payloadId?: number; // Payload ID (optional, if you want to update it)
    }[]
  ) => {
    // Build the array of update promises
    const updatePromises = nodesData.map((data) => {
      const { id, type, position, label, payloadId } = data;

      // Build the update query based on which fields are provided
      let updateQuery = `UPDATE nodes SET `;
      const fieldsToUpdate: string[] = [];

      if (type) fieldsToUpdate.push(`type = '${type}'`);
      if (position)
        fieldsToUpdate.push(
          `position_x = ${position.x}, position_y = ${position.y}`
        );
      if (label) fieldsToUpdate.push(`label = '${label}'`);
      if (payloadId) fieldsToUpdate.push(`payload_id = ${payloadId}`);

      if (fieldsToUpdate.length === 0) {
        throw new Error("No fields to update.");
      }

      updateQuery +=
        fieldsToUpdate.join(", ") +
        ` WHERE id = '${id}' RETURNING id, type, position_x, position_y, label, payload_id;`;

      // Return the promise for the current update query
      return executeRawQuery<
        {
          id: string;
          type: string;
          positionX: number;
          positionY: number;
          label: string;
          payloadId: number;
        }[]
      >(updateQuery);
    });

    // Wait for all the queries to finish and return the results
    try {
      const updatedNodes = await Promise.all(updatePromises);
      return updatedNodes; // Returning an array of updated nodes
    } catch (error) {
      console.error("Error updating nodes:", error);
      throw new Error("Failed to update nodes.");
    }
  };

  const deleteJourneyEdges = async (edgeIds: string[]) => {
    // Build the delete promises for edges
    const deletePromises = edgeIds.map((edgeId) => {
      // Build the delete query for each edge (transition)
      const deleteEdgeQuery = `DELETE FROM transitions WHERE id = '${edgeId}' RETURNING id;`;

      // Return the promise for the current delete query
      return executeRawQuery<{ id: string }[]>(deleteEdgeQuery);
    });

    try {
      // Wait for all the delete queries to finish
      const deletedEdges = await Promise.all(deletePromises);

      // Return the deleted edges' ids
      return deletedEdges.map((edge) => edge[0].id);
    } catch (error) {
      console.error("Error deleting edges:", error);
      throw new Error("Failed to delete edges.");
    }
  };

  const deleteJourneyNodes = async (nodeIds: string[]) => {
    // Build the delete promises for nodes
    const deletePromises = nodeIds.map((id) => {
      // Build the delete query for each node
      const deleteNodeQuery = `DELETE FROM nodes WHERE id = '${id}' RETURNING id;`;

      // Return the promise for the current delete query
      return executeRawQuery<{ id: string }[]>(deleteNodeQuery);
    });

    try {
      // Wait for all the delete queries to finish
      const deletedNodes = await Promise.all(deletePromises);

      // Return the deleted nodes' ids
      return deletedNodes.map((node) => node[0].id);
    } catch (error) {
      console.error("Error deleting nodes:", error);
      throw new Error("Failed to delete nodes.");
    }
  };

  return {
    deleteJourneyEdges,
    deleteJourneyNodes,
    createEntryNode,
    createSocialMediaPostNode,
    fetchJourneyNodesAndEdges,
    nodes,
    setNodes,
    edges,
    setEdges,
    onNodesChange,
    onEdgesChange,
    createTransition,
    updateJourneyNodes,
    createDelayNode,
    createPushNotificationsNode,
    createTriggerNode,
    createEmailNode,
    createEndNode,
    createCheckNode,
  };
};

export default useNodeComponents;
