import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ReactFlow,
  Background,
  Controls,
  applyNodeChanges,
  applyEdgeChanges,
  MarkerType,
  addEdge,
  Node,
  ReactFlowProvider,
  useReactFlow,
  useNodesState,
  useEdgesState,
  ConnectionMode,
  BackgroundVariant,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import styles from "./ReactFlowContainer.module.scss";

import {
  EmailNode,
  SocialMediaNode,
  PushNotificationsNode,
  EntryPointNode,
  TriggerNode,
  DelayNode,
  CheckNode,
  EndNode,
} from "./components/NodeComponents/NodeComponents";

import {
  YesEdgeType,
  NoEdgeType,
} from "./components/EdgeComponents/EdgeComponents";

import dagre from "dagre";
import WidgetSidebar from "./components/WidgetSidebar/WidgetSidebar";
import { useDnD } from "./context/DnDContext";
import UpdateNodeEdge from "./components/UpdateNodeEdgeData/UpdateNodeEdgeData";

import {
  CustomNode,
  CustomEdge,
  CustomNodeTypeVariants,
  PushNotificationsData,
  EmailData,
  DelayData,
  CheckSetupData,
  SocialMediaPostData,
  EntryData,
  TriggerData,
} from "./types";
import classNames from "classnames";
import VerticalTabs from "../VerticalTabs/VerticalTabs";
import CampaignQueryChat from "../CampaignQueryChat/CampaignQueryChat";
import AddPushNotificationModal from "./components/AddPushNotificationModal/AddPushNotificationModal";
import AddDelayModal from "./components/AddDelayModal/AddDelayModal";
import AddCheckSetupModal from "./components/AddCheckSetupModal/AddCheckSetupModal";
import AddSocialMediaPostModal from "./components/AddSocialMediaPostModal/AddSocialMediaPostModal";
import AddEntryModal from "./components/AddEntryModal/AddEntryModal";
import AddTriggerModal from "./components/AddTriggerModal/AddTriggerModal";
import AddEmailModal from "./components/AddEmailModal/AddEmailModal";
import useNodeComponents from "./useNodeComponents";
import { randomUUID } from "../../lib/helpers";

const alignNodes = (
  nodes: CustomNode[],
  edges: CustomEdge[],
  direction: "TB" | "LR" = "TB"
): CustomNode[] => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  let nodeWidth = 280;
  let nodeHeight = 450;
  // Add nodes with dynamic width and height based on DOM elements
  nodes.forEach((node) => {
    const element = document.querySelector(`[data-id='${node.id}']`);
    let width = nodeWidth;
    let height = nodeHeight;
    if (element && element instanceof HTMLElement) {
      width = element.offsetWidth;
      height = element.offsetHeight;
    }
    dagreGraph.setNode(node.id, { width, height });
  });

  // Add edges to the graph
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  // Perform the layout
  dagre.layout(dagreGraph);

  // Map updated positions back to the nodes
  return nodes.map((node) => {
    const layoutNode = dagreGraph.node(node.id);

    return {
      ...node,
      targetPosition: isHorizontal ? "left" : "top",
      sourcePosition: isHorizontal ? "right" : "bottom",
      position: {
        x: layoutNode.x - (layoutNode.width || 280) / 2, // Center align React Flow nodes
        y: layoutNode.y - (layoutNode.height || 450) / 2,
      },
    };
  });
};

interface Props {
  journeyId: number;
  updatedJourneyBlueprint: (nodes: CustomNode[], edges: CustomEdge[]) => void;
}

const ReactFlowContainer: React.FC<Props> = ({
  updatedJourneyBlueprint,
  journeyId,
}) => {
  const {
    createEntryNode,
    createSocialMediaPostNode,
    fetchJourneyNodesAndEdges,
    nodes,
    setNodes,
    edges,
    setEdges,
    onNodesChange,
    onEdgesChange,
    createTransition,
    updateJourneyNodes,
    createDelayNode,
    createPushNotificationsNode,
    createTriggerNode,
    createEmailNode,
    createEndNode,
    createCheckNode,
    deleteJourneyEdges,
    deleteJourneyNodes,
  } = useNodeComponents();

  const { type } = useDnD();
  const reactFlowWrapper = useRef(null);
  const [selectedEdge, setSelectedEdge] = useState<CustomEdge | null>(null);
  const [selectedNode, setSelectedNode] = useState<CustomNode | null>(null);

  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [activePosition, setActivePosition] = React.useState({
    x: 0,
    y: 0,
  });
  const [addPushNotificationModal, setAddPushNotificationModal] =
    useState<boolean>(false);

  const [addDelayModal, setAddDelayModal] = useState<boolean>(false);
  const [addChecksetModal, setAddChecksetModal] = useState<boolean>(false);
  const [addSocialMediaPostModal, setAddSocialMediaPostModal] =
    useState<boolean>(false);
  const [addEntryModal, setAddEntryModal] = useState<boolean>(false);
  const [addTriggerModal, setAddTriggerModal] = useState<boolean>(false);
  const [addEmailModal, setAddEmailModal] = useState<boolean>(false);

  useEffect(() => {
    fetchJourneyNodesAndEdges(journeyId)
      .then(() => {})
      .catch(() => {});
  }, []);

  const { screenToFlowPosition } = useReactFlow();

  const onConnect = useCallback(
    (params: {
      source: string;
      target: string;
      sourceHandle: string | null;
      targetHandle: string | null;
    }) => {
      console.log("Connected", params?.source);

      const sourceNode = nodes.find((node) => node.id === params.source);
      const id = randomUUID();

      let newEdgeLabel = "No"; // Default label for the new edge

      if (sourceNode?.type === "trigger" || sourceNode?.type === "check") {
        // Get all edges connected to the trigger node
        const triggerEdges = edges.filter(
          (edge) => edge.source === params.source
        );

        if (triggerEdges.length === 1) {
          // If there is already one edge, assign the other label
          newEdgeLabel = triggerEdges[0].label === "No" ? "Yes" : "No";
        }

        // Create the new edge with the appropriate label
        const newEdge = {
          id, // Unique ID for the edge
          source: params.source,
          target: params.target,
          label: newEdgeLabel,
          type: newEdgeLabel.toLowerCase(),
          markerEnd: {
            type: MarkerType.Arrow,
            color: "#A3B3C9",
          },
          selected: false, // Optional
        };

        setEdges((eds) => [...eds, newEdge]);
      } else {
        // If the source node is not a trigger, add the edge without a label
        setEdges((eds) => addEdge(params, eds));
      }

      createTransition({
        id,
        source: params.source,
        target: params.target,
        label:
          sourceNode?.type === "trigger" || sourceNode?.type === "check"
            ? newEdgeLabel
            : "",
        journeyId,
        type:
          sourceNode?.type === "trigger" || sourceNode?.type === "check"
            ? newEdgeLabel?.toLowerCase()
            : "",
      }).then(() => {});
    },
    [nodes, edges]
  );

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event: any) => {
      console.log("Dropped", type);
      event.preventDefault();

      if (!type) {
        return;
      }
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      setActivePosition(position);
      if (type === "pushNotifications") {
        setAddPushNotificationModal(true);
        return;
      }
      if (type === "delay") {
        setAddDelayModal(true);
        return;
      }

      if (type === "check") {
        setAddChecksetModal(true);
        return;
      }

      if (type === "socialMedia") {
        setAddSocialMediaPostModal(true);
        return;
      }

      if (type === "entryPoint") {
        setAddEntryModal(true);
        return;
      }

      if (type === "trigger") {
        setAddTriggerModal(true);
        return;
      }

      if (type === "email") {
        setAddEmailModal(true);
        return;
      }
      if (type === "end") {
        addNewNode(undefined, position, "end");
      }
    },
    [screenToFlowPosition, type]
  );

  const addNewNode = (
    data:
      | PushNotificationsData
      | DelayData
      | CheckSetupData
      | SocialMediaPostData
      | EntryData
      | TriggerData
      | EmailData
      | undefined,
    position: {
      x: number;
      y: number;
    },
    type: CustomNodeTypeVariants,
    label?: string
  ) => {
    if (type === "entryPoint" && data) {
      const entryData = data as EntryData;
      createEntryNode({
        journeyId,
        nodeData: {
          title: entryData.title,
        },
        metaData: {
          type: "entryPoint",
          label: entryData.title,
          position: {
            x: position.x,
            y: position.y,
          },
        },
      });
    }

    if (type === "socialMedia") {
      const nodeData = data as SocialMediaPostData;
      createSocialMediaPostNode({
        journeyId,
        nodeData,
        metaData: {
          type: "socialMedia",
          label: label as string,
          position: {
            x: position.x,
            y: position.y,
          },
        },
      });
    }

    if (type === "pushNotifications") {
      const nodeData = data as PushNotificationsData;
      createPushNotificationsNode({
        journeyId,
        nodeData,
        metaData: {
          type: "pushNotifications",
          label: label as string,
          position: {
            x: position.x,
            y: position.y,
          },
        },
      });
    }

    if (type === "delay") {
      const nodeData = data as DelayData;
      createDelayNode({
        journeyId,
        nodeData,
        metaData: {
          type: "delay",
          label: "Delay",
          position: {
            x: position.x,
            y: position.y,
          },
        },
      });
    }

    if (type === "end") {
      createEndNode({
        journeyId,
        nodeData: {
          label: "End",
        },
        metaData: {
          type: "end",
          label: "End",
          position: {
            x: position.x,
            y: position.y,
          },
        },
      });
    }

    if (type === "check") {
      const nodeData = data as CheckSetupData;
      createCheckNode({
        journeyId,
        nodeData,
        metaData: {
          type: "check",
          label: "Check",
          position: {
            x: position.x,
            y: position.y,
          },
        },
      });
    }

    if (type === "trigger") {
      const nodeData = data as TriggerData;
      createTriggerNode({
        journeyId,
        nodeData,
        metaData: {
          type: "trigger",
          label: "Trigger",
          position: {
            x: position.x,
            y: position.y,
          },
        },
      });
    }

    if (type === "email") {
      const nodeData = data as EmailData;
      createEmailNode({
        journeyId,
        nodeData,
        metaData: {
          type: "email",
          label: "Email",
          position: {
            x: position.x,
            y: position.y,
          },
        },
      });
    }
  };

  const updateNode = (updatedNode: CustomNode) => {
    const updatedOne = nodes.findIndex((node) => node.id === updatedNode.id);

    const newNodes = [...nodes];
    newNodes[updatedOne] = updatedNode;
    setNodes(newNodes);
  };

  const updateEdge = (updatedEdge: CustomEdge) => {
    console.log("Updated Edge", updatedEdge, edges);
    const updatedOne = edges.findIndex((edge) => edge.id === updatedEdge.id);
    console.log("Updated One index: ", updatedOne);

    const newEdges = [...edges];
    newEdges[updatedOne] = updatedEdge;
    console.log("New Edges", newEdges[updatedOne]);
    setEdges(newEdges);
  };

  return (
    <div className={styles["container"]}>
      <AddPushNotificationModal
        open={addPushNotificationModal}
        onClose={() => setAddPushNotificationModal(false)}
        onSubmit={(data, label) => {
          setAddPushNotificationModal(false);
          addNewNode(data, activePosition, "pushNotifications", label);
        }}
      />

      <AddDelayModal
        open={addDelayModal}
        onClose={() => setAddDelayModal(false)}
        onSubmit={(data) => {
          setAddDelayModal(false);
          console.log("Delay Data", data);
          addNewNode(data, activePosition, "delay");
        }}
      />

      <AddCheckSetupModal
        open={addChecksetModal}
        onClose={() => setAddChecksetModal(false)}
        onSubmit={(data) => {
          setAddChecksetModal(false);
          console.log("Check Data", data);
          addNewNode(data, activePosition, "check");
        }}
      />

      <AddEntryModal
        open={addEntryModal}
        onClose={() => setAddEntryModal(false)}
        onSubmit={(data) => {
          setAddEntryModal(false);
          console.log("Entry Data", data);
          addNewNode(data, activePosition, "entryPoint");
        }}
      />

      <AddTriggerModal
        open={addTriggerModal}
        onClose={() => setAddTriggerModal(false)}
        onSubmit={(data) => {
          setAddTriggerModal(false);
          console.log("Trigger Data", data);
          addNewNode(data, activePosition, "trigger");
        }}
      />

      <AddSocialMediaPostModal
        open={addSocialMediaPostModal}
        onClose={() => setAddSocialMediaPostModal(false)}
        onSubmit={(data, label) => {
          setAddSocialMediaPostModal(false);
          addNewNode(data, activePosition, "socialMedia", label);
        }}
      />

      <AddEmailModal
        open={addEmailModal}
        onClose={() => setAddEmailModal(false)}
        onSubmit={(data) => {
          setAddEmailModal(false);
          console.log("Email Data", data);
          addNewNode(data, activePosition, "email");
        }}
      />

      <svg style={{ position: "absolute", top: 0, left: 0 }}>
        <defs>
          <marker
            className="react-flow__arrowhead"
            id="marker-pink"
            markerWidth="10"
            markerHeight="10"
            viewBox="-10 -10 20 20"
            markerUnits="strokeWidth"
            orient="auto-start-reverse"
            refX="0"
            refY="0"
          >
            <polyline
              style={{
                stroke: "#A3B3C9",
                fill: "#A3B3C9",
                strokeWidth: 1,
              }}
              strokeLinecap="round"
              strokeLinejoin="round"
              points="-5,-4 0,0 -5,4 -5,-4"
            />
          </marker>
        </defs>
      </svg>

      <div
        className={classNames(
          styles["body"],
          isCollapsed ? styles["content-collapsed"] : styles["content-expanded"]
        )}
      >
        <VerticalTabs
          tabs={[
            {
              id: 1,
              tabIcon: (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5667 3.08333C15.5667 2.72435 15.2757 2.43333 14.9167 2.43333H12.25C11.891 2.43333 11.6 2.72435 11.6 3.08333V3.91667C11.6 4.27565 11.891 4.56667 12.25 4.56667H14.9167C15.2757 4.56667 15.5667 4.27565 15.5667 3.91667V3.08333ZM6.4 3.08333C6.4 2.72435 6.10898 2.43333 5.75 2.43333H3.08333C2.72435 2.43333 2.43333 2.72435 2.43333 3.08333V7.58333C2.43333 7.94232 2.72435 8.23333 3.08333 8.23333H5.75C6.10899 8.23333 6.4 7.94232 6.4 7.58333V3.08333ZM15.5667 10.4167C15.5667 10.0577 15.2757 9.76667 14.9167 9.76667H12.25C11.891 9.76667 11.6 10.0577 11.6 10.4167V14.9167C11.6 15.2757 11.891 15.5667 12.25 15.5667H14.9167C15.2757 15.5667 15.5667 15.2757 15.5667 14.9167V10.4167ZM6.4 14.0833C6.4 13.7243 6.10898 13.4333 5.75 13.4333H3.08333C2.72435 13.4333 2.43333 13.7243 2.43333 14.0833V14.9167C2.43333 15.2757 2.72435 15.5667 3.08333 15.5667H5.75C6.10899 15.5667 6.4 15.2757 6.4 14.9167V14.0833ZM15.25 0.9C16.2717 0.9 17.1 1.72827 17.1 2.75V4.25C17.1 5.27173 16.2717 6.1 15.25 6.1H11.9167C10.8949 6.1 10.0667 5.27173 10.0667 4.25V2.75C10.0667 1.72827 10.8949 0.9 11.9167 0.9H15.25ZM6.08333 0.9C7.10506 0.9 7.93333 1.72827 7.93333 2.75V7.91667C7.93333 8.93839 7.10506 9.76667 6.08333 9.76667H2.75C1.72827 9.76667 0.9 8.93839 0.9 7.91667V2.75C0.9 1.72827 1.72827 0.9 2.75 0.9H6.08333ZM15.25 8.23333C16.2717 8.23333 17.1 9.06161 17.1 10.0833V15.25C17.1 16.2717 16.2717 17.1 15.25 17.1H11.9167C10.8949 17.1 10.0667 16.2717 10.0667 15.25V10.0833C10.0667 9.06161 10.8949 8.23333 11.9167 8.23333H15.25ZM6.08333 11.9C7.10506 11.9 7.93333 12.7283 7.93333 13.75V15.25C7.93333 16.2717 7.10506 17.1 6.08333 17.1H2.75C1.72827 17.1 0.9 16.2717 0.9 15.25V13.75C0.9 12.7283 1.72827 11.9 2.75 11.9H6.08333Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.3"
                  />
                </svg>
              ),
              content: (
                <WidgetSidebar
                  onSave={() => {
                    updatedJourneyBlueprint(nodes, edges);
                  }}
                  onAlign={() => {
                    const updatedNodes = alignNodes(nodes, edges);
                    setNodes(updatedNodes);
                    updateJourneyNodes(updatedNodes);
                  }}
                />
              ),
            },
            {
              id: 2,
              tabIcon: <img src="/gifs/ninja.gif" />,
              content: (
                <div className={classNames(styles["campaign-query-chat"])}>
                  <CampaignQueryChat payload="" />
                </div>
              ),
            },
          ]}
          onCollapse={(isCollapse) => setIsCollapsed(isCollapse)}
          className={styles["vertical-tabs"]}
        />
      </div>
      <div className={styles["reactflow-wrapper"]} ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onConnect={onConnect}
          nodeTypes={{
            email: EmailNode,
            socialMedia: SocialMediaNode,
            pushNotifications: PushNotificationsNode,
            entryPoint: EntryPointNode,
            trigger: TriggerNode,
            delay: DelayNode,
            check: CheckNode,
            end: EndNode,
          }}
          edgeTypes={{
            yes: YesEdgeType,
            no: NoEdgeType,
          }}
          onNodesChange={(changes) => {
            const updatedPositions = changes
              .filter((change) => change.type === "position")
              .filter(
                (change) =>
                  (
                    change as {
                      dragging: boolean;
                    }
                  ).dragging === false
              )
              .map((change) => {
                const { id, position } = change as {
                  id: string;
                  position: { x: number; y: number };
                  dragging: boolean;
                };
                return { id, position };
              });

            updateJourneyNodes(updatedPositions).then(() => {
              console.log("Updated Nodes", updatedPositions);
            });

            onNodesChange(changes);
          }}
          onEdgesChange={(changes) => {
            console.log("Edges Changes", changes);
            onEdgesChange(changes);
          }}
          defaultEdgeOptions={{
            markerEnd: {
              type: MarkerType.ArrowClosed,
              color: "#A3B3C9",
            },
            focusable: true,
            style: {
              strokeWidth: 1,
            },
          }}
          onNodeClick={(event, node) => {
            // setSelectedNode(node);
            // setSelectedEdge(null);
            setNodes((prevNodes) =>
              prevNodes.map(
                (n) =>
                  ({
                    ...n,
                    data: {
                      ...n.data,
                      selected: n.id === node.id,
                    },
                  } as any)
              )
            );
          }}
          onEdgeClick={(event, edge) => {
            // setSelectedEdge(edge);
            // setSelectedNode(null);
            setNodes((prevNodes) =>
              prevNodes.map(
                (n) =>
                  ({
                    ...n,
                    data: {
                      ...n.data,
                      selected: false,
                    },
                  } as any)
              )
            );
          }}
          onEdgesDelete={(edges) => {
            console.log("Edges Deleted", edges);
            deleteJourneyEdges(edges?.map((edge) => edge.id));
          }}
          onNodesDelete={(nodes) => {
            console.log("Nodes Deleted", nodes);
            deleteJourneyNodes(nodes?.map((node) => node.id));
          }}
          snapToGrid={false}
          onDrop={onDrop}
          fitView
          onDragOver={onDragOver}
          style={{ backgroundColor: "#FFF6EB" }}
          connectionMode={ConnectionMode.Loose}
        >
          <Controls />
        </ReactFlow>
      </div>
      <UpdateNodeEdge
        selectedElement={selectedNode || selectedEdge}
        updateNode={updateNode}
        updateEdge={updateEdge}
        onClose={() => {
          setSelectedNode(null);
          setSelectedEdge(null);
        }}
      />
    </div>
  );

  // return (
  //   // <div className={styles["container"]}>
  //   //   <WidgetSidebar
  //   //     onSave={() => {
  //   //       updatedJourneyBlueprint(nodes, edges);
  //   //     }}
  //   //     onAlign={() => {
  //   //       setNodes(alignNodes(nodes, edges));
  //   //     }}
  //   //   />
  //   //   <div className={styles["reactflow-wrapper"]} ref={reactFlowWrapper}>
  //   //     <ReactFlow
  //   //       nodes={nodes}
  //   //       edges={edges}
  //   //       onConnect={onConnect}
  //   //       nodeTypes={{
  //   //         entry: EntryNode,
  //   //         action: ActionNode,
  //   //         delay: DelayNode,
  //   //         email: EmailContentNode,
  //   //         diamond: ConditionalNode,
  //   //         end: EndNode,
  //   //       }}
  //   //       onNodesChange={onNodesChange}
  //   //       onEdgesChange={onEdgesChange}
  //   //       defaultEdgeOptions={{
  //   //         type: "step",
  //   //         label: "add a label",
  //   //         markerEnd: {
  //   //           type: MarkerType.ArrowClosed,
  //   //           color: "#b1b1b7",
  //   //         },
  //   //         focusable: true,
  //   //         style: {
  //   //           strokeWidth: 2,
  //   //         },
  //   //       }}
  //   //       onNodeClick={(event, node) => {
  //   //         setSelectedNode(node);
  //   //         setSelectedEdge(null);
  //   //         setNodes((prevNodes) =>
  //   //           prevNodes.map(
  //   //             (n) =>
  //   //               ({
  //   //                 ...n,
  //   //                 data: {
  //   //                   ...n.data,
  //   //                   selected: n.id === node.id,
  //   //                 },
  //   //               } as any)
  //   //           )
  //   //         );
  //   //       }}
  //   //       onEdgeClick={(event, edge) => {
  //   //         setSelectedEdge(edge);
  //   //         setSelectedNode(null);
  //   //         setNodes((prevNodes) =>
  //   //           prevNodes.map(
  //   //             (n) =>
  //   //               ({
  //   //                 ...n,
  //   //                 data: {
  //   //                   ...n.data,
  //   //                   selected: false,
  //   //                 },
  //   //               } as any)
  //   //           )
  //   //         );
  //   //       }}
  //   //       snapToGrid={false}
  //   //       onDrop={onDrop}
  //   //       onDragOver={onDragOver}
  //   //       style={{ backgroundColor: "#FFF6EB" }}
  //   //       connectionMode={ConnectionMode.Loose}
  //   //     >
  //   //       <Background />
  //   //       <Controls />
  //   //     </ReactFlow>
  //   //   </div>
  //   //   <UpdateNodeEdge
  //   //     selectedElement={selectedNode || selectedEdge}
  //   //     updateNode={updateNode}
  //   //     updateEdge={updateEdge}
  //   //     onClose={() => {
  //   //       setSelectedNode(null);
  //   //       setSelectedEdge(null);
  //   //     }}
  //   //   />
  //   // </div>
  // );
};

export default ReactFlowContainer;
