import React, { useEffect } from "react";

import styles from "./MarketingDashboardReport.module.scss";

import TextElement from "../TextElement/TextElement";

import Metric from "../CampaignReports/components/Metric/Metric";
import Select from "../Select/Select";

import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import GraphWrapper from "../Graph/GraphWrapper";

import useMarketingDashboardReport from "./useMarketingDashboardReport";
import { formatNumberStandard } from "../../lib/helpers";
import { DateCapturedValues } from "./types";
import { Chart } from "react-google-charts";
import CreateStrategyBox from "../SalesDashboardReport/components/CreateStrategyBox/CreateStrategyBox";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { useAppDispatch } from "../../redux/hooks";
import { clearCampaignQueryAnalysis } from "../../redux/campaignPlanAnalysisQueryChat";
import { clearContentGenerationState } from "../../redux/contentGenerationSlice";

import { setSelectedCampaign } from "../../redux/campaignPinningSlice";

type Props = {
  className?: string;
  onClick?: () => void;
};

const multiColumnChartOptions = {
  chartArea: { top: 20, width: "85%", height: "80%" },
  colors: ["#FFCFC6", "#FC8A73", "#EB2701"],
  legend: {
    position: "top",
  },
  vAxis: {
    format: "short",
  },
};

const MarketingDashboardReport: React.FC<Props> = (props) => {
  const { className, onClick } = props;
  const navigate = useNavigate();

  const [selectedProduct, setSelectedProduct] = React.useState("");
  const [selectedCampaignId, setSelectedCampaignId] = React.useState("");
  const [dateCaptured, setDateCaptured] =
    React.useState<DateCapturedValues>("");

  const dispatch = useAppDispatch();

  const {
    keyFindings,
    products,
    campaigns,
    costEngagementScatterData,
    contactsTrendData,
    performanceAcrossKeyMetrics,
    emailPerformanceData,
    landingPagePerformanceData,
    linkedInEngagementData,
    dateCapturedOptions,
  } = useMarketingDashboardReport(
    selectedProduct,
    selectedCampaignId,
    dateCaptured
  );
  console.log("linkedInEngagementData: ", linkedInEngagementData);

  return (
    <div
      className={classNames(styles["container"], className)}
      onClick={onClick}
    >
      <div className={styles["filters"]}>
        <Select
          options={products.map((product) => ({
            label: product.productName,
            value: product.productId,
          }))}
          value={selectedProduct}
          placeholderText="All Products"
          showClearSelection={true}
          clearSelectionLabel="All Products"
          onChange={(value) => setSelectedProduct(value as string)}
          buttonClassName={styles["select-button"]}
          className={styles["select-menu"]}
        />
        <Select
          buttonClassName={styles["select-button"]}
          className={styles["select-menu"]}
          options={campaigns.map((campaign) => ({
            label: campaign.campaignName,
            value: campaign.campaignId,
          }))}
          value={selectedCampaignId}
          placeholderText="All Campaigns"
          showClearSelection={true}
          clearSelectionLabel="All Campaigns"
          onChange={(value) => setSelectedCampaignId(value as string)}
        />
        <Select
          className={styles["select-menu"]}
          buttonClassName={styles["select-button"]}
          options={dateCapturedOptions.map((date) => ({
            label: date?.label,
            value: date?.value,
          }))}
          value={dateCaptured}
          placeholderText="All Dates"
          showClearSelection={true}
          clearSelectionLabel="All Dates"
          onChange={(value) => {
            setDateCaptured(value as DateCapturedValues);
          }}
        />
      </div>
      <TextElement className={styles["sub-heading"]}>Key Findings</TextElement>
      <div className={classNames(styles["create-strategy-boxes"])}>
        <CreateStrategyBox
          value={`${formatNumberStandard(keyFindings?.totalContacts)}`}
          onClick={() => {
            dispatch(clearCampaignQueryAnalysis({}));
            dispatch(clearContentGenerationState());
            dispatch(setSelectedCampaign(""));
            navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path);
          }}
          valueLabel="Total Contacts"
          createBtnText="Create Campaign"
          warningText={`${keyFindings?.idleContactsPercentage}% idle`}
          icon={
            <svg
              width="102"
              height="99"
              viewBox="0 0 102 99"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M85.0049 86.081C84.6781 88.4149 83.3479 90.9465 79.5023 92.2405L75.8402 84.7103L71.5086 75.7896L62.9385 60.6367C62.9385 60.6367 77.9808 62.6521 84.2347 80.4134C84.3076 80.6146 84.3761 80.8205 84.4485 81.03C84.4525 81.0379 85.3798 83.4285 85.0049 86.081Z"
                fill="#01254F"
              />
              <path
                d="M79.5059 92.2442L75.8438 84.714C83.1845 83.1098 84.2367 80.4219 84.2367 80.4219C84.3092 80.6235 84.3777 80.829 84.4501 81.0385C84.4521 81.0377 87.7256 89.4709 79.5059 92.2442Z"
                fill="#F2C5A7"
              />
              <path
                d="M62.5545 98.8404C69.5747 98.7581 76.7454 98.8199 83.7657 98.788C81.4845 93.9603 78.5505 88.6932 75.5558 85.2744C75.5558 85.2744 58.8549 93.8568 32.1289 93.643C32.1289 93.643 32.6593 96.0285 33.2047 98.788C42.9704 98.788 52.8061 98.9546 62.5545 98.8404Z"
                fill="#02285E"
              />
              <path
                d="M74.3385 83.11C74.3385 83.11 68.1145 88.9044 53.058 91.64L53.034 91.6439C37.9618 94.3811 31.1936 91.4222 31.1936 91.4222C28.3072 77.4585 31.3345 69.3479 31.3345 69.3479C31.3345 69.3479 32.8463 65.72 47.8299 62.999C62.8135 60.278 62.8296 59.8882 62.8296 59.8882C62.8296 59.8882 66.6953 65.0467 68.3842 68.2729C70.0936 71.5207 71.1859 76.3036 74.3385 83.11Z"
                fill="#02285E"
              />
              <path
                d="M43.4077 69.8597L49.8172 74.2133L53.8885 67.9976L43.4077 69.8597Z"
                fill="#000072"
              />
              <path
                d="M52.8285 89.2736C65.1312 87.0401 72.98 80.3164 72.98 80.3164C72.98 80.3164 74.4834 82.2514 75.6281 85.278C75.6281 85.278 68.4327 91.9293 53.7916 94.5857C39.1506 97.2421 32.2012 94.2916 32.2012 94.2916C30.2906 91.3819 30.6974 88.2742 30.6974 88.2742C30.6974 88.2742 40.5214 91.5068 52.8285 89.2736Z"
                fill="#EB2701"
              />
              <g opacity="0.3">
                <path
                  d="M74.4592 82.7109C74.4592 82.7109 67.8926 89.35 53.2515 92.0064C38.6105 94.6629 31.0563 91.0614 31.0563 91.0614C30.7338 90.5695 31.1126 92.8751 32.1325 94.4313C32.1325 94.4313 39.0819 97.2409 53.7229 94.5845C68.3639 91.9281 75.624 85.2768 75.624 85.2768C75.5637 84.9665 74.6809 83.1299 74.4592 82.7109Z"
                  fill="#000072"
                />
              </g>
              <path
                d="M51.371 98.8055C50.9343 97.795 50.2917 96.635 49.9341 96.0376L46.6323 97.4324C46.6323 97.4324 46.8859 98.0675 47.1356 98.8055C48.5095 98.7803 49.9987 98.8275 51.371 98.8055Z"
                fill="#EB2701"
              />
              <g opacity="0.3">
                <path
                  d="M48.2008 97.0617C48.437 97.4555 48.7942 98.2135 49.0694 98.8054C49.7952 98.7928 50.628 98.8089 51.3533 98.7967C50.9353 97.8513 50.4627 96.931 49.9381 96.0403L46.6304 97.4307L46.7194 97.6847L48.2008 97.0617Z"
                  fill="#000072"
                />
              </g>
              <path
                d="M60.4659 98.8055C56.9368 96.7689 53.6106 94.7075 53.6106 94.7075L51.5303 97.6215C51.8059 97.8825 52.3482 98.485 52.7537 98.8052C55.1644 98.7674 58.0559 98.8367 60.4659 98.8055Z"
                fill="#EB2701"
              />
              <g opacity="0.3">
                <path
                  d="M56.219 98.7823C54.9939 97.9219 53.5574 96.7741 52.9089 96.1546L53.8402 94.8485L53.6106 94.7075L51.5303 97.6262C51.8028 97.8861 52.3151 98.487 52.7187 98.8075C53.8205 98.7882 55.1172 98.7985 56.219 98.7823Z"
                  fill="#000072"
                />
              </g>
              <path
                d="M52.9491 89.491C54.5494 89.7533 55.8032 91.0389 55.9886 92.6515C56.2221 94.6751 55.4685 97.3721 50.0989 98.0009C46.5474 98.416 44.7499 97.4847 43.8709 96.223C41.9241 93.4213 44.5321 89.6324 47.8174 90.5676C48.0268 90.6286 48.2317 90.7044 48.4305 90.7944C48.4305 90.7893 50.1237 89.0275 52.9491 89.491Z"
                fill="#EB2701"
              />
              <g opacity="0.3">
                <path
                  d="M49.7488 94.9976C46.1973 95.4131 44.3993 94.4818 43.5204 93.2201C43.4724 93.1473 43.4279 93.0787 43.3834 93.0063C43.2076 93.5433 43.1602 94.114 43.245 94.6727C43.3297 95.2313 43.5442 95.7623 43.8713 96.2231C44.7502 97.4831 46.549 98.416 50.0993 98.001C55.4688 97.3709 56.2225 94.6712 55.989 92.6515C55.9336 92.1823 55.7856 91.7288 55.5535 91.3174C55.1664 92.9338 53.7192 94.5302 49.7488 94.9976Z"
                  fill="#000072"
                />
              </g>
              <g opacity="0.11">
                <path
                  d="M47.9832 91.9664C48.1924 92.0271 48.3972 92.1025 48.5959 92.1921C48.5959 92.1921 50.2848 90.4264 53.1148 90.8902C53.7884 91.0003 54.4178 91.2963 54.9321 91.7451C55.4463 92.1938 55.825 92.7773 56.0253 93.4297C56.0324 93.1686 56.0216 92.9073 55.993 92.6477C55.8075 91.0391 54.5537 89.7495 52.9534 89.4872C50.1277 89.0237 48.4344 90.7894 48.4344 90.7894C48.2365 90.6979 48.0316 90.6224 47.8217 90.5634C44.9113 89.7333 42.5329 92.6193 43.3878 95.2356C43.5571 93.1111 45.5886 91.2852 47.9832 91.9664Z"
                  fill="white"
                />
              </g>
              <path
                d="M56.1741 75.999C55.7964 75.9993 55.4206 75.9463 55.0577 75.8415C54.1467 75.5845 53.3529 75.0195 52.8117 74.2429C52.2705 73.4662 52.0154 72.5259 52.0898 71.5822C52.1642 70.6385 52.5636 69.7498 53.2199 69.0675C53.8761 68.3853 54.7486 67.9517 55.6887 67.8407C56.6288 67.7297 57.5783 67.9482 58.3754 68.4588C59.1725 68.9694 59.7679 69.7406 60.0601 70.6411C60.3523 71.5415 60.3232 72.5153 59.9778 73.3967C59.6323 74.2781 58.992 75.0124 58.1658 75.4745C57.558 75.8176 56.872 75.9982 56.1741 75.999ZM56.1662 69.4324C55.7882 69.4333 55.4155 69.5207 55.0765 69.6879C54.7375 69.8552 54.4413 70.0978 54.2107 70.3973C53.98 70.6968 53.821 71.0451 53.7459 71.4155C53.6707 71.786 53.6814 72.1687 53.7771 72.5344C53.8728 72.9 54.051 73.2389 54.298 73.5251C54.545 73.8112 54.8542 74.037 55.202 74.1851C55.5497 74.3332 55.9268 74.3997 56.3043 74.3794C56.6817 74.3592 57.0495 74.2528 57.3795 74.0684C57.8584 73.801 58.235 73.3822 58.45 72.8776C58.665 72.373 58.7064 71.8113 58.5675 71.2807C58.4287 70.7501 58.1175 70.2806 57.6828 69.9461C57.2482 69.6115 56.7147 69.4309 56.1662 69.4324Z"
                fill="#000072"
              />
              <path
                d="M15.4759 42.17C15.4759 42.17 16.7903 46.4673 12.3359 49.3132C7.88148 52.159 3.41094 50.6996 3.41094 50.6996L1.55664 44.2137C1.55664 44.2137 5.87401 44.9879 8.05868 44.2137C10.2434 43.4396 13.9886 38.55 13.9886 38.55"
                fill="#EB2701"
              />
              <path
                d="M52.4697 68.2354C45.6653 70.2062 34.104 71.1537 30.2784 69.6018C24.384 67.7585 20.2934 63.0651 18.3383 59.6247C16.4557 56.311 10.4132 43.5326 17.4271 31.0806C23.982 19.4421 43.7842 10.4612 60.3622 27.1601C64.7922 32.4895 68.1058 46.8096 65.5664 54.858C63.918 60.0842 59.0765 66.3244 52.4697 68.2354Z"
                fill="#02285E"
              />
              <g opacity="0.5">
                <path
                  d="M14.71 60.3665C14.7261 60.298 14.7423 60.2295 14.7627 60.161L14.7423 60.0881C14.7304 60.1811 14.7233 60.2736 14.71 60.3665Z"
                  fill="white"
                />
              </g>
              <path
                d="M26.3883 43.2787C24.0461 44.1655 22.5951 46.4108 22.8089 48.7892C23.1314 52.4214 25.3243 57.5409 28.7943 59.3223C33.5551 61.7693 37.2999 54.9286 45.963 53.6913C54.932 52.4096 55.92 54.8203 60.6926 52.0768C63.5829 50.4163 62.563 37.8513 59.2655 36.8314C54.3193 35.2996 52.3563 40.1891 43.7661 41.7815C38.0221 42.8392 30.2702 41.8087 26.3883 43.2787Z"
                fill="#FFF6EB"
                stroke="#EB2701"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M14.2749 38.9009C12.0052 35.579 8.7077 36.4662 6.02332 37.0222C3.33894 37.5782 0.778607 34.5552 0.778607 34.5552C-0.591737 36.4378 1.0003 43.093 1.0003 43.093C1.0003 43.093 7.47793 41.549 9.1318 41.5652C10.7857 41.5813 14.1828 43.3876 14.1828 43.3876L14.445 43.2947"
                fill="#EB2701"
              />
              <path
                d="M63.4945 33.3622C63.4945 33.3622 36.3609 42.9845 13.6029 43.5217C13.6029 43.5217 11.9463 43.6004 11.9424 40.6597C11.9384 37.719 15.4817 37.5677 15.4817 37.5677C15.4817 37.5677 34.4802 35.6048 41.2079 34.1899C47.9356 32.7751 61.0251 28.7802 61.4685 28.7881C62.4262 28.8031 63.3736 31.5201 63.4945 33.3622Z"
                fill="#EB2701"
              />
              <path
                d="M43.4355 45.5239C41.6498 48.1847 38.9047 49.95 35.8356 50.4178C33.0501 50.6718 31.6553 50.087 29.8774 47.7937C28.3232 45.1692 28.4267 44.4233 28.4267 44.4233L43.4355 45.5239Z"
                fill="#EB2701"
              />
              <path
                d="M57.2746 44.1653C56.2587 46.0318 54.2917 47.084 52.2559 46.8462C50.0791 46.7674 47.9708 46.0078 46.1973 44.6654L57.1214 38.0283C57.1214 38.0283 57.7908 43.0005 57.2746 44.1653Z"
                fill="#EB2701"
              />
              <path
                d="M69.3911 75.4691C65.4277 76.6134 61.1041 75.7546 58.1456 72.8308C53.7322 68.4634 54.011 61.1356 58.7698 56.4824L68.1495 47.3215L71.4179 50.554L62.0381 59.7157C59.2234 62.4682 59.0572 66.8084 61.6676 69.388C62.9347 70.641 64.6567 71.2986 66.5157 71.2388C68.3748 71.1789 70.1507 70.4169 71.5167 69.0848L80.7642 60.0582L84.0325 63.2908L74.7851 72.3173C73.2737 73.7956 71.421 74.8782 69.3911 75.4691Z"
                fill="#FF735D"
              />
              <path
                d="M76.2832 64.4291L79.5622 67.6482L84.0308 63.2851L80.7577 60.0542L76.2832 64.4291Z"
                fill="white"
              />
              <path
                d="M66.7726 55.0853L71.416 50.5474L68.1493 47.3196L63.4937 51.8661L66.7726 55.0853Z"
                fill="white"
              />
              <g opacity="0.3">
                <path
                  d="M69.3911 75.4691C65.4277 76.6134 61.1041 75.7546 58.1456 72.8308C53.7322 68.4634 54.011 61.1356 58.7698 56.4824L68.1495 47.3215L71.4179 50.554L62.0381 59.7157C59.2234 62.4682 59.0572 66.8084 61.6676 69.388C62.9347 70.641 64.6567 71.2986 66.5157 71.2388C68.3748 71.1789 70.1507 70.4169 71.5167 69.0848L80.7642 60.0582L84.0325 63.2908L74.7851 72.3173C73.2737 73.7956 71.421 74.8782 69.3911 75.4691Z"
                  fill="#6A0400"
                />
              </g>
              <path
                d="M67.9823 74.0848C64.0189 75.2292 59.6953 74.3703 56.7368 71.4465C52.3187 67.0807 52.6022 59.7494 57.361 55.0977L66.7415 45.9353L70.0099 49.1678L60.6301 58.3298C57.8154 61.0819 57.6492 65.4225 60.2596 68.0018C61.5267 69.2548 63.2483 69.9124 65.1077 69.8525C66.9672 69.7927 68.7423 69.0303 70.1087 67.6982L79.3558 58.6716L82.6241 61.9041L73.3763 70.9323C71.8651 72.411 70.0123 73.4939 67.9823 74.0848Z"
                fill="#FF2200"
              />
              <path
                d="M74.8682 63.0273L78.1471 66.2464L82.6204 61.8822L79.3521 58.6497L74.8682 63.0273Z"
                fill="white"
              />
              <path
                d="M65.3654 53.7058L70.0132 49.1664L66.7448 45.9338L62.0845 50.4824L65.3654 53.7058Z"
                fill="white"
              />
              <path
                d="M63.7769 71.4439C63.7848 74.6768 61.8904 77.781 58.7539 79.1919C57.48 79.764 56.166 80.3126 54.8398 80.8363C46.8906 83.9865 38.3161 86.2866 33.5676 87.4557C31.7039 87.917 29.7525 87.887 27.9039 87.3687C26.7657 87.0516 25.692 86.5373 24.7316 85.8491C24.1489 85.4322 23.6147 84.9513 23.1392 84.4153C18.0279 78.6878 25.7491 67.1994 25.7491 67.1994C25.7491 67.1994 26.6942 68.0736 30.7895 69.5436C34.9635 71.0399 37.2998 70.6989 37.2998 70.6989C42.6776 68.3043 48.4303 66.8324 48.4303 66.8324C50.5177 62.3638 54.3767 65.2778 54.3767 65.2778C54.3767 65.2778 60.3345 62.5568 62.8823 67.7405C62.9346 67.8495 62.987 67.9543 63.0355 68.063V68.0712C63.5216 69.1293 63.7745 70.2795 63.7769 71.4439Z"
                fill="#01254F"
              />
              <path
                d="M58.7577 79.1917C57.4843 79.7639 56.1698 80.3124 54.8436 80.8361C54.9223 70.7424 48.4341 66.8322 48.4341 66.8322C50.5211 62.3617 54.3801 65.2764 54.3801 65.2764C54.3801 65.2764 60.338 62.5554 62.8857 67.7391C62.9381 67.8482 62.9904 67.9529 63.0389 68.0616C64.9617 72.2904 62.9948 77.2878 58.7577 79.1917Z"
                fill="#F2C5A7"
              />
              <path
                d="M75.044 30.4641C77.3247 28.1834 77.3247 24.4856 75.044 22.2049C72.7633 19.9242 69.0656 19.9242 66.7848 22.2049C64.5041 24.4856 64.5042 28.1833 66.7849 30.4641C69.0656 32.7448 72.7633 32.7448 75.044 30.4641Z"
                fill="#00235F"
              />
              <path
                d="M71.5473 26.5091C72.1494 26.2646 72.5818 25.6535 72.5948 24.9246C72.6078 23.9685 71.8966 23.1872 71.0016 23.1742C70.1065 23.1612 69.3694 23.9224 69.356 24.8765C69.343 25.6097 69.7663 26.2425 70.3688 26.5001C67.8722 26.8494 67.9549 29.5558 67.9549 29.5558L73.8474 29.6471C73.8517 29.6471 74.0179 26.9104 71.5473 26.5091Z"
                fill="white"
              />
              <path
                d="M90.3501 44.604C95.1807 44.604 99.0967 40.688 99.0967 35.8574C99.0967 31.0268 95.1807 27.1108 90.3501 27.1108C85.5195 27.1108 81.6035 31.0268 81.6035 35.8574C81.6035 40.688 85.5195 44.604 90.3501 44.604Z"
                fill="#00235F"
              />
              <path
                d="M91.2984 36.1244C92.1997 35.7578 92.8498 34.8407 92.8652 33.7503C92.8865 32.3249 91.8174 31.1459 90.4809 31.125C89.1444 31.1042 88.0395 32.2465 88.0178 33.672C88.0021 34.7746 88.636 35.7157 89.5374 36.1036C85.8008 36.6226 85.9213 40.684 85.9213 40.684L94.7467 40.8202C94.7415 40.8202 94.9932 36.7218 91.2984 36.1244Z"
                fill="white"
              />
              <path
                d="M79.6307 47.9427C81.7367 45.8367 81.7367 42.4221 79.6307 40.3161C77.5247 38.2101 74.1102 38.2101 72.0042 40.3161C69.8982 42.4221 69.8982 45.8367 72.0042 47.9427C74.1102 50.0487 77.5247 50.0487 79.6307 47.9427Z"
                fill="#00235F"
              />
              <path
                d="M76.4048 44.2917C76.96 44.0664 77.3585 43.5006 77.3688 42.8245C77.3794 41.944 76.7242 41.2206 75.8964 41.2048C75.0687 41.1891 74.3922 41.8967 74.3765 42.7772C74.3658 43.4533 74.7537 44.0349 75.3144 44.2759C73.0085 44.5957 73.0872 47.1009 73.0872 47.1009L78.5273 47.1847C78.5273 47.1847 78.6793 44.6587 76.4048 44.2917Z"
                fill="white"
              />
              <path
                d="M89.2351 59.9011C91.9751 59.9011 94.1963 57.6799 94.1963 54.94C94.1963 52.2 91.9751 49.9788 89.2351 49.9788C86.4951 49.9788 84.2739 52.2 84.2739 54.94C84.2739 57.6799 86.4951 59.9011 89.2351 59.9011Z"
                fill="#00235F"
              />
              <path
                d="M89.7729 55.0853C90.0367 54.9721 90.2615 54.7839 90.4194 54.544C90.5772 54.3042 90.6612 54.0233 90.6608 53.7362C90.6695 52.925 90.0631 52.2588 89.3031 52.2501C88.5431 52.2414 87.9198 52.8801 87.9028 53.6933C87.8955 53.9829 87.9733 54.2684 88.1266 54.5143C88.2798 54.7602 88.5019 54.9557 88.7652 55.0766C86.6475 55.3755 86.7176 57.6728 86.7176 57.6728L91.7213 57.7515C91.7284 57.7567 91.8642 55.4267 89.7729 55.0853Z"
                fill="white"
              />
              <path
                d="M88.8475 22.8988C91.3007 20.4455 91.3007 16.468 88.8475 14.0148C86.3942 11.5616 82.4167 11.5616 79.9635 14.0148C77.5102 16.468 77.5102 20.4455 79.9635 22.8988C82.4167 25.352 86.3942 25.352 88.8475 22.8988Z"
                fill="#00235F"
              />
              <path
                d="M85.0892 18.6459C85.7366 18.3837 86.2005 17.7241 86.2127 16.9365C86.2249 15.9127 85.4617 15.0685 84.4974 15.0503C83.533 15.0322 82.7451 15.856 82.7254 16.8818C82.7132 17.6694 83.1648 18.3466 83.8181 18.6278C81.1318 18.9999 81.2235 21.9182 81.2235 21.9182L87.5602 22.0159C87.5602 22.0159 87.7389 19.0732 85.0892 18.6459Z"
                fill="white"
              />
              <path
                d="M72.0002 16.3659C73.9695 16.3659 75.5659 14.7695 75.5659 12.8003C75.5659 10.831 73.9695 9.23462 72.0002 9.23462C70.031 9.23462 68.4346 10.831 68.4346 12.8003C68.4346 14.7695 70.031 16.3659 72.0002 16.3659Z"
                fill="#00235F"
              />
              <path
                d="M72.3871 12.9054C72.5768 12.824 72.7384 12.6887 72.8519 12.5163C72.9654 12.3438 73.0257 12.1419 73.0254 11.9355C73.0317 11.3527 72.5958 10.8723 72.0496 10.868C71.5035 10.8636 71.0553 11.322 71.0431 11.9048C71.0379 12.1129 71.0939 12.3181 71.2043 12.4947C71.3146 12.6713 71.4744 12.8116 71.6637 12.8983C70.1418 13.1133 70.1906 14.764 70.1906 14.764L73.7874 14.8195C73.7925 14.8254 73.8909 13.1499 72.3871 12.9054Z"
                fill="white"
              />
              <path
                d="M97.8119 23.0464C100.002 23.0464 101.778 21.2709 101.778 19.0807C101.778 16.8905 100.002 15.115 97.8119 15.115C95.6217 15.115 93.8462 16.8905 93.8462 19.0807C93.8462 21.2709 95.6217 23.0464 97.8119 23.0464Z"
                fill="#00235F"
              />
              <path
                d="M98.2435 19.1967C98.4542 19.106 98.6337 18.9554 98.7597 18.7637C98.8857 18.572 98.9527 18.3475 98.9523 18.1181C98.959 17.4696 98.4743 16.9368 97.8667 16.9305C97.2591 16.9242 96.7609 17.4357 96.7476 18.0839C96.7416 18.3154 96.8037 18.5435 96.9262 18.7401C97.0487 18.9366 97.2262 19.0929 97.4367 19.1896C95.7434 19.4286 95.7985 21.2648 95.7985 21.2648L99.7985 21.3262C99.8048 21.3321 99.9143 19.4696 98.2435 19.1967Z"
                fill="white"
              />
              <path
                d="M91.4506 10.6164C94.3048 10.6164 96.6186 8.30261 96.6186 5.44845C96.6186 2.59428 94.3048 0.280518 91.4506 0.280518C88.5965 0.280518 86.2827 2.59428 86.2827 5.44845C86.2827 8.30261 88.5965 10.6164 91.4506 10.6164Z"
                fill="#00235F"
              />
              <path
                d="M92.0114 5.59966C92.2862 5.48165 92.5203 5.28558 92.6847 5.03575C92.8492 4.78593 92.9367 4.49334 92.9364 4.19427C92.9454 3.34922 92.3138 2.65539 91.5223 2.64633C90.7308 2.63727 90.0811 3.30473 90.0653 4.14977C90.0578 4.45145 90.1388 4.74873 90.2985 5.00481C90.4581 5.2609 90.6893 5.46456 90.9635 5.5906C88.7584 5.90208 88.8289 8.29467 88.8289 8.29467L94.0413 8.37342C94.0484 8.38366 94.1905 5.95524 92.0114 5.59966Z"
                fill="white"
              />
            </svg>
          }
          furtherInfo="Idle contacts are those who have not engaged in any marketing activities for a defined period. The numbers show varying levels of inactivity across campaigns, highlighting which campaigns have generated leads that have become idle."
        />
        <CreateStrategyBox
          value={`${formatNumberStandard(keyFindings?.totalMqls)}`}
          valueLabel="Total MQLs"
          createBtnText="Create Outreach Campaign"
          warningText={`${keyFindings?.dormantMqlsPercentage}% dormant`}
          icon={
            <svg
              width="102"
              height="99"
              viewBox="0 0 102 99"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.25">
                <path
                  d="M63.811 89.1712C82.1253 89.1712 96.972 87.4686 96.972 85.3684C96.972 83.2682 82.1253 81.5657 63.811 81.5657C45.4966 81.5657 30.6499 83.2682 30.6499 85.3684C30.6499 87.4686 45.4966 89.1712 63.811 89.1712Z"
                  fill="#EB2701"
                />
              </g>
              <g opacity="0.5">
                <path
                  d="M30.5702 98.8798C30.5364 98.8798 30.5026 98.8758 30.4697 98.8679L15.1657 95.16L0.675732 97.0469C0.588388 97.0578 0.499813 97.0413 0.422223 96.9998C0.344634 96.9582 0.281851 96.8936 0.242536 96.8148C0.20322 96.7361 0.189309 96.6471 0.202719 96.5601C0.21613 96.4731 0.256202 96.3924 0.317409 96.3291L6.28313 90.2309L3.95794 84.8687C3.92765 84.799 3.91662 84.7224 3.92599 84.6469C3.93536 84.5715 3.9648 84.4999 4.01123 84.4397C4.05766 84.3794 4.11939 84.3328 4.18999 84.3045C4.26059 84.2762 4.33747 84.2674 4.41263 84.279L15.7242 86.0278L28.7994 83.6036C28.8799 83.5889 28.963 83.5977 29.0387 83.629C29.1143 83.6603 29.1793 83.7128 29.2259 83.7801C29.2724 83.8475 29.2985 83.9268 29.3011 84.0086C29.3037 84.0904 29.2826 84.1713 29.2404 84.2414L25.5861 90.2466L30.9233 98.219C30.9661 98.283 30.9907 98.3575 30.9945 98.4345C30.9982 98.5115 30.981 98.5881 30.9446 98.656C30.9082 98.7239 30.8541 98.7807 30.7879 98.8203C30.7218 98.8598 30.6462 98.8807 30.5691 98.8805L30.5702 98.8798ZM15.1891 94.3031C15.2228 94.3033 15.2564 94.3074 15.2892 94.3154L29.6098 97.7847L24.7284 90.494C24.6834 90.4266 24.6586 90.3478 24.6568 90.2668C24.6551 90.1857 24.6766 90.1059 24.7187 90.0367L28.018 84.6153L15.809 86.8799C15.7622 86.8883 15.7143 86.889 15.6672 86.8821L5.04593 85.2382L7.17652 90.1547C7.21022 90.2322 7.22004 90.318 7.20473 90.4011C7.18942 90.4842 7.14967 90.5608 7.09057 90.6213L1.78382 96.0452L15.134 94.3064C15.1523 94.3039 15.1707 94.3027 15.1891 94.3027V94.3031Z"
                  fill="#EB2701"
                />
                <path
                  d="M15.8045 91.9625C13.3815 91.9625 9.28027 91.5803 9.28027 90.1489C9.28027 88.7175 13.3815 88.335 15.8045 88.335C18.2276 88.335 22.3287 88.7171 22.3287 90.1489C22.3287 91.5807 18.2276 91.9625 15.8045 91.9625ZM10.1446 90.1489C10.4155 90.5102 12.4408 91.1119 15.8045 91.1119C19.1682 91.1119 21.1935 90.5095 21.4644 90.1489C21.1935 89.7869 19.1682 89.1852 15.8045 89.1852C12.4408 89.1852 10.4155 89.7869 10.1446 90.1489Z"
                  fill="#EB2701"
                />
              </g>
              <path
                d="M24.9578 87.1501L31.9252 55.6549L30.7959 55.1801L29.802 57.2128L27.8088 54.6833L26.9116 55.9031L29.2506 59.3754L23.4951 86.4386L24.9578 87.1501Z"
                fill="#AF5528"
              />
              <path
                d="M41.174 77.7549C41.174 77.7549 38.4976 80.4987 36.8034 81.6507C35.1093 82.8027 35.8754 85.3466 35.8754 85.3466L54.285 85.5569C54.9964 84.3033 54.6236 77.9242 54.6236 77.9242C47.8255 78.1277 41.174 77.7549 41.174 77.7549Z"
                fill="#02285E"
              />
              <path
                d="M87.7188 85.0111C87.7188 85.0111 92.4651 90.5552 94.166 86.7733C95.2004 84.4756 95.93 75.8443 95.363 73.1038C95.1126 71.893 90.4514 72.5371 90.4514 72.5371C90.4514 72.5371 92.0863 81.9034 87.7188 85.0111Z"
                fill="#02285E"
              />
              <path
                d="M27.2495 61.6131C26.1823 62.3074 26.1313 64.1876 26.3683 65.4084C26.6054 66.6292 28.1641 66.4246 28.1641 66.4246C27.6219 66.7635 27.8094 67.7124 28.7062 68C29.5166 68.2605 33.3342 67.5535 34.0702 67.4143C33.8578 65.8411 33.0782 61.2585 33.0782 61.2585C31.6874 60.8663 28.3162 60.9173 27.2495 61.6131Z"
                fill="#F2C5A7"
              />
              <path
                d="M34.0766 67.4499C34.0757 67.4372 34.0739 67.4246 34.0713 67.4121C34.0731 67.4242 34.0752 67.4378 34.0766 67.4499Z"
                fill="#3E4049"
              />
              <path
                d="M96.2495 42.5773C93.4037 38.7377 75.834 36.7571 75.834 36.7571C73.6923 40.2845 72.8104 40.5364 65.5662 44.19C56.4102 48.808 48.3693 46.8988 48.3693 46.8988C45.6902 46.9966 40.1915 54.3089 38.2607 56.6129C36.3299 58.9169 33.0786 61.1919 33.0786 61.1919C33.0786 61.1919 33.8581 65.8359 34.0706 67.4102L34.1949 67.3861V67.6912C38.6667 67.2848 45.4086 64.6772 45.4086 64.6772L45.2054 65.7276C45.2054 65.7276 42.2238 68.0662 41.3431 71.1821C40.4624 74.298 41.1738 77.7547 41.1738 77.7547C41.1738 77.7547 47.8253 78.1268 54.6234 77.924L55.5383 76.9756C59.9763 78.0256 67.9048 76.3318 67.9048 76.3318C67.9048 76.3318 69.4638 79.6394 73.6298 81.6721C77.7957 83.7048 80.4959 84.1279 80.4959 84.1279C80.8769 84.0218 83.8682 83.8302 84.9052 84.0021C86.0215 84.1882 87.4808 85.3416 88.0549 84.8843C92.0865 81.6713 90.449 72.5373 90.449 72.5373C90.449 72.5373 86.6488 71.4229 85.4883 70.9782C84.09 70.4428 81.7555 68.5224 81.7555 66.6791C81.7555 57.8881 78.9841 46.8995 78.9841 46.8995C84.0866 45.8286 90.9528 50.3644 90.9528 50.3644L91.7714 48.7027L95.9328 43.0283L96.2495 42.5773Z"
                fill="#02285E"
              />
              <path
                d="M99.8181 46.1913C98.192 44.4295 95.9334 43.0286 95.9334 43.0286L91.772 48.7029L92.0094 49.0081C92.9578 50.9213 94.3297 52.633 94.9225 52.5652C95.5152 52.4975 95.3969 49.584 95.5152 49.584C95.6335 49.584 96.9723 49.4884 96.9723 49.4884C96.9723 49.4884 98.7342 51.7012 99.4117 51.6093C100.089 51.5174 99.773 50.1638 99.773 50.1638C99.773 50.1638 100.541 51.2931 101.444 50.7963C102.347 50.2996 101.444 47.9528 99.8181 46.1913Z"
                fill="#F2C5A7"
              />
              <path
                d="M60.9368 49.9341C60.9368 49.9341 57.6996 47.6309 57.377 47.3715C57.0544 47.1122 56.4873 46.6393 56.4873 46.6393L56.7329 46.2623C56.7329 46.2623 59.8458 45.933 61.7621 45.4876C63.6783 45.0423 64.9992 44.6318 64.9992 44.6318C64.9862 44.8023 64.9862 44.9734 64.9992 45.1438C65.0212 45.427 61.9756 50.7423 61.9756 50.7423L61.2199 50.2054L60.9368 49.9341Z"
                fill="#F2C5A7"
              />
              <path
                d="M46.1203 62.8168C46.1203 62.8168 53.0199 66.473 62.9577 64.892C72.8955 63.311 77.5347 61.7493 80.8575 56.5698C80.8575 56.5698 81.4718 60.7744 81.5645 62.7584C81.5645 62.7584 77.0216 68.7037 59.879 69.0423C52.2884 69.3135 46.06 67.3106 45.1919 66.4514C45.1938 66.4499 45.1714 64.412 46.1203 62.8168Z"
                fill="#EB2701"
              />
              <path
                d="M45.3662 54.0765C45.3673 54.0708 45.3695 54.0654 45.3728 54.0606C45.376 54.0558 45.3801 54.0516 45.3849 54.0484C45.3897 54.0452 45.3951 54.043 45.4008 54.0419C45.4064 54.0407 45.4123 54.0407 45.4179 54.0419C45.4254 54.0434 45.4324 54.0468 45.4382 54.0519C45.444 54.0569 45.4485 54.0633 45.451 54.0705C45.835 55.3059 46.0323 56.5434 46.3303 57.7914C46.6242 59.0171 47.5467 59.9678 47.9121 61.1693C47.9608 61.3255 47.534 62.3346 47.534 62.3346L48.4159 63.7519L46.1011 62.8217L46.3511 61.7348C46.1867 61.1001 46.0363 60.4787 45.9046 59.8454"
                fill="#00284C"
              />
              <path
                d="M72.7775 60.3699C72.7419 60.3699 72.7063 60.3661 72.6715 60.3587C61.13 57.9029 51.6595 47.5636 51.7864 47.5778C55.4876 47.9871 56.9213 46.9586 57.1099 47.1685C57.1933 47.2619 68.0419 56.2475 79.2519 58.6333C79.5272 58.6917 77.2675 60.4443 76.984 60.7278C76.8099 60.9008 73.0119 60.3699 72.7775 60.3699Z"
                fill="#00284C"
              />
              <path
                d="M61.8914 51.0564C61.7998 51.0563 61.7099 51.0314 61.6312 50.9845C61.5524 50.9376 61.4877 50.8704 61.4439 50.7899C61.4001 50.7094 61.3788 50.6186 61.3822 50.5271C61.3856 50.4355 61.4135 50.3465 61.4632 50.2694L65.2362 44.4317C65.2725 44.3752 65.3197 44.3264 65.3749 44.2882C65.4302 44.25 65.4924 44.223 65.5581 44.2089C65.6238 44.1948 65.6916 44.1938 65.7577 44.206C65.8238 44.2182 65.8868 44.2433 65.9431 44.2799C66.1798 44.4328 70.3814 42.348 70.2307 42.5847L64.8432 52.5377C64.7435 52.6895 62.0589 51.0564 61.8914 51.0564Z"
                fill="#00284C"
              />
              <path
                d="M55.8937 65.3455C55.8937 65.3455 56.9356 64.2687 58.5776 64.1273C60.2197 63.9859 61.2786 65.1342 61.2786 65.1342C61.2786 65.1342 62.9381 65.7344 64.367 67.747C65.7958 69.7596 66.3971 72.1786 67.0683 72.8137C67.7396 73.4489 68.569 73.7849 68.569 73.7849C68.569 73.7849 67.7746 78.4264 64.4388 79.1877C64.4388 79.1877 63.0438 78.234 62.5143 75.8329C61.9849 73.4318 61.3664 70.3877 60.2896 69.7202C60.2896 69.7202 59.2106 71.1713 57.8338 70.5351C57.8338 70.5351 55.5559 72.0711 53.6493 70.1808C53.6493 70.1808 53.6668 72.1228 54.4616 73.9416C55.2563 75.7603 56.898 78.1856 56.898 78.1856L52.7514 79.7339C52.7514 79.7339 50.6123 77.9266 49.7576 73.469C48.9029 69.0114 51.4465 65.401 51.4465 65.401C51.4465 65.401 53.2579 63.0687 55.8937 65.3455Z"
                fill="#EB2701"
              />
              <g opacity="0.25">
                <path
                  d="M67.2151 72.9434C67.2151 72.9434 66.7898 75.2504 65.6412 75.2132C64.4926 75.1759 64.1577 73.7125 63.6107 72.0173C63.0637 70.322 62.5123 69.899 62.4092 69.2456C62.3062 68.5922 62.9406 67.2504 62.6396 66.0676C62.6396 66.0676 62.4323 65.7208 61.9326 65.4592C61.4329 65.1976 61.0024 65.0264 60.9629 64.9275C60.9235 64.8285 60.5376 64.5732 60.5376 64.5732C60.5376 64.5732 60.6611 66.9048 59.533 67.6463C58.4048 68.3879 57.7097 68.13 57.6617 68.0582C57.6137 67.9864 56.826 66.587 56.826 66.587C56.826 66.587 56.8848 69.2002 55.4608 69.1057C54.0368 69.0112 51.8977 67.4141 51.8977 67.4141L51.5036 66.7637C51.5036 66.7637 51.3035 67.8 51.5978 68.7295C51.8921 69.659 52.2921 70.2833 52.3509 70.5367C52.4097 70.7901 52.304 71.6136 53.0456 73.9559C53.7872 76.2982 53.9989 77.1339 53.2227 77.5811C52.4465 78.0284 51.3276 77.7229 51.3276 77.7229C51.3276 77.7229 52.3371 79.6697 52.6672 79.6578C52.9972 79.6459 56.7531 78.2438 56.7531 78.2438L56.8171 78.0615C56.8171 78.0615 55.0716 75.4941 54.3185 73.5838C53.5654 71.6735 53.7414 70.7425 53.7514 70.6837C53.7615 70.6249 53.7868 70.3946 53.7868 70.3946C53.7868 70.3946 54.6578 71.154 55.8936 71.125C57.1293 71.096 57.9401 70.5367 57.9401 70.5367C57.9401 70.5367 58.7151 70.9297 59.3332 70.5044C59.9512 70.0791 60.2433 69.7765 60.2433 69.7765L60.5186 69.906C60.5186 69.906 61.3744 70.9081 61.9047 73.1908C62.4349 75.4736 62.4714 76.5958 63.3004 77.8613C64.1294 79.1268 64.4769 79.1785 64.4769 79.1785C64.4769 79.1785 66.0739 78.7692 67.1035 77.298C68.1331 75.8267 68.6905 73.9756 68.5547 73.848C68.4189 73.7203 67.8164 73.391 67.7361 73.3371C67.6557 73.2831 67.2151 72.9434 67.2151 72.9434Z"
                  fill="#C91102"
                />
              </g>
              <path
                d="M29.7373 65.5443L31.9252 55.6549L30.7959 55.1801L29.802 57.2128L27.8087 54.6833L26.9116 55.9031L29.2506 59.3754L27.7503 66.4288L29.7373 65.5443Z"
                fill="#AF5528"
              />
              <path
                d="M74.5079 12.1401C74.5079 12.1401 75.2055 16.0284 79.8336 16.463C84.4616 16.8976 87.3684 13.9581 87.3684 13.9581L86.2149 8.14233C86.2149 8.14233 83.1497 10.4809 81.1314 10.7552C79.1132 11.0294 74.2188 8.71349 74.2188 8.71349"
                fill="#EB2701"
              />
              <path
                d="M56.0586 47.3812C62.1705 46.1875 71.59 42.2861 73.9584 39.5345C77.1033 35.8791 79.1382 30.4131 79.2852 26.9341C79.427 23.5875 79.0151 11.1701 68.5281 4.25331C58.7276 -2.21027 39.639 -1.27856 33.3853 18.4381C32.0644 24.3845 35.2241 36.9105 40.4449 42.1816C43.8343 45.6055 50.1267 48.5406 56.0586 47.3812Z"
                fill="#02285E"
              />
              <g opacity="0.5">
                <path
                  d="M82.4183 26.0593L82.2951 25.9175L82.2832 25.8513C82.3312 25.9209 82.374 25.9901 82.4183 26.0593Z"
                  fill="white"
                />
              </g>
              <path
                d="M66.424 17.3884C68.6112 17.1402 70.6469 18.3127 71.4376 20.2584C72.645 23.2269 72.987 28.1095 70.9911 30.9001C68.2525 34.7252 62.5751 30.8812 55.3056 33.3931C47.7797 35.9955 47.9754 38.275 43.1383 38.0476C40.2133 37.9103 35.9614 27.6775 38.1292 25.5543C41.3801 22.3707 44.88 25.4055 52.2377 23.196C57.1497 21.7207 62.7988 17.7996 66.424 17.3884Z"
                fill="#FFF6EB"
                stroke="#EB2701"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M74.1328 9.0998C74.573 5.59173 77.5088 4.95918 79.8258 4.31621C82.1428 3.67324 82.9406 0.280518 82.9406 0.280518C84.7683 1.20144 86.1967 7.04586 86.1967 7.04586C86.1967 7.04586 80.5119 8.4412 79.2282 9.11915C77.9445 9.7971 76.01 12.5714 76.01 12.5714L75.77 12.6049"
                fill="#EB2701"
              />
              <path
                d="M33.429 24.5467C33.429 24.5467 58.5079 21.1685 76.517 12.4434C76.517 12.4434 77.8432 11.8387 76.664 9.5377C75.4849 7.23669 72.6592 8.54385 72.6592 8.54385C72.6592 8.54385 57.0154 14.6409 51.1863 16.2387C45.3571 17.8364 33.5213 19.9707 33.179 20.1531C32.434 20.5501 32.7853 23.0557 33.429 24.5467Z"
                fill="#EB2701"
              />
              <path
                d="M54.0005 25.9927C56.4671 27.3545 59.3232 27.6336 61.907 26.7655C64.1865 25.8442 65.0419 24.8265 65.5089 22.3197C65.6711 19.6444 65.2893 19.103 65.2893 19.103L54.0005 25.9927Z"
                fill="#EB2701"
              />
              <path
                d="M42.6309 30.4921C44.1755 31.5432 46.1367 31.5741 47.6329 30.571C49.3029 29.6359 50.6446 28.1952 51.493 26.4318L40.2871 25.6318C40.2871 25.6318 41.7617 29.7888 42.6309 30.4921Z"
                fill="#EB2701"
              />
            </svg>
          }
          onClick={() => {
            navigate(ROUTE_DEFINITIONS.OUTREACH_CAMPAIGN.path);
          }}
          furtherInfo="This number represents contacts that were previously engaged but have become inactive. These leads were once classified as Marketing Qualified Leads (MQLs) based on their interest level but have not taken action recently."
        />

        <CreateStrategyBox
          value={`${keyFindings?.leadDropOffRate?.toFixed(2)}%`}
          valueLabel="Average Lead Drop-Off Rate"
          createBtnText="Create Nurture Strategy"
          warningText={`${keyFindings?.likelyToCloseOpportunities} likely to close`}
          icon={
            <svg
              width="76"
              height="100"
              viewBox="0 0 76 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M42.1545 17.3674C41.3058 14.6368 41.1059 13.4685 40.4817 13.2115C39.8575 12.9545 37.5369 12.4419 37.5369 12.4419L34.6616 26.2032C36.2388 26.6686 39.8581 27.834 40.9792 29.0281C40.9792 29.0281 41.6782 26.3645 44.2245 22.276C44.2256 22.2762 43.0025 20.0984 42.1545 17.3674Z"
                fill="#01234C"
              />
              <path
                d="M44.4756 22.0454L44.2257 22.2769C41.6794 26.3667 40.9805 29.029 40.9805 29.029C42.4284 30.5708 44.7713 39.2921 52.2876 39.2075C52.2876 39.2067 57.3073 25.8098 44.4756 22.0454Z"
                fill="#01234C"
              />
              <g opacity="0.3">
                <path
                  d="M52.2876 39.2069C52.466 38.8244 52.6056 38.3151 52.7153 37.7498C52.4935 38.658 52.2876 39.2069 52.2876 39.2069Z"
                  fill="#0F0F14"
                />
              </g>
              <path
                d="M64.2347 58.5881L64.1577 58.9225C64.2501 58.8351 64.2347 58.5881 64.2347 58.5881Z"
                fill="#3E4049"
              />
              <path
                d="M73.3846 54.2602C73.3846 54.2602 75.0571 54.2602 75.257 53.419C75.4568 52.5779 74.2581 52.1342 72.7854 52.4159C71.3127 52.6975 70.9628 52.1587 69.2903 51.6303C67.6178 51.102 66.2331 51.6303 66.2331 51.6303C65.0237 54.5593 64.2349 58.5894 64.2349 58.5894C64.2349 58.5894 66.594 61.9012 67.9795 61.796C69.3651 61.6908 68.6725 59.3079 68.6725 59.3079C68.6725 59.3079 71.1253 60.6395 72.4358 60.9724C73.7463 61.3052 74.1949 61.2352 74.4956 60.7622C74.7963 60.2892 73.085 58.6772 73.085 58.6772C73.085 58.6772 75.0821 58.8405 75.4068 58.0696C75.7314 57.2987 73.8588 56.6446 73.8588 56.6446C74.6578 56.9018 75.9059 56.5744 76.0059 55.7567C76.106 54.939 73.3846 54.2602 73.3846 54.2602Z"
                fill="#F2C5A7"
              />
              <path
                d="M50.0838 47.0071C46.9419 52.2287 50.3037 59.4119 50.3037 59.4119C50.3037 59.4119 64.0282 59.0088 64.1406 58.9386C64.1466 58.9338 64.1521 58.9284 64.1571 58.9226L64.234 58.5882C64.234 58.5882 65.0223 54.5581 66.2323 51.6291C61.129 47.8297 50.0838 47.0071 50.0838 47.0071Z"
                fill="#02285E"
              />
              <path
                d="M18.2694 63.8223C18.2694 63.8223 13.8992 59.9778 11.9416 47.9569C9.9841 35.9359 15.3996 26.9303 22.0452 24.1687C28.6907 21.4072 47.5025 23.6263 51.9096 35.0785C53.9581 40.1144 49.6852 47.9931 49.9581 51.8918C50.2311 55.7905 51.195 61.9873 50.2849 63.6929C49.3749 65.3985 24.6406 68.7676 18.2694 63.8223Z"
                fill="#02285E"
              />
              <path
                d="M29.1491 60.6267C29.1491 60.6267 30.1983 57.6187 32.7984 55.1026C35.3986 52.5864 36.4103 51.7703 36.4103 51.7703C36.4103 51.7703 36.5992 51.6584 36.9458 51.8423C37.2923 52.0262 39.6325 54.4402 41.4114 56.1816C43.1903 57.923 43.3398 59.2217 43.3398 59.2217C43.3398 59.2217 38.5487 60.3962 34.6193 60.8937C30.69 61.3911 29.0875 61.5694 29.0875 61.5694L28.8853 61.3073L29.1491 60.6267Z"
                fill="#F2C5A7"
              />
              <path
                d="M11.4763 42.2104C11.4763 42.2104 18.9544 34.2149 38.6515 38.28C46.5155 40.0322 51.4613 44.148 51.4613 44.148C51.4613 44.148 52.3469 40.9662 52.2774 39.9911C52.2774 39.9911 47.7337 34.0562 30.3934 33.3033C13.0532 32.5504 11.9045 37.3656 11.9045 37.3656C11.9045 37.3656 11.186 39.9298 11.4763 42.2104Z"
                fill="#EB2701"
              />
              <path
                d="M42.3218 59.3163C42.3218 59.3163 34.0881 51.4497 30.8973 49.0928C27.3986 46.508 15.5459 39.6042 15.5459 39.6042L18.0092 38.5017C18.0092 38.5017 28.1789 43.4612 33.6464 47.489C39.8582 52.0676 47.1772 60.7195 47.1772 60.7195L42.3218 59.3163Z"
                fill="#01254F"
              />
              <path
                d="M26.863 60.7196C28.7779 54.9321 34.5425 50.137 34.7885 49.9616C34.9845 49.8219 37.0663 51.8213 36.8703 51.9612C36.8126 52.0026 31.0499 56.165 29.221 61.6932C29.1616 61.8725 26.7896 60.9401 26.863 60.7196Z"
                fill="#01254F"
              />
              <path
                d="M37.635 40.0928V0.347395L32.8423 0.280518V40.2782L37.635 40.0928Z"
                fill="#AF5528"
              />
              <g opacity="0.3">
                <path
                  d="M34.4948 34.1925C34.5874 34.1925 34.6625 34.1222 34.6625 34.0356C34.6625 33.9489 34.5874 33.8787 34.4948 33.8787C34.4022 33.8787 34.3271 33.9489 34.3271 34.0356C34.3271 34.1222 34.4022 34.1925 34.4948 34.1925Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M35.0412 29.8998C35.0808 29.7429 34.9769 29.5856 34.8092 29.5486C34.6416 29.5115 34.4735 29.6087 34.434 29.7657C34.3944 29.9226 34.4982 30.0798 34.6659 30.1169C34.8336 30.1539 35.0016 30.0567 35.0412 29.8998Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M35.9501 27.7433C36.1294 27.7433 36.2747 27.6073 36.2747 27.4395C36.2747 27.2717 36.1294 27.1357 35.9501 27.1357C35.7708 27.1357 35.6255 27.2717 35.6255 27.4395C35.6255 27.6073 35.7708 27.7433 35.9501 27.7433Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M34.9047 23.401C35.0169 23.296 35.0169 23.1258 34.9047 23.0209C34.7925 22.9159 34.6107 22.9159 34.4985 23.0209C34.3863 23.1258 34.3863 23.296 34.4985 23.401C34.6107 23.506 34.7925 23.506 34.9047 23.401Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M35.9494 17.7089C35.9494 17.739 35.94 17.7684 35.9221 17.7934C35.9043 17.8185 35.8789 17.838 35.8492 17.8495C35.8196 17.8611 35.7869 17.8641 35.7553 17.8583C35.7238 17.8524 35.6948 17.8379 35.6721 17.8166C35.6494 17.7954 35.6339 17.7683 35.6276 17.7387C35.6214 17.7092 35.6246 17.6786 35.6369 17.6509C35.6493 17.6231 35.6701 17.5993 35.6969 17.5826C35.7236 17.566 35.7551 17.5571 35.7872 17.5571C35.8302 17.5571 35.8715 17.5731 35.9019 17.6016C35.9323 17.63 35.9494 17.6686 35.9494 17.7089Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M35.2289 15.0018C35.3411 14.8969 35.3411 14.7267 35.2289 14.6217C35.1168 14.5167 34.9349 14.5167 34.8227 14.6217C34.7106 14.7267 34.7106 14.8969 34.8227 15.0018C34.9349 15.1068 35.1168 15.1068 35.2289 15.0018Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M34.5392 11.1158C34.5392 11.1587 34.5255 11.2007 34.5001 11.2364C34.4746 11.2721 34.4383 11.2999 34.396 11.3163C34.3536 11.3328 34.3069 11.3371 34.2619 11.3287C34.2169 11.3203 34.1756 11.2996 34.1431 11.2693C34.1107 11.2389 34.0886 11.2002 34.0797 11.1581C34.0707 11.116 34.0753 11.0724 34.0929 11.0327C34.1104 10.993 34.1401 10.9591 34.1783 10.9353C34.2164 10.9114 34.2613 10.8987 34.3072 10.8987C34.3687 10.8987 34.4277 10.9216 34.4712 10.9623C34.5147 11.003 34.5392 11.0582 34.5392 11.1158Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M35.925 7.4645C36.0009 7.4645 36.0625 7.40692 36.0625 7.33589C36.0625 7.26486 36.0009 7.20728 35.925 7.20728C35.8491 7.20728 35.7876 7.26486 35.7876 7.33589C35.7876 7.40692 35.8491 7.4645 35.925 7.4645Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M34.5392 5.55547C34.5392 5.59841 34.5255 5.64038 34.5001 5.67609C34.4746 5.71179 34.4383 5.73961 34.396 5.75604C34.3536 5.77247 34.3069 5.77677 34.2619 5.7684C34.2169 5.76002 34.1756 5.73934 34.1431 5.70898C34.1107 5.67862 34.0886 5.63994 34.0797 5.59783C34.0707 5.55571 34.0753 5.51206 34.0929 5.4724C34.1104 5.43273 34.1401 5.39882 34.1783 5.37497C34.2164 5.35111 34.2613 5.33838 34.3072 5.33838C34.3687 5.33838 34.4277 5.36125 34.4712 5.40196C34.5147 5.44268 34.5392 5.4979 34.5392 5.55547Z"
                  fill="#0F0F14"
                />
              </g>
              <path
                d="M17.8984 27.9072C17.9147 29.1038 17.9534 30.5067 18.0375 31.4345L18.2121 33.3423C18.2121 33.3423 21.6574 37.1371 26.6502 35.816C31.643 34.4949 32.1801 27.4394 32.1801 27.4394L31.9132 27.1358C24.6811 23.1407 17.8984 27.9072 17.8984 27.9072Z"
                fill="#01234C"
              />
              <path
                d="M32.29 17.8545C29.5087 16.6862 26.2923 16.7852 23.6503 17.5538C19.7067 18.7007 16.9028 22.8325 16.9028 22.8325L18.0177 26.5331C18.0339 26.5872 18.03 26.6449 18.0065 26.6966C17.983 26.7484 17.9414 26.791 17.8885 26.8176C17.8901 27.1425 17.8931 27.5137 17.8986 27.9075C17.8986 27.9075 24.6813 23.1409 31.9134 27.1358C31.9134 27.1358 33.9902 22.2296 34.5394 21.4353L34.6188 21.3211C34.7727 19.8755 35.0712 19.0225 32.29 17.8545Z"
                fill="#01234C"
              />
              <g opacity="0.3">
                <path
                  d="M18.6876 20.0621L18.6719 20.0792C18.6773 20.073 18.6827 20.0674 18.6884 20.0613L18.6876 20.0621Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M31.6365 30.1265C31.6365 30.1299 31.6344 30.1336 31.6333 30.1371L31.6365 30.1265Z"
                  fill="#0F0F14"
                />
              </g>
              <path
                d="M37.6144 34.9314C37.6144 34.9314 36.4133 34.2112 34.8614 34.4262C33.3096 34.6412 32.5801 35.853 32.5801 35.853C32.5801 35.853 31.1745 36.7139 30.2914 38.7521C29.4082 40.7904 29.3827 43.0102 28.9009 43.699C28.419 44.3879 27.7236 44.8488 27.7236 44.8488C27.7236 44.8488 29.4781 48.7105 32.7354 48.6837C32.7354 48.6837 33.8181 47.571 33.7821 45.3807C33.7461 43.1905 33.6507 40.4254 34.5019 39.6259C34.5019 39.6259 35.8177 40.6628 36.9539 39.8294C36.9539 39.8294 39.4002 40.6931 40.7522 38.6642C40.7522 38.6642 41.1617 40.3513 40.8248 42.0906C40.4878 43.83 39.4988 46.2694 39.4988 46.2694L43.6814 46.7582C43.6814 46.7582 45.267 44.7518 45.0807 40.7135C44.8943 36.6751 41.7464 34.0669 41.7464 34.0669C41.7464 34.0669 39.5571 32.4157 37.6144 34.9314Z"
                fill="#EB2701"
              />
              <g opacity="0.3">
                <path
                  d="M28.7963 43.8407C28.7963 43.8407 29.6972 45.7529 30.7524 45.4869C31.8076 45.221 31.7969 43.8816 31.9321 42.3002C32.0673 40.7188 32.4818 40.2386 32.437 39.6508C32.3922 39.0631 31.508 38.0303 31.5264 36.9433C31.5264 36.9433 31.6424 36.6002 32.047 36.271C32.4516 35.9417 32.8166 35.7051 32.8284 35.6096C32.8402 35.5142 33.1445 35.2156 33.1445 35.2156C33.1445 35.2156 33.5414 37.2618 34.7505 37.6726C35.9596 38.0833 36.5462 37.7165 36.5745 37.6468C36.6028 37.5771 37.0258 36.2 37.0258 36.2C37.0258 36.2 37.5444 38.4771 38.8429 38.1034C40.1413 37.7297 41.772 35.9057 41.772 35.9057L41.9919 35.2626C41.9919 35.2626 42.4042 36.1192 42.3358 36.986C42.2673 37.8529 42.0334 38.4753 42.0334 38.7066C42.0334 38.9378 42.3132 39.6305 42.1401 41.8131C41.9669 43.9956 41.954 44.7634 42.7722 44.9918C43.5905 45.2202 44.5588 44.7251 44.5588 44.7251C44.5588 44.7251 44.0509 46.6198 43.743 46.6766C43.4352 46.7334 39.6477 46.2908 39.6477 46.2908L39.5482 46.1462C39.5482 46.1462 40.6022 43.5632 40.8807 41.7518C41.1591 39.9405 40.7919 39.1709 40.768 39.1223C40.7441 39.0736 40.6718 38.8787 40.6718 38.8787C40.6718 38.8787 40.0316 39.7159 38.8805 39.9443C37.7294 40.1727 36.8546 39.8548 36.8546 39.8548C36.8546 39.8548 36.2224 40.3546 35.5569 40.112C34.8915 39.8695 34.5543 39.6678 34.5543 39.6678L34.3275 39.8366C34.3275 39.8366 33.7541 40.8809 33.7646 42.9685C33.775 45.0561 33.9845 46.0361 33.4961 47.3029C33.0076 48.5697 32.6951 48.6865 32.6951 48.6865C32.6951 48.6865 31.1259 48.6593 29.8498 47.5956C28.5736 46.532 27.6509 45.0414 27.7471 44.9036C27.8433 44.7657 28.3304 44.3557 28.3939 44.2922C28.4574 44.2286 28.7963 43.8407 28.7963 43.8407Z"
                  fill="#0F0F14"
                />
              </g>
              <path
                d="M34.5015 40.0435C34.4215 40.0436 34.3431 40.0233 34.2744 39.9849C33.1824 39.3789 31.381 37.6442 32.3779 35.412C32.4236 35.3097 32.5108 35.2285 32.6204 35.1864C32.7301 35.1443 32.8531 35.1446 32.9624 35.1873C33.0718 35.2301 33.1585 35.3117 33.2035 35.4143C33.2485 35.5169 33.2482 35.632 33.2025 35.7343C32.252 37.8605 34.6287 39.2101 34.7304 39.2665C34.8146 39.3132 34.8801 39.3845 34.9167 39.4694C34.9534 39.5543 34.9592 39.6482 34.9332 39.7365C34.9073 39.8248 34.8511 39.9027 34.7732 39.9582C34.6954 40.0137 34.6002 40.0437 34.5023 40.0435H34.5015Z"
                fill="#0F0F14"
              />
              <g opacity="0.5">
                <path
                  d="M30.4253 40.7672C30.1941 41.5844 30.2238 42.38 30.3555 42.4134C30.4871 42.4469 30.6702 41.703 30.9013 40.8858C31.1325 40.0686 31.3235 39.4862 31.1919 39.4536C31.0602 39.4209 30.6562 39.9503 30.4253 40.7672Z"
                  fill="white"
                />
              </g>
              <path
                d="M39.2961 38.0346V37.3687C39.2961 37.3687 37.0192 33.9253 33.6712 34.1097C30.3231 34.2941 27.7246 38.2883 27.7246 38.2883C27.7246 38.2883 30.5969 42.4385 32.843 45.0084C32.843 45.0084 38.1349 44.1323 38.7341 43.0108C39.3333 41.8894 37.0115 41.8194 37.0115 41.8194C37.0115 41.8194 40.232 40.1022 40.569 39.3663C40.906 38.6304 39.2961 38.0346 39.2961 38.0346Z"
                fill="#F2C5A7"
              />
              <path
                d="M14.2178 48.7467C14.4507 50.0908 15.0751 51.3488 16.0223 52.3825C16.9695 53.4161 18.2029 54.1854 19.5869 54.6057L24.3535 56.0533C26.0012 53.3247 32.6481 46.236 32.8422 45.9204C33.0362 45.6047 32.8422 45.0093 32.8422 45.0093C30.596 42.4391 27.7243 38.2883 27.7243 38.2883C19.8974 41.5208 14.2178 48.7467 14.2178 48.7467Z"
                fill="#01234C"
              />
              <g opacity="0.3">
                <path
                  d="M24.6699 55.5647C24.8074 55.3625 24.9632 55.1449 25.1333 54.9154C25.2265 54.8187 25.3029 54.7506 25.3532 54.7277C25.6528 54.5875 28.6034 51.0178 28.886 50.535C28.9135 50.4899 28.933 50.4105 28.9486 50.3035C29.1724 50.048 29.3945 49.7962 29.6122 49.55C27.8088 51.588 25.7232 54.019 24.671 55.5639L24.6699 55.5647Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M30.2433 48.8389C30.1466 48.9472 30.0485 49.0573 29.9492 49.1691C30.0485 49.0575 30.1466 48.9474 30.2433 48.8389Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M40.4966 39.4825L40.5114 39.4619C40.5069 39.4687 40.5016 39.4757 40.4966 39.4825Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M29.9318 49.1895C29.8268 49.3073 29.7208 49.4268 29.6138 49.548C29.721 49.4271 29.827 49.3076 29.9318 49.1895Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M40.568 39.3665C40.5275 39.4461 40.4751 39.5198 40.4121 39.5854C40.5355 39.4786 40.5968 39.4007 40.568 39.3665Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M33.7899 44.8448C33.8498 44.8543 34.0677 44.8021 34.3794 44.7087C34.1521 44.7581 33.9397 44.8019 33.75 44.8397L33.7899 44.8448Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M32.9047 45.7612L32.9286 45.7329V44.9939L32.8423 45.0086C32.8423 45.0086 32.9816 45.4384 32.9047 45.7612Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M38.8223 42.76C39.2643 42.1941 37.7188 41.7798 37.2765 41.6748C37.1116 41.7659 37.0107 41.8196 37.0107 41.8196C37.0107 41.8196 38.9708 41.8796 38.8223 42.76Z"
                  fill="#0F0F14"
                />
              </g>
              <path
                d="M35.7963 41.6645L38.4375 41.1038C38.4375 41.1038 35.5853 35.8454 34.8981 33.373C34.8981 33.373 33.653 33.09 31.9126 34.2491C31.9131 34.2491 33.7104 39.6366 35.7963 41.6645Z"
                fill="#AF5528"
              />
              <g opacity="0.3">
                <path
                  d="M32.7722 33.8787C32.7722 33.8787 33.8595 36.9653 34.9523 38.673C36.0452 40.3807 37.7009 40.0931 37.7009 40.0931L37.9664 40.2174L38.3685 40.9777L38.1893 41.256L35.7412 41.6483C35.7412 41.6483 33.8383 39.1085 33.0107 37.0587C32.1831 35.0089 32.019 34.1796 32.019 34.1796L32.7722 33.8787Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M34.0994 34.4456C34.0821 34.4959 34.0493 34.5402 34.0051 34.5731C33.9609 34.6059 33.9073 34.6257 33.8511 34.63C33.7949 34.6343 33.7385 34.6229 33.6892 34.5973C33.6399 34.5717 33.5998 34.5329 33.574 34.4859C33.5482 34.439 33.5379 34.3859 33.5443 34.3334C33.5508 34.281 33.5737 34.2315 33.6102 34.1912C33.6467 34.151 33.6951 34.1217 33.7494 34.1072C33.8036 34.0927 33.8612 34.0936 33.915 34.1097C33.9507 34.1204 33.9838 34.1375 34.0124 34.1602C34.0411 34.1829 34.0646 34.2106 34.0818 34.2418C34.0989 34.273 34.1093 34.3071 34.1123 34.3421C34.1153 34.377 34.1109 34.4122 34.0994 34.4456Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M33.8098 36.1649C33.7926 36.2151 33.7598 36.2595 33.7156 36.2923C33.6714 36.3251 33.6178 36.3449 33.5615 36.3493C33.5053 36.3536 33.449 36.3422 33.3997 36.3165C33.3503 36.2909 33.3102 36.2521 33.2845 36.2052C33.2587 36.1582 33.2484 36.1051 33.2548 36.0527C33.2612 36.0002 33.2842 35.9507 33.3206 35.9105C33.3571 35.8702 33.4056 35.841 33.4598 35.8264C33.5141 35.8119 33.5717 35.8128 33.6254 35.8289C33.6611 35.8396 33.6943 35.8568 33.7229 35.8794C33.7515 35.9021 33.7751 35.9298 33.7922 35.961C33.8093 35.9923 33.8197 36.0263 33.8227 36.0613C33.8258 36.0963 33.8214 36.1315 33.8098 36.1649Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M36.3826 39.7498C36.3653 39.8001 36.3325 39.8444 36.2883 39.8773C36.2441 39.9101 36.1905 39.9299 36.1343 39.9342C36.0781 39.9385 36.0217 39.9271 35.9724 39.9015C35.9231 39.8759 35.883 39.8371 35.8572 39.7901C35.8314 39.7432 35.8211 39.6901 35.8275 39.6376C35.834 39.5852 35.8569 39.5357 35.8934 39.4954C35.9299 39.4552 35.9783 39.4259 36.0326 39.4114C36.0868 39.3969 36.1445 39.3978 36.1982 39.4139C36.2339 39.4246 36.267 39.4418 36.2956 39.4644C36.3242 39.4871 36.3477 39.5148 36.3649 39.5461C36.382 39.5773 36.3924 39.6113 36.3954 39.6463C36.3985 39.6813 36.3941 39.7164 36.3826 39.7498Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M35.1192 36.5021C35.1096 36.53 35.0915 36.5547 35.067 36.5729C35.0425 36.5912 35.0127 36.6022 34.9815 36.6046C34.9503 36.6071 34.919 36.6008 34.8916 36.5866C34.8643 36.5724 34.842 36.5509 34.8276 36.5249C34.8133 36.4988 34.8075 36.4694 34.8111 36.4402C34.8146 36.4111 34.8273 36.3836 34.8475 36.3613C34.8678 36.3389 34.8946 36.3227 34.9247 36.3146C34.9548 36.3065 34.9868 36.307 35.0167 36.3159C35.0566 36.3279 35.0898 36.3543 35.109 36.3892C35.1282 36.4241 35.1319 36.4647 35.1192 36.5021Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M35.1148 38.823C35.1052 38.8509 35.087 38.8755 35.0625 38.8938C35.038 38.912 35.0082 38.923 34.977 38.9255C34.9458 38.9279 34.9145 38.9216 34.8871 38.9073C34.8597 38.8931 34.8375 38.8716 34.8232 38.8455C34.8088 38.8194 34.8031 38.79 34.8067 38.7609C34.8103 38.7317 34.823 38.7043 34.8433 38.6819C34.8636 38.6596 34.8905 38.6434 34.9206 38.6353C34.9507 38.6273 34.9827 38.6278 35.0125 38.6368C35.0524 38.6488 35.0856 38.6752 35.1048 38.7101C35.124 38.745 35.1276 38.7856 35.1148 38.823Z"
                  fill="#0F0F14"
                />
              </g>
              <path
                d="M36.2449 41.7309L38.0211 39.8275C38.0211 39.8275 32.4986 36.8694 30.427 35.1262C30.427 35.1262 29.2523 35.5545 28.5679 37.4844C28.5676 37.4849 33.3229 41.1218 36.2449 41.7309Z"
                fill="#AF5528"
              />
              <g opacity="0.3">
                <path
                  d="M29.0296 36.7053C29.0296 36.7053 31.8001 38.7574 33.7277 39.6284C35.6553 40.4993 36.8092 39.3604 36.8092 39.3604L37.0989 39.3233L37.8891 39.7565L37.9166 40.0909L36.1902 41.7461C36.1902 41.7461 33.101 40.6004 31.1762 39.2943C29.2514 37.9881 28.6099 37.3677 28.6099 37.3677L29.0296 36.7053Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M30.2879 37.0092C30.4367 36.9671 30.519 36.8142 30.4717 36.6676C30.4243 36.521 30.2654 36.4362 30.1166 36.4783C29.9678 36.5203 29.8855 36.6733 29.9329 36.8199C29.9802 36.9665 30.1392 37.0513 30.2879 37.0092Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M31.0885 38.4385C31.2367 38.3961 31.3182 38.2433 31.2706 38.0973C31.2229 37.9512 31.0641 37.8672 30.9158 37.9096C30.7676 37.952 30.6861 38.1047 30.7338 38.2508C30.7815 38.3968 30.9403 38.4809 31.0885 38.4385Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M35.5373 39.7786C35.5506 39.8285 35.548 39.8809 35.5299 39.9295C35.5117 39.978 35.4787 40.0204 35.435 40.0516C35.3913 40.0827 35.3388 40.1012 35.284 40.1047C35.2292 40.1082 35.1745 40.0966 35.1267 40.0714C35.0788 40.0461 35.04 40.0082 35.0149 39.9625C34.9898 39.9168 34.9796 39.8652 34.9855 39.8141C34.9915 39.7629 35.0133 39.7146 35.0483 39.675C35.0833 39.6353 35.1299 39.6062 35.1825 39.5911C35.2561 39.572 35.3348 39.581 35.4013 39.6162C35.4679 39.6513 35.5168 39.7098 35.5373 39.7786Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M32.5274 37.6824C32.5351 37.71 32.5339 37.7392 32.524 37.7662C32.5141 37.7932 32.4959 37.8169 32.4717 37.8343C32.4476 37.8518 32.4185 37.8622 32.388 37.8643C32.3576 37.8664 32.3272 37.8601 32.3005 37.8462C32.2739 37.8323 32.2522 37.8113 32.2382 37.786C32.2241 37.7606 32.2184 37.7319 32.2216 37.7035C32.2248 37.6751 32.2368 37.6483 32.2562 37.6262C32.2756 37.6041 32.3015 37.5879 32.3306 37.5795C32.3713 37.5689 32.4148 37.5737 32.4517 37.593C32.4886 37.6123 32.5158 37.6444 32.5274 37.6824Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M33.9503 39.669C33.9579 39.6966 33.9567 39.7257 33.9468 39.7528C33.9369 39.7798 33.9188 39.8035 33.8946 39.8209C33.8704 39.8384 33.8413 39.8488 33.8109 39.8509C33.7805 39.853 33.75 39.8467 33.7234 39.8328C33.6968 39.8188 33.6751 39.7979 33.661 39.7725C33.647 39.7472 33.6412 39.7185 33.6444 39.6901C33.6476 39.6617 33.6597 39.6348 33.6791 39.6128C33.6984 39.5907 33.7243 39.5745 33.7535 39.5661C33.7942 39.5554 33.8377 39.5603 33.8745 39.5796C33.9114 39.5988 33.9386 39.631 33.9503 39.669Z"
                  fill="#0F0F14"
                />
              </g>
              <path
                d="M36.2121 42.2173L38.7617 40.8077C38.7617 40.8077 33.9151 36.224 32.3173 33.8643C32.3173 33.8643 30.9194 33.9576 29.5215 35.7101C29.5215 35.7101 33.3162 40.7578 36.2121 42.2173Z"
                fill="#AF5528"
              />
              <g opacity="0.3">
                <path
                  d="M30.2834 35.0571C30.2834 35.0571 32.5175 37.9308 34.265 39.3676C36.0126 40.8045 37.6354 39.9868 37.6354 39.9868L37.9564 40.0331L38.6463 40.699L38.5589 41.0447L36.15 42.2187C36.15 42.2187 33.2621 40.1689 31.6698 38.2935C30.0776 36.4181 29.6084 35.6055 29.6084 35.6055L30.2834 35.0571Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M31.8586 35.2965C31.8982 35.1396 31.7943 34.9824 31.6266 34.9453C31.4589 34.9083 31.2909 35.0055 31.2513 35.1624C31.2118 35.3193 31.3156 35.4766 31.4833 35.5136C31.651 35.5506 31.819 35.4534 31.8586 35.2965Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M32.1701 37.1774C32.2097 37.0205 32.1058 36.8632 31.9382 36.8262C31.7705 36.7891 31.6024 36.8863 31.5629 37.0433C31.5233 37.2002 31.6271 37.3574 31.7948 37.3945C31.9625 37.4315 32.1305 37.3343 32.1701 37.1774Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M35.9445 40.3105C36.1036 40.2488 36.1792 40.0781 36.1133 39.9291C36.0474 39.7801 35.8649 39.7094 35.7057 39.7711C35.5465 39.8328 35.4709 40.0036 35.5369 40.1525C35.6028 40.3015 35.7853 40.3722 35.9445 40.3105Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M33.4905 37.2155C33.5862 37.2155 33.6637 37.1429 33.6637 37.0534C33.6637 36.9639 33.5862 36.8914 33.4905 36.8914C33.3949 36.8914 33.3174 36.9639 33.3174 37.0534C33.3174 37.1429 33.3949 37.2155 33.4905 37.2155Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M34.4874 39.4716C34.4874 39.5037 34.4773 39.535 34.4582 39.5617C34.4392 39.5883 34.4121 39.6091 34.3805 39.6214C34.3488 39.6336 34.314 39.6368 34.2804 39.6305C34.2468 39.6243 34.2159 39.6088 34.1917 39.5861C34.1675 39.5634 34.1511 39.5345 34.1444 39.5031C34.1378 39.4716 34.1412 39.439 34.1544 39.4094C34.1675 39.3798 34.1898 39.3545 34.2183 39.3368C34.2468 39.319 34.2803 39.3095 34.3145 39.3096C34.3604 39.3096 34.4044 39.3267 34.4368 39.3571C34.4692 39.3875 34.4874 39.4287 34.4874 39.4716Z"
                  fill="#0F0F14"
                />
              </g>
              <path
                d="M34.9143 44.518C34.9143 44.518 35.464 44.9607 33.9655 46.7373C32.467 48.5139 32.3163 50.1017 33.1667 52.1809L37.6354 50.1717C37.6354 50.1717 36.6869 48.5129 37.0112 47.6252C37.3355 46.7375 38.477 44.4923 37.9564 43.6172L34.9143 44.518Z"
                fill="#AF5528"
              />
              <g opacity="0.3">
                <path
                  d="M36.0623 45.6392C36.0623 45.6669 36.0535 45.694 36.037 45.7171C36.0206 45.7401 35.9972 45.7581 35.9698 45.7687C35.9424 45.7793 35.9123 45.7821 35.8833 45.7767C35.8542 45.7713 35.8275 45.7579 35.8066 45.7383C35.7856 45.7187 35.7714 45.6938 35.7656 45.6666C35.7598 45.6394 35.7628 45.6112 35.7741 45.5856C35.7854 45.5599 35.8046 45.5381 35.8293 45.5227C35.8539 45.5072 35.8829 45.499 35.9125 45.499C35.9522 45.499 35.9903 45.5138 36.0184 45.5401C36.0465 45.5664 36.0623 45.602 36.0623 45.6392Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M35.083 47.307C35.083 47.3341 35.0745 47.3606 35.0585 47.3831C35.0424 47.4056 35.0196 47.4232 34.9929 47.4336C34.9662 47.444 34.9368 47.4467 34.9084 47.4415C34.88 47.4362 34.8539 47.4232 34.8335 47.4041C34.813 47.385 34.799 47.3606 34.7934 47.334C34.7877 47.3075 34.7906 47.28 34.8016 47.2549C34.8127 47.2299 34.8314 47.2085 34.8555 47.1935C34.8795 47.1784 34.9078 47.1704 34.9368 47.1704C34.9755 47.1704 35.0126 47.1848 35.0401 47.2104C35.0675 47.236 35.0829 47.2707 35.083 47.307Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M34.2939 48.7605C34.294 48.7957 34.2829 48.8301 34.262 48.8593C34.2412 48.8886 34.2115 48.9114 34.1768 48.9249C34.1421 48.9384 34.1039 48.9419 34.067 48.9351C34.0301 48.9282 33.9963 48.9113 33.9697 48.8864C33.9431 48.8616 33.925 48.8299 33.9177 48.7954C33.9104 48.7609 33.9142 48.7251 33.9286 48.6926C33.943 48.6601 33.9674 48.6324 33.9986 48.6129C34.0299 48.5933 34.0667 48.583 34.1043 48.583C34.1546 48.583 34.2028 48.6017 34.2384 48.635C34.2739 48.6683 34.2939 48.7134 34.2939 48.7605Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M35.0828 50.0638C35.0829 50.1049 35.0699 50.1451 35.0456 50.1793C35.0212 50.2135 34.9865 50.2402 34.9459 50.256C34.9054 50.2717 34.8607 50.2759 34.8176 50.2679C34.7745 50.2599 34.7349 50.2401 34.7038 50.2111C34.6727 50.182 34.6516 50.145 34.643 50.1047C34.6344 50.0644 34.6388 50.0226 34.6556 49.9846C34.6724 49.9466 34.7008 49.9141 34.7373 49.8912C34.7739 49.8684 34.8168 49.8562 34.8608 49.8562C34.8899 49.8562 34.9188 49.8615 34.9457 49.8719C34.9727 49.8823 34.9972 49.8976 35.0178 49.9169C35.0384 49.9362 35.0548 49.9591 35.0659 49.9843C35.0771 50.0095 35.0828 50.0365 35.0828 50.0638Z"
                  fill="#0F0F14"
                />
              </g>
              <g opacity="0.3">
                <path
                  d="M36.1368 48.4544C36.1368 48.4937 36.1243 48.5321 36.101 48.5648C36.0777 48.5974 36.0446 48.6229 36.0058 48.6379C35.967 48.6529 35.9244 48.6569 35.8832 48.6492C35.842 48.6415 35.8042 48.6226 35.7746 48.5948C35.7449 48.5671 35.7247 48.5317 35.7165 48.4932C35.7083 48.4547 35.7125 48.4147 35.7286 48.3784C35.7446 48.3422 35.7718 48.3111 35.8067 48.2893C35.8416 48.2675 35.8826 48.2559 35.9246 48.2559C35.9809 48.2559 36.0348 48.2768 36.0746 48.314C36.1144 48.3513 36.1368 48.4018 36.1368 48.4544Z"
                  fill="#0F0F14"
                />
              </g>
              <path
                d="M12.3597 82.5712C12.3597 82.5712 13.0172 79.0782 8.9927 77.2794C4.96822 75.4807 1.37912 77.0587 1.37912 77.0587L0.494629 82.3179C0.494629 82.3179 4.02903 81.2954 5.93682 81.689C7.84462 82.0825 11.489 85.5494 11.489 85.5494"
                fill="#EB2701"
              />
              <path
                d="M40.6332 58.5775C34.7337 57.6922 24.9594 58.0654 21.9154 59.6514C17.873 61.7588 14.2331 65.7365 12.9506 68.6223C11.7162 71.4003 7.98004 81.9929 15.1382 91.0685C21.8277 99.5501 39.3282 104.672 51.4816 89.9894C54.6381 85.3852 55.9343 73.85 52.9757 67.7912C51.0558 63.8578 46.3599 59.4369 40.6332 58.5775Z"
                fill="#02285E"
              />
              <path
                d="M21.3774 80.6494C19.3259 80.1815 17.8798 78.5631 17.8111 76.6784C17.7053 73.8027 19.0125 69.5791 21.7336 67.848C25.4651 65.4715 29.3073 70.4688 36.6859 70.6003C44.3248 70.7361 44.9042 68.7547 49.1829 70.4423C51.7718 71.4638 52.2173 81.4046 49.5625 82.5233C45.5814 84.2006 43.4331 80.5594 36.0755 80.1442C31.1637 79.8682 24.7791 81.4247 21.3774 80.6494Z"
                fill="#FFF6EB"
                stroke="#EB2701"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M11.693 85.2502C10.1365 88.0708 7.28291 87.6938 4.98207 87.5196C2.68123 87.3455 0.843249 89.9573 0.843249 89.9573C-0.498609 88.6151 0.147858 83.2469 0.147858 83.2469C0.147858 83.2469 5.72968 83.8305 7.11002 83.6564C8.49036 83.4823 11.1504 81.7427 11.1504 81.7427L11.3775 81.7887"
                fill="#EB2701"
              />
              <path
                d="M53.4633 84.829C53.4633 84.829 29.7584 79.913 10.653 81.6943C10.653 81.6943 9.25867 81.7933 9.55964 84.0978C9.86061 86.4022 12.8381 86.1751 12.8381 86.1751C12.8381 86.1751 28.9448 85.8777 34.7229 86.3351C40.501 86.7924 51.8675 88.6568 52.2366 88.6092C53.0389 88.5047 53.5499 86.2839 53.4633 84.829Z"
                fill="#EB2701"
              />
              <path
                d="M35.4133 77.2428C33.6421 75.3311 31.1621 74.2122 28.5479 74.1443C26.1902 74.2153 25.0828 74.8082 23.8332 76.7764C22.8025 78.9813 22.9672 79.556 22.9672 79.556L35.4133 77.2428Z"
                fill="#EB2701"
              />
              <path
                d="M47.1401 76.9692C46.0957 75.6059 44.3407 74.9718 42.6599 75.3543C40.8459 75.6257 39.1615 76.4246 37.8145 77.6485L47.6428 81.7897C47.6428 81.7897 47.6904 77.8308 47.1401 76.9692Z"
                fill="#EB2701"
              />
            </svg>
          }
          onClick={() => {
            navigate(
              `${ROUTE_DEFINITIONS.CREATE_NURTURE_CAMPAIGN.path}?product=${selectedProduct}`
            );
          }}
          disableBtnText={
            selectedProduct ? "" : "Select Product from the dropdown to enable"
          }
          furtherInfo="This average drop-off rate is calculated based on the bounce rates across the campaigns. The drop-off occurs at various stages, such as initial outreach, landing page interaction, email open, lead qualification, and content engagement."
        />
      </div>

      <div className={styles["findings"]}>
        <Metric
          label="Total Contacts Generated"
          value={formatNumberStandard(keyFindings?.totalContactsGenerated)}
          className={styles["metric"]}
          icon={
            <svg
              width="15"
              height="13"
              viewBox="0 0 15 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 6.51777C9.57187 6.51777 11.25 5.0662 11.25 3.27402C11.25 1.48185 9.57187 0.0302734 7.5 0.0302734C5.42812 0.0302734 3.75 1.48185 3.75 3.27402C3.75 5.0662 5.42812 6.51777 7.5 6.51777ZM7.5 8.13965C4.99687 8.13965 0 9.22631 0 11.3834V13.0053H15V11.3834C15 9.22631 10.0031 8.13965 7.5 8.13965Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Total Engaged Contacts"
          value={formatNumberStandard(keyFindings?.totalEngagedContacts)}
          className={styles["metric"]}
          icon={
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.7436 7.97005C19.9145 8.16241 20 8.39084 20 8.65534C20 8.91983 19.9145 9.14826 19.7436 9.34063L16.4336 12.731C16.2626 12.9234 16.0451 13.0196 15.7809 13.0196C15.5167 13.0196 15.2991 12.9234 15.1282 12.731L13.7995 11.3845C13.6131 11.1921 13.5198 10.9637 13.5198 10.6992C13.5198 10.4347 13.6131 10.2063 13.7995 10.0139C13.986 9.83756 14.2075 9.7494 14.4639 9.7494C14.7203 9.7494 14.9417 9.83756 15.1282 10.0139L15.7809 10.6872L18.4149 7.97005C18.6014 7.79371 18.8228 7.70555 19.0793 7.70555C19.3357 7.70555 19.5571 7.79371 19.7436 7.97005ZM8.69464 17.492L6.36364 15.3039C5.24476 14.2619 4.28516 13.3321 3.48485 12.5146C2.68454 11.6971 2.02409 10.9276 1.5035 10.2063C0.982906 9.4849 0.602176 8.78759 0.361305 8.11432C0.120435 7.44105 0 6.73572 0 5.99833C0 4.4915 0.48951 3.23714 1.46853 2.23525C2.44755 1.23336 3.66744 0.732422 5.1282 0.732422C5.93629 0.732422 6.70552 0.904746 7.4359 1.2494C8.16628 1.59404 8.79565 2.08697 9.32401 2.72818C9.85237 2.08697 10.4817 1.59404 11.2121 1.2494C11.9425 0.904746 12.7117 0.732422 13.5198 0.732422C14.8407 0.732422 15.9479 1.1452 16.8415 1.97075C17.735 2.79631 18.2906 3.76213 18.5082 4.86821C18.1974 4.73997 17.871 4.65981 17.5291 4.62775C17.1873 4.59569 16.8376 4.57966 16.4802 4.57966C15.1593 4.57966 13.9472 5.1287 12.8438 6.22676C11.7405 7.32483 11.1888 8.69942 11.1888 10.3505C11.1888 11.12 11.352 11.9014 11.6783 12.6949C12.0047 13.4884 12.4631 14.1336 13.0536 14.6306C12.7584 14.9031 12.3737 15.2518 11.8998 15.6766C11.4258 16.1014 11.0179 16.4741 10.676 16.7947L9.93007 17.492C9.75913 17.6523 9.55322 17.7324 9.31235 17.7324C9.07148 17.7324 8.86558 17.6523 8.69464 17.492Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Average Engagement Score"
          value={`${formatNumberStandard(
            keyFindings?.averageEngagementScore
          )}%`}
          className={styles["metric"]}
          icon={
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8.87528C16 8.12885 15.4 7.51814 14.6667 7.51814H7.76L8.64 3.20242L8.66667 2.89028C8.66667 2.46956 8.49333 2.08956 8.22667 1.80456L7.17333 0.732422L0.586667 7.43671C0.226667 7.80314 0 8.31885 0 8.87528V17.6967C0 18.8231 0.893333 19.7324 2 19.7324H11C11.8267 19.7324 12.5333 19.2167 12.84 18.4974L15.8533 11.3181C15.9467 11.0874 16 10.8296 16 10.5717V8.87528Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Average Email Open Rate"
          value={`${formatNumberStandard(keyFindings?.averageEmailOpenRate)}%`}
          className={styles["metric"]}
          icon={
            <svg
              width="19"
              height="17"
              viewBox="0 0 19 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.04762 14.0658C9.04762 10.6258 11.8795 7.84353 15.381 7.84353C16.3581 7.84353 17.2719 8.06576 18.0952 8.44798V2.5102C18.0952 1.53242 17.281 0.732422 16.2857 0.732422H1.80952C0.814286 0.732422 0 1.53242 0 2.5102V13.1769C0 14.1546 0.814286 14.9546 1.80952 14.9546H9.12C9.07476 14.6613 9.04762 14.368 9.04762 14.0658ZM1.80952 2.5102L9.04762 6.95464L16.2857 2.5102V4.28798L9.04762 8.73242L1.80952 4.28798V2.5102ZM13.879 16.7324L10.6762 13.5858L11.9519 12.3324L13.87 14.2169L17.7062 10.448L19 11.7013L13.879 16.7324Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Average Email Click Rate"
          value={`${formatNumberStandard(keyFindings?.averageEmailClickRate)}%`}
          className={styles["metric"]}
          icon={
            <svg
              width="11"
              height="18"
              viewBox="0 0 11 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 0L11 10.08L5.68333 10.53L8.70833 17.1L6.69167 18L3.75833 11.34L0 14.85V0Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Landing Page Conversion Rate"
          value={`${formatNumberStandard(
            keyFindings?.landingPageConversionRate
          )}%`}
          className={styles["metric"]}
          icon={
            <svg
              width="18"
              height="23"
              viewBox="0 0 18 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.3 17.7574C1.58333 16.9574 1.02083 16.0408 0.6125 15.0074C0.204167 13.9741 0 12.8824 0 11.7324C0 9.23242 0.875 7.10742 2.625 5.35742C4.375 3.60742 6.5 2.73242 9 2.73242V0.732422L14 4.48242L9 8.23242V6.23242C7.48333 6.23242 6.1875 6.76992 5.1125 7.84492C4.0375 8.91992 3.5 10.2158 3.5 11.7324C3.5 12.4991 3.64583 13.2158 3.9375 13.8824C4.22917 14.5491 4.625 15.1324 5.125 15.6324L2.3 17.7574ZM9 22.7324L4 18.9824L9 15.2324V17.2324C10.5167 17.2324 11.8125 16.6949 12.8875 15.6199C13.9625 14.5449 14.5 13.2491 14.5 11.7324C14.5 10.9658 14.3542 10.2491 14.0625 9.58242C13.7708 8.91576 13.375 8.33242 12.875 7.83242L15.7 5.70742C16.4167 6.50742 16.9792 7.42409 17.3875 8.45742C17.7958 9.49076 18 10.5824 18 11.7324C18 14.2324 17.125 16.3574 15.375 18.1074C13.625 19.8574 11.5 20.7324 9 20.7324V22.7324Z"
                fill="white"
              />
            </svg>
          }
        />
      </div>

      <div className={styles["three-column"]}>
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="ScatterChart"
              width="100%"
              height="100%"
              data={costEngagementScatterData}
              options={{
                chartArea: { width: "88%", height: "80%" },
                legend: "none",
                vAxis: { title: "Engagement Rate" },
                hAxis: { title: "Cost Per Engagement" },
              }}
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Cost per Engagement vs. Engagement Rate"
        />
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="100%"
              data={contactsTrendData}
              legendToggle
              options={multiColumnChartOptions}
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Converted, Engaged and Idle Contacts Trend"
        />

        <GraphWrapper
          graphComonent={
            <Chart
              chartType="AreaChart"
              width="100%"
              height="100%"
              data={performanceAcrossKeyMetrics}
              legendToggle
              options={{
                isStacked: false,
                colors: ["#FE5A3A", "#FC8A73", "#FFCFC6"],
                vAxis: {
                  title: "Percentage",
                  minValue: 0,
                  maxValue: 100,
                  viewWindow: {
                    max: 100,
                  },
                },
                legend: {
                  position: "top",
                },
                chartArea: { width: "81%", height: "72%" },
              }}
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Performance Across Key Metrics"
        />
      </div>
      <TextElement className={styles["sub-heading"]}>
        Marketing Channels Performance Overview
      </TextElement>

      <div className={styles["channels-container"]}>
        <div className={styles["channel"]}>
          <TextElement className={styles["channel-title"]}>
            Email Marketing
          </TextElement>
          <div className={classNames(styles["channel-metrics"])}>
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Total Email Sent"
              value={formatNumberStandard(keyFindings?.totalEmailsSent)}
              className={styles["metric"]}
              icon={
                <svg
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0.280273H2C1.175 0.280273 0.5075 0.955273 0.5075 1.78027L0.5 10.7803C0.5 11.6053 1.175 12.2803 2 12.2803H14C14.825 12.2803 15.5 11.6053 15.5 10.7803V1.78027C15.5 0.955273 14.825 0.280273 14 0.280273ZM14 3.28027L8 7.03027L2 3.28027V1.78027L8 5.53027L14 1.78027V3.28027Z"
                    fill="white"
                  />
                </svg>
              }
            />
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Total Emails Opened"
              value={formatNumberStandard(keyFindings?.totalEmailsOpened)}
              className={styles["metric"]}
              icon={
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.16667 10.2803C7.16667 7.70027 9.25333 5.61361 11.8333 5.61361C12.5533 5.61361 13.2267 5.78027 13.8333 6.06694V1.61361C13.8333 0.880273 13.2333 0.280273 12.5 0.280273H1.83333C1.1 0.280273 0.5 0.880273 0.5 1.61361V9.61361C0.5 10.3469 1.1 10.9469 1.83333 10.9469H7.22C7.18667 10.7269 7.16667 10.5069 7.16667 10.2803ZM1.83333 1.61361L7.16667 4.94694L12.5 1.61361V2.94694L7.16667 6.28027L1.83333 2.94694V1.61361ZM10.7267 12.2803L8.36667 9.92027L9.30667 8.98027L10.72 10.3936L13.5467 7.56694L14.5 8.50694L10.7267 12.2803Z"
                    fill="white"
                  />
                </svg>
              }
            />
          </div>
          <GraphWrapper
            graphComonent={
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="100%"
                data={emailPerformanceData}
                legendToggle
                options={multiColumnChartOptions}
              />
            }
            containerClassName={styles["graph-container"]}
            titleClassName={styles["sub-heading"]}
            title={
              <>
                Email Performance Trend <br />
                (Sent vs Opened vs Clicked)
              </>
            }
          />
        </div>
        <div className={styles["channel"]}>
          <TextElement className={styles["channel-title"]}>
            Landing Page
          </TextElement>
          <div className={classNames(styles["channel-metrics"])}>
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Total Landing Page Visits"
              value={formatNumberStandard(keyFindings?.totalLandingPageVisits)}
              className={styles["metric"]}
              icon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 15.7803C6.9625 15.7803 5.9875 15.5834 5.075 15.1896C4.1625 14.7959 3.36875 14.2615 2.69375 13.5865C2.01875 12.9115 1.48438 12.1178 1.09063 11.2053C0.696875 10.2928 0.5 9.31777 0.5 8.28027C0.5 7.24277 0.696875 6.26777 1.09063 5.35527C1.48438 4.44277 2.01875 3.64902 2.69375 2.97402C3.36875 2.29902 4.1625 1.76465 5.075 1.3709C5.9875 0.977148 6.9625 0.780273 8 0.780273C9.0375 0.780273 10.0125 0.977148 10.925 1.3709C11.8375 1.76465 12.6313 2.29902 13.3063 2.97402C13.9813 3.64902 14.5156 4.44277 14.9094 5.35527C15.3031 6.26777 15.5 7.24277 15.5 8.28027C15.5 9.31777 15.3031 10.2928 14.9094 11.2053C14.5156 12.1178 13.9813 12.9115 13.3063 13.5865C12.6313 14.2615 11.8375 14.7959 10.925 15.1896C10.0125 15.5834 9.0375 15.7803 8 15.7803ZM8 14.2803C9.675 14.2803 11.0938 13.699 12.2563 12.5365C13.4188 11.374 14 9.95527 14 8.28027C14 8.19277 13.9969 8.10215 13.9906 8.0084C13.9844 7.91465 13.9813 7.83652 13.9813 7.77402C13.9188 8.13652 13.75 8.43652 13.475 8.67402C13.2 8.91152 12.875 9.03027 12.5 9.03027H11C10.5875 9.03027 10.2344 8.8834 9.94063 8.58965C9.64688 8.2959 9.5 7.94277 9.5 7.53027V6.78027H6.5V5.28027C6.5 4.86777 6.64688 4.51465 6.94063 4.2209C7.23438 3.92715 7.5875 3.78027 8 3.78027H8.75C8.75 3.49277 8.82812 3.23965 8.98438 3.0209C9.14062 2.80215 9.33125 2.62402 9.55625 2.48652C9.30625 2.42402 9.05313 2.37402 8.79688 2.33652C8.54063 2.29902 8.275 2.28027 8 2.28027C6.325 2.28027 4.90625 2.86152 3.74375 4.02402C2.58125 5.18652 2 6.60527 2 8.28027H5.75C6.575 8.28027 7.28125 8.57402 7.86875 9.16152C8.45625 9.74902 8.75 10.4553 8.75 11.2803V12.0303H6.5V14.0928C6.75 14.1553 6.99688 14.2021 7.24063 14.2334C7.48438 14.2646 7.7375 14.2803 8 14.2803Z"
                    fill="white"
                  />
                </svg>
              }
            />
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Average Landing Page Bounce Rate"
              value={`${formatNumberStandard(
                keyFindings?.averageLandingPageBounceRate
              )}%`}
              className={styles["metric"]}
              icon={
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 8.87528C16 8.12885 15.4 7.51814 14.6667 7.51814H7.76L8.64 3.20242L8.66667 2.89028C8.66667 2.46956 8.49333 2.08956 8.22667 1.80456L7.17333 0.732422L0.586667 7.43671C0.226667 7.80314 0 8.31885 0 8.87528V17.6967C0 18.8231 0.893333 19.7324 2 19.7324H11C11.8267 19.7324 12.5333 19.2167 12.84 18.4974L15.8533 11.3181C15.9467 11.0874 16 10.8296 16 10.5717V8.87528Z"
                    fill="white"
                  />
                </svg>
              }
            />
          </div>
          <GraphWrapper
            graphComonent={
              <Chart
                chartType="BarChart"
                width="100%"
                height="100%"
                data={landingPagePerformanceData}
                options={{
                  isStacked: true,
                  chartArea: { top: 20, width: "90%", height: "85%" },
                  colors: ["#02285E", "#FC8A73"],
                  legend: {
                    position: "top",
                  },
                }}
              />
            }
            containerClassName={styles["graph-container"]}
            titleClassName={styles["sub-heading"]}
            title={
              <>
                Landing Page Performance <br />
                (Bounce Rate vs Conversion Rate)
              </>
            }
          />
        </div>
        <div className={styles["channel"]}>
          <TextElement className={styles["channel-title"]}>
            LinkedIn
          </TextElement>
          <div className={classNames(styles["channel-metrics"])}>
            <Metric
              label="Total LinkedIn Impressions"
              value={formatNumberStandard(
                keyFindings?.totalLinkedInImpressions
              )}
              className={styles["metric"]}
              iconContainerClassName={styles["icon-container"]}
              icon={
                <svg
                  width="18"
                  height="13"
                  viewBox="0 0 18 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.0002 0.280273C5.0002 0.280273 1.5842 2.76827 0.200195 6.28027C1.5842 9.79227 5.0002 12.2803 9.0002 12.2803C13.0002 12.2803 16.4162 9.79227 17.8002 6.28027C16.4162 2.76827 13.0002 0.280273 9.0002 0.280273ZM9.0002 10.2803C6.7922 10.2803 5.0002 8.48827 5.0002 6.28027C5.0002 4.07227 6.7922 2.28027 9.0002 2.28027C11.2082 2.28027 13.0002 4.07227 13.0002 6.28027C13.0002 8.48827 11.2082 10.2803 9.0002 10.2803ZM9.0002 3.88027C7.6722 3.88027 6.6002 4.95227 6.6002 6.28027C6.6002 7.60827 7.6722 8.68027 9.0002 8.68027C10.3282 8.68027 11.4002 7.60827 11.4002 6.28027C11.4002 4.95227 10.3282 3.88027 9.0002 3.88027Z"
                    fill="white"
                  />
                </svg>
              }
            />
            <Metric
              className={styles["metric"]}
              label="Total LinkedIn Clicks"
              value={formatNumberStandard(keyFindings?.totalLinkedInClicks)}
              iconContainerClassName={styles["icon-container"]}
              icon={
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.78537 9.32266C3.57972 9.25987 2.56245 8.79519 1.73356 7.92863C0.904677 7.06206 0.490234 6.01968 0.490234 4.80147C0.490234 3.54558 0.929795 2.47808 1.80892 1.59895C2.68804 0.719834 3.75554 0.280273 5.01143 0.280273C6.22964 0.280273 7.27202 0.694716 8.13859 1.5236C9.00515 2.35249 9.46983 3.36976 9.53262 4.57541L7.9502 4.10445C7.78694 3.42627 7.43529 2.87054 6.89526 2.43726C6.35523 2.00398 5.72728 1.78734 5.01143 1.78734C4.18254 1.78734 3.47297 2.08247 2.8827 2.67274C2.29243 3.26301 1.9973 3.97258 1.9973 4.80147C1.9973 5.51732 2.21394 6.14527 2.64722 6.6853C3.0805 7.22533 3.63623 7.57698 4.31441 7.74024L4.78537 9.32266ZM8.21394 9.4922L7.64879 11.2065C7.586 11.3823 7.46669 11.4671 7.29086 11.4608C7.11504 11.4545 6.99573 11.3635 6.93293 11.1877L5.21865 5.47965C5.16841 5.3415 5.19981 5.21591 5.31284 5.10288C5.42587 4.98985 5.55146 4.95845 5.68961 5.00869L11.3976 6.72297C11.5734 6.78577 11.6645 6.90508 11.6708 7.0809C11.677 7.25673 11.5923 7.37604 11.4165 7.43883L9.70216 8.00398L12.1888 10.4906C12.4023 10.7041 12.5091 10.949 12.5091 11.2253C12.5091 11.5016 12.4023 11.7465 12.1888 11.96C11.9753 12.1735 11.7273 12.2803 11.4447 12.2803C11.1621 12.2803 10.9141 12.1735 10.7006 11.96L8.21394 9.4922Z"
                    fill="white"
                  />
                </svg>
              }
            />
          </div>
          <GraphWrapper
            graphComonent={
              <Chart
                chartType="LineChart"
                width="100%"
                height="100%"
                data={linkedInEngagementData}
                legendToggle
                options={{
                  pointSize: 5,
                  curveType: "function",
                  colors: ["#02285E", "#FC8A73"],
                  chartArea: { top: 20, width: "85%", height: "81%" },
                  legend: {
                    position: "top",
                  },
                  vAxes: {
                    0: {
                      title: "Impressions",
                      format: "short",
                      textStyle: { color: "#02285E" },
                    },
                    1: {
                      title: "Clicks",
                      format: "short",
                      textStyle: { color: "#FC8A73" },
                    },
                  },
                  series: {
                    0: { targetAxisIndex: 0 }, // "Impressions" on the first Y-axis
                    1: { targetAxisIndex: 1 }, // "Clicks" on the second Y-axis
                  },
                }}
              />
            }
            containerClassName={classNames(
              styles["graph-container"],
              styles["line-container"]
            )}
            titleClassName={styles["sub-heading"]}
            title={
              <>
                LinkedIn Engagement Trends <br />
                (Impressions vs Clicks)
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MarketingDashboardReport;
