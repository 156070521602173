import React, { useEffect, useState } from "react";
import { Handle, Position, useEdges } from "@xyflow/react";
import { NodeTypes } from "@xyflow/react";
import styles from "./NodeComponents.module.scss";
import classNames from "classnames";

import TextElement from "../../../TextElement/TextElement";
import ShowMoreLess from "../../../ShowMoreLess/ShowMoreLess";
import {
  CheckSetupData,
  DelayData,
  EmailData,
  EntryData,
  PushNotificationsData,
  SocialMediaPostData,
  TriggerData,
} from "../../types";
import useNodeComponents from "./useNodeComponents";

interface NodeProps {
  id: string;
  data: { label: string; selected: boolean };
}

export const EmailNode: React.FC<NodeProps> = ({ id, data }) => {
  const passedData = data as unknown as {
    payloadId: number;
    selected: boolean;
  };
  const { fetchEmailData } = useNodeComponents();
  const [nodeData, setNodeData] = useState<EmailData>();

  useEffect(() => {
    fetchEmailData(passedData?.payloadId).then((data) => {
      setNodeData(data);
    });
  }, []);

  return (
    <div
      className={classNames(styles["node"], styles["email-point-node"], {
        "border-[1px] border-[#EB2701]": passedData.selected,
        "border-[1px] border-transparent": !passedData.selected,
      })}
    >
      <div className={styles["icon"]}>
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4 0.600098H1.6C0.72 0.600098 0.00799999 1.3201 0.00799999 2.2001L0 11.8001C0 12.6801 0.72 13.4001 1.6 13.4001H14.4C15.28 13.4001 16 12.6801 16 11.8001V2.2001C16 1.3201 15.28 0.600098 14.4 0.600098ZM14.4 3.8001L8 7.8001L1.6 3.8001V2.2001L8 6.2001L14.4 2.2001V3.8001Z"
            fill="#02285E"
          />
        </svg>
      </div>
      <TextElement className={styles["step-label"]}>Send Email</TextElement>
      <TextElement className={styles["step-description"]}>
        {nodeData?.emailName}
      </TextElement>
      <div className={styles["body"]}>
        <div>
          <TextElement className={styles["label"]}>Subject</TextElement>
          <TextElement className={styles["value"]}>
            {nodeData?.subject}
          </TextElement>
        </div>
        <div>
          <TextElement className={styles["label"]}>Body</TextElement>
          <TextElement className={styles["value"]}>
            <ShowMoreLess
              text={
                nodeData?.emailBody
                  ? nodeData?.emailBody
                  : "No email body provided"
              }
              maxWords={30}
              className={styles["show-more"]}
            />
          </TextElement>
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} />
      <Handle type="target" position={Position.Top} />
    </div>
  );
};

export const SocialMediaNode: React.FC<NodeProps> = ({ id, data }) => {
  const passedData = data as unknown as {
    payloadId: number;
    selected: boolean;
    label: string;
  };
  const { fetchSocialMediaPostData } = useNodeComponents();
  const [nodeData, setNodeData] = useState<SocialMediaPostData>();

  useEffect(() => {
    fetchSocialMediaPostData(passedData?.payloadId).then((data) => {
      setNodeData(data);
    });
  }, []);
  return (
    <div
      className={classNames(styles["node"], styles["notification-point-node"], {
        "border-[1px] border-[#EB2701]": passedData.selected,
        "border-[1px] border-transparent": !passedData.selected,
      })}
    >
      <div className={styles["icon"]}>
        <svg
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.500061 10.0211V18.021C0.500061 19.126 1.43981 20 2.60005 20H4.70002V8.0001H2.60005C1.43981 8.0001 0.500061 8.91609 0.500061 10.0211ZM21.4253 11.3551L19.6004 17.89C19.3001 19.131 18.1399 20 16.8022 20H6.80001V8.0211L8.60494 1.82615C8.81809 0.50916 10.2545 -0.374838 11.7098 0.157157C12.5802 0.476154 13.0999 1.33913 13.0999 2.22812V7.02111C13.0999 7.57311 13.5703 8.0001 14.1499 8.0001H18.6271C20.474 8.0001 21.8401 9.64207 21.4253 11.3551Z"
            fill="#02285E"
          />
        </svg>
      </div>
      <TextElement className={styles["step-label"]}>Facebook Post</TextElement>
      <TextElement className={styles["step-description"]}>
        {passedData?.label}
      </TextElement>
      <div className={styles["body"]}>
        <div>
          <TextElement className={styles["label"]}>Post Media</TextElement>
          <img className={styles["img"]} src={nodeData?.imgSrc} />
          <TextElement className={styles["label"]}>Caption</TextElement>
          <TextElement className={styles["value"]}>
            {nodeData?.text}
          </TextElement>
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} />
      <Handle type="target" position={Position.Top} />
    </div>
  );
};

export const PushNotificationsNode: React.FC<NodeProps> = ({ id, data }) => {
  const pushData = data as unknown as {
    payloadId: number;
    selected: boolean;
    label: string;
  };
  const { fetchPushNotificationsData } = useNodeComponents();
  const [nodeData, setNodeData] = useState<PushNotificationsData>();

  useEffect(() => {
    fetchPushNotificationsData(pushData?.payloadId).then((data) => {
      setNodeData(data);
    });
  }, []);

  return (
    <div
      className={classNames(styles["node"], styles["notification-point-node"], {
        "border-[1px] border-[#EB2701]": pushData.selected,
        "border-[1px] border-transparent": !pushData.selected,
      })}
    >
      <div className={styles["icon"]}>
        <svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0149 11.2075V8.01965C15.0134 6.59929 14.5094 5.22527 13.592 4.14092C12.6746 3.05657 11.4031 2.33186 10.0026 2.09513V1.00246C10.0026 0.736588 9.89696 0.481609 9.70896 0.293613C9.52096 0.105616 9.26598 0 9.00012 0C8.73425 0 8.47927 0.105616 8.29127 0.293613C8.10328 0.481609 7.99766 0.736588 7.99766 1.00246V2.09513C6.59717 2.33186 5.32564 3.05657 4.40825 4.14092C3.49087 5.22527 2.98682 6.59929 2.98538 8.01965V11.2075C2.4004 11.4143 1.8937 11.797 1.53477 12.3031C1.17583 12.8092 0.982213 13.4139 0.980469 14.0344V16.0393C0.980469 16.3052 1.08608 16.5601 1.27408 16.7481C1.46208 16.9361 1.71706 17.0418 1.98292 17.0418H5.13064C5.36149 17.8912 5.86544 18.6411 6.56475 19.1757C7.26405 19.7103 8.11986 20 9.00012 20C9.88038 20 10.7362 19.7103 11.4355 19.1757C12.1348 18.6411 12.6387 17.8912 12.8696 17.0418H16.0173C16.2832 17.0418 16.5382 16.9361 16.7262 16.7481C16.9141 16.5601 17.0198 16.3052 17.0198 16.0393V14.0344C17.018 13.4139 16.8244 12.8092 16.4655 12.3031C16.1065 11.797 15.5998 11.4143 15.0149 11.2075ZM9.00012 18.0442C8.65023 18.0421 8.30699 17.9485 8.00451 17.7726C7.70202 17.5967 7.45083 17.3448 7.27589 17.0418H10.7243C10.5494 17.3448 10.2982 17.5967 9.99572 17.7726C9.69324 17.9485 9.35 18.0421 9.00012 18.0442Z"
            fill="#02285E"
          />
        </svg>
      </div>
      <TextElement className={styles["step-label"]}>
        Send Push Notification
      </TextElement>
      <TextElement className={styles["step-description"]}>
        {pushData?.label}
      </TextElement>
      <div className={styles["body"]}>
        <div>
          <TextElement className={styles["label"]}>Messag</TextElement>
          <TextElement className={styles["value"]}>
            {nodeData?.message}
          </TextElement>
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} />
      <Handle type="target" position={Position.Top} />
    </div>
  );
};

export const EntryPointNode: React.FC<NodeProps> = ({ id, data }) => {
  const entryData = data as unknown as {
    payloadId: number;
    selected: boolean;
  };
  const { fetchEntryData } = useNodeComponents();
  const [nodeData, setNodeData] = useState<EntryData>();

  useEffect(() => {
    fetchEntryData(entryData?.payloadId).then((data) => {
      setNodeData(data);
    });
  }, []);

  return (
    <div
      className={classNames(styles["node"], styles["entry-point-node"], {
        "border-[1px] border-[#EB2701]": entryData.selected,
        "border-[1px] border-transparent": !entryData.selected,
      })}
    >
      <div className={styles["icon"]}>
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.06 10.64L11.14 8.56C11.2867 8.41333 11.36 8.22667 11.36 8C11.36 7.77333 11.2867 7.58667 11.14 7.44L9.04 5.34C8.89333 5.19333 8.71013 5.1232 8.4904 5.1296C8.27013 5.13653 8.08667 5.21333 7.94 5.36C7.79333 5.50667 7.72 5.69333 7.72 5.92C7.72 6.14667 7.79333 6.33333 7.94 6.48L8.66 7.2H6.08C5.85333 7.2 5.66667 7.27653 5.52 7.4296C5.37333 7.5832 5.3 7.77333 5.3 8C5.3 8.22667 5.3768 8.41653 5.5304 8.5696C5.68347 8.7232 5.87333 8.8 6.1 8.8H8.66L7.92 9.54C7.77333 9.68667 7.7032 9.87013 7.7096 10.0904C7.71653 10.3101 7.79333 10.4933 7.94 10.64C8.08667 10.7867 8.27333 10.86 8.5 10.86C8.72667 10.86 8.91333 10.7867 9.06 10.64ZM8.5 16C7.39333 16 6.35333 15.7899 5.38 15.3696C4.40667 14.9499 3.56 14.38 2.84 13.66C2.12 12.94 1.55013 12.0933 1.1304 11.12C0.710133 10.1467 0.5 9.10667 0.5 8C0.5 6.89333 0.710133 5.85333 1.1304 4.88C1.55013 3.90667 2.12 3.06 2.84 2.34C3.56 1.62 4.40667 1.04987 5.38 0.6296C6.35333 0.209867 7.39333 0 8.5 0C9.60667 0 10.6467 0.209867 11.62 0.6296C12.5933 1.04987 13.44 1.62 14.16 2.34C14.88 3.06 15.4499 3.90667 15.8696 4.88C16.2899 5.85333 16.5 6.89333 16.5 8C16.5 9.10667 16.2899 10.1467 15.8696 11.12C15.4499 12.0933 14.88 12.94 14.16 13.66C13.44 14.38 12.5933 14.9499 11.62 15.3696C10.6467 15.7899 9.60667 16 8.5 16Z"
            fill="#38B05A"
          />
        </svg>
      </div>
      <TextElement className={styles["step-label"]}>Entry Point</TextElement>
      <TextElement className={styles["step-description"]}>
        {nodeData?.title}
      </TextElement>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export const TriggerNode: React.FC<NodeProps> = ({ id, data }) => {
  const passedData = data as unknown as {
    payloadId: number;
    selected: boolean;
  };
  const { fetchTriggerData } = useNodeComponents();
  const [nodeData, setNodeData] = useState<TriggerData>();

  useEffect(() => {
    fetchTriggerData(passedData?.payloadId).then((data) => {
      setNodeData(data);
    });
  }, []);

  return (
    <div
      className={classNames(styles["node"], styles["trigger-point-node"], {
        "border-[1px] border-[#EB2701]": passedData.selected,
        "border-[1px] border-transparent": !passedData.selected,
      })}
    >
      <div className={styles["icon"]}>
        <svg
          width="17"
          height="18"
          viewBox="0 0 25 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.75 14.5488V18.8301C11.75 19.1634 11.9062 19.3667 12.2188 19.4401C12.5312 19.5126 12.7708 19.403 12.9375 19.1113L16.7188 11.7051C16.8229 11.4967 16.8125 11.2938 16.6875 11.0963C16.5625 10.898 16.3854 10.7988 16.1562 10.7988H13.4375V6.45508C13.4375 6.12175 13.2812 5.91341 12.9688 5.83008C12.6562 5.74675 12.4167 5.85091 12.25 6.14258L8.3125 13.6426C8.20833 13.8509 8.21375 14.0538 8.32875 14.2513C8.44292 14.4497 8.61458 14.5488 8.84375 14.5488H11.75ZM12.5 25.1738C10.7708 25.1738 9.14583 24.8455 7.625 24.1888C6.10417 23.533 4.78125 22.6426 3.65625 21.5176C2.53125 20.3926 1.64083 19.0697 0.985 17.5488C0.328333 16.028 0 14.403 0 12.6738C0 10.9447 0.328333 9.31966 0.985 7.79883C1.64083 6.278 2.53125 4.95508 3.65625 3.83008C4.78125 2.70508 6.10417 1.81424 7.625 1.15758C9.14583 0.501745 10.7708 0.173828 12.5 0.173828C14.2292 0.173828 15.8542 0.501745 17.375 1.15758C18.8958 1.81424 20.2188 2.70508 21.3438 3.83008C22.4688 4.95508 23.3592 6.278 24.015 7.79883C24.6717 9.31966 25 10.9447 25 12.6738C25 14.403 24.6717 16.028 24.015 17.5488C23.3592 19.0697 22.4688 20.3926 21.3438 21.5176C20.2188 22.6426 18.8958 23.533 17.375 24.1888C15.8542 24.8455 14.2292 25.1738 12.5 25.1738Z"
            fill="#18A2F2"
          />
        </svg>
      </div>
      <TextElement className={styles["step-label"]}>Condition</TextElement>
      <TextElement className={styles["step-description"]}>
        {nodeData?.title}
      </TextElement>
      <Handle type="source" position={Position.Bottom} />
      <Handle type="target" position={Position.Top} />
    </div>
  );
};

export const DelayNode: React.FC<NodeProps> = ({ id, data }) => {
  const passedData = data as unknown as {
    payloadId: number;
    selected: boolean;
  };
  const { fetchDelayData } = useNodeComponents();
  const [nodeData, setNodeData] = useState<DelayData>();

  useEffect(() => {
    fetchDelayData(passedData?.payloadId).then((data) => {
      setNodeData(data);
    });
  }, []);

  return (
    <div
      className={classNames(styles["node"], styles["delay-point-node"], {
        "border-[1px] border-[#EB2701]": passedData.selected,
        "border-[1px] border-transparent": !passedData.selected,
      })}
    >
      <div className={styles["icon"]}>
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 2H12V0H0V2H1V4.41421L4.58579 8L1 11.5858V14H0V16H12V14H11V11.5858L7.41421 8L11 4.41421V2ZM3 3.58579V2H9V3.58579L6 6.58579L3 3.58579Z"
            fill="#9C27B0"
          />
        </svg>
      </div>
      <TextElement className={styles["step-label"]}>Delay</TextElement>
      <TextElement className={styles["step-description"]}>
        {nodeData?.delayInterval === "specific-date"
          ? new Date(nodeData?.date as string).toDateString()
          : nodeData?.delayInterval}
      </TextElement>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export const CheckNode: React.FC<NodeProps> = ({ id, data }) => {
  const passedData = data as unknown as {
    payloadId: number;
    selected: boolean;
  };
  const { fetchCheckData } = useNodeComponents();
  const [nodeData, setNodeData] = useState<CheckSetupData>();

  useEffect(() => {
    fetchCheckData(passedData?.payloadId).then((data) => {
      setNodeData(data);
    });
  }, []);

  return (
    <div
      className={classNames(styles["node"], styles["check-point-node"], {
        "border-[1px] border-[#EB2701]": passedData.selected,
        "border-[1px] border-transparent": !passedData.selected,
      })}
    >
      <div className={styles["icon"]}>
        <svg
          width="23"
          height="24"
          viewBox="0 0 23 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.186523"
            y="11.9229"
            width="16"
            height="16"
            rx="2"
            transform="rotate(-44.6094 0.186523 11.9229)"
            fill="#EB8D01"
          />
          <path
            d="M10.9885 12.1921C11.4114 11.4286 12.2244 10.9781 12.6969 10.3024C13.1968 9.59381 12.9166 8.26993 11.4993 8.26993C10.571 8.26993 10.115 8.97307 9.92276 9.55536L8.5 8.95659C8.89002 7.78652 9.95023 6.78125 11.4938 6.78125C12.7848 6.78125 13.6692 7.36903 14.1196 8.10513C14.5042 8.73686 14.7294 9.91791 14.1361 10.7968C13.4769 11.7692 12.8452 12.0658 12.5046 12.692C12.3673 12.9447 12.3123 13.1095 12.3123 13.9225H10.7248C10.7193 13.494 10.6534 12.7964 10.9885 12.1921ZM12.598 16.1198C12.598 16.7241 12.1036 17.2185 11.4993 17.2185C10.8951 17.2185 10.4007 16.7241 10.4007 16.1198C10.4007 15.5156 10.8951 15.0212 11.4993 15.0212C12.1036 15.0212 12.598 15.5156 12.598 16.1198Z"
            fill="#FBF9FF"
          />
        </svg>
      </div>
      <TextElement className={styles["step-label"]}>Check</TextElement>
      <TextElement className={styles["step-description"]}>
        {nodeData?.condition}
      </TextElement>
      <Handle type="source" position={Position.Bottom} />
      <Handle type="target" position={Position.Top} />
    </div>
  );
};

export const EndNode: React.FC<NodeProps> = ({ id, data }) => {
  return (
    <div
      className={classNames(styles["node"], styles["end-point-node"], {
        "border-[1px] border-[#EB2701]": data.selected,
        "border-[1px] border-transparent": !data.selected,
      })}
    >
      <div className={styles["icon"]}>
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 0C5.6075 0 0 5.6074 0 12.5C0 19.3926 5.6075 25 12.5 25C19.3925 25 25 19.3926 25 12.5C25 5.6074 19.3925 0 12.5 0ZM17.9879 15.1652C17.9879 16.4798 17.4656 17.7406 16.536 18.6701C15.6063 19.5997 14.3457 20.1219 13.0311 20.1219C10.7031 20.1219 8.547 18.8968 7.3554 16.897L5.5294 13.8327C5.1952 13.3358 5.3281 12.6557 5.825 12.3214C6.0104 12.1967 6.2213 12.137 6.4302 12.137C6.7812 12.137 7.1267 12.3054 7.3363 12.6169L8.2145 13.9223C8.5345 14.3979 9.277 14.1713 9.277 13.5981V7.4475C9.277 6.8962 9.6751 6.4105 10.2218 6.3393C10.2704 6.3329 10.3184 6.3299 10.3659 6.3299C10.7373 6.3299 11.0668 6.5184 11.2636 6.8045C11.3163 6.881 11.4352 6.8562 11.4547 6.7654V5.9669C11.4547 5.3681 11.9447 4.8781 12.5436 4.8781C12.5889 4.8781 12.635 4.8809 12.6814 4.8867C13.2309 4.9551 13.6324 5.442 13.6324 5.9957V6.7654L13.6574 6.8076C13.693 6.8678 13.7792 6.8703 13.8182 6.8123L13.8191 6.811C14.0153 6.5212 14.347 6.3298 14.7213 6.3298C14.7667 6.3298 14.8127 6.3326 14.8592 6.3384C15.4086 6.4069 15.8102 6.8938 15.8102 7.4475V9.9865C15.8513 10.0816 15.9783 10.0968 16.0422 10.0154C16.2419 9.7607 16.5523 9.5963 16.8991 9.5963C16.9443 9.5963 16.9901 9.5991 17.0364 9.6049C17.5861 9.673 17.9879 10.1601 17.9879 10.714V15.1652Z"
            fill="#EB2701"
          />
        </svg>
      </div>
      <TextElement className={styles["step-label"]}>Exit</TextElement>
      <Handle type="source" position={Position.Top} />
    </div>
  );
};

// Export Node Types
export const nodeTypes: NodeTypes = {
  email: EmailNode,
  socialMedia: SocialMediaNode,
  pushNotifications: PushNotificationsNode,
  entryPoint: EntryPointNode,
  trigger: TriggerNode,
  delay: DelayNode,
  check: CheckNode,
  end: EndNode,
};
