import React, { useState } from "react";
import styles from "./AddEntryModal.module.scss";
import Modal from "../../../Modal/Modal";
import Button from "../../../Button/Button";
import Input from "../../../MarketingBriefFormCard/components/Input/Input";
import TextElement from "../../../TextElement/TextElement";
import Select from "../../../Select/Select";
import { EntryData } from "../../types";

interface AddEntryModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: EntryData) => void;
}

const AddEntryModal: React.FC<AddEntryModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const handleOk = () => {};
  const [data, setData] = useState<EntryData>({
    title: "",
  });

  return (
    <Modal
      isOpen={open}
      closeModal={onClose}
      className={styles["container"]}
      contentClassName={styles["content"]}
    >
      <TextElement className={styles["title"]}>Entry</TextElement>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(data);
        }}
      >
        <TextElement className={styles["label"]}>Title</TextElement>
        <input
          className={styles["textarea"]}
          onChange={(e) => {
            setData({
              ...data,
              title: e.target.value,
            });
          }}
        />

        <div className={styles["modal-footer"]}>
          <Button type={"submit"} onClick={handleOk}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddEntryModal;
