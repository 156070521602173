import React, { useState } from "react";
import styles from "./AddPushNotificationModal.module.scss";
import Modal from "../../../Modal/Modal";
import Button from "../../../Button/Button";
import Input from "../../../MarketingBriefFormCard/components/Input/Input";
import TextElement from "../../../TextElement/TextElement";
import Select from "../../../Select/Select";
import { PushNotificationsData } from "../../types";

interface AddPushNotificationModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: PushNotificationsData,label: string) => void;
}

const AddPushNotificationModal: React.FC<AddPushNotificationModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const handleOk = () => {};
  const [pushNotificationData, setPushNotificationData] =
    useState<PushNotificationsData>({
      message: "",
      screenType: "",
      selectedScreen: "",
    });

  const [label, setLabel] = useState<string>("");

  return (
    <Modal
      isOpen={open}
      closeModal={onClose}
      className={styles["container"]}
      contentClassName={styles["content"]}
    >
      <TextElement className={styles["title"]}>
        Add Push Notification
      </TextElement>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(pushNotificationData, label);
        }}
      >
        <TextElement className={styles["label"]}>Label</TextElement>
        <input
          value={label}
          required
          onChange={(e) => setLabel(e.target.value)}
          placeholder="Add a Label e.g Reintroduction Message"
        />
        <TextElement className={styles["label"]}>Message</TextElement>
        <textarea
          className={styles["textarea"]}
          rows={6}
          cols={10}
          required
          onChange={(e) => {
            setPushNotificationData({
              ...pushNotificationData,
              message: e.target.value,
            });
          }}
        ></textarea>
        <TextElement className={styles["label"]}>Choose screen</TextElement>
        <div className={styles["radio-field"]}>
          <label>
            {" "}
            <input
              required
              type="radio"
              name="screen-type"
              value={"all-screen"}
              onChange={(e) => {
                setPushNotificationData({
                  ...pushNotificationData,
                  screenType: e.target.value as unknown as
                    | "all-screen"
                    | "specific-screen"
                    | "",
                });
              }}
            />
            Display on all screens
          </label>
          <label>
            {" "}
            <input
              required
              type="radio"
              name="screen-type"
              value={"specific-screen"}
              onChange={(e) => {
                setPushNotificationData({
                  ...pushNotificationData,
                  screenType: e.target.value as unknown as
                    | "all-screen"
                    | "specific-screen"
                    | "",
                });
              }}
            />
            Specific screen
          </label>
        </div>

        {pushNotificationData?.screenType === "specific-screen" && (
          <Select
            options={[
              {
                value: "home",
                label: "Home Screen",
              },
              {
                value: "service",
                label: "Service Screen",
              },
              {
                value: "account",
                label: "Account Screen",
              },
            ]}
            onChange={(value) => {
              setPushNotificationData({
                ...pushNotificationData,
                selectedScreen: value as string,
              });
            }}
            value={pushNotificationData?.selectedScreen as string}
            placeholderText="Choose Screen"
          />
        )}
        <div className={styles["modal-footer"]}>
          <Button type={"submit"} onClick={handleOk}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddPushNotificationModal;
