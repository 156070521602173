import React from "react";

import styles from "./WidgetSidebar.module.scss";

import { useDnD } from "../../context/DnDContext";
import { CustomNodeTypeVariants } from "../../types";
import Button from "../../../Button/Button";
import TextElement from "../../../TextElement/TextElement";
import classNames from "classnames";

interface Props {
  onSave: () => void;
  onAlign: () => void;
}

const WidgetSidebar: React.FC<Props> = ({ onSave, onAlign }) => {
  const { setType } = useDnD();

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: CustomNodeTypeVariants
  ) => {
    console.log("dragging", nodeType);
    setType(nodeType);
    event.dataTransfer.effectAllowed = "move";
    console.log("dragstart", event);
  };

  return (
    <div className={styles["toolbox"]}>
      <div className={"flex flex-col w-full"}>
        <TextElement className={styles["toolbox-title"]}>
          Choose Widgets
        </TextElement>
        <TextElement
          className="font-light"
        >Drag and drop to the canvas to use</TextElement>
      </div>
      <div className={styles["group"]}>
        <TextElement className={styles["heading"]}>Channels</TextElement>
        <div
          draggable
          onDragStart={(event) => onDragStart(event, "email")}
          className={styles["entry"]}
        >
          <div className={styles["icon"]}>
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z"
                fill="#02285E"
              />
            </svg>
          </div>
          <div className={styles["text"]}>
            <TextElement className={styles["title"]}>Email</TextElement>
            <TextElement className={styles["description"]}>
              Send an email to selected audience
            </TextElement>
          </div>
        </div>

        <div
          draggable
          onDragStart={(event) => onDragStart(event, "socialMedia")}
          className={styles["entry"]}
        >
          <div className={styles["icon"]}>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 12.0251V21.5489C0 22.8644 1.11875 23.9048 2.5 23.9048H4.99998V9.61919H2.5C1.11875 9.61919 0 10.7097 0 12.0251ZM24.9111 13.6132L22.7386 21.3929C22.3811 22.8703 20.9999 23.9048 19.4074 23.9048H7.49997V9.64419L9.64871 2.26921C9.90246 0.70136 11.6124 -0.351023 13.3449 0.282307C14.3812 0.662068 14.9999 1.68942 14.9999 2.74775V8.45372C14.9999 9.11086 15.5599 9.61919 16.2499 9.61919H21.5799C23.7786 9.61919 25.4049 11.5739 24.9111 13.6132Z"
                fill="#02285E"
              />
            </svg>
          </div>
          <div className={styles["text"]}>
            <TextElement className={styles["title"]}>Social Media</TextElement>
            <TextElement className={styles["description"]}>
              Publish content to selected social platforms
            </TextElement>
          </div>
        </div>

        <div
          draggable
          onDragStart={(event) => onDragStart(event, "pushNotifications")}
          className={styles["entry"]}
        >
          <div className={styles["icon"]}>
            <svg
              width="25"
              height="32"
              viewBox="0 0 25 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.875 17.4688V12.5C21.8728 10.2861 21.0871 8.14448 19.6572 6.45434C18.2273 4.76419 16.2454 3.6346 14.0625 3.26563V1.5625C14.0625 1.1481 13.8979 0.750671 13.6049 0.457646C13.3118 0.16462 12.9144 0 12.5 0C12.0856 0 11.6882 0.16462 11.3951 0.457646C11.1021 0.750671 10.9375 1.1481 10.9375 1.5625V3.26563C8.75459 3.6346 6.77269 4.76419 5.34279 6.45434C3.91289 8.14448 3.12724 10.2861 3.125 12.5V17.4688C2.2132 17.7911 1.42343 18.3876 0.863966 19.1765C0.304498 19.9653 0.00271799 20.9079 0 21.875V25C0 25.4144 0.16462 25.8118 0.457646 26.1049C0.750672 26.3979 1.1481 26.5625 1.5625 26.5625H6.46875C6.82857 27.8865 7.61407 29.0553 8.70406 29.8887C9.79405 30.722 11.128 31.1734 12.5 31.1734C13.872 31.1734 15.206 30.722 16.2959 29.8887C17.3859 29.0553 18.1714 27.8865 18.5312 26.5625H23.4375C23.8519 26.5625 24.2493 26.3979 24.5424 26.1049C24.8354 25.8118 25 25.4144 25 25V21.875C24.9973 20.9079 24.6955 19.9653 24.136 19.1765C23.5766 18.3876 22.7868 17.7911 21.875 17.4688ZM12.5 28.125C11.9546 28.1217 11.4196 27.9758 10.9482 27.7016C10.4767 27.4275 10.0852 27.0348 9.8125 26.5625H15.1875C14.9148 27.0348 14.5233 27.4275 14.0518 27.7016C13.5804 27.9758 13.0454 28.1217 12.5 28.125Z"
                fill="#02285E"
              />
            </svg>
          </div>
          <div className={styles["text"]}>
            <TextElement className={styles["title"]}>
              Push Notifications
            </TextElement>
            <TextElement className={styles["description"]}>
              Sends in-app notifications to users
            </TextElement>
          </div>
        </div>
      </div>

      <div className={styles["group"]}>
        <TextElement className={styles["heading"]}>Steps</TextElement>
        <div
          draggable
          onDragStart={(event) => onDragStart(event, "entryPoint")}
          className={classNames(styles["entry"], styles["entry--start"])}
        >
          <div className={styles["icon"]}>
            <svg
              width="25"
              height="26"
              viewBox="0 0 25 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.375 16.7983L16.625 13.5483C16.8542 13.3192 16.9688 13.0275 16.9688 12.6733C16.9688 12.3192 16.8542 12.0275 16.625 11.7983L13.3438 8.51709C13.1146 8.28792 12.8283 8.17834 12.485 8.18834C12.1408 8.19917 11.8542 8.31917 11.625 8.54834C11.3958 8.77751 11.2812 9.06917 11.2812 9.42334C11.2812 9.77751 11.3958 10.0692 11.625 10.2983L12.75 11.4233H8.71875C8.36458 11.4233 8.07292 11.5429 7.84375 11.7821C7.61458 12.0221 7.5 12.3192 7.5 12.6733C7.5 13.0275 7.62 13.3242 7.86 13.5633C8.09917 13.8033 8.39583 13.9233 8.75 13.9233H12.75L11.5938 15.0796C11.3646 15.3088 11.255 15.5954 11.265 15.9396C11.2758 16.2829 11.3958 16.5692 11.625 16.7983C11.8542 17.0275 12.1458 17.1421 12.5 17.1421C12.8542 17.1421 13.1458 17.0275 13.375 16.7983ZM12.5 25.1733C10.7708 25.1733 9.14583 24.845 7.625 24.1883C6.10417 23.5325 4.78125 22.6421 3.65625 21.5171C2.53125 20.3921 1.64083 19.0692 0.985 17.5483C0.328333 16.0275 0 14.4025 0 12.6733C0 10.9442 0.328333 9.31917 0.985 7.79834C1.64083 6.27751 2.53125 4.95459 3.65625 3.82959C4.78125 2.70459 6.10417 1.81376 7.625 1.15709C9.14583 0.501256 10.7708 0.17334 12.5 0.17334C14.2292 0.17334 15.8542 0.501256 17.375 1.15709C18.8958 1.81376 20.2188 2.70459 21.3438 3.82959C22.4688 4.95459 23.3592 6.27751 24.015 7.79834C24.6717 9.31917 25 10.9442 25 12.6733C25 14.4025 24.6717 16.0275 24.015 17.5483C23.3592 19.0692 22.4688 20.3921 21.3438 21.5171C20.2188 22.6421 18.8958 23.5325 17.375 24.1883C15.8542 24.845 14.2292 25.1733 12.5 25.1733Z"
                fill="#38B05A"
              />
            </svg>
          </div>
          <div className={styles["text"]}>
            <TextElement className={styles["title"]}>Entry</TextElement>
            <TextElement className={styles["description"]}>
              Starting point of the journey
            </TextElement>
          </div>
        </div>

        <div
          draggable
          onDragStart={(event) => onDragStart(event, "trigger")}
          className={classNames(styles["entry"], styles["entry--trigger"])}
        >
          <div className={styles["icon"]}>
            <svg
              width="25"
              height="26"
              viewBox="0 0 25 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.75 14.5483V18.8296C11.75 19.1629 11.9062 19.3663 12.2188 19.4396C12.5312 19.5121 12.7708 19.4025 12.9375 19.1108L16.7188 11.7046C16.8229 11.4963 16.8125 11.2933 16.6875 11.0958C16.5625 10.8975 16.3854 10.7983 16.1562 10.7983H13.4375V6.45459C13.4375 6.12126 13.2812 5.91292 12.9688 5.82959C12.6562 5.74626 12.4167 5.85042 12.25 6.14209L8.3125 13.6421C8.20833 13.8504 8.21375 14.0533 8.32875 14.2508C8.44292 14.4492 8.61458 14.5483 8.84375 14.5483H11.75ZM12.5 25.1733C10.7708 25.1733 9.14583 24.845 7.625 24.1883C6.10417 23.5325 4.78125 22.6421 3.65625 21.5171C2.53125 20.3921 1.64083 19.0692 0.985 17.5483C0.328333 16.0275 0 14.4025 0 12.6733C0 10.9442 0.328333 9.31917 0.985 7.79834C1.64083 6.27751 2.53125 4.95459 3.65625 3.82959C4.78125 2.70459 6.10417 1.81376 7.625 1.15709C9.14583 0.501256 10.7708 0.17334 12.5 0.17334C14.2292 0.17334 15.8542 0.501256 17.375 1.15709C18.8958 1.81376 20.2188 2.70459 21.3438 3.82959C22.4688 4.95459 23.3592 6.27751 24.015 7.79834C24.6717 9.31917 25 10.9442 25 12.6733C25 14.4025 24.6717 16.0275 24.015 17.5483C23.3592 19.0692 22.4688 20.3921 21.3438 21.5171C20.2188 22.6421 18.8958 23.5325 17.375 24.1883C15.8542 24.845 14.2292 25.1733 12.5 25.1733Z"
                fill="#18A2F2"
              />
            </svg>
          </div>
          <div className={styles["text"]}>
            <TextElement className={styles["title"]}>Trigger</TextElement>
            <TextElement className={styles["description"]}>
              Start a step based on user or system activity
            </TextElement>
          </div>
        </div>

        <div
          draggable
          onDragStart={(event) => onDragStart(event, "delay")}
          className={classNames(styles["entry"], styles["entry--delay"])}
        >
          <div className={styles["icon"]}>
            <svg
              width="19"
              height="26"
              viewBox="0 0 19 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3125 3.79834H18.875V0.67334H0.125V3.79834H1.6875V7.57054L7.2903 13.1733L1.6875 18.7762V22.5483H0.125V25.6733H18.875V22.5483H17.3125V18.7762L11.7097 13.1733L17.3125 7.57054V3.79834ZM4.8125 6.27614V3.79834H14.1875V6.27614L9.5 10.9636L4.8125 6.27614Z"
                fill="#9C27B0"
              />
            </svg>
          </div>
          <div className={styles["text"]}>
            <TextElement className={styles["title"]}>Delay</TextElement>
            <TextElement className={styles["description"]}>
              Add a time-based wait
            </TextElement>
          </div>
        </div>

        <div
          draggable
          onDragStart={(event) => onDragStart(event, "check")}
          className={classNames(styles["entry"], styles["entry--check"])}
        >
          <div className={styles["icon"]}>
            <svg
              width="37"
              height="36"
              viewBox="0 0 37 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.822754"
                y="17.5527"
                width="25"
                height="25"
                rx="2"
                transform="rotate(-44.6094 0.822754 17.5527)"
                fill="#EB8D01"
              />
              <path
                d="M17.7159 17.9681C18.3643 16.7976 19.6106 16.107 20.3348 15.0712C21.1011 13.9849 20.6716 11.9554 18.499 11.9554C17.0759 11.9554 16.3769 13.0333 16.0822 13.926L13.9011 13.0081C14.499 11.2144 16.1243 9.67334 18.4906 9.67334C20.4695 9.67334 21.8253 10.5744 22.5159 11.7028C23.1053 12.6712 23.4506 14.4818 22.5411 15.8291C21.5306 17.3197 20.5622 17.7744 20.0401 18.7344C19.8295 19.1218 19.7453 19.3744 19.7453 20.6207H17.3117C17.3032 19.9639 17.2022 18.8944 17.7159 17.9681ZM20.1832 23.9891C20.1832 24.9154 19.4253 25.6733 18.499 25.6733C17.5727 25.6733 16.8148 24.9154 16.8148 23.9891C16.8148 23.0628 17.5727 22.3049 18.499 22.3049C19.4253 22.3049 20.1832 23.0628 20.1832 23.9891Z"
                fill="#FBF9FF"
              />
            </svg>
          </div>
          <div className={styles["text"]}>
            <TextElement className={styles["title"]}>Check</TextElement>
            <TextElement className={styles["description"]}>
              Evaluate if certain condition is met
            </TextElement>
          </div>
        </div>

        <div
          draggable
          onDragStart={(event) => onDragStart(event, "end")}
          className={classNames(styles["entry"], styles["entry--end"])}
        >
          <div className={styles["icon"]}>
            <svg
              width="25"
              height="26"
              viewBox="0 0 25 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_7365_6614)">
                <path
                  d="M12.5 0.17334C5.60752 0.17334 0 5.78076 0 12.6733C0 19.5659 5.60752 25.1733 12.5 25.1733C19.3925 25.1733 25 19.5659 25 12.6733C25 5.78076 19.3925 0.17334 12.5 0.17334ZM17.9879 15.3385H17.9878C17.9878 16.6532 17.4656 17.9139 16.536 18.8435C15.6063 19.773 14.3457 20.2953 13.0311 20.2953C10.7031 20.2953 8.54697 19.0702 7.35537 17.0703L5.52944 14.006C5.19517 13.5091 5.32812 12.8291 5.82505 12.4948C6.0104 12.37 6.22129 12.3103 6.43022 12.3103C6.7812 12.3103 7.12671 12.4788 7.33628 12.7903L8.21445 14.0956C8.53452 14.5713 9.277 14.3447 9.277 13.7715V7.62085C9.277 7.06953 9.6751 6.58384 10.2218 6.5126C10.2704 6.50625 10.3184 6.50322 10.3659 6.50322C10.7373 6.50322 11.0668 6.69175 11.2636 6.97783C11.3163 7.0543 11.4352 7.02954 11.4547 6.93877V6.14023C11.4547 5.54141 11.9447 5.05142 12.5436 5.05142C12.5889 5.05142 12.635 5.0542 12.6814 5.06001C13.2309 5.12847 13.6324 5.61538 13.6324 6.16904V6.93872L13.6574 6.98091C13.693 7.04111 13.7792 7.0436 13.8182 6.9856L13.8191 6.98433C14.0153 6.69458 14.347 6.50317 14.7213 6.50317C14.7667 6.50317 14.8127 6.50596 14.8592 6.51177C15.4086 6.58022 15.8102 7.06714 15.8102 7.6208V10.1599C15.8513 10.2549 15.9783 10.2702 16.0422 10.1887C16.2419 9.93408 16.5523 9.76968 16.8991 9.76968C16.9443 9.76968 16.9901 9.77246 17.0364 9.77822C17.5861 9.84639 17.9879 10.3334 17.9879 10.8873V15.3385Z"
                  fill="#EB2701"
                />
              </g>
              <defs>
                <clipPath id="clip0_7365_6614">
                  <rect
                    width="25"
                    height="25"
                    fill="white"
                    transform="translate(0 0.17334)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className={styles["text"]}>
            <TextElement className={styles["title"]}>End</TextElement>
            <TextElement className={styles["description"]}>
              The endpoint of the journey
            </TextElement>
          </div>
        </div>
      </div>

      <Button onClick={onAlign} className={styles["save-button"]}>
        Align
      </Button>

      {/* <Button onClick={onSave} className={styles["save-button"]}>
        Save Blueprint
      </Button>  */}
    </div>
  );
};

export default WidgetSidebar;
