import { useState, useEffect } from "react";
import {
  CustomEdge,
  CustomNode,
} from "../../componenets/ReactFlowContainer/types";
import HttpService from "../../lib/api";
import { toast } from "react-toastify";
import { Product } from "../../componenets/MarketingDashboardReport/types";

export type JourneyTypes =
  | "onboarding"
  | "nurture-journey-by-product"
  | "retention"
  | "upsell-and-cross-sell";

export interface Journey {
  id: number;
  type: JourneyTypes;
  blueprint: {
    Nodes: CustomNode[];
    Edges: CustomEdge[];
  };
  title: string;
  description: string;
  tags: string[];
  product?: string | null;
}

const useJourneyManagement = () => {
  const [journeys, setJourneys] = useState<Journey[]>([]);
  const [selectedJourney, setSelectedJourney] = useState<Journey | null>(null);
  const httpService = new HttpService();
  const [selectedType, setSelectedType] = useState<JourneyTypes | null>(
    "retention"
  );

  const [products, setProducts] = useState<Product[]>([]);

  const fetchJourneys = async (type: string) => {
    let query = "SELECT * FROM journeys";
    if (type && type !== "all") {
      query = `SELECT * FROM journeys WHERE type = '${type}'`;
    }
    const result = await executeRawQuery<Journey[]>(query);
    setJourneys(
      result?.map((r) => {
        return {
          ...r,
          blueprint: JSON.parse(r.blueprint as unknown as string),
        };
      })
    );
  };

  const fetchJourneyById = async (id: number) => {
    const query = `SELECT * FROM journeys WHERE id = ${id}`; // Raw SQL to get journeys
    const result = await executeRawQuery<Journey[]>(query);
    if (!result) {
      toast.error("Journey not found");
      return;
    }
    setSelectedJourney(
      result?.map((r) => {
        return {
          ...r,
        };
      })[0]
    );
  };

  const fetchJourneyByProductId = async (productId: string) => {
    const query = `SELECT * FROM journeys WHERE product_id = '${productId}'`; // Raw SQL to get journeys
    const result = await executeRawQuery<Journey[]>(query);
    if (!result) {
      toast.error("Journey not found");
      return;
    }
    setSelectedJourney(
      result?.map((r) => {
        return {
          ...r,
          blueprint: JSON.parse(r.blueprint as unknown as string),
        };
      })[0]
    );
  };

  const executeRawQuery = async <T = any>(query: string): Promise<T> => {
    try {
      const response = await httpService.post<T, { query: string }>(
        "/api/execute-raw-sql/",
        { query }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to execute raw query", error);
      toast.error("Failed to execute raw query");
      return [] as T;
    }
  };

  const handleSaveJourney = async (journey: Journey, selectedType: string) => {
    const { id, title, description, tags, type, blueprint, product } = journey;

    // Convert tags array to PostgreSQL array format
    const tagsStr = `{${tags?.join(",")}}`; // Join tags with commas and wrap them in curly braces
    // Validate type against the allowed values (optional, based on your business logic)
    const validTypes = [
      "onboarding",
      "nurture-journey-by-product",
      "retention",
      "upsell-and-cross-sell",
      "outreach-campaign",
    ];

    if (!validTypes.includes(type)) {
      toast.error("Invalid journey type");
      return;
    }

    let query = "";
    if (id != -1) {
      // Update journey SQL, include product_id only if it is not falsy
      query = `
        UPDATE journeys
        SET title = '${title}', description = '${description}', tags = '${tagsStr}',
            type = '${type}', blueprint = '${JSON.stringify(blueprint)}'
            ${product ? `, product_id = '${product}'` : ""}
        WHERE id = ${id};
      `;
    } else {
      console.log("Whole Journey", {
        title,
        description,
        tagsStr,
        type,
        product,
        blueprint,
      });

      // Insert new journey SQL, include product_id only if it is not falsy
      query = `
        INSERT INTO journeys (title, description, tags, type, blueprint
          ${product ? ", product_id" : ""})
        VALUES ('${title}', '${description}', '${tagsStr}', '${type}', '${JSON.stringify(
        blueprint
      )}'
          ${product ? `, '${product}'` : ""});
      `;
    }

    await executeRawQuery(query);
    toast.success(
      id ? "Journey updated successfully" : "Journey added successfully"
    );
    fetchJourneys(selectedType); // Refetch after save
  };

  const fetchProducts = async () => {
    const query = "SELECT * FROM product"; // Raw SQL to get products
    const result = await executeRawQuery<Product[]>(query);
    setProducts(result);
  };

  // Delete journey using raw SQL
  const handleDeleteJourney = async (id: number) => {
    const query = `DELETE FROM journeys WHERE id = ${id}`;
    await executeRawQuery(query);
    toast.success("Journey deleted successfully");
    fetchJourneys(selectedType as string); // Refetch after delete
  };

  return {
    journeys,
    selectedJourney,
    setSelectedJourney,
    handleSaveJourney,
    handleDeleteJourney,
    fetchJourneyById,
    fetchJourneyByProductId,
    selectedType,
    setSelectedType,
    fetchJourneys,
    products,
    fetchProducts,
  };
};

export default useJourneyManagement;
