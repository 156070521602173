import { useState, useEffect } from "react";
import HttpService from "../../../../lib/api";
import { toast } from "react-toastify";
import {
  CheckSetupData,
  DelayData,
  EmailData,
  EntryData,
  PushNotificationsData,
  SocialMediaPostData,
  TriggerData,
} from "../../types";

const httpService = new HttpService();

function useNodeComponents() {
  const executeRawQuery = async <T = any>(query: string): Promise<T> => {
    try {
      const response = await httpService.post<T, { query: string }>(
        "/api/execute-raw-sql/",
        { query }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to execute raw query", error);
      toast.error("Failed to execute raw query");
      return [] as T;
    }
  };

  const fetchTriggerData = async (triggerId: number) => {
    const query = `
      SELECT * FROM trigger_data
      WHERE id = '${triggerId}';
    `;

    const triggerData = await executeRawQuery<TriggerData[]>(query);

    return triggerData[0];
  };

  const fetchEmailData = async (emailId: number) => {
    const query = `
      SELECT * FROM email_data
      WHERE id = '${emailId}';
    `;

    const emailData = await executeRawQuery<EmailData[]>(query);

    return emailData[0];
  };

  const fetchDelayData = async (delayId: number) => {
    const query = `
      SELECT * FROM delay_data
      WHERE id = '${delayId}';
    `;

    const delayData = await executeRawQuery<DelayData[]>(query);

    return delayData[0];
  };

  const fetchSocialMediaPostData = async (socialMediaId: number) => {
    const query = `
      SELECT * FROM social_media_post_data
      WHERE id = '${socialMediaId}';
    `;

    const socialMediaData = await executeRawQuery<SocialMediaPostData[]>(query);

    return socialMediaData[0]; // Assuming query returns an array with one object
  };

  const fetchCheckData = async (checkId: number) => {
    const query = `
      SELECT * FROM check_data
      WHERE id = '${checkId}';
    `;

    const checkData = await executeRawQuery<CheckSetupData[]>(query);

    return checkData[0];
  };

  const fetchEntryData = async (entryId: number) => {
    const query = `
      SELECT * FROM entry_data
      WHERE id = '${entryId}';
    `;

    const entryData = await executeRawQuery<EntryData[]>(query);

    return entryData[0];
  };

  const fetchPushNotificationsData = async (
    pushNotificationId: number
  ): Promise<PushNotificationsData> => {
    const query = `
      SELECT * FROM push_notifications_data
      WHERE id = '${pushNotificationId}';
    `;

    const pushNotificationsData = await executeRawQuery<
      PushNotificationsData[]
    >(query);

    return pushNotificationsData[0]; // Assuming query returns an array with one object
  };

  return {
    fetchPushNotificationsData,
    fetchEntryData,
    fetchCheckData,
    fetchSocialMediaPostData,
    fetchDelayData,
    fetchEmailData,
    fetchTriggerData,
  };
}

export default useNodeComponents;
