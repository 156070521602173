import React, { useEffect, useState } from "react";

import styles from "./UpdateNodeEdgeData.module.scss";

import {
  CustomNodeTypeVariants,
  CustomNode,
  CustomEdge,
} from "../../types";
import Button from "../../../Button/Button";
import AddFieldModal from "../AddFieldModal/AddFieldModal";

interface UpdateNodeEdgeProps {
  selectedElement: CustomNode | CustomEdge | null;
  updateNode: (node: CustomNode) => void;
  updateEdge: (edge: CustomEdge) => void;
  onClose: () => void;
}

const UpdateNodeEdge: React.FC<UpdateNodeEdgeProps> = ({
  selectedElement,
  updateNode,
  updateEdge,
  onClose,
}) => {
  const [formData, setFormData] = useState<{ [key: string]: string | boolean }>(
    {}
  );

  const [isAddFieldModalVisible, setIsAddFieldModalVisible] = useState(false);

  // useEffect(() => {
  //   if (
  //     selectedElement &&
  //     typeof selectedElement !== "undefined" &&
  //     "data" in selectedElement
  //   ) {
  //     setFormData(
  //       Object.fromEntries(
  //         Object.entries(selectedElement.data).filter(
  //           ([key, value]) => value !== undefined && key !== "selected"
  //         )
  //       )
  //     );
  //   } else if (selectedElement) {
  //     setFormData({ label: selectedElement?.label });
  //   }
  // }, [selectedElement]);

  const handleInputChange = (key: string, value: string | boolean): void => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSave = (): void => {
    if (!selectedElement) return;

    // if ("data" in selectedElement) {
    //   switch (selectedElement?.type) {
    //     case "action":
    //       updateNode({
    //         ...selectedElement,
    //         data: { ...formData, label: formData.label as string },
    //       } as CustomActionNode);
    //       break;
    //     case "diamond":
    //       updateNode({
    //         ...selectedElement,
    //         data: { ...formData, label: formData.label as string },
    //       } as CustomDecisionNode);
    //       break;
    //     case "delay":
    //       updateNode({
    //         ...selectedElement,
    //         data: { ...formData, label: formData.label as string },
    //       } as CustomDelayNode);
    //       break;
    //     case "email":
    //       updateNode({
    //         ...selectedElement,
    //         data: { ...formData, label: formData.label as string },
    //       } as CustomEmailNode);
    //       break;
    //     case "entry":
    //       updateNode({
    //         ...selectedElement,
    //         data: { ...formData, label: formData.label as string },
    //       } as CustomEntryNode);
    //       break;
    //     case "end":
    //       updateNode({
    //         ...selectedElement,
    //         data: { ...formData, label: formData.label as string },
    //       } as CustomEndNode);
    //       break;
    //     case "check":
    //       updateNode({
    //         ...selectedElement,
    //         data: { ...formData, label: formData.label as string },
    //       } as CustomCheckNode);
    //       break;
    //     default:
    //       break;
    //   }
    // } else {
    //   updateEdge({ ...selectedElement, label: formData.label as string });
    // }

    onClose();
  };

  const handleDeleteField = (key: string) => {
    if (key !== "label") {
      const newFormData = { ...formData };
      delete newFormData[key];
      setFormData(newFormData);
    }
  };

  if (!selectedElement) return null;

  return (
    <div className={styles.sidebar}>
      <AddFieldModal
        visible={isAddFieldModalVisible}
        onClose={() => setIsAddFieldModalVisible(false)}
        onSubmit={(label, value) => {
          setFormData((prev) => ({ ...prev, [label]: value }));
          setIsAddFieldModalVisible(false);
        }}
      />
      <div className={styles.header}>
        <h3>
          {selectedElement && "data" in selectedElement
            ? `Update Node: ${selectedElement.id}`
            : `Update Edge: ${selectedElement.id}`}
        </h3>
        <button className={styles.closeButton} onClick={onClose}>
          ✖
        </button>
      </div>
      <Button
        onClick={() => setIsAddFieldModalVisible(true)}
        variant="secondary"
      >
        Add a new Field
      </Button>
      <div className={styles.content}>
        {selectedElement && "data" in selectedElement ? (
          Object.entries(formData).map(([key, value]) => (
            <div key={key} className={styles.field}>
              <label>{key}</label>
              <div className={styles.inputWithDelete}>
                <input
                  type="text"
                  value={value as string}
                  className={styles.textarea}
                  onChange={(e) => handleInputChange(key, e.target.value)}
                />
                {key !== "label" && (
                  <button
                    onClick={() => handleDeleteField(key)}
                    className={styles.deleteButton}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className={styles.field}>
            <label>Label</label>
            <input
              type="text"
              value={formData.label as string}
              onChange={(e) => handleInputChange("label", e.target.value)}
            />
          </div>
        )}
      </div>

      <div className={styles.footer}>
        <button className={styles.saveButton} onClick={handleSave}>
          Save
        </button>
        <button className={styles.cancelButton} onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UpdateNodeEdge;
