import { Disclosure } from "@headlessui/react";
import styles from "./Disclosure.module.scss";
import TextElement from "../TextElement/TextElement";
import classNames from "classnames";
import { useEffect, useState } from "react";

interface DisclosureComponentProps {
  className?: string;
  header?: React.ReactNode;
  content?: React.ReactNode;
  headerClassName?: string;
  contentClassName?: string;
  openHeaderClassName?: string;
  defaultOpen?: boolean;
}

const DisclosureComponent: React.FC<DisclosureComponentProps> = ({
  className,
  header,
  content,
  headerClassName,
  contentClassName,
  openHeaderClassName,
  defaultOpen,
}) => {
  const openClassName = openHeaderClassName || styles["open"];
  // const [openPanel, setOpenPanel] = useState(defaultOpen);
  // useEffect(() => {
  //   setOpenPanel(defaultOpen);
  // }, [defaultOpen]);

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open, close }) => (
        <div className={classNames(styles["container"], className)}>
          <Disclosure.Button
            className={classNames(
              styles["bar"],
              open ? openClassName : "",
              headerClassName
            )}
            // onClick={() => {
            //   setOpenPanel(!openPanel);
            // }}
          >
            {header}
            <svg
              width="17"
              height="10"
              viewBox="0 0 17 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: open ? "rotate(0deg)" : "rotate(180deg)",
              }}
            >
              <path
                d="M1.90283 10L8.09717 3.81916L14.2915 10L16.1943 8.09717L8.09717 0L0 8.09717L1.90283 10Z"
                // fill={open ? "white" : "#02285E"}
              />
            </svg>
          </Disclosure.Button>
          {/* {openPanel && ( */}
            <Disclosure.Panel
              className={classNames(styles["panel"], contentClassName)}
            >
              {content}
            </Disclosure.Panel>
          {/* )} */}
        </div>
      )}
    </Disclosure>
  );
};

export default DisclosureComponent;
