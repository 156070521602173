import React, { useEffect, useState } from "react";

import styles from "./StrategiesList.module.scss";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { Button, TextElement } from "../../componenets";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import TableComponent from "../../componenets/Table/Table";
import SearchInput from "../../componenets/SearchInput/SearchInput";
import BinIcon from "../../icons/BinIcon";
import {
  CampaignStatusType,
  fetchCampaigns,
} from "../../redux/campaignPinningSlice";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import { UserRoleType } from "../../redux/authSlice";
import ApproverViewLayout from "../../componenets/ApproverViewLayout/ApproverViewLayout";
import Tag from "../../componenets/Tag/Tag";

interface Props {
  userRole: UserRoleType;
}

enum StrategyType {
  OUTREACH = "Outreach Strategy",
  CLOSING = "Closing Strategy",
}

const StrategiesList: React.FC<Props> = (props) => {
  const { userRole } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const getTagBackgroundColor = (text: string) => {
    switch (text as CampaignStatusType) {
      case CampaignStatusType.APPROVED:
        return "#EB2701";
      case CampaignStatusType.ACTIVE:
        return "#38B05A";
      case CampaignStatusType.REJECTED:
        return "#EB8D01";
      default:
        return "#02285E";
    }
  };

  const strategies = [
    {
      id: 1,
      name: "Staff Augmentation Sales Outreach",
      createdBy: "Sabeh Hassan",
      type: StrategyType.OUTREACH,
      status: CampaignStatusType.NEEDS_APPROVAL,
    },
    {
      id: 2,
      name: "Cybersecurity Sales Outreach",
      createdBy: "Sabeh Hassan",
      type: StrategyType.OUTREACH,
      status: CampaignStatusType.DRAFT,
    },
    {
      id: 3,
      name: "Digital Transformation Sales Outreach",
      createdBy: "Sabeh Hassan",
      type: StrategyType.OUTREACH,
      status: CampaignStatusType.ACTIVE,
    },
    {
      id: 4,
      name: "Cybersecurity Sales - Closing",
      createdBy: "Sabeh Hassan",
      type: StrategyType.CLOSING,
      status: "Approved",
    },
    {
      id: 5,
      name: "Digital Transformation Sales - Closing",
      createdBy: "Sabeh Hassan",
      type: StrategyType.CLOSING,
      status: "Rejected",
    },
  ];

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, []);

  const getUserLayoutWrapper = (children: React.ReactNode) => {
    const header = (
      <TextElement className={styles["header-text"]}>
        <button
          onClick={() => {
            navigate(ROUTE_DEFINITIONS.HOME.path);
          }}
          className={styles["back-link"]}
        >
          Back to Main Funnel
        </button>
        <br />
        Sales Campaigns
      </TextElement>
    );
    if (userRole === UserRoleType.MARKETING)
      return (
        <MarkerterViewLayout header={header}>{children}</MarkerterViewLayout>
      );
    else if (userRole === UserRoleType.PRIMARY_APPROVER)
      return (
        <ApproverViewLayout header={header}>{children}</ApproverViewLayout>
      );
    else {
      return null;
    }
  };

  return getUserLayoutWrapper(
    <>
      <div className={styles["filter-container"]}>
        <SearchInput
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search Sales Campaigns"
        />

        <button className={styles["delete-btn"]} onClick={() => {}}>
          <BinIcon />
          <span>Delete</span>
        </button>
      </div>
      <div className={styles["table-wrapper"]}>
        <TableComponent
          rows={strategies?.map((strategy) => {
            return {
              ...strategy,
              name: (
                <button
                  onClick={() => {
                    navigate(ROUTE_DEFINITIONS.PRESENT_AND_PROVE_VALUE.path);
                  }}
                  className={styles["campaign-name"]}
                >
                  {strategy?.name}
                </button>
              ),
              type: (
                <TextElement
                  className={
                    strategy?.type === StrategyType.OUTREACH
                      ? styles["outreach"]
                      : styles["closing"]
                  }
                >
                  {strategy?.type}
                </TextElement>
              ),
              status: (
                <Tag
                  text={strategy?.status?.toString() as string}
                  backgroundColor={getTagBackgroundColor(
                    strategy?.status as CampaignStatusType
                  )}
                />
              ),
            };
          })}
          columns={[
            {
              name: "Campaign",
              key: "name",
            },
            {
              name: "Created By",
              key: "createdBy",
            },
            {
              name: "Type",
              key: "type",
            },
            {
              name: "Status",
              key: "status",
            },
          ]}
          rowClassName={styles["table-row"]}
        />
      </div>
    </>
  );
};

export default StrategiesList;
