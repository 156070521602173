import React, { useState } from "react";
import styles from "./AddCheckSetupModal.module.scss";
import Modal from "../../../Modal/Modal";
import Button from "../../../Button/Button";
import TextElement from "../../../TextElement/TextElement";
import Select from "../../../Select/Select";
import { CheckSetupData } from "../../types";

interface AddCheckSetupModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: CheckSetupData) => void;
}

const AddCheckSetupModal: React.FC<AddCheckSetupModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const handleOk = () => {};
  const [data, setData] = useState<CheckSetupData>({
    condition: "",
  });

  return (
    <Modal
      isOpen={open}
      closeModal={onClose}
      className={styles["container"]}
      contentClassName={styles["content"]}
    >
      <TextElement className={styles["title"]}>
        Select Condition to Check
      </TextElement>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(data);
        }}
      >
        <TextElement className={styles["label"]}>Check if</TextElement>
        <div className={styles["group"]}>
          <Select
            className={styles["select"]}
            options={[
              {
                value: "Opened?",
                label: "Opened?",
              },
              {
                value: "Link clicked?",
                label: "Link clicked?",
              },
              {
                value: "Unsubscribed?",
                label: "Unsubscribed?",
              },
              {
                value: "Read?",
                label: "Read?",
              },
              {
                value: "Reacted?",
                label: "Reacted?",
              },
            ]}
            onChange={(value) => {
              setData({
                ...data,
                condition: value as string,
              });
            }}
            value={data.condition}
            placeholderText="Choose Screen"
          />
        </div>
        <div className={styles["modal-footer"]}>
          <Button type={"submit"} onClick={handleOk}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddCheckSetupModal;
