import React, { useEffect, useRef, useState } from "react";
import styles from "./MyAccount.module.scss";
import { UserRoleType } from "../../redux/authSlice";

import { useNavigate } from "react-router-dom";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import ApproverViewLayout from "../../componenets/ApproverViewLayout/ApproverViewLayout";
import CMOViewLayout from "../../componenets/CMOViewLayout/CMOViewLayout";
import { Button, TextElement } from "../../componenets";
import InputField from "../../componenets/Input/input";
import { Avatar } from "@files-ui/react";
import Pen from "../../icons/Pen";
import { useAppSelector } from "../../redux/hooks";

interface MyAccountProps {
  role: UserRoleType;
}

const MyAccount: React.FC<MyAccountProps> = (props) => {
  const { role } = props;
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState<string>("John Smith");
  const textMirrorRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
 
  const { loggedInUser } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (textMirrorRef.current && inputRef.current) {
      textMirrorRef.current.textContent =
        inputValue || inputRef.current.placeholder;
      const mirrorWidth = textMirrorRef.current.offsetWidth + 10;
      inputRef.current.style.width = mirrorWidth + "px";
    }
  }, [inputValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const [imageSource, setImageSource] = React.useState<
    string | File | undefined
  >("");

  const getAuthWrapper = (role: UserRoleType, children: React.ReactNode) => {
    const header = (
      <TextElement className={styles["header-text"]}>My Account</TextElement>
    );
    switch (role) {
      case UserRoleType.MARKETING:
        return (
          <MarkerterViewLayout header={header}>{children}</MarkerterViewLayout>
        );
      case UserRoleType.PRIMARY_APPROVER:
      case UserRoleType.SECONDARY_APPROVER:
        return (
          <ApproverViewLayout header={header}>{children}</ApproverViewLayout>
        );
      case UserRoleType.CMO:
        return <CMOViewLayout header={header}>{children}</CMOViewLayout>;
      default:
        navigate("/");
        return null;
    }
  };

  const handleChangeSource = (selectedFile: File) => {
    setImageSource(selectedFile);
  };

  return getAuthWrapper(
    role,
    <div className={styles["container"]}>
      <TextElement className={styles["heading"]}>Profile</TextElement>
      <div className={styles["divider"]}></div>
      <div className={styles["user-info"]}>
        <Avatar
          src={imageSource || "/avatar.png"}
          alt="Avatar"
          variant="square"
          onChange={handleChangeSource}
          className={styles["avatar"]}
          changeLabel={
            <div className={styles["change-avatar"]}>
              <TextElement>Change</TextElement>
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.83355 0L5.20688 1.77778H2.38911C1.41133 1.77778 0.611328 2.57778 0.611328 3.55556V14.2222C0.611328 15.2 1.41133 16 2.38911 16H16.6113C17.5891 16 18.3891 15.2 18.3891 14.2222V3.55556C18.3891 2.57778 17.5891 1.77778 16.6113 1.77778H13.7935L12.1669 0H6.83355ZM9.50022 13.3333C7.04688 13.3333 5.05577 11.3422 5.05577 8.88889C5.05577 6.43556 7.04688 4.44444 9.50022 4.44444C11.9535 4.44444 13.9447 6.43556 13.9447 8.88889C13.9447 11.3422 11.9535 13.3333 9.50022 13.3333Z"
                  fill="white"
                />
              </svg>
            </div>
          }
        />
        <div className={styles["meta-info"]}>
          <div className={styles["editable-input"]}>
            <input
              type="text"
              className={styles["auto-width-input"]}
              placeholder="Type here..."
              value={inputValue}
              onChange={handleInputChange}
              ref={inputRef}
            />
            <span className={styles["text-mirror"]} ref={textMirrorRef}></span>
            <Pen />
          </div>
          <TextElement className={styles["role"]}>{role}</TextElement>
        </div>
      </div>
      <form className={styles["user-form"]}>
        <div className={styles["two-col"]}>
          <InputField
            label="Email"
            className={styles["input-field"]}
            inputClassName={styles["input"]}
            value={loggedInUser?.email}
            disabled
          />
          <InputField
            label="Password"
            className={styles["input-field"]}
            inputClassName={styles["input"]}
            type="password"
          />
        </div>
        <TextElement className={styles["sub-heading"]}>
          Change Password
        </TextElement>
        <div className={styles["two-col"]}>
          <InputField
            label="New Password"
            className={styles["input-field"]}
            inputClassName={styles["input"]}
            type="password"
          />
          <InputField
            label="Confirm New Password"
            className={styles["input-field"]}
            inputClassName={styles["input"]}
            type="password"
          />
        </div>
        <Button className={styles["save-btn"]}>Save New Password</Button>
      </form>
    </div>
  );
};

export default MyAccount;
