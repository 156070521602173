import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "../lib/api";

interface SideBarState {
  dropDownSections: {
    marketing: boolean;
    sales: boolean;
    customer: boolean;
    discover: boolean;
  };
}
const initialState: SideBarState = {
  dropDownSections: {
    marketing: true,
    sales: true,
    customer: true,
    discover: true,
  },
};

const httpService = new HttpService();

const sideBarSlice = createSlice({
  name: "sideBarSlice",
  initialState,
  reducers: {
    updateDropDownSections: (
      state,
      action: {
        payload: {
          marketing: boolean;
          sales: boolean;
          customer: boolean;
          discover: boolean;
        };
      }
    ) => {
      state.dropDownSections = action.payload;
    },
  },
});

export const { updateDropDownSections } = sideBarSlice.actions;
export default sideBarSlice.reducer;
