import React, { useState } from "react";
import styles from "./AddDelayModal.module.scss";
import Modal from "../../../Modal/Modal";
import Button from "../../../Button/Button";
import Input from "../../../MarketingBriefFormCard/components/Input/Input";
import TextElement from "../../../TextElement/TextElement";
import Select from "../../../Select/Select";
import { DelayData } from "../../types";

interface AddDelayModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: DelayData) => void;
}

const AddDelayModal: React.FC<AddDelayModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const handleOk = () => {};
  const [data, setData] = useState<DelayData>({
    delayInterval: "",
    date: "",
    
  });

  return (
    <Modal
      isOpen={open}
      closeModal={onClose}
      className={styles["container"]}
      contentClassName={styles["content"]}
    >
      <TextElement className={styles["title"]}>Select Delay</TextElement>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(data);
        }}
      >
        <TextElement className={styles["label"]}>Delay by</TextElement>
        <div
          className={styles["group"]}
        >
          <Select
            className={styles["select"]}
            options={[
              {
                value: "1 day",
                label: "1 day",
              },
              {
                value: "3 days",
                label: "3 days",
              },
              {
                value: "1 week",
                label: "1 week",
              },
              {
                value: "1 month",
                label: "1 month",
              },
              {
                value: "3 months",
                label: "3 months",
              },
              {
                value: "6 months",
                label: "6 months",
              },
              {
                value: "specific-date",
                label: "specific date (specify)",
              },
            ]}
            onChange={(value) => {
              setData({
                ...data,
                delayInterval: value as string,
              });
            }}
            value={data.delayInterval}
            placeholderText="Choose Screen"
          />
          {data?.delayInterval === "specific-date" && (
            <Input
              label={""}
              value={new Date().toISOString().substring(0, 10)}
              type="date"
              onChange={(e) => {
                setData({
                  ...data,
                  date: e.target.value,
                });
              }}
              inputClassName={styles["date-input"]}
              placeholder="Specific Date"
            />
          )}
        </div>
        <div className={styles["modal-footer"]}>
          <Button type={"submit"} onClick={handleOk}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddDelayModal;
