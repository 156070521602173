import React from "react";
import styles from "./PerformanceMetricsCard.module.scss";
import classNames from "classnames";
import Card, { CardType } from "../Card/Card";
import TextElement from "../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface Props {
  className?: string;
  title: string;
  rate: string;
  description: string;
  id: number;
  cardType: CardType;
}

const PerformanceMetricsCard: React.FC<Props> = ({
  className,
  title,
  rate,
  description, 
  id,
  cardType,
}) => {
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );


  const handlePin = (isPinned: boolean) => {
    console.log(selectedCampaign,isPinned, "=====" )
    if (selectedCampaign) {
      if (!isPinned) {
        dispatch(
          updateCampaign({
            ...selectedCampaign,
            includedData: {
              ...selectedCampaign.includedData,
              pastInsights: selectedCampaign.includedData.pastInsights.filter(
                (insightId) => insightId !== id
              ),
            },
          })
        );
      } else {
        if (selectedCampaign.includedData.pastInsights.includes(id)) return;
        const updatedPastInsights = [
          ...selectedCampaign.includedData.pastInsights,
          id,
        ];
        console.log(updatedPastInsights, "*******")
        dispatch(
          updateCampaign({
            ...selectedCampaign,
            includedData: {
              ...selectedCampaign.includedData,
              pastInsights: updatedPastInsights,
            },
          })
        );
      }
    }
  };

  return (
    <Card
      className={classNames(styles.card, styles["projection-card"], className)}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      type={cardType}
    >
      <TextElement className={styles.title}>{title}</TextElement>
      <TextElement className={styles.rate}>{rate}</TextElement>
      <TextElement className={styles.description}>{description}</TextElement>
    </Card>
  );
};

export default PerformanceMetricsCard;
