import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  KeyFindings,
  Product,
  Campaign,
  ScatterChartData,
  DateCapturedValues,
} from "./types";
import HttpService from "../../lib/api";

const Month_Names = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const useMarketingDashboardReport = (
  product: string,
  campaign: string,
  dateCaptured: DateCapturedValues
) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  const dateCapturedOptions: { label: string; value: DateCapturedValues }[] = [
    { label: "Last Month", value: "lastMonth" },
    { label: "Last 2 Months", value: "last2Months" },
    { label: "Last 3 Months", value: "last3Months" },
    { label: "Last 4 Months", value: "last4Months" },
    { label: "Last 6 Months", value: "last6Months" },
  ];

  const [keyFindings, setKeyFindings] = useState<KeyFindings>({
    totalContactsGenerated: 0,
    totalEngagedContacts: 0,
    averageEngagementScore: 0,
    averageEmailOpenRate: 0,
    averageEmailClickRate: 0,
    landingPageConversionRate: 0,
    totalEmailsOpened: 0,
    totalEmailsSent: 0,
    totalLandingPageVisits: 0,
    averageLandingPageBounceRate: 0,
    totalLinkedInClicks: 0,
    totalLinkedInImpressions: 0,
    totalContacts: 0,
    idleContactsPercentage: 0,
    totalMqls: 0,
    dormantMqlsPercentage: 0,
    leadDropOffRate: 0,
    likelyToCloseOpportunities: 0,
  });

  const [costEngagementScatterData, setCostEngagementScatterData] = useState<
    (string | number)[][]
  >([]);

  const [contactsTrendData, setContactsTrendData] = useState<
    (string | number)[][]
  >([]);

  const [performanceAcrossKeyMetrics, setPerformanceAcrossKeyMetrics] =
    useState<(string | number)[][]>([]);

  const [emailPerformanceData, setEmailPerformanceData] = useState<
    (string | number)[][]
  >([]);

  const [landingPagePerformanceData, setLandingPagePerformanceData] = useState<
    (string | number)[][]
  >([]);

  const [linkedInEngagementData, setLinkedInEngagementData] = useState<
    (string | number)[][]
  >([]);

  const httpService = new HttpService();

  const executeRawQuery = async <T = any>(query: string): Promise<T> => {
    try {
      const response = await httpService.post<
        T,
        {
          query: string;
        }
      >("/api/execute-raw-sql/", { query });
      return response.data;
    } catch (error) {
      console.error("Failed to execute raw query", error);
      toast.error("Failed to execute raw query");
      return [] as T;
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsReponse = await executeRawQuery<Product[]>(
          "SELECT * FROM public.product;"
        );
        setProducts(productsReponse);
      } catch (error) {
        console.error("Error fetching products", error);
        toast.error("Error fetching products");
      }
    };

    const fetchCampaigns = async () => {
      try {
        let query = "SELECT * FROM public.campaign;";
        if (product) {
          query = `SELECT * FROM public.campaign WHERE product_id = ${product};`;
        }
        const campaignsResponse = await executeRawQuery<Campaign[]>(query);
        setCampaigns(campaignsResponse);
      } catch (error) {
        console.error("Error fetching campaigns", error);
        toast.error("Error fetching campaigns");
      }
    };

    fetchProducts();
    fetchCampaigns();
  }, [product, campaign]);

  useEffect(() => {
    const joinClause = `
        JOIN campaign c ON ca.campaign_id = c.campaign_id
        JOIN product p ON ca.product_id = p.product_id
    `;

    const generateWhereClause = (
      campaign?: string,
      product?: string
    ): string => {
      let whereClause = "";

      if (campaign && product) {
        whereClause = `WHERE c.campaign_id = '${campaign}' AND p.product_id = '${product}'`;
      } else if (campaign) {
        whereClause = `WHERE c.campaign_id = '${campaign}'`;
      } else if (product) {
        whereClause = `WHERE p.product_id = '${product}'`;
      }

      return whereClause;
    };

    const addDateCondition = (
      whereClause: string,
      dateCaptured?: string
    ): string => {
      let dateCondition = "";
      switch (dateCaptured) {
        case "lastMonth":
          dateCondition =
            "month >= date_trunc('month', now()) - interval '1 month' AND month < date_trunc('month', now())";
          break;
        case "last2Months":
          dateCondition =
            "month >= date_trunc('month', now()) - interval '2 months' AND month < date_trunc('month', now())";
          break;
        case "last3Months":
          dateCondition =
            "month >= date_trunc('month', now()) - interval '3 months' AND month < date_trunc('month', now())";
          break;
        case "last4Months":
          dateCondition =
            "month >= date_trunc('month', now()) - interval '4 months' AND month < date_trunc('month', now())";
          break;
        case "last6Months":
          dateCondition =
            "month >= date_trunc('month', now()) - interval '6 months' AND month < date_trunc('month', now())";
          break;
        default:
          dateCondition = "";
      }
      if (dateCondition) {
        whereClause += whereClause
          ? ` AND ${dateCondition}`
          : `WHERE ${dateCondition}`;
      }
      return whereClause;
    };

    const fetchKeyFindings = async () => {
      try {
        // Start by applying the date condition
        let where = addDateCondition("", dateCaptured);

        // Add product filter if provided
        if (product) {
          // If where is not initialized yet, start with "WHERE"
          if (!where) {
            where = `WHERE product_id = ${product}`;
          } else {
            where += ` AND product_id = ${product}`;
          }
        }

        // Add campaign filter if provided
        if (campaign) {
          // If where is not initialized yet, start with "WHERE"
          if (!where) {
            where = `WHERE campaign_id = '${campaign}'`;
          } else {
            where += ` AND campaign_id = '${campaign}'`;
          }
        }

        // Key Findings Query with dynamic filters
        let keyFindingsQuery = `
          SELECT 
            SUM(total_contacts) AS total_contacts,
            ROUND((SUM(idle_contacts) * 1.0 / SUM(total_contacts)) * 100, 2) AS idle_contacts_percentage,
            SUM(engaged_contacts) AS total_mqls,
            ROUND((SUM(idle_contacts) * 1.0 / SUM(engaged_contacts)) * 100, 2) AS dormant_mqls_percentage,
            ROUND(((SUM(total_contacts) - SUM(engaged_contacts)) * 1.0 / SUM(total_contacts)) * 100, 2) AS lead_drop_off_rate,
            '8%' AS likely_to_close_opportunities -- Placeholder: Calculate based on assumptions or other criteria
          FROM 
            campaign_analysis ca
          ${where}
        `;

        // Metrics Query with dynamic filters
        let metricsQuery = `
          SELECT 
            SUM(total_contacts) AS total_contacts_generated, 
            SUM(engaged_contacts) AS total_engaged_contacts, 
            SUM(engaged_contacts) * 1.0 / SUM(total_contacts) * 100 AS average_engagement_score, 
            SUM(emails_opened) AS total_emails_opened,
            SUM(emails_sent) AS total_emails_sent,
            SUM(emails_opened) * 1.0 / SUM(emails_sent) * 100 AS average_email_open_rate, 
            SUM(emails_clicked) * 1.0 / SUM(emails_sent) * 100 AS average_email_click_rate, 
            SUM(landing_page_visits) AS total_landing_page_visits,
            SUM(landing_page_conversions) * 1.0 / SUM(landing_page_visits) * 100 AS landing_page_conversion_rate,
            SUM(landing_page_visits - landing_page_conversions) * 1.0 / SUM(landing_page_visits) * 100 AS average_landing_page_bounce_rate,
            SUM(linkedin_clicks) AS total_linkedin_clicks,
            SUM(linkedin_impressions) AS total_linkedin_impressions
          FROM campaign_analysis ca
          ${where}
        `;

        // Execute the queries
        const metrics = await executeRawQuery(metricsQuery);
        const keyFindingsResponse = await executeRawQuery<
          {
            totalContacts: number;
            idleContactsPercentage: number;
            totalMqls: number;
            dormantMqlsPercentage: number;
            leadDropOffRate: number;
            likelyToCloseOpportunities: number;
          }[]
        >(keyFindingsQuery);

        // Set the fetched data
        setKeyFindings({
          totalContacts: keyFindingsResponse[0].totalContacts || 0,
          idleContactsPercentage: keyFindingsResponse[0].idleContactsPercentage || 0,
          totalMqls: keyFindingsResponse[0].totalMqls || 0,
          dormantMqlsPercentage: keyFindingsResponse[0].dormantMqlsPercentage || 0,
          leadDropOffRate: keyFindingsResponse[0].leadDropOffRate || 0,
          likelyToCloseOpportunities:
            keyFindingsResponse[0].likelyToCloseOpportunities || 0,

          totalContactsGenerated: metrics[0].totalContactsGenerated,
          totalEngagedContacts: metrics[0].totalEngagedContacts,
          averageEngagementScore: metrics[0].averageEngagementScore,
          averageEmailOpenRate: metrics[0].averageEmailOpenRate,
          averageEmailClickRate: metrics[0].averageEmailClickRate,
          landingPageConversionRate: metrics[0].landingPageConversionRate,
          totalEmailsOpened: metrics[0].totalEmailsOpened,
          totalEmailsSent: metrics[0].totalEmailsSent,
          totalLandingPageVisits: metrics[0].totalLandingPageVisits,
          averageLandingPageBounceRate: metrics[0].averageLandingPageBounceRate,
          totalLinkedInClicks: metrics[0].totalLinkedinClicks,
          totalLinkedInImpressions: metrics[0].totalLinkedinImpressions,
        });
      } catch (error) {
        console.error("Error fetching key findings", error);
        toast.error("Error fetching key findings");
      }
    };

    const fetchEngagementData = async () => {
      try {
        let query = `
          SELECT 
            to_char(month, 'YYYY-MM') AS month, 
            AVG(monthly_cost / NULLIF(engaged_contacts, 0)) AS cost_per_engagement, 
            AVG(engaged_contacts * 1.0 / NULLIF(total_contacts, 0) * 100) AS engagement_rate 
          FROM campaign_analysis ca
          ${joinClause}
        `;

        let whereClause = generateWhereClause(campaign, product);
        whereClause = addDateCondition(whereClause, dateCaptured);

        query += whereClause;
        query += `
          GROUP BY to_char(month, 'YYYY-MM')
          ORDER BY to_char(month, 'YYYY-MM');
        `;

        const response = await executeRawQuery<
          {
            month: string;
            costPerEngagement: number;
            engagementRate: number;
          }[]
        >(query);

        setCostEngagementScatterData([
          ["Month", "Cost per Engagement", "Engagement Rate"],
          ...response.map(
            (row) =>
              [
                Month_Names[new Date(row.month)?.getMonth()],
                row.costPerEngagement,
                row.engagementRate,
              ] as [string, number, number]
          ),
        ]);
      } catch (error) {
        console.error("Error fetching scatter chart data", error);
        toast.error("Error fetching scatter chart data");
      }
    };

    const fetchContactsTrendData = async () => {
      try {
        let query = `
          SELECT 
            to_char(month, 'YYYY-MM') AS month,
            SUM(total_contacts) AS total_contacts,
            SUM(engaged_contacts) AS engaged_contacts,
            SUM(idle_contacts) AS idle_contacts
          FROM campaign_analysis ca
          ${joinClause}
        `;

        let whereClause = generateWhereClause(campaign, product);
        whereClause = addDateCondition(whereClause, dateCaptured);

        query += whereClause;
        query += `
          GROUP BY to_char(month, 'YYYY-MM')
          ORDER BY to_char(month, 'YYYY-MM');
        `;

        const response = await executeRawQuery<
          {
            month: string;
            totalContacts: number;
            engagedContacts: number;
            idleContacts: number;
          }[]
        >(query);

        setContactsTrendData([
          ["Month", "Converted", "Engaged", "Idle"],
          ...response.map(
            (row) =>
              [
                Month_Names[new Date(row.month)?.getMonth()],
                row.totalContacts,
                row.engagedContacts,
                row.idleContacts,
              ] as [string, number, number, number]
          ),
        ]);
      } catch (error) {
        console.error("Error fetching contacts trend data", error);
        toast.error("Error fetching contacts trend data");
      }
    };

    const fetchPerformanceAcrossKeyMetrics = async () => {
      try {
        let query = `
          SELECT 
            to_char(month, 'YYYY-MM') AS month, 
            AVG(email_open_rate) AS open_rate,
            AVG(landing_page_bounce_rate) AS bounce_rate, 
            AVG(landing_page_conversion_rate) AS conversion_rate
          FROM campaign_analysis ca
          ${joinClause}
        `;

        let whereClause = generateWhereClause(campaign, product);
        whereClause = addDateCondition(whereClause, dateCaptured);

        query += whereClause;
        query += `
          GROUP BY to_char(month, 'YYYY-MM')
          ORDER BY to_char(month, 'YYYY-MM');
        `;

        const response = await executeRawQuery<
          {
            month: string;
            openRate: number;
            bounceRate: number;
            conversionRate: number;
          }[]
        >(query);

        setPerformanceAcrossKeyMetrics([
          ["Month", "Open Rate", "Conversion Rate", "Bounce Rate"],
          ...response.map(
            (row) =>
              [
                Month_Names[new Date(row.month)?.getMonth()],
                row.openRate,
                row.conversionRate,
                row.bounceRate,
              ] as [string, number, number, number]
          ),
        ]);
      } catch (error) {
        console.error("Error fetching performance metrics", error);
        toast.error("Error fetching performance metrics");
      }
    };

    const fetchEmailPerformanceData = async () => {
      try {
        let query = `
          SELECT 
            to_char(month, 'YYYY-MM') AS month,
            SUM(emails_opened) AS emails_opened,
            SUM(emails_clicked) AS emails_clicked,
            SUM(emails_sent) AS emails_sent
          FROM 
            campaign_analysis ca
          ${joinClause}
        `;

        let whereClause = generateWhereClause(campaign, product);
        whereClause = addDateCondition(whereClause, dateCaptured);

        query += whereClause;
        query += `
          GROUP BY 
            to_char(month, 'YYYY-MM')
          ORDER BY 
            to_char(month, 'YYYY-MM');
        `;

        const result = await executeRawQuery<
          {
            month: string;
            emailsOpened: string;
            emailsClicked: string;
            emailsSent: string;
          }[]
        >(query);

        setEmailPerformanceData([
          ["Month", "Emails Sent", "Emails Opened", "Emails Clicked"],
          ...result.map(
            (row) =>
              [
                Month_Names[new Date(row.month)?.getMonth()],
                parseInt(row.emailsSent),
                parseInt(row.emailsOpened),
                parseInt(row.emailsClicked),
              ] as [string, number, number, number]
          ),
        ]);
      } catch (error) {
        console.error("Error fetching email performance data", error);
        toast.error("Error fetching email performance data");
      }
    };

    const fetchLandingPagePerformanceData = async () => {
      try {
        let query = `
          SELECT 
            to_char(month, 'YYYY-MM') AS month,
            COALESCE(AVG(landing_page_conversions * 1.0 / NULLIF(landing_page_visits, 0)), 0) AS landing_page_conversion_rate,
            COALESCE(AVG((landing_page_visits - landing_page_conversions) * 1.0 / NULLIF(landing_page_visits, 0)), 0) AS landing_page_bounce_rate
          FROM 
            campaign_analysis ca
          ${joinClause}
        `;

        let whereClause = generateWhereClause(campaign, product);
        whereClause = addDateCondition(whereClause, dateCaptured);

        query += whereClause;
        query += `
          GROUP BY 
            to_char(month, 'YYYY-MM')
          ORDER BY 
            to_char(month, 'YYYY-MM');
        `;

        const result = await executeRawQuery<
          {
            month: string;
            landingPageConversionRate: string;
            landingPageBounceRate: string;
          }[]
        >(query);

        const conversionRate = result.map((row) =>
          parseFloat(row.landingPageConversionRate)
        );
        const bounceRate = result.map((row) =>
          parseFloat(row.landingPageBounceRate)
        );

        setLandingPagePerformanceData([
          ["Month", "Conversion Rate", "Bounce Rate"],
          ...result.map(
            (row) =>
              [
                Month_Names[new Date(row.month)?.getMonth()],
                parseFloat(row.landingPageConversionRate),
                parseFloat(row.landingPageBounceRate),
              ] as [string, number, number]
          ),
        ]);
      } catch (error) {
        console.error("Error fetching landing page performance data", error);
        toast.error("Error fetching landing page performance data");
      }
    };

    const fetchLinkedInEngagementData = async () => {
      try {
        let query = `
          SELECT 
            to_char(month, 'YYYY-MM') AS month,
            SUM(linkedin_impressions) AS impressions,
            SUM(linkedin_clicks) AS clicks
          FROM 
            campaign_analysis ca
          ${joinClause}
        `;

        let whereClause = generateWhereClause(campaign, product);
        whereClause = addDateCondition(whereClause, dateCaptured);

        query += whereClause;
        query += `
          GROUP BY 
            to_char(month, 'YYYY-MM')
          ORDER BY 
            to_char(month, 'YYYY-MM');
        `;

        const result = await executeRawQuery<
          {
            month: string;
            impressions: string;
            clicks: string;
          }[]
        >(query);

        setLinkedInEngagementData([
          ["Month", "Impressions", "Clicks"],
          ...result.map(
            (row) =>
              [
                Month_Names[new Date(row.month)?.getMonth()],
                parseInt(row.impressions),
                parseInt(row.clicks),
              ] as [string, number, number]
          ),
        ]);
      } catch (error) {
        console.error("Error fetching LinkedIn engagement data", error);
        toast.error("Error fetching LinkedIn engagement data");
      }
    };

    fetchContactsTrendData();
    fetchEngagementData();
    fetchKeyFindings();
    fetchPerformanceAcrossKeyMetrics();
    fetchEmailPerformanceData();
    fetchLandingPagePerformanceData();
    fetchLinkedInEngagementData();
  }, [product, campaign, dateCaptured]);

  return {
    emailPerformanceData,
    landingPagePerformanceData,
    linkedInEngagementData,
    keyFindings,
    products,
    campaigns,
    costEngagementScatterData,
    contactsTrendData,
    performanceAcrossKeyMetrics,
    dateCapturedOptions,
  };
};

export default useMarketingDashboardReport;
