import React, { type FC } from "react";
import {
  getBezierPath,
  EdgeLabelRenderer,
  BaseEdge,
  type EdgeProps,
  type Edge,
} from "@xyflow/react";
import styles from "./EdgeComponents.module.scss";

const YesEdgeType: FC<EdgeProps<Edge<{ label: string }>>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge markerEnd='url(#marker-pink)' id={id} path={edgePath} className={styles.yesEdge} />
      <EdgeLabelRenderer>
        <div
          style={{
            transform: `translate(-40%, -150%) translate(${labelX}px,${labelY+20}px)`,
          }}
          className={`${styles.edgeLabel} nodrag nopan ${styles.yesEdgeLabel}`}
        >
          Yes
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

const NoEdgeType: FC<EdgeProps<Edge<{ label: string }>>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge markerEnd='url(#marker-pink)' id={id} path={edgePath} className={styles.noEdge} />
      <EdgeLabelRenderer>
        <div
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY-10}px)`,
          }}
          className={`${styles.edgeLabel} nodrag nopan ${styles.noEdgeLabel}`}
        >
          No
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export { YesEdgeType, NoEdgeType };
