import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useCampaignQueryChat from "../CampaignQueryChat/useCampaignQueryChat";
import { fetchSegments } from "../../redux/campaignPlanAnalysisQueryChat";

export enum CampaignAnalysisType {
  PAST_INSIGHTS = "past-insights",
  AUDIENCE_SEGMENTATION = "audience-segmentation",
  MARKET_SENTIMENTS = "market-sentiments",
  COMPETITOR_ANALYSIS = "competitor-analysis",
  CAMPAIGN_RECOMMENDATIONS = "campaign-recommendations",
}

const useCampaignFlags = () => {
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const {
    marketSentiments,
    swotAnalysis,
    competitors,
    audienceSegments,
    recommendation,
    reverse_campaign_brief,
    pastCampaignAnalysis,
  } = useAppSelector((state) => state.campaignPlanAnalysisQueryChat);

  const { posts } = useAppSelector((state) => state.contentGeneration);

  const [hasPastInsights, setHasPastInsights] = useState<boolean | null>(false);
  const [hasAudienceSegmentation, setHasAudienceSegmentation] = useState<
    boolean | null
  >(false);
  const [hasMarketSentiments, setHasMarketSentiments] = useState<
    boolean | null
  >(false);
  const [hasCompetitorAnalysis, setHasCompetitorAnalysis] = useState<
    boolean | null
  >(false);
  const [hasCampaignRecommendations, setHasCampaignRecommendations] = useState<
    boolean | null
  >(false);

  const [hasSwoTAnalysis, setHasSwoTAnalysis] = useState<boolean | null>(false);

  useEffect(() => {
    console.log("condition: ,", selectedCampaign && pastCampaignAnalysis ? 'true' : 'false');
    console.log(
      "another condition: ",
      Array.isArray(pastCampaignAnalysis) && pastCampaignAnalysis?.length > 0
    );
    if (selectedCampaign && pastCampaignAnalysis) {
      if (
        Array.isArray(pastCampaignAnalysis) &&
        pastCampaignAnalysis?.length > 0
      ) {
        setHasPastInsights(true);
      } else if (
        typeof pastCampaignAnalysis === "object" &&
        Object.keys(pastCampaignAnalysis).length > 0
      ) {
        setHasPastInsights(true);
      } else {
        setHasPastInsights(false);
      }
    }
  }, [selectedCampaign, pastCampaignAnalysis]);

  useEffect(() => {
    if (selectedCampaign && audienceSegments) {
      if (Array.isArray(audienceSegments) && audienceSegments.length > 0) {
        setHasAudienceSegmentation(true);
      } else {
        setHasAudienceSegmentation(false);
      }
    }
  }, [selectedCampaign, audienceSegments]);

  useEffect(() => {
    if (selectedCampaign && marketSentiments) {
      if (Array.isArray(marketSentiments) && marketSentiments.length > 0) {
        setHasMarketSentiments(true);
      } else {
        setHasMarketSentiments(false);
      }
    }
  }, [selectedCampaign, marketSentiments]);

  useEffect(() => {
    if (selectedCampaign && swotAnalysis) {
      const hasNonEmptyFields = Object.values(
        swotAnalysis?.company_swot?.opportunities
      ).some((value) => value);

      if (Object.keys(swotAnalysis).length > 0 && hasNonEmptyFields) {
        setHasSwoTAnalysis(true);
      } else {
        setHasSwoTAnalysis(false);
      }
    }
  }, [selectedCampaign, swotAnalysis]);

  useEffect(() => {
    if (selectedCampaign && competitors) {
      if (Array.isArray(competitors) && competitors.length > 0) {
        setHasCompetitorAnalysis(true);
      } else {
        setHasCompetitorAnalysis(false);
      }
    }
  }, [selectedCampaign, competitors]);

  useEffect(() => {
    if (selectedCampaign && recommendation) {
      if (
        Object.keys(recommendation).length > 0 &&
        recommendation?.marketingChannelsWithReason &&
        recommendation?.execution_plans &&
        recommendation?.execution_plans?.length > 0
      ) {
        setHasCampaignRecommendations(true);
      } else {
        setHasCampaignRecommendations(false);
      }
    }
  }, [selectedCampaign, recommendation]);

  useEffect(() => {
    if (posts && posts.length > 0) {
      // setHasPastInsights(true);
    } else {
      // setHasPastInsights(false);
    }
  }, [selectedCampaign, posts]);

  return {
    hasPastInsights,
    hasAudienceSegmentation,
    hasMarketSentiments,
    hasCompetitorAnalysis,
    hasCampaignRecommendations,
    hasSwoTAnalysis,
  };
};

export default useCampaignFlags;
