import React, { useEffect, useRef, useState } from "react";

import styles from "./PromptEngineering.module.scss";
import TextArea from "../../../../componenets/MarketingBriefFormCard/components/TextArea/TextArea";
import { Button, TextElement } from "../../../../componenets";
import Input from "../../../../componenets/MarketingBriefFormCard/components/Input/Input";
import { PlusIcon } from "../../../../icons";
import { AiOutlineMinus } from "react-icons/ai";
import { TiMinus } from "react-icons/ti";
import {
  ModelKeyType,
  PromptTemplate,
  usePromptEngineering,
} from "./usePromptEngineering";
import MarketingBriefFormCard from "../../../../componenets/MarketingBriefFormCard/MarketingBriefFormCard";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  fetchBudgetAllocationForDifferentChannels,
  fetchMarketSentiments,
  fetchPastCampaignAnalysis,
  fetchSegments,
  fetchSwotAnalysis,
} from "../../../../redux/campaignPlanAnalysisQueryChat";
import { JSONTree } from "react-json-tree";
import { setSelectedCampaign } from "../../../../redux/campaignPinningSlice";
import GifLoadingModal from "../../../../componenets/GifLoadingModal/GifLoadingModal";
import useCampaignQueryChat from "../../../../componenets/CampaignQueryChat/useCampaignQueryChat";
import { fetchPosts } from "../../../../redux/contentGenerationSlice";
import { toast } from "react-toastify";
interface PromptEngineeringProps {
  modelKey: ModelKeyType;
  apiPath: string;
  heading: string;
}

interface InternalPromptTemplate extends PromptTemplate {
  loading: boolean;
}

const PromptEngineering: React.FC<PromptEngineeringProps> = (props) => {
  const { modelKey, apiPath, heading } = props;
  const { promptTemplates, log, updateTemplate, fetchLogs, setLog } =
    usePromptEngineering(apiPath, modelKey);

  const [gifLoading, setGifLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const {} = useCampaignQueryChat();

  const jsonRef = React.useRef<HTMLDivElement>(null);
  const [internalPromptTemplates, SetInternalPromptTemplates] = useState<
    InternalPromptTemplate[]
  >([]);

  const selectedCampaignRef = useRef(selectedCampaign);

  useEffect(() => {
    if (selectedCampaign && selectedCampaignRef.current !== selectedCampaign) {
      selectedCampaignRef.current = selectedCampaign;
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (promptTemplates) {
      SetInternalPromptTemplates(
        promptTemplates.map((template) => ({
          ...template,
          loading: false,
        }))
      );
    }
  }, [promptTemplates]);

  useEffect(() => {
    if (jsonRef.current && log) {
      jsonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [log]);

  return (
    <div className={styles["prompt-container"]}>
      <GifLoadingModal
        gifSrc={"gifs/loading.gif"}
        loadingText={"Loading Model Output...Please wait a moment"}
        isOpen={gifLoading}
      />
      <TextElement className={styles["header-text"]}>
        {heading} Prompt Engineering
      </TextElement>
      {internalPromptTemplates?.map((template) => {
        return (
          <div key={template.task} className={styles["template-item"]}>
            <TextArea
              label={template?.task?.split("_").join(" ")}
              value={template?.template}
              placeholder="Prompt template"
              onChange={(e) => {
                const updatedTemplate = {
                  ...template,
                  template: e.target.value,
                };
                SetInternalPromptTemplates(
                  internalPromptTemplates.map((temp) =>
                    temp.task === template.task ? updatedTemplate : temp
                  )
                );
              }}
              rows={10}
            />
            <TextElement>
              Variable that are being used in tempalte:{" "}
              <span>{template?.args?.join(", ")}</span>
            </TextElement>
            <Button
              className={styles["submit-button"]}
              disabled={template.loading}
              onClick={() => {
                SetInternalPromptTemplates(
                  internalPromptTemplates.map((temp) =>
                    temp.task === template.task
                      ? { ...temp, loading: true }
                      : temp
                  )
                );
                updateTemplate(template)
                  .then(() => {
                    dispatch(setSelectedCampaign(""));
                  })
                  .finally(() => {
                    SetInternalPromptTemplates(
                      internalPromptTemplates.map((temp) =>
                        temp.task === template.task
                          ? { ...temp, loading: false }
                          : temp
                      )
                    );
                  });
              }}
            >
              {template.loading ? "Loading..." : "Update"}
            </Button>
          </div>
        );
      })}
      <MarketingBriefFormCard
        className={styles["marketing-brief-form-card"]}
        onErrorMessage={() => {}}
        onSubmit={() => {
          if (modelKey === "market_sentiments") {
            setGifLoading(true);
            dispatch(
              fetchMarketSentiments({
                campaignPlanId: selectedCampaignRef?.current?.id as string,
              })
            )
              .then(() => {
                fetchLogs(selectedCampaignRef?.current?.id as string);
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setGifLoading(false);
              });
          } else if (modelKey === "audience_segmentation") {
            setGifLoading(true);
            dispatch(
              fetchSegments({
                campaignPlanId: selectedCampaignRef?.current?.id as string,
              })
            )
              .then((response) => {
                setLog(response.payload as JSON);
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setGifLoading(false);
              });
          } else if (modelKey === "content_generation") {
            setGifLoading(true);
            dispatch(
              fetchBudgetAllocationForDifferentChannels({
                campaignPlanId: selectedCampaignRef?.current?.id as string,
              })
            )
              .then((response) => {
                dispatch(
                  fetchPosts({
                    campaignPlanId: selectedCampaignRef.current?.id as string,
                  })
                )
                  .then((response) => {
                    setLog(response.payload as JSON);
                  })
                  .catch(() => {
                    toast.error("Error occurred during fetching posts");
                  })
                  .finally(() => {
                    setGifLoading(false);
                  });
              })
              .catch((err) => {
                toast.error("Error occurred during content");
                setGifLoading(false);
              });
          } else if (modelKey === "swot_competitor_analysis") {
            setGifLoading(true);
            dispatch(
              fetchSwotAnalysis({
                campaignPlanId: selectedCampaignRef?.current?.id as string,
              })
            )
              .then((response) => {
                setLog(response.payload as JSON);
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setGifLoading(false);
              });
          } else if (modelKey === "past_campaign_analysis") {
            setGifLoading(true);
            dispatch(
              fetchPastCampaignAnalysis({
                campaignPlanId: selectedCampaignRef?.current?.id as string,
              })
            )
              .then((response) => {
                setLog(response.payload as JSON);
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setGifLoading(false);
              });
          }
        }}
        initialData={{
          productName: "Salesforce Managed Services",
          campaignName: "Prompt Engineering Test Campaign",
          campaignOverview:
            "Infotech aims to strategically leverage LinkedIn to boost brand visibility and generate inbound inquiries for its Salesforce consulting services within the Australian B2B market. The campaign will focus on positioning Infotech as a thought leader in the Salesforce ecosystem by sharing organic posts that showcase its technical expertise, successful client implementations, and tailored solutions for B2B enterprises. Through the consistent dissemination of insightful content, Infotech will highlight its ability to deliver high-impact Salesforce solutions that drive business transformation.",
          campaignObjective:
            "The primary goal is to increase inbound inquiries by fostering engagement with potential clients through value-focused, organic content. The campaign will target LinkedIn users within relevant industries and roles to achieve the following: \nShowcase Salesforce Success Stories: Publish detailed case studies that demonstrate Infotech’s successful Salesforce implementations for B2B companies, focusing on measurable results and client feedback. \nEducate the Audience: Share educational content that highlights the advantages of Salesforce for B2B operations, including efficiency improvements, customer relationship management, and sales growth. \nHighlight Unique Services: Present Infotech’s customized approach to Salesforce implementation, optimization, and support, with a focus on industry-specific solutions that cater to the unique challenges of Australian businesses. \nBuild Trust and Credibility: Establish thought leadership through regular, informative posts on emerging trends, Salesforce best practices, and strategic advice to engage and build trust with the LinkedIn audience.",
          campaignStartDate: new Date().getTime(),
          campaignBudget: "",
          isOrganic: false,
        }}
      />
      {
        // Hide logs
        /* <div className={styles["json-container"]} ref={jsonRef}>
          <JSONTree shouldExpandNodeInitially={() => true} data={log} />
        </div> */
      }
    </div>
  );
};

export default PromptEngineering;
