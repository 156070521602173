export const optionalCondition = (field: string, value: string, emptyValue: string = '') => {
  // If the value is empty, match the row value, otherwise, match the value
  return `${field} = (CASE WHEN '${value}' = '${emptyValue}' THEN ${field} ELSE '${value}' END)`;
}

export const generateOptionalConditions = (conditionValueMapping: Record<string, string>) => {
  const conditions = [];
  for (const [field, value] of Object.entries(conditionValueMapping)) {
    conditions.push(optionalCondition(field, value));
  }
  
  let condition = conditions.join(" AND ");
  if (condition) {
    condition = `WHERE ${condition}`;
  }

  return condition
}