import React, { useState, useEffect } from "react";
import HttpService from "../../lib/api";
import { Opportunity } from "./types";
import { Journey } from "../JourneyManagment/useJourneyManagment";
import { toast } from "react-toastify";

export type OpportunityStage = "Consideration" | "Evaluation" | "Decision";

const useOutreachCampaign = () => {
  const httpService = new HttpService();

  const [journey, setJourney] = useState<null | Journey>(null);
  const [loading, setLoading] = useState(false);
  const [showJourney, setShowJourney] = useState(false);

  const [selectedPersona, setSelectedPersona] = React.useState();
  const [selectedIndustry, setSelectedIndustry] = React.useState<any>([]);
  console.log("selectedIndustry: ", selectedIndustry, selectedPersona);

  const [filteredPersonaList, setFilteredPersonaList] = useState<
    { personaId: number; personaName: string }[]
  >([]);

  const [filteredIndustryList, setFilteredIndustryList] = useState<
    { industrySegmentId: number; industrySegmentName: string }[]
  >([]);

  const [personaList, setPersonaList] = useState<
    {
      personaId: number;
      personaName: string;
    }[]
  >([]);

  const [industrySegmentList, setIndustrySegmentList] = useState<
    {
      industrySegmentId: number;
      industrySegmentName: string;
    }[]
  >([]);

  const [metrics, setMetrics] = useState<{
    totalMarketingQualifiedLeads: number;
    dormantMarketingQualifiedLeads: number;
    averageDormacyPeriod: string;
    reactivationRate: string;
    leadConversionPotential: string;
    engagementScoreTrend: string;
  }>({
    totalMarketingQualifiedLeads: 10183,
    dormantMarketingQualifiedLeads: 0,
    averageDormacyPeriod: "60 days",
    reactivationRate: "12%",
    leadConversionPotential: "25%",
    engagementScoreTrend: "20%",
  });

  const [totalEnagagementSources, setTotalEnagagementSources] = useState<
    (string | number)[][]
  >([
    ["Source", "Leads"],
    // ["Email Opened", 30],
    ["Email", 30],
    ["LinkedIn", 30],
    ["Landing Page", 45],
  ]);

  const [dormancyAgeGroups, setDormancyAgeGroups] = useState<
    (string | number)[][]
  >([
    ["Age Group", "Leads"],
    ["30-60 days", 0],
    ["60-90 days", 0],
    ["91+ days", 0],
  ]);

  console.log("dormancyAgeGroups: 1", dormancyAgeGroups);

  const [reactivationOpportunities, setReactivationOpportunities] = useState<
    (string | number)[][]
  >([
    ["Metrics", "Leads Percentage"],
    ["Warm", 30],
    ["Cold", 35],
    ["Priority", 25],
  ]);

  const executeRawQuery = async <T = any,>(query: string): Promise<T> => {
    try {
      const response = await httpService.post<T, { query: string }>(
        "/api/execute-raw-sql/",
        { query }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to execute raw query", error);
      return [] as T;
    }
  };

  // useEffect(() => {
  //   const response = executeRawQuery<
  //     {
  //       personaId: number;
  //       personaName: string;
  //       industrySegmentId: number;
  //       industrySegmentName: string;
  //     }[]
  //   >(`
  //       SELECT
  //         j.persona_id,
  //         p.persona_name,
  //         j.industry_segment_id,
  //         i.industry_segment_name
  //     FROM
  //         public.journeys j
  //     INNER JOIN
  //         public.persona p ON j.persona_id = p.persona_id
  //     INNER JOIN
  //         public.industry_segment i ON j.industry_segment_id = i.industry_segment_id
  //     WHERE
  //         j.type = 'outreach-campaign';
  //       `);

  //   response.then((data) => {
  //     const personas = data.map((item) => ({
  //       personaId: item.personaId,
  //       personaName: item.personaName,
  //     }));

  //     const industrySegments = data.map((item) => ({
  //       industrySegmentId: item.industrySegmentId,
  //       industrySegmentName: item.industrySegmentName,
  //     }));

  //     setPersonaList(personas);
  //     setIndustrySegmentList(industrySegments);
  //   });
  // }, []);

  // const fetchIndusrtySegment = async () => {
  //   const response = await executeRawQuery<
  //     { industrySegmentId: number; industrySegmentName: string }[]
  //   >(`
  //     SELECT
  //     industry_segment_name ,industry_segment_id
  //     FROM
  //     industry_segment;
  //     `);

  //   const industrySegments = response.map((item) => ({
  //     industrySegmentId: item.industrySegmentId,
  //     industrySegmentName: item.industrySegmentName,
  //   }));

  //   setIndustrySegmentList(industrySegments);
  // };

  //
  const fetchIndustrySegment = async (selectedPersona?: number) => {
    let whereClause = "";

    // Build conditions for the WHERE clause
    const conditions: string[] = [];

    if (selectedPersona) {
      conditions.push(`p.persona_id = ${selectedPersona}`);
    }

    if (selectedIndustry && selectedIndustry.length > 0 && selectedPersona) {
      conditions.push(
        `ca.industry_segment_id IN (${selectedIndustry.join(",")})`
      );
    }

    // Combine conditions if any exist
    if (conditions.length > 0) {
      whereClause = `WHERE ${conditions.join(" AND ")}`;
    }

    let industrySegments: IndustrySegment[] = [];

    //     const response1 = await executeRawQuery<any>(
    //       `SELECT DISTINCT
    //       ca.industry_segment_id,
    //       i.industry_segment_name
    //       FROM
    //     campaign_analysis ca
    //     INNER JOIN
    //     persona p ON ca.persona_id = p.persona_id
    //     INNER JOIN
    //     industry_segment i ON ca.industry_segment_id = i.industry_segment_id
    // where
    // (ca.persona_id = ${selectedPersona});`
    //     );
    // console.log("response1: ", response1);

    const response = await executeRawQuery<
      { industrySegmentId: number; industrySegmentName: string }[]
    >(`
       SELECT DISTINCT 
    ca.persona_id, 
    p.persona_name,
    ca.industry_segment_id,
    i.industry_segment_name
FROM 
    campaign_analysis ca
JOIN 
    persona p ON ca.persona_id = p.persona_id
JOIN 
    industry_segment i ON ca.industry_segment_id = i.industry_segment_id
        ${whereClause} ;
    `);
    console.log("response>>> 01 ", response);

    // if (!selectedIndustry && selectedIndustry.length != 0 && selectedPersona) {
    //   console.log("calllll");
    //   industrySegments = response1.map((item: any) => ({
    //     industrySegmentId: item.industrySegmentId,
    //     industrySegmentName: item.industrySegmentName,
    //   }));
    // } else {
    console.log("calllll 2");

    interface IndustrySegment {
      industrySegmentId: number;
      industrySegmentName: string;
    }

    industrySegments = Array.from(
      new Map<number, IndustrySegment>(
        response.map((item) => [
          item.industrySegmentId,
          {
            industrySegmentId: item.industrySegmentId,
            industrySegmentName: item.industrySegmentName,
          },
        ])
      ).values()
    );
    // }

    console.log("industrySegments: ", industrySegments);
    setIndustrySegmentList(industrySegments);
  };

  const fetchPersona = async (
    selectedIndustry?: number[],
    selectedPersona?: number
  ) => {
    let whereClause = "";

    // Build conditions for the WHERE clause
    const conditions: string[] = [];

    if (selectedIndustry && selectedIndustry.length > 0) {
      conditions.push(
        `ca.industry_segment_id IN (${selectedIndustry.join(",")})`
      );
    }

    if (selectedPersona && selectedIndustry) {
      conditions.push(`p.persona_id = ${selectedPersona}`);
      // conditions.push(`p.persona_id = ${selectedPersona}`);
    }

    // Combine conditions if any exist
    if (conditions.length > 0) {
      whereClause = `WHERE ${conditions.join(" AND ")}`;
    }

    console.log("sellle", selectedIndustry?.join(","));

    let personas = [];
    const response1 = await executeRawQuery<any>(
      `SELECT DISTINCT
      ca.persona_id,
      p.persona_name
      FROM
      campaign_analysis ca
      INNER JOIN
    persona p ON ca.persona_id = p.persona_id
    INNER JOIN
    industry_segment i ON ca.industry_segment_id = i.industry_segment_id
    where
    (ca.industry_segment_id in (${selectedIndustry?.join(",")}));`
    );
    console.log("response1:>>>>> ", response1);

    const response = await executeRawQuery<
      { personaId: number; personaName: string }[]
    >(`SELECT DISTINCT 
    ca.persona_id, 
    p.persona_name,
    ca.industry_segment_id,
    i.industry_segment_name
FROM 
    campaign_analysis ca
JOIN 
    persona p ON ca.persona_id = p.persona_id
JOIN 
    industry_segment i ON ca.industry_segment_id = i.industry_segment_id
        ${whereClause}
    `);

    if (selectedIndustry && selectedIndustry.length > 0) {
      console.log("asdfasdfas 111");
      personas = response1.map((item: any) => ({
        personaId: item.personaId,
        personaName: item.personaName,
      }));
    } else {
      console.log("asdfasdfas");
      personas = response.map((item) => ({
        personaId: item.personaId,
        personaName: item.personaName,
      }));
    }

    console.log("personas:>>>>>>>>> ", personas);
    setPersonaList(personas);
  };

  //
  //   const fetchPersona = async () => {
  //     const response = await executeRawQuery<
  //       { personaId: number; personaName: string }[]
  //     >(`
  //      SELECT persona_name,persona_id from
  // persona;
  //       `);

  //     const personas = response.map((item) => ({
  //       personaId: item.personaId,
  //       personaName: item.personaName,
  //     }));

  //     setPersonaList(personas);
  //   };

  useEffect(() => {
    const fetchMetrics = async () => {
      const selectedInd = selectedIndustry?.length
        ? selectedIndustry?.join(",")
        : "";

      let whereClause = "";

      if (selectedInd && selectedPersona) {
        whereClause = `WHERE  
    (ca.industry_segment_id in(${selectedInd}) OR industry_segment_id IS NULL)  
    AND (ca.persona_id =${selectedPersona} OR persona_id IS NULL)`;
      } else if (selectedInd) {
        whereClause = `WHERE (ca.industry_segment_id in(${selectedInd}) OR industry_segment_id IS NULL)`;
      } else if (selectedPersona) {
        whereClause = `WHERE (ca.persona_id =${selectedPersona} OR persona_id IS NULL)`;
      }
      // const response = await executeRawQuery<any>(`
      //      WITH persona_sum AS (
      //     SELECT
      //         SUM(engaged_contacts) AS persona_engaged_contacts
      //     FROM
      //         campaign_analysis
      //         ${selectedPersona && `WHERE persona_id IN (${selectedPersona})`}),
      // industry_sum AS (
      //     SELECT
      //         SUM(engaged_contacts) AS industry_engaged_contacts
      //     FROM
      //         campaign_analysis
      //         ${selectedInd && `WHERE industry_segment_id IN (${selectedInd})`}
      // )
      // SELECT
      //     persona_sum.persona_engaged_contacts,
      //     industry_sum.industry_engaged_contacts,
      //     COALESCE(persona_sum.persona_engaged_contacts, 0) + COALESCE(industry_sum.industry_engaged_contacts, 0) AS total_engaged_contacts
      // FROM
      //     persona_sum, industry_sum;
      //       `);

      const response = await executeRawQuery<any>(`

              SELECT COUNT(mql.mql_id) AS total_mqls
FROM mql_overview mql
JOIN (SELECT DISTINCT campaign_id , industry_segment_id, persona_id from campaign_analysis) ca ON mql.campaign_id = ca.campaign_id
  
   ${whereClause};
           `);

      if (response) {
        setMetrics((prevMetrics) => ({
          ...prevMetrics,
          totalMarketingQualifiedLeads: response[0]?.totalMqls || 0,
        }));
      }
    };

    const fetchDormatMarketingQualifiedLeads = async () => {
      const selectedInd = selectedIndustry?.length
        ? selectedIndustry?.join(",")
        : "";

      let whereClause = "";

      if (selectedInd && selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}
  AND i.industry_segment_id in (${selectedInd})`;
      } else if (selectedInd) {
        whereClause = ` AND i.industry_segment_id in (${selectedInd})`;
      } else if (selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}`;
      }

      const response = await executeRawQuery<any>(`
       SELECT 
  COUNT(DISTINCT CONCAT(mql.mql_id, mql.campaign_id, mql.mql_generation_date, mql.accepted_by_sales)) AS dmql
FROM mql_overview mql
INNER JOIN campaign_analysis ca ON mql.campaign_id = ca.campaign_id  -- Joining with campaign_analysis table
INNER JOIN persona p ON ca.persona_id = p.persona_id  -- Joining persona table via campaign_analysis
INNER JOIN industry_segment i ON ca.industry_segment_id = i.industry_segment_id  -- Joining industry_segment table via campaign_analysis
WHERE 
  mql.accepted_by_sales = 'No'
  AND CURRENT_DATE - mql.mql_generation_date >= 30
  ${whereClause};
 
       `);

      console.log("response:>>>> ", response);
      if (response) {
        setMetrics((prevMetrics) => ({
          ...prevMetrics,
          dormantMarketingQualifiedLeads: response[0]?.dmql || 0,
        }));
      }
    };

    // below Need to update this
    const fetchAverageDormacyPeriod = async () => {
      const selectedInd = selectedIndustry?.length
        ? selectedIndustry?.join(",")
        : "";

      let whereClause = "";

      if (selectedInd && selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}
      AND i.industry_segment_id IN (${selectedInd})`;
      } else if (selectedInd) {
        whereClause = ` AND i.industry_segment_id IN (${selectedInd})`;
      } else if (selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}`;
      }

      const response = await executeRawQuery<any>(`
     SELECT 
  AVG(CURRENT_DATE - mql.mql_generation_date) AS avg_dormancy_period
FROM mql_overview mql
INNER JOIN campaign_analysis ca ON mql.campaign_id = ca.campaign_id
INNER JOIN persona p ON ca.persona_id = p.persona_id
INNER JOIN industry_segment i ON ca.industry_segment_id = i.industry_segment_id
WHERE 
  mql.accepted_by_sales = 'No'
  AND CURRENT_DATE - mql.mql_generation_date >= 30
${whereClause};`);

      console.log("responseprevMetrics: ", response);
      if (response) {
        setMetrics((prevMetrics) => ({
          ...prevMetrics,
          averageDormacyPeriod: response[0]?.avgDormancyPeriod || 0,
        }));
      }
    };
    // below  Need to update this
    const fetchReactivationRate = async () => {
      const selectedInd = selectedIndustry?.length
        ? selectedIndustry?.join(",")
        : "";

      let whereClause = "";

      if (selectedInd && selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}
        AND i.industry_segment_id IN (${selectedInd})`;
      } else if (selectedInd) {
        whereClause = ` AND i.industry_segment_id IN (${selectedInd})`;
      } else if (selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}`;
      }

      const response = await executeRawQuery<any>(`
   SELECT 
  COUNT(DISTINCT CONCAT(mql.mql_id, mql.campaign_id, mql.mql_generation_date, mql.accepted_by_sales)) AS qualified_mqls
FROM mql_overview mql
INNER JOIN campaign_analysis ca ON mql.campaign_id = ca.campaign_id
INNER JOIN persona p ON ca.persona_id = p.persona_id
INNER JOIN industry_segment i ON ca.industry_segment_id = i.industry_segment_id
WHERE 
  mql.accepted_by_sales = 'Yes'
  AND CURRENT_DATE - mql.mql_generation_date >= 45
  ${whereClause};`);

      if (response) {
        setMetrics((prevMetrics) => ({
          ...prevMetrics,
          reactivationRate: response[0]?.qualifiedMqls || 0,
        }));
      }
    };
    // below Need to update this
    const fetchLeadConversion = async () => {
      const selectedInd = selectedIndustry?.length
        ? selectedIndustry?.join(",")
        : "";

      let whereClause = "";

      if (selectedInd && selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}
          AND i.industry_segment_id IN (${selectedInd})`;
      } else if (selectedInd) {
        whereClause = ` AND i.industry_segment_id IN (${selectedInd})`;
      } else if (selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}`;
      }

      //  Need to update this query for not selected industry and persona
      const response = await executeRawQuery<any>(`
    SELECT 
  AVG(mql.lead_score) AS avg_lead_score
FROM mql_overview mql
INNER JOIN campaign_analysis ca ON mql.campaign_id = ca.campaign_id
INNER JOIN persona p ON ca.persona_id = p.persona_id
INNER JOIN industry_segment i ON ca.industry_segment_id = i.industry_segment_id
WHERE 
  mql.accepted_by_sales = 'No'
 ${whereClause};`);

      if (response) {
        setMetrics((prevMetrics) => ({
          ...prevMetrics,
          leadConversionPotential: response[0]?.avgLeadScore || 0,
        }));
      }
    };
    // below Need to update this
    const fetchEngagementScores = async () => {
      const selectedInd = selectedIndustry?.length
        ? selectedIndustry?.join(",")
        : "";
      //  Need to update this query for not selected industry and persona
      // const whereClause =
      //   selectedIndustry && selectedPersona
      //     ? `  WHERE
      //   industry_segment_id IN (${selectedInd})
      //   AND persona_id =${selectedPersona}`
      //     : "";

      let whereClause = "";

      if (selectedInd && selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}
          AND i.industry_segment_id IN (${selectedInd})`;
      } else if (selectedInd) {
        whereClause = ` AND i.industry_segment_id IN (${selectedInd})`;
      } else if (selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}`;
      }

      const response = await executeRawQuery<any>(`
   SELECT 
  AVG(CAST(mql." cost_per_mql " AS NUMERIC)) AS avg_cost_per_dormant_mql
FROM mql_overview mql
INNER JOIN campaign_analysis ca ON mql.campaign_id = ca.campaign_id
INNER JOIN persona p ON ca.persona_id = p.persona_id
INNER JOIN industry_segment i ON ca.industry_segment_id = i.industry_segment_id
WHERE 
  mql.accepted_by_sales = 'No'
  AND CURRENT_DATE - mql.mql_generation_date >= 30  -- Dormant MQLs (older than 30 days) ${whereClause};`);

      if (response) {
        setMetrics((prevMetrics) => ({
          ...prevMetrics,
          engagementScoreTrend: response[0]?.avgCostPerDormantMql || 0,
        }));
      }
    };

    //  Top Engagement Sources
    const fetchTopEngagementSources = async () => {
      const selectedInd = selectedIndustry?.length
        ? selectedIndustry?.join(",")
        : "";

      // const whereClause =
      //   selectedIndustry && selectedPersona
      //     ? ` WHERE industry_segment_id IN (${selectedInd}) AND persona_id = ${selectedPersona}`
      //     : "";

      let whereClause = "";

      if (selectedInd && selectedPersona) {
        whereClause = ` AND i.industry_segment_id in (${selectedInd})  AND p.persona_id  = ${selectedPersona}`;
      } else if (selectedInd) {
        whereClause = `AND i.industry_segment_id in (${selectedInd})`;
      } else if (selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}`;
      }

      const response = await executeRawQuery<any>(`
SELECT 
    CASE 
        WHEN CAST(mql." cost_per_mql " AS NUMERIC) <= 100 THEN 'Low (≤ 100)'
        WHEN CAST(mql." cost_per_mql " AS NUMERIC) > 100 AND CAST(mql." cost_per_mql " AS NUMERIC) <= 200 THEN 'Medium (101 - 200)'
        WHEN CAST(mql." cost_per_mql " AS NUMERIC) > 200 THEN 'High (> 200)'
        ELSE 'Unknown'
    END AS cost_group,
    COUNT(*) AS dormant_mql_count,
    AVG(CAST(mql." cost_per_mql " AS NUMERIC)) AS avg_cost_per_mql
FROM mql_overview mql
INNER JOIN campaign_analysis ca ON mql.campaign_id = ca.campaign_id
INNER JOIN persona p ON ca.persona_id = p.persona_id
INNER JOIN industry_segment i ON ca.industry_segment_id = i.industry_segment_id
WHERE 
    mql.accepted_by_sales = 'No'
    AND CURRENT_DATE - mql.mql_generation_date > 30  -- Dormant MQLs (older than 30 days)
   ${whereClause}
GROUP BY cost_group
ORDER BY dormant_mql_count DESC;;`);

      console.log("fetchTopEngagementSources: ", response);
      // if (response) {
      //   const cData = response[0] || null;

      //   setTotalEnagagementSources([
      //     ["Source", "Leads"],
      //     ["Email", cData?.emailsClicked || 0],

      //     ["LinkedIn", cData?.linkedinClicks || 0],
      //     ["Landing Page", cData?.landingPageConversions || 0],
      //   ]);
      // }
      if (response) {
        const formattedData = [
          ["Cost Group", "Dormant MQL Count"], // Header row
          ...response.map((item: any) => [
            item.costGroup,
            item.dormantMqlCount,
          ]), // Data rows
        ];

        setTotalEnagagementSources(formattedData);
      }
    };

    // Dormancy Age Groups
    const fetchDormancyAgeGroupss = async () => {
      const selectedInd = selectedIndustry?.length
        ? selectedIndustry?.join(",")
        : "";

      // const whereClause =
      //   selectedIndustry && selectedPersona
      //     ? `    WHERE
      //   industry_segment_id IN (${selectedInd})
      //   AND persona_id = ${selectedPersona}`
      //     : "";

      let whereClause = "";

      if (selectedInd && selectedPersona) {
        whereClause = ` AND i.industry_segment_id in (${selectedInd})  AND p.persona_id  = ${selectedPersona}`;
      } else if (selectedInd) {
        whereClause = `AND i.industry_segment_id in (${selectedInd})`;
      } else if (selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}`;
      }

      const response = await executeRawQuery<any>(`
   WITH dormancy_groups AS (
    SELECT '30-60 days' AS dormancy_age_group, 30 AS min_days, 60 AS max_days
    UNION ALL
    SELECT '61-90 days', 61, 90
    UNION ALL
    SELECT '90+ days', 91, NULL
)
SELECT 
    dg.dormancy_age_group,
    COUNT(mql.mql_id) AS dormant_mql_count,
    AVG(CAST(mql." cost_per_mql " AS NUMERIC)) AS avg_cost_per_mql
FROM dormancy_groups dg
LEFT JOIN mql_overview mql 
    ON (CURRENT_DATE - mql.mql_generation_date) BETWEEN dg.min_days AND COALESCE(dg.max_days, 9999)
INNER JOIN campaign_analysis ca ON mql.campaign_id = ca.campaign_id
INNER JOIN persona p ON ca.persona_id = p.persona_id
INNER JOIN industry_segment i ON ca.industry_segment_id = i.industry_segment_id
WHERE 
    mql.accepted_by_sales = 'No'
    AND CURRENT_DATE - mql.mql_generation_date >= 30
${whereClause}
GROUP BY dg.dormancy_age_group
ORDER BY dormant_mql_count DESC;`);

      const transformedData = response?.length
        ? [
            ["Age Group", "Dormant MQL count"],
            ...response.map((item: any) => [
              item.dormancyAgeGroup,
              item.dormantMqlCount,
            ]),
          ]
        : [
            ["Age Group", "Leads"],
            ["30-60 days", 0],
            ["60-90 days", 0],
            ["91+ days", 0],
          ];

      setDormancyAgeGroups(transformedData);
    };

    // Reactivation Opportunity Metrics
    const fetchReactivationOpportunities = async () => {
      const selectedInd = selectedIndustry?.length
        ? selectedIndustry?.join(",")
        : "";

      let whereClause = "";

      if (selectedInd && selectedPersona) {
        whereClause = ` AND i.industry_segment_id in (${selectedInd})  AND p.persona_id  = ${selectedPersona}`;
      } else if (selectedInd) {
        whereClause = `AND i.industry_segment_id in (${selectedInd})`;
      } else if (selectedPersona) {
        whereClause = `AND p.persona_id = ${selectedPersona}`;
      }

      const response = await executeRawQuery<any>(`
   WITH lead_score_groups AS (
    SELECT 'Low (≤ 50)' AS lead_score_group, 0 AS min_score, 50 AS max_score
    UNION ALL
    SELECT 'Medium (51 - 80)', 51, 80
    UNION ALL
    SELECT 'High (> 80)', 81, NULL
)
SELECT 
    lsg.lead_score_group,
    COUNT(mql.mql_id) AS mql_count,
    AVG(CAST(mql.lead_score AS NUMERIC)) AS avg_lead_score
FROM lead_score_groups lsg
LEFT JOIN mql_overview mql
    ON mql.lead_score BETWEEN lsg.min_score AND COALESCE(lsg.max_score, 9999)
INNER JOIN campaign_analysis ca ON mql.campaign_id = ca.campaign_id
INNER JOIN persona p ON ca.persona_id = p.persona_id
INNER JOIN industry_segment i ON ca.industry_segment_id = i.industry_segment_id
WHERE 
    mql.accepted_by_sales = 'No'  -- Filter MQLs not accepted by sales
${whereClause}
GROUP BY lsg.lead_score_group
ORDER BY avg_lead_score DESC;`);

      console.log("Reactivation1>>>>>: ", response);
      if (response) {
        // const cData = response[0] || null;
        // setTotalEnagagementSources([
        //   ["Source", "Leads"],
        //   ["Email", cData?.emailsClicked || 0],
        //   ["Email Opened", cData?.emailsOpened || 0],
        //   ["LinkedIn", cData?.linkedinClicks || 0],
        //   ["Landing Page", cData?.landingPageConversions || 0],
        // ]);
        // setMetrics((prevMetrics) => ({
        //   ...prevMetrics,
        //   reactivationRate: response[0]?.reactivationRatePercentage || 0,
        // }));

        const transformedData = response?.length
          ? [
              ["Metrics", "Leads Percentage"],
              ...response.map((item: any) => [
                item.leadScoreGroup,
                item.mqlCount,
              ]),
            ]
          : [
              ["Metrics", "Leads Percentage"],
              ["Warm", 0],
              ["Cold", 0],
              ["Priority", 0],
            ];

        setReactivationOpportunities(transformedData);
      }
    };

    // if (selectedPersona && selectedIndustry?.length) {
    fetchMetrics();
    fetchDormatMarketingQualifiedLeads();
    fetchAverageDormacyPeriod();
    fetchReactivationRate();
    fetchLeadConversion();
    fetchEngagementScores();
    fetchTopEngagementSources();
    fetchDormancyAgeGroupss();
    fetchReactivationOpportunities();
    // }
  }, [selectedPersona, selectedIndustry]);

  useEffect(() => {
    // fetchIndusrtySegment();
    fetchIndustrySegment();
    fetchPersona();
  }, []);

  const fetchJourneyBasedOnPersonaAndIndustry = async (
    personaId: number,
    industrySegmentId: number
  ) => {
    setLoading(true);
    setShowJourney(true);
    const response = await executeRawQuery<Journey[]>(
      `SELECT * FROM journeys WHERE persona_id = ${personaId} AND industry_segment_id = ${industrySegmentId} AND type = 'outreach-campaign'`
    );

    if (response?.length === 0) {
      setShowJourney(false);
      setLoading(false);
      return toast.error(
        "No journey found for the selected persona and industry segment"
      );
    }

    if (response.length) {
      setLoading(false);
      setJourney(response[0]);
    }
  };

  return {
    metrics,
    totalEnagagementSources,
    dormancyAgeGroups,
    reactivationOpportunities,
    executeRawQuery,
    personaList,
    industrySegmentList,
    journey,
    loading,
    fetchJourneyBasedOnPersonaAndIndustry,
    showJourney,
    selectedPersona,
    setSelectedPersona,
    selectedIndustry,
    setSelectedIndustry,
    fetchPersona,
    fetchIndustrySegment,
  };
};

export default useOutreachCampaign;
