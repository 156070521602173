import React from "react";

const AquaIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.99985 14.3337C12.5131 14.3337 15.3742 11.5053 15.3742 8.00033C15.3742 4.49531 12.5131 1.66699 8.99985 1.66699C5.48662 1.66699 2.62549 4.49531 2.62549 8.00033C2.62549 11.5053 5.48662 14.3337 8.99985 14.3337Z"
        stroke="#EB2701"
        stroke-width="2"
      />
      <path d="M9 0V5.33333" stroke="#EB2701" stroke-width="2" />
      <path d="M9 10.667V16.0003" stroke="#EB2701" stroke-width="2" />
      <path d="M17.0619 8H11.6875" stroke="#EB2701" stroke-width="2" />
      <path d="M0.938477 8H6.31284" stroke="#EB2701" stroke-width="2" />
    </svg>
  );
};

export default AquaIcon;
