import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import useJourneyManagement, { Journey } from "../useJourneyManagment";

interface JourneySidebarProps {
  journey: Journey | null;
  onClose: () => void;
  onSave: (journey: Journey, json?: string) => void;
}

export type JourneyType =
  | "onboarding"
  | "nurture-journey-by-product" 
  | "retention"
  | "upsell-and-cross-sell";

const JourneySidebar: React.FC<JourneySidebarProps> = ({
  journey,
  onClose,
  onSave,
}) => {
  const [title, setTitle] = useState(journey?.title || "");
  const [description, setDescription] = useState(journey?.description || "");
  const [tags, setTags] = useState(journey?.tags?.join(", ") || "");
  const [type, setType] = useState<JourneyType>(journey?.type || "onboarding");
  const [product, setProduct] = useState<string>("");

  const { products, fetchProducts } = useJourneyManagement();

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(()=>{
    if(journey){
      setTitle(journey.title);
      setDescription(journey.description);
      setTags(journey?.tags?.join(", ") || "");
      setType(journey.type);
      setProduct(journey?.product || "");
    }
  },[journey])

  const handleSave = () => {
    if (!title) {
      toast.error("Title is required");
      return;
    }

    const newJourney: Journey = {
      id: journey?.id ?? -1, // Set default id if undefined
      title,
      description,
      tags: tags?.split(",").map((tag) => tag.trim()),
      type,
      blueprint: journey?.blueprint || { Nodes: [], Edges: [] },
      product,
    };

    onSave(newJourney);
    onClose();
  };

  return (
    <div className="fixed top-0 right-0 h-full w-[40%] bg-white shadow-lg p-6 z-50">
      <h3 className="text-2xl font-semibold mb-6">
        {journey ? "Edit Journey" : "Add Journey"}
      </h3>

      <div className="mb-4">
        <label htmlFor="title" className="block text-gray-700 font-medium mb-1">
          Title
        </label>
        <input
          id="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="description"
          className="block text-gray-700 font-medium mb-1"
        >
          Description
        </label>
        <textarea
          id="description"
          value={description}
          rows={14}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="tags" className="block text-gray-700 font-medium mb-1">
          Tags (comma separated)
        </label>
        <input
          id="tags"
          type="text"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="type" className="block text-gray-700 font-medium mb-1">
          Journey Type
        </label>
        <select
          id="type"
          value={type}
          onChange={(e) => setType(e.target.value as JourneyType)}
          className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="onboarding">Onboarding</option>
          <option value="nurture-journey-by-product">
            Nurture Journey by Product
          </option>
          <option value="retention">Retention</option>
          <option value="upsell-and-cross-sell">Upsell & Cross-Sell</option>
        </select>
      </div>

      {type === "nurture-journey-by-product" && (
        <div className="mb-4">
          <label
            htmlFor="product"
            className="block text-gray-700 font-medium mb-1"
          >
            Product
          </label>
          <select
            id="product"
            value={product}
            onChange={(e) => setProduct(e.target.value)}
            className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {products.map((product) => (
              <option key={product?.productId} value={product?.productId}>
                {product.productName}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="flex justify-between items-center mt-6">
        <button
          onClick={onClose}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default JourneySidebar;
