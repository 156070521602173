import React, { useEffect, useState } from "react";
import styles from "./OutreachCampaign.module.scss";
import VerticalTabs from "../../componenets/VerticalTabs/VerticalTabs";
import CampaignQueryChat from "../../componenets/CampaignQueryChat/CampaignQueryChat";
import { Button, TextElement } from "../../componenets";
import classNames from "classnames";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import useOutreachCampaign from "./useOutreachCampaign";
import Select from "../../componenets/Select/Select";
import OutreachMetric from "./components/OutreachMetric/OutreachMetric";
import OutReachGraphPlaceholder from "./components/OutReachGraphPlaceholder/OutReachGraphPlaceholder";
import Chart from "react-google-charts";
import OutreachCampaignHeader from "./components/OutreachCampaignHeader/OutreachCampaignHeader";
import DisclosureComponent from "../../componenets/Disclosure/Disclosure";
import Card from "../../componenets/Card/Card";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import StagesAndActions from "../../componenets/VisualizeUserJourney/StagesAndActions";
import DottedLoader from "../../componenets/DottedLoader/DottedLoader";
import MultiSelect from "../../componenets/MultiSelect/MultiSelect";
import { formatNumberStandard } from "../../lib/helpers";

const OutreachCampaign: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const stage = query.get("stage");
  // const [selectedPersona, setSelectedPersona] = React.useState();
  // const [selectedIndustry, setSelectedIndustry] = React.useState();
  // const [selectedValues, setSelectedValues] = useState<
  //   (string | number | null)[]
  // >([]);

  const navigate = useNavigate();

  const {
    metrics,
    totalEnagagementSources,
    dormancyAgeGroups,
    reactivationOpportunities,
    personaList,
    industrySegmentList,
    journey,
    fetchJourneyBasedOnPersonaAndIndustry,
    showJourney,
    loading,
    selectedPersona,
    setSelectedPersona,
    selectedIndustry,
    setSelectedIndustry,
    fetchPersona,
    fetchIndustrySegment,
  } = useOutreachCampaign();

  console.log("fetchTopEngagementSources 2: ", totalEnagagementSources);
  return (
    <div className={styles["container"]}>
      <OutreachCampaignHeader
        defaultCampaignName={journey?.title || "Outreach Campaign"}
        className={styles["header"]}
      />
      <div
        className={classNames(
          styles["body"],
          isCollapsed ? styles["content-collapsed"] : styles["content-expanded"]
        )}
      >
        <VerticalTabs
          tabs={[
            {
              id: 1,
              tabIcon: <img src="/gifs/ninja.gif" />,
              content: (
                <div className={classNames(styles["campaign-query-chat"])}>
                  <CampaignQueryChat payload={JSON.stringify({})} />
                </div>
              ),
            },
          ]}
          onCollapse={(isCollapse) => setIsCollapsed(isCollapse)}
          className={styles["vertical-tabs"]}
          defaultActiveTab={null}
        />
        <div className={styles["model-response"]}>
          <TextElement className={styles["title"]}>
            Your OUTREACH CAMPAIGN
          </TextElement>

          {!showJourney && (
            <div className={styles["content"]}>
              <TextElement className={styles["sub-title"]}>
                Dormant MQLs Snapshot{" "}
                {/* <span
                  
                  className="text-2xl"
                >
                  (In Development)
                </span> */}
              </TextElement>
              <div className={styles["filters"]}>
                <MultiSelect
                  placeholderText="Industry"
                  buttonClassName={styles["select-button"]}
                  className={styles["select-menu"]}
                  options={
                    industrySegmentList
                      ? industrySegmentList.map((item) => ({
                          value: item.industrySegmentId,
                          label: item.industrySegmentName,
                        }))
                      : []
                  }
                  values={selectedIndustry as any}
                  // onChange={(value) => {
                  //   if (Array.isArray(value)) setSelectedIndustry(value);
                  // }}
                  onChange={async (value: any) => {
                    if (Array.isArray(value)) {
                      setSelectedIndustry(value);
                      await fetchPersona(value);
                    }
                  }}
                />

                <Select
                  buttonClassName={styles["select-button"]}
                  className={styles["select-menu"]}
                  placeholderText="Persona"
                  options={
                    personaList
                      ? personaList.map((item) => ({
                          value: item.personaId,
                          label: item.personaName,
                        }))
                      : []
                  }
                  showClearSelection={true}
                  clearSelectionLabel="All Persona"
                  value={selectedPersona as unknown as number}
                  // onChange={(value) => setSelectedPersona(value as any)}
                  onChange={async (value: any) => {
                    setSelectedPersona(value as any);
                    await fetchIndustrySegment(value);
                  }}
                />

                {/* <Select
                  placeholderText="Industry"
                  buttonClassName={styles["select-button"]}
                  className={styles["select-menu"]}
                  options={
                    industrySegmentList
                      ? industrySegmentList.map((item) => ({
                          value: item.industrySegmentId,
                          label: item.industrySegmentName,
                        }))
                      : []
                  }
                  // values={selectedValues}
                  value={selectedIndustry as unknown as number}
                  onChange={(value) => setSelectedIndustry(value as any)}
                /> */}

                <Button
                  className={styles["create-campaign-button"]}
                  onClick={() => {
                    if (selectedPersona && selectedIndustry)
                      fetchJourneyBasedOnPersonaAndIndustry(
                        selectedPersona,
                        selectedIndustry
                      );
                  }}
                  disabled={!selectedPersona || !selectedIndustry}
                >
                  Create Outreach Campaign
                </Button>
              </div>
              <div className={styles["metrics"]}>
                <OutreachMetric
                  valueLabel="Total Marketing Qualified Leads"
                  value={formatNumberStandard(
                    metrics?.totalMarketingQualifiedLeads
                  )}
                  furtherInfo=""
                  className={styles["metric"]}
                />
                <OutreachMetric
                  valueLabel="Dormant Marketing Qualified Leads"
                  value={formatNumberStandard(
                    metrics?.dormantMarketingQualifiedLeads
                  )}
                  furtherInfo=""
                />
                <OutreachMetric
                  valueLabel="Average Dormancy Period"
                  value={`${Math.round(
                    Number(metrics?.averageDormacyPeriod) || 0
                  )} days`}
                  furtherInfo=""
                />
                <OutreachMetric
                  // valueLabel="Reactivation Rate"
                  valueLabel="MQLs Qualified After 45 Days"
                  // value={metrics?.reactivationRate}
                  value={`${formatNumberStandard(
                    Number(metrics?.reactivationRate) || 0
                  )}`}
                  furtherInfo=""
                />
                <OutreachMetric
                  // valueLabel="Lead Conversion Potential"
                  valueLabel="Avg lead Score Not Accepted by Sales"
                  // value={metrics?.leadConversionPotential}
                  value={`${formatNumberStandard(
                    Math.round(Number(metrics?.leadConversionPotential) || 0)
                  )}`}
                  furtherInfo=""
                />
                <OutreachMetric
                  // valueLabel="Engagement Score Trends (in development)  "
                  valueLabel="Avg Cost Per Dormant MQLs"
                  // value={metrics?.engagementScoreTrend}
                  value={`$${Math.round(
                    Number(metrics?.engagementScoreTrend) || 0
                  )}`}
                  furtherInfo=""
                />
              </div>
              <div className={styles["graph-container"]}>
                <OutReachGraphPlaceholder
                  title="Cost Distribution per MQL"
                  // title="Top Engagement Sources"
                  graph={
                    <Chart
                      data={totalEnagagementSources}
                      width={"100%"}
                      height={"100%"}
                      chartType="BarChart"
                      options={{
                        colors: ["#EB2701"],
                        chartArea: { top: 20, width: "76%", height: "74%" },
                        legend: { position: "top" },
                        bars: "vertical",
                      }}
                    />
                  }
                  furtherInfo=""
                />
                <OutReachGraphPlaceholder
                  title="Dormancy Age Groups"
                  graph={
                    <Chart
                      data={dormancyAgeGroups}
                      width={"100%"}
                      height={"100%"}
                      chartType="BarChart"
                      options={{
                        colors: ["#EB2701"],
                        chartArea: { top: 20, width: "76%", height: "74%" },
                        legend: { position: "top" },
                        bars: "vertical",
                      }}
                    />
                  }
                  furtherInfo=""
                />
                <OutReachGraphPlaceholder
                  title="Avg lead score not accept by sales"
                  // title="Reactivation Opportunity Metrics"
                  graph={
                    <Chart
                      data={reactivationOpportunities}
                      width={"100%"}
                      height={"100%"}
                      chartType="BarChart"
                      options={{
                        colors: ["#EB2701"],
                        chartArea: { top: 20, width: "76%", height: "74%" },
                        legend: { position: "top" },
                        bars: "vertical",
                      }}
                    />
                  }
                  furtherInfo=""
                />
              </div>
            </div>
          )}
          {loading && (
            <div className={styles["loading-container"]}>
              <TextElement>
                Fetching Journey based on Persona and Industry
              </TextElement>
              <DottedLoader />
            </div>
          )}

          {journey && (
            <div className={styles["content"]}>
              <DisclosureComponent
                header={<TextElement>Stages and Actions</TextElement>}
                content={
                  <StagesAndActions
                    blueprint={JSON.parse(journey.blueprint as any)}
                  />
                }
                headerClassName={styles["closure-header"]}
                contentClassName={styles["closure-content"]}
                openHeaderClassName={styles["open-closure-header"]}
                defaultOpen
              />
              <Button
                onClick={() => {
                  navigate(
                    `${ROUTE_DEFINITIONS.CAMPAIGN_TREE_VIEW.path}?id=${journey?.id}`
                  );
                }}
                className={styles["build-journey-button"]}
              >
                Build Nurture Journey
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OutreachCampaign;
