import React, { forwardRef, useEffect } from "react";

import styles from "./MarketingBriefFormCard.module.scss";

import classNames from "classnames";
import Button from "../Button/Button";
import Input from "./components/Input/Input";
import TextArea from "./components/TextArea/TextArea";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  createCampaign,
  CreateCampaignResponse,
} from "../../redux/campaignPinningSlice";

import Select from "../Select/Select";
import TextElement from "../TextElement/TextElement";
import { toast } from "react-toastify";

interface MarketingBriefFormCardProps {
  className?: string;
  onSubmit?: () => void;
  onErrorMessage: (message: string) => void;
  initialData?: {
    productName: string;
    campaignName: string;
    campaignOverview: string;
    campaignObjective: string;
    campaignStartDate: number;
    campaignBudget: string;
    isOrganic: boolean;
  };
}

const MarketingBriefFormCard = forwardRef<
  HTMLDivElement,
  MarketingBriefFormCardProps
>((props, ref) => {
  const { className, onSubmit } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const [formData, setFormData] = React.useState({
    productName: "Salesforce Managed Services",
    campaignName: "Infotech Salesforce Test Campaign",
    campaignOverview: "Infotech aims to strategically leverage LinkedIn to boost brand visibility and generate inbound inquiries for its Salesforce consulting services within the Australian B2B market. The campaign will focus on positioning Infotech as a thought leader in the Salesforce ecosystem by sharing organic posts that showcase its technical expertise, successful client implementations, and tailored solutions for B2B enterprises. Through the consistent dissemination of insightful content, Infotech will highlight its ability to deliver high-impact Salesforce solutions that drive business transformation.",
    campaignObjective: "The primary goal is to increase inbound inquiries by fostering engagement with potential clients through value-focused, organic content. The campaign will target LinkedIn users within relevant industries and roles to achieve the following: Showcase Salesforce Success Stories: Publish detailed case studies that demonstrate Infotech’s successful Salesforce implementations for B2B companies, focusing on measurable results and client feedback. Educate the Audience: Share educational content that highlights the advantages of Salesforce for B2B operations, including efficiency improvements, customer relationship management, and sales growth. Highlight Unique Services: Present Infotech’s customized approach to Salesforce implementation, optimization, and support, with a focus on industry-specific solutions that cater to the unique challenges of Australian businesses. Build Trust and Credibility: Establish thought leadership through regular, informative posts on emerging trends, Salesforce best practices, and strategic advice to engage and build trust with the LinkedIn audience.",
    campaignStartDate: new Date().getTime(),
    campaignBudget: "",
    isOrganic: false,
  });

  useEffect(() => {
    if (props?.initialData) {
      setFormData(props.initialData);
    }
  }, []);

  useEffect(() => {
    if (selectedCampaign) {
      setFormData({
        productName: selectedCampaign.productName as string,
        campaignName: selectedCampaign.title as string,
        campaignOverview: selectedCampaign.campaignOverview as string,
        campaignObjective: selectedCampaign.campaignObjective as string,
        campaignStartDate: selectedCampaign.campaignStartDate as number,
        campaignBudget: selectedCampaign.campaignBudget as string,
        isOrganic: selectedCampaign.isOrganic as boolean,
      });
    }
  }, [selectedCampaign]);

  return (
    <div className={classNames(styles["container"], className)} ref={ref}>
      <form
        className={styles["form"]}
        onSubmit={async (e) => {
          e.preventDefault();
          if (!formData?.productName) {
            return toast.error("Please select a product");
          }
          const campaign = {
            title: formData.campaignName,
            campaignOverview: formData.campaignOverview,
            campaignObjective: formData.campaignObjective,
            campaignStartDate: new Date(formData.campaignStartDate).getTime(),
            campaignBudget: formData.campaignBudget,
            productName: formData.productName,
            includedData: {
              pastInsights: [],
              audienceSegmentation: [],
              marketSentimentIds: [],
              competitorIds: [],
              competitorStrategies: false,
              swotAnalysis: {
                strengths: false,
                weaknesses: false,
                opportunities: false,
                threats: false,
              },
              recommendations: {
                targetAudienceAndEstimatedNumbers: false,
                recommendedOfferings: false,
                recommendedChannels: false,
                recommendedBudget: false,
                projectedRoi: false,
              },
              content: {
                googleAds: false,
                contentMarketing: false,
                emailMarketing: false,
                socialMediaAdvertising: false,
              },
            },
            activeAnalysisStep: true,
            isOrganic: false,
          };

          try {
            dispatch(createCampaign(campaign)).then((response) => {
              if (
                response.payload &&
                (response.payload as CreateCampaignResponse).success
              ) {
                onSubmit && onSubmit();
              } else {
                toast.error("Error occurred during creating campaign");
              }
            });
          } catch (error) {
            console.error("Error occurred during dispatch:", error);
            toast.error("Error occurred during dispatch");
          }
        }}
      >
        <div className={styles["select-container"]}>
          <TextElement className={styles["select-label"]}>Product</TextElement>
          <Select
            options={[
              {
                label: "Salesforce Managed Services",
                value: "Salesforce Managed Services",
              },
              {
                label: "Salesforce Staffing Services",
                value: "Salesforce Staffing Services",
              },
              {
                label: "Salesforce Implementation",
                value: "Salesforce Implementation",
              },
              {
                label: "Salesforce Consulting",
                value: "Salesforce Consulting",
              },
              {
                label: "Salesforce Training and Development",
                value: "Salesforce Training and Development",
              },
              {
                label: "Salesforce Custom Development",
                value: "Salesforce Custom Development",
              },
              {
                label: "Salesforce Integration Services",
                value: "Salesforce Integration Services",
              },
              {
                label: "Salesforce Data Migration",
                value: "Salesforce Data Migration",
              },
              {
                label: "Salesforce Reporting and Analytics",
                value: "Salesforce Reporting and Analytics",
              },
              {
                label: "Salesforce AppExchange Solutions",
                value: "Salesforce AppExchange Solutions",
              },
              {
                label: "Salesforce Sales Cloud",
                value: "Salesforce Sales Cloud",
              },
              {
                label: "Salesforce Service Cloud",
                value: "Salesforce Service Cloud",
              },
              {
                label: "Salesforce Marketing Cloud",
                value: "Salesforce Marketing Cloud",
              },
              {
                label: "Salesforce Commerce Cloud",
                value: "Salesforce Commerce Cloud",
              },
              {
                label: "Salesforce Community Cloud",
                value: "Salesforce Community Cloud",
              },
              {
                label: "Salesforce Analytics Cloud (Tableau CRM)",
                value: "Salesforce Analytics Cloud (Tableau CRM)",
              },
              {
                label: "Salesforce Platform (Lightning Platform)",
                value: "Salesforce Platform (Lightning Platform)",
              },
              { label: "Salesforce Pardot", value: "Salesforce Pardot" },
              {
                label: "Salesforce Health Cloud",
                value: "Salesforce Health Cloud",
              },
              {
                label: "Salesforce Financial Services Cloud",
                value: "Salesforce Financial Services Cloud",
              },
              {
                label: "Salesforce Nonprofit Cloud",
                value: "Salesforce Nonprofit Cloud",
              },
              {
                label: "Salesforce Education Cloud",
                value: "Salesforce Education Cloud",
              },
              {
                label: "Salesforce Manufacturing Cloud",
                value: "Salesforce Manufacturing Cloud",
              },
              {
                label: "Salesforce Media Cloud",
                value: "Salesforce Media Cloud",
              },
              {
                label: "Salesforce Consumer Goods Cloud",
                value: "Salesforce Consumer Goods Cloud",
              },
              {
                label: "Salesforce Field Service Cloud",
                value: "Salesforce Field Service Cloud",
              },
              {
                label: "Salesforce Digital 360",
                value: "Salesforce Digital 360",
              },
              {
                label: "Salesforce Einstein Analytics",
                value: "Salesforce Einstein Analytics",
              },
              {
                label: "Salesforce Customer 360",
                value: "Salesforce Customer 360",
              },
              {
                label: "Salesforce Loyalty Management",
                value: "Salesforce Loyalty Management",
              },
            ]}
            value={formData.productName}
            onChange={(value) =>
              setFormData({ ...formData, productName: value as string })
            }
            className={styles["select"]}
            optionClassName={styles["option"]}
          />
        </div>
        <Input
          label={"Campaign Name*"}
          value={formData.campaignName}
          required
          placeholder="Campaign Name"
          onChange={(e) =>
            setFormData({ ...formData, campaignName: e.target.value })
          }
        />
        <TextArea
          label={"Campaign Overview*"}
          value={formData.campaignOverview}
          onChange={(e) =>
            setFormData({ ...formData, campaignOverview: e.target.value })
          }
          rows={8}
          placeholder="Share a brief summary of your campaign: its goals, target audience, and key messages. Also, list the marketing channels where you'll be promoting the campaign."
        />
        <TextArea
          label={"Campaign Objective*"}
          value={formData.campaignObjective}
          onChange={(e) =>
            setFormData({ ...formData, campaignObjective: e.target.value })
          }
          rows={8}
          placeholder=""
        />
        <div className={classNames(styles["campaign-budget-container"])}>
          <div className={classNames(styles["checkbox-container"])}>
            <input
              type="checkbox"
              checked={formData?.isOrganic}
              onChange={(e) => {
                // setIsBudgetPaidActive(e.target.checked);
                setFormData({ ...formData, isOrganic: e.target.checked });
              }}
            />  
            <label>Paid Campaign</label>
          </div>
          <Input
          label={!formData?.isOrganic ? "Campaign Budget": "Campaign Budget*"}
            value={formData.campaignBudget}
            onChange={(e) =>
              setFormData({ ...formData, campaignBudget: e.target.value })
            }
            disabled={!formData?.isOrganic}
            inputClassName={classNames(
              styles["date-input"],
              !formData?.isOrganic && styles["disabled-input"]
            )}
            placeholder="Exp: $1000, $500M, $1B, etc."
            min={160}
          />
        </div>
        <Input
          label={"Campaign Start Date*"}
          value={new Date().toISOString().substring(0, 10)}
          type="date"
          onChange={(e) => {
            setFormData({
              ...formData,
              campaignStartDate: new Date(e.target.value).getTime(),
            });
          }}
          inputClassName={styles["date-input"]}
          placeholder="Campaign Start Date"
        />

        <div className={styles["footer"]}>
          <Button 
            disabled={selectedCampaign ? true : false}
          className={styles["submit"]}>Submit</Button>
        </div>
      </form>
    </div>
  );
});

export default MarketingBriefFormCard;
