import React, { useState } from "react";
import styles from "./AddEmailModal.module.scss";
import Modal from "../../../Modal/Modal";
import Button from "../../../Button/Button";
import TextElement from "../../../TextElement/TextElement";
import Select from "../../../Select/Select";
import { EmailData } from "../../types";
import DisclosureComponent from "../../../Disclosure/Disclosure";

interface AddEmailModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: EmailData) => void;
}

const AddEmailModal: React.FC<AddEmailModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const handleOk = () => {};
  const [isSetUp, setIsSetUp] = useState(false);
  const [data, setData] = useState<EmailData>({
    emailBody: "",
    subject: "",
    fromName: "",
    fromEmail: "",
    previewText: "",
    emailName: "",
  });
 
  return (
    <Modal
      isOpen={open}
      closeModal={onClose}
      className={styles["container"]}
      contentClassName={styles["content"]}
    >
      <TextElement className={styles["title"]}>Create New Email</TextElement>
      <DisclosureComponent
        content={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsSetUp(true);
            }}
          >
            <TextElement className={styles["group-title"]}>
              Email Information
            </TextElement>
            <TextElement className={styles["label"]}>
              Email Name
              <span className={styles["required"]}>
                &nbsp; (only shows internally)
              </span>
            </TextElement>
            <input
              className={styles["input"]}
              required
              onChange={(e) => {
                setData({
                  ...data,
                  emailName: e.target.value,
                });
              }}
            />
            <TextElement className={styles["label"]}>Subject</TextElement>
            <input
              className={styles["input"]}
              required
              onChange={(e) => {
                setData({
                  ...data,
                  subject: e.target.value,
                });
              }}
            />
            <TextElement className={styles["label"]}>Preview Text</TextElement>
            <input
              className={styles["input"]}
              onChange={(e) => {
                setData({
                  ...data,
                  previewText: e.target.value,
                });
              }}
            />
            <TextElement className={styles["group-title"]}>
              Sender Information
            </TextElement>
            <TextElement className={styles["label"]}>From Name</TextElement>
            <input
              className={styles["input"]}
              required
              onChange={(e) => {
                setData({
                  ...data,
                  fromName: e.target.value,
                });
              }}
            />
            <TextElement className={styles["label"]}>From email address</TextElement>
            <input
              className={styles["input"]}
              required
               type="email"
              onChange={(e) => {
                setData({
                  ...data,
                  fromEmail: e.target.value,
                });
              }}
            />
            {!isSetUp && (
              <div className={styles["modal-footer"]}>
                <Button type={"submit"} onClick={handleOk}>
                  Next
                </Button>
              </div>
            )}
          </form>
        }
        defaultOpen
        header={<TextElement>Setup</TextElement>}
        headerClassName={styles["closure-header"]}
        contentClassName={styles["closure-content"]}
        openHeaderClassName={styles["open-closure-header"]}
        className={styles["disclosure"]}
      />
      {isSetUp && (
        <DisclosureComponent
          content={
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit(data);
              }}
            >
              <TextElement className={styles["label"]}>Email Body</TextElement>
              <textarea></textarea>
              <div className={styles["modal-footer"]}>
                <Button type={"submit"} onClick={handleOk}>
                  Save
                </Button>
              </div>
            </form>
          }
          defaultOpen
          header={<TextElement>Content</TextElement>}
          headerClassName={styles["closure-header"]}
          contentClassName={styles["closure-content"]}
          openHeaderClassName={styles["open-closure-header"]}
          className={styles["disclosure"]}
        />
      )}
    </Modal>
  );
};

export default AddEmailModal;
