import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  DateCapturedValues,
  KeyFindings,
  customerServiceInsights,
  SalesRevenueMetrics,
  MarketingCampaignEngagementMetrics,
  PaymentFinanceHealthMetrics,
  CustomerRiskData,
} from "./types";
import HttpService from "../../lib/api";
import { Product } from "../MarketingDashboardReport/types";

const useCustomerSuccessReport = (
  product: string,
  dateCaptured: DateCapturedValues
) => {
  const [products, setProducts] = useState<Product[]>([]);

  const dateCapturedOptions: { label: string; value: DateCapturedValues }[] = [
    { label: "Date Captured", value: "" },
    { label: "Last Month", value: "1 month" },
    { label: "Last 2 Months", value: "2 months" },
    { label: "Last 3 Months", value: "3 months" },
    { label: "Last 4 Months", value: "4 months" },
    { label: "Last 5 Months", value: "5 months" },
  ];

  const [keyFindings, setKeyFindings] = useState<KeyFindings>({
    totalTransactionValue: 0,
    averageTransactionValue: 0,
    customerLifetimeValue: 0,
    churnRate: 0,
    averageAccountHealthScore: 0,
    averageReputationScore: 0,
  });

  const [customerRiskData, setCustomerRiskData] = useState<CustomerRiskData>({
    newCustomers: 0,
    highRiskCustomers: 0,
    MediumLowRiskCustomers: 0,
  });

  const [salesRevenueMetrics, setSalesRevenueMetrics] =
    useState<SalesRevenueMetrics>({
      totalSales: 0,
      repeatPurchaseRate: 0,
    });

  const [
    marketingCampaignEngagementMetrics,
    setMarketingCampaignEngagementMetrics,
  ] = useState<MarketingCampaignEngagementMetrics>({
    campaignSuccessRate: 0,
    averageCustomerEngagementScore: 0,
  });

  const [paymentFinanceHealthMetrics, setPaymentFinanceHealthMetrics] =
    useState<PaymentFinanceHealthMetrics>({
      onTimePaymentRate: 0,
      paymentAlertRate: 0,
    });

  const [customerServiceInsights, setCustomerServiceInsights] =
    useState<customerServiceInsights>({
      totalNumbersOfIssues: 0,
      firstContactResolutionRate: 0,
      escalationRate: 0,
      averageSatisfactionResovledCases: 0,
      averageSatisfactionPendingCases: 0,
      averageSatisfactionEscalatedCases: 0,
    });

  const [avgAccountHealthScore, setAvgAccountHealthScore] = useState<
    (string | number)[][]
  >([]);
  const [churnRiskDistribution, setChurnRiskDistribution] = useState<
    (string | number)[][]
  >([]);
  const [avgReputationScore, setAvgReputationScore] = useState<
    (string | number)[][]
  >([]);
  const [paymentTrends, setPaymentTrends] = useState<(string | number)[][]>([]);
  const [customerEngagement, setCustomerEngagement] = useState<
    (string | number)[][]
  >([]);
  const [revenueTrends, setRevenueTrends] = useState<(string | number)[][]>([]);
  const [escalationRateByIssue, setEscalationRateByIssue] = useState<
    (string | number)[][]
  >([]);
  const [resolutionStatusDistribution, setResolutionStatusDistribution] =
    useState<(string | number)[][]>([]);
  const [issueCountByType, setIssueCountByType] = useState<
    (string | number)[][]
  >([]);

  const httpService = new HttpService();

  const executeRawQuery = async <T = any>(query: string): Promise<T> => {
    try {
      const response = await httpService.post<T, { query: string }>(
        "/api/execute-raw-sql/",
        { query }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to execute raw query", error);
      toast.error("Failed to execute raw query");
      return [] as T;
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsResponse = await executeRawQuery<Product[]>(
          "SELECT * FROM public.product;"
        );
        setProducts(productsResponse);
      } catch (error) {
        console.error("Error fetching products", error);
        toast.error("Error fetching products");
      }
    };

    fetchProducts();
  }, []);

  const [selectedProduct, setSelectedProduct] = useState<Product>();

  useEffect(() => {
    if (product) {
      products?.forEach((p) => {
        if (p.productId === parseInt(product)) {
          setSelectedProduct(p);
        }
      });
    }
  }, [product, products]);

  useEffect(() => {
    const product = selectedProduct?.productName;

    const fetchKeyFindings = async () => {
      try {
        const filters: string[] = [];

        if (product) {
          filters.push(
            `st.product_service_name = '${selectedProduct?.productName}'`
          );
        }

        if (dateCaptured) {
          filters.push(
            `st.purchase_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
          );
        }

        const whereClause =
          filters.length > 0 ? `WHERE ${filters.join(" AND ")}` : "";

        const churnRateResponse = await executeRawQuery<
          { churnRate: number }[]
        >(
          `
          SELECT 
              COUNT(*) * 100.0 / (SELECT COUNT(*) FROM customer_profile) AS churn_rate
          FROM 
              customer_profile cp
          JOIN 
              product pt ON cp.product_id = pt.product_id
          WHERE 
              cp.churn_risk = 'High'
              ${product ? "AND pt.product_name = 'Consulting Services'" : ""}
              ${
                dateCaptured
                  ? `AND cp.last_interaction_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
                  : ""
              }
          `
        );

        const response = await executeRawQuery<
          {
            totalTransactionValue: number;
            averageTransactionValue: number;
            churnRate: number;
            averageAccountHealthScore: number;
            averageReputationScore: number;
          }[]
        >(
          `
          SELECT 
              SUM(st.amount) AS total_transaction_value,
              AVG(st.amount) AS average_transaction_value,
              (SELECT COUNT(*) * 100.0 / (SELECT COUNT(*) FROM customer_profile) 
               FROM customer_profile WHERE churn_risk = 'High') AS churn_rate,
              AVG(cp.account_health_score) AS average_account_health_score,
              AVG(cp.reputation_score) AS average_reputation_score
          FROM 
              sales_transactions st
          JOIN 
              customer_profile cp ON st.customer_id = cp.customer_id
          ${whereClause};
          `
        );

        const customerLifetimeValue = await executeRawQuery<{ avg: number }[]>(`
          WITH customer_summary AS (
              SELECT 
                  customer_id AS customer_id,
                  SUM(amount) AS total_purchase_amount,
                  COUNT(transaction_id) AS total_transactions,
                  MIN(EXTRACT(YEAR FROM purchase_date)) AS first_year,
                  MAX(EXTRACT(YEAR FROM purchase_date)) AS last_year
              FROM 
                  sales_transactions st
              ${whereClause}
              GROUP BY 
                  customer_id
          ),
          clv_calculation AS (
              SELECT 
                  customer_id,
                  (total_purchase_amount * 1.0) / total_transactions AS apv,
                  (last_year - first_year + 1) AS acl,
                  ((total_purchase_amount * 1.0) / total_transactions) * (last_year - first_year + 1) AS clv_without_cac
              FROM 
                  customer_summary
          )
          SELECT 
              avg(clv_without_cac - 4000)
          FROM 
              clv_calculation;
        `);

        console.log(churnRateResponse, "=====");

        setKeyFindings({
          totalTransactionValue: response[0]?.totalTransactionValue
            ? response[0]?.totalTransactionValue
            : 0,
          averageTransactionValue: response[0]?.averageTransactionValue
            ? response[0]?.averageTransactionValue
            : 0,
          churnRate: churnRateResponse[0]?.churnRate || 0,
          averageAccountHealthScore: response[0]?.averageAccountHealthScore
            ? response[0]?.averageAccountHealthScore
            : 0,
          averageReputationScore: response[0]?.averageReputationScore
            ? response[0]?.averageReputationScore
            : 0,
          customerLifetimeValue: customerLifetimeValue[0]?.avg
            ? customerLifetimeValue[0]?.avg
            : 0,
        });
      } catch (error) {
        console.error("Error fetching key findings:", error);
        toast.error("Error fetching key findings");
      }
    };

    const fetchCustomerRiskData = async () => {
      const dateCondition = dateCaptured
        ? `AND st.purchase_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      const productCondition = product
        ? `AND st.product_service_name = '${product}'`
        : "";

      const query = `
        WITH filtered_customers AS (
          SELECT DISTINCT customer_id
          FROM sales_transactions st
          WHERE 1=1
          ${productCondition}
          ${dateCondition}
        )
        SELECT
          COUNT(CASE WHEN cp.lifecycle_stage = 'New' THEN 1 END) AS new_customers,
          COUNT(CASE WHEN cp.churn_risk = 'High' THEN 1 END) AS high_risk_customers,
          COUNT(CASE WHEN cp.churn_risk IN ('Medium', 'Low') THEN 1 END) AS medium_low_risk_customers
        FROM customer_profile cp
        WHERE cp.customer_id IN (SELECT customer_id FROM filtered_customers);
      `;

      const response = await executeRawQuery<
        {
          newCustomers: number;
          highRiskCustomers: number;
          mediumLowRiskCustomers: number;
        }[]
      >(query);

      setCustomerRiskData({
        newCustomers: response[0].newCustomers,
        highRiskCustomers: response[0].highRiskCustomers,
        MediumLowRiskCustomers: response[0].mediumLowRiskCustomers,
      });
    };

    const fetchSalesRevenueMetrics = async () => {
      // Build the date filter condition using the provided `dateCaptured` value
      const dateCondition = dateCaptured
        ? `AND st.purchase_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      // Build the product filter condition
      const productCondition = product
        ? `AND st.product_service_name = '${selectedProduct?.productName}'`
        : "";

      const query = `
        WITH customer_purchase_counts AS (
          SELECT 
              customer_id,
              COUNT(transaction_id) AS purchase_count
          FROM 
              sales_transactions
          GROUP BY 
              customer_id
        )
        SELECT 
            SUM(st.amount) AS total_sales,
            AVG(cpc.purchase_count) AS repeat_purchase_rate  -- average purchase count per customer
        FROM 
            sales_transactions st
        JOIN 
            customer_purchase_counts cpc ON st.customer_id = cpc.customer_id
        WHERE 
            1=1
            ${dateCondition}  -- Apply dateCaptured filter
            ${productCondition}  -- Apply product filter
      `;

      try {
        const response = await executeRawQuery<
          {
            totalSales: number;
            repeatPurchaseRate: number;
          }[]
        >(query);

        setSalesRevenueMetrics(response[0]);
      } catch (error) {
        console.error("Error fetching sales revenue metrics:", error);
      }
    };

    const fetchMarketingCampaignEngagementMetrics = async () => {
      const dateCondition = dateCaptured
        ? `AND interaction_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      const productCondition = product
        ? `AND st.product_service_name = '${product}'`
        : "";

      const query = `
        WITH filtered_customers AS (
          SELECT DISTINCT customer_id
          FROM public.sales_transactions st
          WHERE 1=1
          ${productCondition}
        )
        SELECT 
            AVG(CASE WHEN resolution_provided = 'Resolved' THEN 1 ELSE 0 END) * 100 AS campaign_success_rate,
            AVG(satisfaction_score) AS average_customer_engagement_score
        FROM 
            public.customer_service_interactions csi
        WHERE 
            csi.customer_id IN (SELECT customer_id FROM filtered_customers)
            ${dateCondition};
      `;

      try {
        const response = await executeRawQuery<
          MarketingCampaignEngagementMetrics[]
        >(query);
        setMarketingCampaignEngagementMetrics(response[0]);
      } catch (error) {
        console.error(
          "Error fetching marketing campaign engagement metrics:",
          error
        );
      }
    };

    const fetchPaymentFinanceHealthMetrics = async () => {
      const response = await executeRawQuery<
        {
          onTimePaymentRate: number;
          paymentAlertRate: number;
        }[]
      >(
        `SELECT 
            AVG(CASE WHEN payment_status = 'Paid' AND payment_date <= due_date THEN 1 ELSE 0 END) * 100 AS on_time_payment_rate,
            AVG(CASE WHEN payment_status = 'Pending' AND alerts_sent = 'Yes' THEN 1 ELSE 0 END) * 100 AS payment_alert_rate
        FROM payment_history`
      );
      setPaymentFinanceHealthMetrics(response[0]);
    };

    const fetchCustomerServiceInsights = async () => {
      const dateCondition = dateCaptured
        ? `AND interaction_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      const productCondition = product
        ? `AND st.product_service_name = '${product}'`
        : "";

      const query = `
        WITH filtered_customers AS (
          SELECT DISTINCT customer_id
          FROM public.sales_transactions st
          WHERE 1=1
          ${productCondition}
        )
        SELECT 
            COUNT(*) AS total_Numbers_Of_Issues,
            AVG(CASE WHEN first_contact_resolution = TRUE THEN 1 ELSE 0 END) * 100 AS first_contact_resolution_rate,
            AVG(CASE WHEN number_of_escalations > 0 THEN 1 ELSE 0 END) * 100 AS escalation_Rate,
            AVG(CASE WHEN resolution_provided = 'Resolved' THEN satisfaction_score ELSE NULL END) AS average_Satisfaction_Resovled_Cases,
            AVG(CASE WHEN resolution_provided = 'Pending' THEN satisfaction_score ELSE NULL END) AS average_Satisfaction_Pending_Cases,
            AVG(CASE WHEN number_of_escalations > 0 THEN satisfaction_score ELSE NULL END) AS average_Satisfaction_Escalated_Cases
        FROM 
            customer_service_interactions csi
        WHERE 
            csi.customer_id IN (SELECT customer_id FROM filtered_customers)
            ${dateCondition};
      `;

      try {
        const response = await executeRawQuery<customerServiceInsights[]>(
          query
        );
        setCustomerServiceInsights(response[0]);
      } catch (error) {
        console.error("Error fetching customer service insights:", error);
      }
    };

    const getAvgAccountHealthScore = async () => {
      const dateCondition = dateCaptured
        ? `AND st.purchase_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      const productCondition = product
        ? `AND st.product_service_name = '${product}'`
        : "";

      const query = `
        WITH filtered_customers AS (
          SELECT DISTINCT customer_id
          FROM public.sales_transactions st
          WHERE 1=1
          ${productCondition}
          ${dateCondition}
        )
        SELECT 
          i.industry_segment_name,
          AVG(cp.account_health_score) AS avg_account_health_score
        FROM customer_profile cp
        JOIN industry_segment i ON cp.industry_segment_id = i.industry_segment_id
        WHERE cp.customer_id IN (SELECT customer_id FROM filtered_customers)
        GROUP BY i.industry_segment_name;
      `;

      const result = await executeRawQuery<
        {
          industrySegmentName: string;
          avgAccountHealthScore: number;
        }[]
      >(query);

      if (result.length === 0) {
        return setAvgAccountHealthScore([]);
      }

      setAvgAccountHealthScore([
        ["Industry Segment", "Average Account Health Score"],
        ...result.map((r) => [r.industrySegmentName, r.avgAccountHealthScore]),
      ]);
    };

    const getChurnRiskDistribution = async () => {
      const dateCondition = dateCaptured
        ? `AND st.purchase_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      const productCondition = product
        ? `AND st.product_service_name = '${product}'`
        : "";

      const query = `
        WITH filtered_customers AS (
          SELECT DISTINCT customer_id
          FROM public.sales_transactions st
          WHERE 1=1
          ${productCondition}
          ${dateCondition}
        )
        SELECT 
          cp.churn_risk,
          COUNT(*) AS customer_count
        FROM customer_profile cp
        WHERE cp.customer_id IN (SELECT customer_id FROM filtered_customers)
        GROUP BY cp.churn_risk;
      `;

      const result = await executeRawQuery<
        {
          churnRisk: string;
          customerCount: number;
        }[]
      >(query);

      if (result.length === 0) {
        return setChurnRiskDistribution([]);
      }

      setChurnRiskDistribution([
        ["Churn Risk", "Customer Count"],
        ...result.map((r) => [r.churnRisk, r.customerCount]),
      ]);
    };

    const getAvgReputationScore = async () => {
      const dateCondition = dateCaptured
        ? `AND st.purchase_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      const productCondition = product
        ? `AND st.product_service_name = '${product}'`
        : "";

      const query = `
        WITH filtered_customers AS (
          SELECT DISTINCT customer_id
          FROM public.sales_transactions st
          WHERE 1=1
          ${productCondition}
          ${dateCondition}
        )
        SELECT 
          i.industry_segment_name,
          AVG(cp.reputation_score) AS avg_reputation_score
        FROM customer_profile cp
        JOIN industry_segment i ON cp.industry_segment_id = i.industry_segment_id
        WHERE cp.customer_id IN (SELECT customer_id FROM filtered_customers)
        GROUP BY i.industry_segment_name;
      `;

      const result = await executeRawQuery<
        {
          industrySegmentName: string;
          avgReputationScore: number;
        }[]
      >(query);

      if (result.length === 0) {
        return setAvgReputationScore([]);
      }

      setAvgReputationScore([
        ["Industry Segment", "Average Reputation Score"],
        ...result.map((r) => [r.industrySegmentName, r.avgReputationScore]),
      ]);
    };

    const getPaymentTrends = async () => {
      const dateCondition = dateCaptured
        ? `AND ph.payment_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      const productCondition = product
        ? `AND st.product_service_name = '${product}'`
        : "";

      const query = `
        WITH filtered_customers AS (
          SELECT DISTINCT customer_id
          FROM public.sales_transactions st
          WHERE 1=1
          ${productCondition}
        )
        SELECT 
          TO_CHAR(DATE_TRUNC('month', ph.payment_date), 'Month') AS month,
          DATE_TRUNC('month', ph.payment_date) AS month_date,
          ph.payment_status,
          COUNT(*) AS status_count
        FROM payment_history ph
        WHERE ph.customer_id IN (SELECT customer_id FROM filtered_customers)
          ${dateCondition}
        GROUP BY 
          DATE_TRUNC('month', ph.payment_date),
          ph.payment_status
        ORDER BY 
          month_date, 
          ph.payment_status;
      `;

      const result = await executeRawQuery<
        {
          month: string;
          paymentStatus: string;
          statusCount: number;
        }[]
      >(query);

      if (result.length === 0) {
        return setPaymentTrends([]);
      }

      // Initialize the data in the format for Google Charts
      const formattedData: (string | number)[][] = [
        ["Month", "Overdue", "Pending", "Paid"],
      ]; // Header row

      // Create a map to organize the results by month_date
      const monthMap: Record<string, Record<string, number>> = {};

      result.forEach((r) => {
        if (!monthMap[r.month]) {
          monthMap[r.month] = { Overdue: 0, Pending: 0, Paid: 0 };
        }
        monthMap[r.month][r.paymentStatus] = r.statusCount;
      });

      // Order monthMap by chronological order using month_date
      const monthOrder = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Sort the months logically
      Object.keys(monthMap)
        .sort((a, b) => {
          const monthA = monthOrder.indexOf(a.trim());
          const monthB = monthOrder.indexOf(b.trim());
          return monthA - monthB;
        })
        .forEach((month) => {
          formattedData.push([
            month,
            monthMap[month]["Overdue"],
            monthMap[month]["Pending"],
            monthMap[month]["Paid"],
          ]);
        });

      setPaymentTrends(formattedData);
    };

    const getCustomerEngagement = async () => {
      const dateCondition = dateCaptured
        ? `AND st.purchase_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      const productCondition = product
        ? `AND st.product_service_name = '${product}'`
        : "";

      const query = `
        WITH filtered_customers AS (
          SELECT DISTINCT customer_id
          FROM public.sales_transactions st
          WHERE 1=1
          ${productCondition}
          ${dateCondition}
        )
        SELECT 
          cp.preferred_channel,
          COUNT(DISTINCT cp.customer_id) AS customer_count
        FROM customer_profile cp
        WHERE cp.customer_id IN (SELECT customer_id FROM filtered_customers)
        GROUP BY cp.preferred_channel;
      `;

      const result = await executeRawQuery<
        {
          preferredChannel: string;
          customerCount: number;
        }[]
      >(query);

      if (result.length === 0) {
        return setCustomerEngagement([]);
      }

      setCustomerEngagement([
        ["Preferred Channel", "Customer Count"],
        ...result.map((r) => [r.preferredChannel, r.customerCount]),
      ]);
    };

    const getRevenueTrends = async () => {
      const dateCondition = dateCaptured
        ? `AND st.purchase_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      const productCondition = product
        ? `AND st.product_service_name = '${product}'`
        : "";

      const query = `
        SELECT 
          TO_CHAR(DATE_TRUNC('month', st.purchase_date), 'Mon') AS month,
          SUM(st.amount) AS total_revenue
        FROM sales_transactions st
        WHERE 1=1
          ${productCondition}
          ${dateCondition}
        GROUP BY DATE_TRUNC('month', st.purchase_date)
        ORDER BY DATE_TRUNC('month', st.purchase_date);
      `;

      const result = await executeRawQuery<
        {
          month: string;
          totalRevenue: number;
        }[]
      >(query);

      if (result.length === 0) {
        return setRevenueTrends([]);
      }

      setRevenueTrends([
        ["Month", "Total Revenue"],
        ...result.map((r) => [r.month, r.totalRevenue]),
      ]);
    };

    const getEscalationRateByIssue = async () => {
      const dateCondition = dateCaptured
        ? `AND csi.interaction_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      const productCondition = product
        ? `AND st.product_service_name = '${product}'`
        : "";

      const query = `
        WITH filtered_customers AS (
          SELECT DISTINCT customer_id
          FROM public.sales_transactions st
          WHERE 1=1
          ${productCondition}  -- Insert product filter here
        )
        SELECT 
            csi.issue_raised,  -- Grouping by issue_raised to get escalation rate by issue type
            COUNT(*) AS total_issues,  -- Counting the total number of issues for each issue type
            SUM(csi.number_of_escalations) AS total_escalations,  -- Summing the number of escalations for each issue type
            CASE
                WHEN COUNT(*) > 0 THEN 
                    CAST(SUM(csi.number_of_escalations) AS FLOAT) / COUNT(*)  -- Calculating escalation rate
                ELSE 
                    0  -- If no issues, set escalation rate to 0
            END AS escalation_rate  -- Escalation rate
        FROM 
            public.customer_service_interactions csi
        WHERE 
            csi.customer_id IN (SELECT customer_id FROM filtered_customers)
            ${dateCondition}  -- Apply the date condition if provided
        GROUP BY 
            csi.issue_raised;  -- Group by issue type for escalation rate calculation
      `;

      try {
        const result = await executeRawQuery<
          {
            issueRaised: string;
            totalIssues: number;
            totalEscalations: number;
            escalationRate: number;
          }[] // Adjusting result structure to include escalation_rate
        >(query);
        console.log("result:>>>>> ", result);
        if (result.length === 0) {
          return setEscalationRateByIssue([]);
        }
        // setEscalationRateByIssue([
        //   [
        //     "Issue Raised",
        //     "Total Issues",
        //     "Total Escalations",
        //     "Escalation Rate",
        //   ],
        //   ...result.map((r) => [
        //     r.issueRaised,
        //     r.totalIssues,
        //     r.totalEscalations,
        //     r.escalationRate.toFixed(2),
        //   ]),
        // ]);

        setEscalationRateByIssue([
          ["Issue Raised", "Total Issues"],
          ...result.map((r) => [r.issueRaised, r.totalEscalations]),
        ]);
      } catch (error) {
        console.error("Error fetching escalation rate by issue:", error);
      }
    };

    // const getEscalationRateByIssue = async () => {
    //   const dateCondition = dateCaptured
    //     ? `AND interaction_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
    //     : "";

    //   const productCondition = product
    //     ? `AND st.product_service_name = '${product}'`
    //     : "";

    //   const query = `
    //     WITH filtered_customers AS (
    //       SELECT DISTINCT customer_id
    //       FROM public.sales_transactions st
    //       WHERE 1=1
    //       ${productCondition}
    //     )
    //     SELECT
    //         csi.issue_raised,
    //         COUNT(*) AS total_issues
    //     FROM
    //         customer_service_interactions csi
    //     WHERE
    //         csi.customer_id IN (SELECT customer_id FROM filtered_customers)
    //         ${dateCondition}
    //     GROUP BY
    //         csi.issue_raised;
    //   `;

    //   try {
    //     const result = await executeRawQuery<
    //       { issueRaised: string; totalIssues: number }[]
    //     >(query);
    //     if (result.length === 0) {
    //       return setEscalationRateByIssue([]);
    //     }
    //     setEscalationRateByIssue([
    //       ["Issue Raised", "Total Issues"],
    //       ...result.map((r) => [r.issueRaised, r.totalIssues]),
    //     ]);
    //   } catch (error) {
    //     console.error("Error fetching escalation rate by issue:", error);
    //   }
    // };

    const getResolutionStatusDistribution = async () => {
      const dateCondition = dateCaptured
        ? `AND csi.interaction_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      const productCondition = product
        ? `AND st.product_service_name = '${product}'`
        : "";

      const query = `
        WITH filtered_customers AS (
          SELECT DISTINCT customer_id
          FROM public.sales_transactions st
          WHERE 1=1
          ${productCondition}
        )
        SELECT 
          csi.resolution_provided,
          COUNT(*) AS issue_count
        FROM 
          customer_service_interactions csi
        WHERE 
          csi.customer_id IN (SELECT customer_id FROM filtered_customers)
          ${dateCondition}
        GROUP BY 
          csi.resolution_provided;
      `;

      const result = await executeRawQuery<
        {
          resolutionProvided: string;
          issueCount: number;
        }[]
      >(query);

      if (result.length === 0) {
        return setResolutionStatusDistribution([]);
      }

      setResolutionStatusDistribution([
        ["Resolution Provided", "Issue Count"],
        ...result.map((r) => [r.resolutionProvided, r.issueCount]),
      ]);
    };

    const getIssueCountByType = async () => {
      const dateCondition = dateCaptured
        ? `AND csi.interaction_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
        : "";

      const productCondition = product
        ? `AND st.product_service_name = '${product}'`
        : "";

      const query = `
        WITH filtered_customers AS (
          SELECT DISTINCT customer_id
          FROM public.sales_transactions st
          WHERE 1=1
          ${productCondition}
        )
        SELECT 
          csi.issue_raised,
          COUNT(*) AS issue_count
        FROM 
          customer_service_interactions csi
        WHERE 
          csi.customer_id IN (SELECT customer_id FROM filtered_customers)
          ${dateCondition}
        GROUP BY 
          csi.issue_raised;
      `;

      const result = await executeRawQuery<
        {
          issueRaised: string;
          issueCount: number;
        }[]
      >(query);
      if (result.length === 0) {
        return setIssueCountByType([]);
      }

      setIssueCountByType([
        ["Issue Raised", "Issue Count"],
        ...result.map((r) => [r.issueRaised, r.issueCount]),
      ]);
    };

    try {
      fetchKeyFindings();
      fetchCustomerRiskData();
      fetchSalesRevenueMetrics();
      fetchMarketingCampaignEngagementMetrics();
      fetchPaymentFinanceHealthMetrics();
      fetchCustomerServiceInsights();
      getAvgAccountHealthScore();
      getChurnRiskDistribution();
      getAvgReputationScore();
      getPaymentTrends();
      getCustomerEngagement();
      getRevenueTrends();
      getEscalationRateByIssue();
      getResolutionStatusDistribution();
      getIssueCountByType();
    } catch (error) {
      console.error("Error fetching customer success report data", error);
      toast.error("Error fetching customer success report data");
    }
  }, [selectedProduct, dateCaptured]);

  return {
    products,
    dateCapturedOptions,
    keyFindings,
    customerRiskData,
    salesRevenueMetrics,
    marketingCampaignEngagementMetrics,
    paymentFinanceHealthMetrics,
    customerServiceInsights,

    avgAccountHealthScore,
    churnRiskDistribution,
    avgReputationScore,
    paymentTrends,
    customerEngagement,
    revenueTrends,
    escalationRateByIssue,
    resolutionStatusDistribution,
    issueCountByType,
  };
};

export default useCustomerSuccessReport;
