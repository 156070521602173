import React, { useEffect } from "react";

import styles from "./AcquisitionDashboardReport.module.scss";

import TextElement from "../TextElement/TextElement";

import Metric from "../CampaignReports/components/Metric/Metric";
// import Select from "../Select/Select";

import Select from "../../componenets/Select/Select";

import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import GraphWrapper from "../Graph/GraphWrapper";

import useAcquisitionDashboardReport from "./useAcquisitionDashboardReport";
import {
  formatLargeNumber,
  formatNumberStandard,
  formatNumberWithPlus,
} from "../../lib/helpers";
import { DateCapturedValues } from "./types";
import { Chart } from "react-google-charts";
import CreateStrategyBox from "../SalesDashboardReport/components/CreateStrategyBox/CreateStrategyBox";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { useAppDispatch } from "../../redux/hooks";
import { clearCampaignQueryAnalysis } from "../../redux/campaignPlanAnalysisQueryChat";
import { clearContentGenerationState } from "../../redux/contentGenerationSlice";

import { setSelectedCampaign } from "../../redux/campaignPinningSlice";

type Props = {
  className?: string;
  onClick?: () => void;
};

function roundOff(value: number, decimals: number = 0): number {
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
}

const barChartOptions = {
  colors: ["#FFE5E0"],
  chartArea: { top: 20, left: 150,  width: "70%", height: "80%" },
  legend: { position: "top" },
}

const columnChartOptions = {
  colors: ["#FFE5E0"],
  chartArea: { top: 20, width: "90%", height: "80%" },
  vAxis: {
    format: "short",
  },
  legend: { position: "top" },
}

const AcquisitionDashboardReport: React.FC<Props> = (props) => {
  const { className, onClick } = props;
  const navigate = useNavigate();

  const [selectedProduct, setSelectedProduct] = React.useState("");
  const [selectedCampaignId, setSelectedCampaignId] = React.useState("");
  const [dateCaptured, setDateCaptured] =
    React.useState<DateCapturedValues>("");

  const dispatch = useAppDispatch();

  const {
    keyFindings,
    organisationSize,
    technologyTrends,
    graphicImpact,
    salesForceFinding,
    salesforcePG,
    salesInsights,
    setSelectServices,
    selectServices,
    salesforceDemand,
    //
    dataAnalyticsFindings,
    dataAnalyticsUseCases,
    dataAnalyticsYoyBudgetAllocation,
    dataAnalyticsInsights,
    //
    securityNetworkFindings,
    securityChallenges,
    securityNetworkInsights,
    securityService,
    //
    iotGisFindings,
    iotGisUseCase,
    iotGisInsights,
    verticalDemandsService,
    //
    services,
    products,
    campaigns,
    costEngagementScatterData,
    contactsTrendData,
    performanceAcrossKeyMetrics,
    emailPerformanceData,
    landingPagePerformanceData,
    linkedInEngagementData,
    dateCapturedOptions,
    industrySegmentList,
    selectedIndustry,
    setSelectedIndustry,
    dashboardData,
  } = useAcquisitionDashboardReport(
    selectedProduct,
    selectedCampaignId,
    dateCaptured
  );

  const salesKeyInsights =
    salesInsights && salesInsights[0]?.insights
      ? JSON.parse(salesInsights[0]?.insights)["Investment Priorities"]
      : "";

  const dataAInsights =
    dataAnalyticsInsights && dataAnalyticsInsights[0]?.insights
      ? JSON.parse(dataAnalyticsInsights[0]?.insights)[
          "Cloud-First Strategy Opportunity"
        ]
      : "";

  const securityNetwork =
    securityNetworkInsights && securityNetworkInsights[0]?.insights
      ? JSON.parse(securityNetworkInsights[0]?.insights)[
          "Regional Market Opportunities"
        ]
      : "";

  const iotGis =
    iotGisInsights && iotGisInsights[0]?.insights
      ? JSON.parse(iotGisInsights[0]?.insights)[
          "Growing Demand for IOT with GIS"
        ]
      : "";

  console.log("selectServices", selectServices);
  return (
    <div
      className={classNames(styles["container"], className)}
      onClick={onClick}
    >
      <div className={styles["filters"]}>
        {/* <Select
          options={[]}
          value={""}
          placeholderText="Target Industry"
          onChange={(value) => {}}
          buttonClassName={styles["select-button"]}
          className={styles["select-menu"]}
        /> */}

        <Select
          placeholderText="All Industries"
          buttonClassName={styles["select-button"]}
          className={styles["select-menu"]}
          showClearSelection={true}
          clearSelectionLabel="All Industries"
          options={
            industrySegmentList
              ? industrySegmentList.map((item) => ({
                  value: item.industrySegmentId,
                  label: item.industrySegmentName,
                }))
              : []
          }
          // values={selectedValues}
          value={selectedIndustry}
          onChange={(value) => {
            console.log("value:>>> ", value);

            setSelectedIndustry(value as any);
          }}
          // onChange={(value) => {
          //   console.log("Selected Value:", value);

          //   // Find the label corresponding to the selected value
          //   const selectedOption = industrySegmentList?.find(
          //     (item) => item.industrySegmentId === value
          //   );
          //   console.log("selectedOption: ", selectedOption);

          //   console.log("Selected Name:", selectedOption?.industrySegmentName);

          //   // Update state
          //   setSelectedIndustry(selectedOption?.industrySegmentName as any);
          // }}
        />
        <Select
          buttonClassName={styles["select-button"]}
          className={styles["select-menu"]}
          options={services}
          value={selectServices}
          showClearSelection={true}
          clearSelectionLabel="All Services"
          // onChange={(value) => {}}
          onChange={(value) => {
            console.log("value:>>> ", value);

            setSelectServices(value as any);
          }}
          placeholderText="All Services"
        />
      </div>
      <TextElement className={styles["sub-heading"]}>Key Findings</TextElement>
      <div className={styles["findings"]}>
        <Metric
          label="Total Addressable Market Size (TAM)"
          value={formatLargeNumber(roundOff(keyFindings?.tam || 0, 2))}
          // value={`${(dashboardData && dashboardData["extract TAM"]) || 0}`}
          className={styles["metric"]}
          icon={
            <svg
              width="15"
              height="13"
              viewBox="0 0 15 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 6.51777C9.57187 6.51777 11.25 5.0662 11.25 3.27402C11.25 1.48185 9.57187 0.0302734 7.5 0.0302734C5.42812 0.0302734 3.75 1.48185 3.75 3.27402C3.75 5.0662 5.42812 6.51777 7.5 6.51777ZM7.5 8.13965C4.99687 8.13965 0 9.22631 0 11.3834V13.0053H15V11.3834C15 9.22631 10.0031 8.13965 7.5 8.13965Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Potential Target Organisations"
          value={formatNumberStandard(
            Math.round(keyFindings?.targetOrganizations || 0)
          )}
          // value={"80,000+"}
          // value={`${
          //   (dashboardData && dashboardData["extract number of orgs"]) || 0
          // }`}
          className={styles["metric"]}
          icon={
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.7436 7.97005C19.9145 8.16241 20 8.39084 20 8.65534C20 8.91983 19.9145 9.14826 19.7436 9.34063L16.4336 12.731C16.2626 12.9234 16.0451 13.0196 15.7809 13.0196C15.5167 13.0196 15.2991 12.9234 15.1282 12.731L13.7995 11.3845C13.6131 11.1921 13.5198 10.9637 13.5198 10.6992C13.5198 10.4347 13.6131 10.2063 13.7995 10.0139C13.986 9.83756 14.2075 9.7494 14.4639 9.7494C14.7203 9.7494 14.9417 9.83756 15.1282 10.0139L15.7809 10.6872L18.4149 7.97005C18.6014 7.79371 18.8228 7.70555 19.0793 7.70555C19.3357 7.70555 19.5571 7.79371 19.7436 7.97005ZM8.69464 17.492L6.36364 15.3039C5.24476 14.2619 4.28516 13.3321 3.48485 12.5146C2.68454 11.6971 2.02409 10.9276 1.5035 10.2063C0.982906 9.4849 0.602176 8.78759 0.361305 8.11432C0.120435 7.44105 0 6.73572 0 5.99833C0 4.4915 0.48951 3.23714 1.46853 2.23525C2.44755 1.23336 3.66744 0.732422 5.1282 0.732422C5.93629 0.732422 6.70552 0.904746 7.4359 1.2494C8.16628 1.59404 8.79565 2.08697 9.32401 2.72818C9.85237 2.08697 10.4817 1.59404 11.2121 1.2494C11.9425 0.904746 12.7117 0.732422 13.5198 0.732422C14.8407 0.732422 15.9479 1.1452 16.8415 1.97075C17.735 2.79631 18.2906 3.76213 18.5082 4.86821C18.1974 4.73997 17.871 4.65981 17.5291 4.62775C17.1873 4.59569 16.8376 4.57966 16.4802 4.57966C15.1593 4.57966 13.9472 5.1287 12.8438 6.22676C11.7405 7.32483 11.1888 8.69942 11.1888 10.3505C11.1888 11.12 11.352 11.9014 11.6783 12.6949C12.0047 13.4884 12.4631 14.1336 13.0536 14.6306C12.7584 14.9031 12.3737 15.2518 11.8998 15.6766C11.4258 16.1014 11.0179 16.4741 10.676 16.7947L9.93007 17.492C9.75913 17.6523 9.55322 17.7324 9.31235 17.7324C9.07148 17.7324 8.86558 17.6523 8.69464 17.492Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Market CAGR"
          // value={"11.6%"}
          // value={roundOff(keyFindings?.cagr)}
          value={`${roundOff(keyFindings?.cagr || 0, 2)}%`}
          // value={`${(dashboardData && dashboardData["extract CAGR"]) || 0}`}
          className={styles["metric"]}
          icon={
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8.87528C16 8.12885 15.4 7.51814 14.6667 7.51814H7.76L8.64 3.20242L8.66667 2.89028C8.66667 2.46956 8.49333 2.08956 8.22667 1.80456L7.17333 0.732422L0.586667 7.43671C0.226667 7.80314 0 8.31885 0 8.87528V17.6967C0 18.8231 0.893333 19.7324 2 19.7324H11C11.8267 19.7324 12.5333 19.2167 12.84 18.4974L15.8533 11.3181C15.9467 11.0874 16 10.8296 16 10.5717V8.87528Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="of Market Size, SMBs Contribution"
          // value={keyFindings?.smbsContribution||0}
          value={`${roundOff(keyFindings?.smbsContribution || 0, 2)}%`}
          // value={`48%`}
          // value={`${
          //   (dashboardData && dashboardData["extract SMBs contribution"]) || 0
          // }`}
          className={styles["metric"]}
          icon={
            <svg
              width="19"
              height="17"
              viewBox="0 0 19 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.04762 14.0658C9.04762 10.6258 11.8795 7.84353 15.381 7.84353C16.3581 7.84353 17.2719 8.06576 18.0952 8.44798V2.5102C18.0952 1.53242 17.281 0.732422 16.2857 0.732422H1.80952C0.814286 0.732422 0 1.53242 0 2.5102V13.1769C0 14.1546 0.814286 14.9546 1.80952 14.9546H9.12C9.07476 14.6613 9.04762 14.368 9.04762 14.0658ZM1.80952 2.5102L9.04762 6.95464L16.2857 2.5102V4.28798L9.04762 8.73242L1.80952 4.28798V2.5102ZM13.879 16.7324L10.6762 13.5858L11.9519 12.3324L13.87 14.2169L17.7062 10.448L19 11.7013L13.879 16.7324Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Expected Investment Year (2025)"
          // value={keyFindings?.expectedInvestment||0}
          value={`${formatLargeNumber(
            roundOff(keyFindings?.expectedInvestment || 0, 2)
          )}`}
          // value={`$35B`}
          // value={`${
          //   (dashboardData && dashboardData["extract expected investment"]) || 0
          // }`}
          className={styles["metric"]}
          icon={
            <svg
              width="11"
              height="18"
              viewBox="0 0 11 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 0L11 10.08L5.68333 10.53L8.70833 17.1L6.69167 18L3.75833 11.34L0 14.85V0Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Cloud Uptake (Adoption Trend)"
          // value={`82%`}
          // value={`${
          //   (dashboardData && dashboardData["extract cloud adoption"]) || 0
          // }`}
          // value={keyFindings?.cloudUptake||0}
          value={`${roundOff(keyFindings?.cloudUptake || 0, 2)}%`}
          className={styles["metric"]}
          icon={
            <svg
              width="18"
              height="23"
              viewBox="0 0 18 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.3 17.7574C1.58333 16.9574 1.02083 16.0408 0.6125 15.0074C0.204167 13.9741 0 12.8824 0 11.7324C0 9.23242 0.875 7.10742 2.625 5.35742C4.375 3.60742 6.5 2.73242 9 2.73242V0.732422L14 4.48242L9 8.23242V6.23242C7.48333 6.23242 6.1875 6.76992 5.1125 7.84492C4.0375 8.91992 3.5 10.2158 3.5 11.7324C3.5 12.4991 3.64583 13.2158 3.9375 13.8824C4.22917 14.5491 4.625 15.1324 5.125 15.6324L2.3 17.7574ZM9 22.7324L4 18.9824L9 15.2324V17.2324C10.5167 17.2324 11.8125 16.6949 12.8875 15.6199C13.9625 14.5449 14.5 13.2491 14.5 11.7324C14.5 10.9658 14.3542 10.2491 14.0625 9.58242C13.7708 8.91576 13.375 8.33242 12.875 7.83242L15.7 5.70742C16.4167 6.50742 16.9792 7.42409 17.3875 8.45742C17.7958 9.49076 18 10.5824 18 11.7324C18 14.2324 17.125 16.3574 15.375 18.1074C13.625 19.8574 11.5 20.7324 9 20.7324V22.7324Z"
                fill="white"
              />
            </svg>
          }
        />
      </div>

      <div className={styles["three-column"]}>
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="100%"
              data={organisationSize || []}
              options={columnChartOptions}
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Key Investment Drivers by Organisation Size"
        />

        <GraphWrapper
          graphComonent={
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="100%"
              data={technologyTrends || []}
              legendToggle
              options={columnChartOptions}
            />
          }
          containerClassName={classNames(
            styles["graph-container"],
            styles["line-container"]
          )}
          titleClassName={styles["sub-heading"]}
          title={"Cross-Service Technology Trends"}
        />

        <GraphWrapper
          graphComonent={
            <Chart
              chartType="PieChart"
              data={graphicImpact || []}
              // data={[
              //   ["Area", "Percentage"],
              //   ["New South Wales", 28],
              //   ["Victoria", 15],
              //   ["Queensland", 22],
              //   ["Others (WA, SA)", 35],
              // ]}
              // data={[
              //   ["Area", "Percentage"],
              //   [
              //     "New South Wales",
              //     dashboardData?.["extract region impact"]?.[
              //       "New South Wales"
              //     ] || 0,
              //   ],
              //   [
              //     "Victoria",
              //     dashboardData?.["extract region impact"]?.["Victoria"] || 0,
              //   ],
              //   [
              //     "Queensland",
              //     dashboardData?.["extract region impact"]?.["Queensland"] || 0,
              //   ],
              //   [
              //     "Western Australia",
              //     dashboardData?.["extract region impact"]?.[
              //       "Western Australia"
              //     ] || 0,
              //   ],
              //   [
              //     "South Australia",
              //     dashboardData?.["extract region impact"]?.[
              //       "South Australia"
              //     ] || 0,
              //   ],
              //   [
              //     "Tasmania, Northern Territory, and Australian Capital Territory",
              //     dashboardData?.["extract region impact"]?.[
              //       "Tasmania, Northern Territory, and Australian Capital Territory"
              //     ] || 0,
              //   ],
              // ]}
              options={{
                is3D: true,
                pieStartAngle: 0,
                chartArea: {
                  top: 30,
                  width: "100%",
                  height: "91%",
                },
                legend: {
                  position: "top",
                  textStyle: {
                    color: "#222",
                    fontSize: 11,
                  },
                },
                colors: [
                  "#CE2605",
                  "#FF6143",
                  "#FF7A60",
                  "#FFA998",
                  "#EB2701",
                  "#F93A15",
                ],
              }}
              width={"100%"}
              height={"100%"}
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Geographic Impact"
        />
      </div>

      <TextElement className={classNames(styles["sub-heading"], "py-2")}>
        Market Breakdown Per Service
      </TextElement>

      {/* Salesforce */}

      {(selectServices === "Salesforce" || !selectServices) && (
        <div className={styles["channel"]}>
          <TextElement className={styles["channel-title"]}>
            Salesforce
          </TextElement>
          <div className={classNames(styles["findings"], "py-2")}>
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Total Addressable Market Size (TAM)"
              value={formatLargeNumber(
                roundOff(
                  salesForceFinding.avgTotalAddressableMarketSize || 0,
                  2
                )
              )}
              // value={"$45B"}
              className={styles["white-metric"]}
              icon={
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.0001 7.28062C10.1566 7.28062 11.9032 5.8626 11.9032 4.11187C11.9032 2.36113 10.1566 0.943115 8.0001 0.943115C5.84362 0.943115 4.09697 2.36113 4.09697 4.11187C4.09697 5.8626 5.84362 7.28062 8.0001 7.28062ZM8.0001 8.86499C5.39476 8.86499 0.193848 9.92652 0.193848 12.0337V13.6181H15.8063V12.0337C15.8063 9.92652 10.6054 8.86499 8.0001 8.86499Z"
                    fill="#EB2701"
                  />
                </svg>
              }
            />
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Compound Annual Growth Rate (CAGR)"
              // value={"13.4%"}
              value={`${roundOff(
                salesForceFinding.avgCompoundAnnualGrowthRate || 0,
                2
              )}%`}
              className={styles["white-metric"]}
              icon={
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15.2805" r="15" fill="white" />
                  <path
                    d="M24.7436 14.2506C24.9145 14.4429 25 14.6714 25 14.9359C25 15.2004 24.9145 15.4288 24.7436 15.6211L21.4336 19.0115C21.2626 19.2039 21.0451 19.3001 20.7809 19.3001C20.5167 19.3001 20.2991 19.2039 20.1282 19.0115L18.7995 17.665C18.6131 17.4726 18.5198 17.2442 18.5198 16.9797C18.5198 16.7152 18.6131 16.4868 18.7995 16.2944C18.986 16.1181 19.2075 16.0299 19.4639 16.0299C19.7203 16.0299 19.9417 16.1181 20.1282 16.2944L20.7809 16.9677L23.4149 14.2506C23.6014 14.0742 23.8228 13.9861 24.0793 13.9861C24.3357 13.9861 24.5571 14.0742 24.7436 14.2506ZM13.6946 23.7725L11.3636 21.5844C10.2448 20.5424 9.28516 19.6127 8.48485 18.7951C7.68454 17.9776 7.02409 17.2081 6.5035 16.4868C5.98291 15.7654 5.60218 15.0681 5.36131 14.3948C5.12044 13.7216 5 13.0162 5 12.2789C5 10.772 5.48951 9.51765 6.46853 8.51577C7.44755 7.51388 8.66744 7.01294 10.1282 7.01294C10.9363 7.01294 11.7055 7.18526 12.4359 7.52991C13.1663 7.87456 13.7956 8.36749 14.324 9.0087C14.8524 8.36749 15.4817 7.87456 16.2121 7.52991C16.9425 7.18526 17.7117 7.01294 18.5198 7.01294C19.8407 7.01294 20.9479 7.42572 21.8415 8.25127C22.735 9.07682 23.2906 10.0426 23.5082 11.1487C23.1974 11.0205 22.871 10.9403 22.5291 10.9083C22.1873 10.8762 21.8376 10.8602 21.4802 10.8602C20.1593 10.8602 18.9472 11.4092 17.8438 12.5073C16.7405 13.6053 16.1888 14.9799 16.1888 16.631C16.1888 17.4005 16.352 18.182 16.6783 18.9755C17.0047 19.769 17.4631 20.4142 18.0536 20.9111C17.7584 21.1836 17.3737 21.5323 16.8998 21.9571C16.4258 22.3819 16.0179 22.7546 15.676 23.0752L14.9301 23.7725C14.7591 23.9328 14.5532 24.0129 14.3124 24.0129C14.0715 24.0129 13.8656 23.9328 13.6946 23.7725Z"
                    fill="#EB2701"
                  />
                </svg>
              }
            />
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Potential Target Organisations"
              // value={"18,000+"}
              value={formatNumberStandard(
                Math.round(
                  salesForceFinding.avgPotentialTargetOrganizations || 0
                )
              )}
              // value={formatNumberWithPlus(roundOff(
              //   salesForceFinding.avgPotentialTargetOrganizations || 0,
              //   2
              // ))}
              className={styles["white-metric"]}
              icon={
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15.2805" r="15" fill="white" />
                  <path
                    d="M23 13.1558C23 12.4094 22.4 11.7987 21.6667 11.7987H14.76L15.64 7.48294L15.6667 7.1708C15.6667 6.75008 15.4933 6.37008 15.2267 6.08508L14.1733 5.01294L7.58667 11.7172C7.22667 12.0837 7 12.5994 7 13.1558V21.9772C7 23.1037 7.89333 24.0129 9 24.0129H18C18.8267 24.0129 19.5333 23.4972 19.84 22.7779L22.8533 15.5987C22.9467 15.3679 23 15.1101 23 14.8522V13.1558Z"
                    fill="#EB2701"
                  />
                </svg>
              }
            />
          </div>
          <div className="flex flex-row gap-4 w-full">
            <div className="flex flex-row gap-[10px] flex-[.8]">
              <GraphWrapper
                graphComonent={
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="100%"
                    data={salesforceDemand}
                    options={columnChartOptions}
                  />
                }
                containerClassName={classNames(
                  styles["graph-container"],
                  "flex-1"
                )}
                titleClassName={styles["sub-heading"]}
                title="Industries Driving Salesforce Demand"
              />
              <GraphWrapper
                graphComonent={
                  <Chart
                    chartType="PieChart"
                    data={salesforcePG}
                    // data={[
                    //   ["Professionals", "Growth"],
                    //   ["Admins", 28],
                    //   ["Consults", 15],
                    //   ["Developers", 22],
                    // ]}
                    options={{
                      is3D: true,
                      pieStartAngle: 0,
                      chartArea: {
                        top: 30,
                        width: "100%",
                        height: "91%",
                      },
                      legend: {
                        position: "top",
                        textStyle: {
                          color: "#222",
                          fontSize: 11,
                        },
                      },
                      colors: [
                        "#CE2605",
                        "#FF6143",
                        "#FF7A60",
                        "#FFA998",
                        "#EB2701",
                        "#F93A15",
                      ],
                    }}
                    width={"100%"}
                    height={"100%"}
                  />
                }
                containerClassName={classNames(
                  styles["graph-container"],
                  "flex-1"
                )}
                titleClassName={styles["sub-heading"]}
                title="Salesforce Professionals Growth"
              />
            </div>
            <div className="flex flex-col gap-[10px] flex-[.2]">
              <div className="flex flex-col flex-1 gap-[10px] p-[20px] bg-[#FFF6EB] rounded-[10px] border-[1px] border-[#EB8D01]">
                <TextElement className="text-[#EB2701] font-inter font-semibold text-[20px] leading-[28px]">
                  {" "}
                  Top Industries
                </TextElement>
                <TextElement className="text-black font-inter font-bold text-[24px] leading-[32px]">
                  {(salesInsights && salesInsights[0]?.industryName) || " "}
                </TextElement>
              </div>
              <div className="flex flex-col flex-1 gap-[10px] p-[20px] bg-[#FFF6EB] rounded-[10px] border-[1px] border-[#EB8D01]">
                <TextElement className="text-[#EB2701] font-inter font-semibold text-[20px] leading-[28px]">
                  {" "}
                  Key Insights
                </TextElement>
                <TextElement>{salesKeyInsights}</TextElement>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Salesforce */}

      {/* Data Analytics */}
      {(selectServices === "Data Analytics" || !selectServices) && (
        <div className={styles["channel"]}>
          <TextElement className={styles["channel-title"]}>
            Data Analytics
          </TextElement>
          <div className={classNames(styles["findings"], "py-2")}>
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Total Addressable Market Size (TAM)"
              value={formatLargeNumber(
                roundOff(
                  dataAnalyticsFindings.avgTotalAddressableMarketSize || 0,
                  2
                )
              )}
              // value={"$6.8B"}
              className={styles["white-metric"]}
              icon={
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.0001 7.28062C10.1566 7.28062 11.9032 5.8626 11.9032 4.11187C11.9032 2.36113 10.1566 0.943115 8.0001 0.943115C5.84362 0.943115 4.09697 2.36113 4.09697 4.11187C4.09697 5.8626 5.84362 7.28062 8.0001 7.28062ZM8.0001 8.86499C5.39476 8.86499 0.193848 9.92652 0.193848 12.0337V13.6181H15.8063V12.0337C15.8063 9.92652 10.6054 8.86499 8.0001 8.86499Z"
                    fill="#EB2701"
                  />
                </svg>
              }
            />
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Compound Annual Growth Rate (CAGR)"
              // value={"10.5%"}
              value={`${roundOff(
                dataAnalyticsFindings.avgCompoundAnnualGrowthRate || 0,
                2
              )}%`}
              className={styles["white-metric"]}
              icon={
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15.2805" r="15" fill="white" />
                  <path
                    d="M24.7436 14.2506C24.9145 14.4429 25 14.6714 25 14.9359C25 15.2004 24.9145 15.4288 24.7436 15.6211L21.4336 19.0115C21.2626 19.2039 21.0451 19.3001 20.7809 19.3001C20.5167 19.3001 20.2991 19.2039 20.1282 19.0115L18.7995 17.665C18.6131 17.4726 18.5198 17.2442 18.5198 16.9797C18.5198 16.7152 18.6131 16.4868 18.7995 16.2944C18.986 16.1181 19.2075 16.0299 19.4639 16.0299C19.7203 16.0299 19.9417 16.1181 20.1282 16.2944L20.7809 16.9677L23.4149 14.2506C23.6014 14.0742 23.8228 13.9861 24.0793 13.9861C24.3357 13.9861 24.5571 14.0742 24.7436 14.2506ZM13.6946 23.7725L11.3636 21.5844C10.2448 20.5424 9.28516 19.6127 8.48485 18.7951C7.68454 17.9776 7.02409 17.2081 6.5035 16.4868C5.98291 15.7654 5.60218 15.0681 5.36131 14.3948C5.12044 13.7216 5 13.0162 5 12.2789C5 10.772 5.48951 9.51765 6.46853 8.51577C7.44755 7.51388 8.66744 7.01294 10.1282 7.01294C10.9363 7.01294 11.7055 7.18526 12.4359 7.52991C13.1663 7.87456 13.7956 8.36749 14.324 9.0087C14.8524 8.36749 15.4817 7.87456 16.2121 7.52991C16.9425 7.18526 17.7117 7.01294 18.5198 7.01294C19.8407 7.01294 20.9479 7.42572 21.8415 8.25127C22.735 9.07682 23.2906 10.0426 23.5082 11.1487C23.1974 11.0205 22.871 10.9403 22.5291 10.9083C22.1873 10.8762 21.8376 10.8602 21.4802 10.8602C20.1593 10.8602 18.9472 11.4092 17.8438 12.5073C16.7405 13.6053 16.1888 14.9799 16.1888 16.631C16.1888 17.4005 16.352 18.182 16.6783 18.9755C17.0047 19.769 17.4631 20.4142 18.0536 20.9111C17.7584 21.1836 17.3737 21.5323 16.8998 21.9571C16.4258 22.3819 16.0179 22.7546 15.676 23.0752L14.9301 23.7725C14.7591 23.9328 14.5532 24.0129 14.3124 24.0129C14.0715 24.0129 13.8656 23.9328 13.6946 23.7725Z"
                    fill="#EB2701"
                  />
                </svg>
              }
            />
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Potential Target Organisations"
              // value={"21,000+"}
              value={formatNumberStandard(
                Math.round(
                  dataAnalyticsFindings.avgPotentialTargetOrganizations || 0
                )
              )}
              className={styles["white-metric"]}
              icon={
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15.2805" r="15" fill="white" />
                  <path
                    d="M23 13.1558C23 12.4094 22.4 11.7987 21.6667 11.7987H14.76L15.64 7.48294L15.6667 7.1708C15.6667 6.75008 15.4933 6.37008 15.2267 6.08508L14.1733 5.01294L7.58667 11.7172C7.22667 12.0837 7 12.5994 7 13.1558V21.9772C7 23.1037 7.89333 24.0129 9 24.0129H18C18.8267 24.0129 19.5333 23.4972 19.84 22.7779L22.8533 15.5987C22.9467 15.3679 23 15.1101 23 14.8522V13.1558Z"
                    fill="#EB2701"
                  />
                </svg>
              }
            />
          </div>
          <div className="flex flex-row gap-4 w-full">
            <div className="flex flex-row gap-[10px] flex-[.8]">
              <GraphWrapper
                graphComonent={
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="100%"
                    data={dataAnalyticsUseCases}
                    options={barChartOptions}
                  />
                }
                containerClassName={classNames(
                  styles["graph-container"],
                  "flex-1"
                )}
                titleClassName={styles["sub-heading"]}
                title="Key Use Cases (By Industry)"
              />

              <GraphWrapper
                graphComonent={
                  <Chart
                    chartType="LineChart"
                    width="100%"
                    height="100%"
                    data={dataAnalyticsYoyBudgetAllocation}
                    legendToggle
                    options={{
                      curveType: "function",
                      colors: ["#FC8A73"],
                      chartArea: {
                        top: 20,
                        width: "85%",
                        height: "81%",
                      },
                      pointSize: 5,
                      legend: {
                        position: "top",
                        textStyle: {
                          color: "#222",
                          fontSize: 11,
                        },
                      },
                    }}
                  />
                }
                containerClassName={classNames(
                  styles["graph-container"],
                  styles["line-container"],
                  "flex-1"
                )}
                titleClassName={styles["sub-heading"]}
                title={"Projected YoY Budget Allocations"}
              />
            </div>
            <div className="flex flex-col gap-[10px] flex-[.2]">
              <div className="flex flex-col flex-1 gap-[10px] p-[20px] bg-[#FFF6EB] rounded-[10px] border-[1px] border-[#EB8D01]">
                <TextElement className="text-[#EB2701] font-inter font-semibold text-[20px] leading-[28px]">
                  {" "}
                  Top Industries
                </TextElement>
                <TextElement className="text-black font-inter font-bold text-[24px] leading-[32px]">
                  {/* {dataAInsights.industryName || ""} */}
                  {(dataAnalyticsInsights &&
                    dataAnalyticsInsights[0]?.industryName) ||
                    " "}
                </TextElement>
              </div>
              <div className="flex flex-col flex-1 gap-[10px] p-[20px] bg-[#FFF6EB] rounded-[10px] border-[1px] border-[#EB8D01]">
                <TextElement className="text-[#EB2701] font-inter font-semibold text-[20px] leading-[28px]">
                  {" "}
                  Key Insights
                </TextElement>
                <TextElement>{dataAInsights}</TextElement>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Data Analytics */}

      {/* Security & Network */}
      {(selectServices === "Security and Networking" || !selectServices) && (
        <div className={styles["channel"]}>
          <TextElement className={styles["channel-title"]}>
            Security & Network
          </TextElement>
          <div className={classNames(styles["findings"], "py-2")}>
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Total Addressable Market Size (TAM)"
              // value={"$7.9B"}
              value={`$${formatNumberStandard(
                roundOff(
                  securityNetworkFindings.avgPotentialTargetOrganizations || 0,
                  2
                )
              )}`}
              className={styles["white-metric"]}
              icon={
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.0001 7.28062C10.1566 7.28062 11.9032 5.8626 11.9032 4.11187C11.9032 2.36113 10.1566 0.943115 8.0001 0.943115C5.84362 0.943115 4.09697 2.36113 4.09697 4.11187C4.09697 5.8626 5.84362 7.28062 8.0001 7.28062ZM8.0001 8.86499C5.39476 8.86499 0.193848 9.92652 0.193848 12.0337V13.6181H15.8063V12.0337C15.8063 9.92652 10.6054 8.86499 8.0001 8.86499Z"
                    fill="#EB2701"
                  />
                </svg>
              }
            />
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Compound Annual Growth Rate (CAGR)"
              // value={"14.0%"}
              value={`${roundOff(
                securityNetworkFindings.avgCompoundAnnualGrowthRate || 0,
                2
              )}%`}
              className={styles["white-metric"]}
              icon={
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15.2805" r="15" fill="white" />
                  <path
                    d="M24.7436 14.2506C24.9145 14.4429 25 14.6714 25 14.9359C25 15.2004 24.9145 15.4288 24.7436 15.6211L21.4336 19.0115C21.2626 19.2039 21.0451 19.3001 20.7809 19.3001C20.5167 19.3001 20.2991 19.2039 20.1282 19.0115L18.7995 17.665C18.6131 17.4726 18.5198 17.2442 18.5198 16.9797C18.5198 16.7152 18.6131 16.4868 18.7995 16.2944C18.986 16.1181 19.2075 16.0299 19.4639 16.0299C19.7203 16.0299 19.9417 16.1181 20.1282 16.2944L20.7809 16.9677L23.4149 14.2506C23.6014 14.0742 23.8228 13.9861 24.0793 13.9861C24.3357 13.9861 24.5571 14.0742 24.7436 14.2506ZM13.6946 23.7725L11.3636 21.5844C10.2448 20.5424 9.28516 19.6127 8.48485 18.7951C7.68454 17.9776 7.02409 17.2081 6.5035 16.4868C5.98291 15.7654 5.60218 15.0681 5.36131 14.3948C5.12044 13.7216 5 13.0162 5 12.2789C5 10.772 5.48951 9.51765 6.46853 8.51577C7.44755 7.51388 8.66744 7.01294 10.1282 7.01294C10.9363 7.01294 11.7055 7.18526 12.4359 7.52991C13.1663 7.87456 13.7956 8.36749 14.324 9.0087C14.8524 8.36749 15.4817 7.87456 16.2121 7.52991C16.9425 7.18526 17.7117 7.01294 18.5198 7.01294C19.8407 7.01294 20.9479 7.42572 21.8415 8.25127C22.735 9.07682 23.2906 10.0426 23.5082 11.1487C23.1974 11.0205 22.871 10.9403 22.5291 10.9083C22.1873 10.8762 21.8376 10.8602 21.4802 10.8602C20.1593 10.8602 18.9472 11.4092 17.8438 12.5073C16.7405 13.6053 16.1888 14.9799 16.1888 16.631C16.1888 17.4005 16.352 18.182 16.6783 18.9755C17.0047 19.769 17.4631 20.4142 18.0536 20.9111C17.7584 21.1836 17.3737 21.5323 16.8998 21.9571C16.4258 22.3819 16.0179 22.7546 15.676 23.0752L14.9301 23.7725C14.7591 23.9328 14.5532 24.0129 14.3124 24.0129C14.0715 24.0129 13.8656 23.9328 13.6946 23.7725Z"
                    fill="#EB2701"
                  />
                </svg>
              }
            />
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Potential Target Organisations"
              // value={"16,500+"}
              value={formatNumberStandard(
                Math.round(
                  securityNetworkFindings.avgPotentialTargetOrganizations || 0
                )
              )}
              className={styles["white-metric"]}
              icon={
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15.2805" r="15" fill="white" />
                  <path
                    d="M23 13.1558C23 12.4094 22.4 11.7987 21.6667 11.7987H14.76L15.64 7.48294L15.6667 7.1708C15.6667 6.75008 15.4933 6.37008 15.2267 6.08508L14.1733 5.01294L7.58667 11.7172C7.22667 12.0837 7 12.5994 7 13.1558V21.9772C7 23.1037 7.89333 24.0129 9 24.0129H18C18.8267 24.0129 19.5333 23.4972 19.84 22.7779L22.8533 15.5987C22.9467 15.3679 23 15.1101 23 14.8522V13.1558Z"
                    fill="#EB2701"
                  />
                </svg>
              }
            />
          </div>
          <div className="flex flex-row gap-4 w-full">
            <div className="flex flex-row gap-[10px] flex-[.8]">
              <GraphWrapper
                graphComonent={
                  <Chart
                    chartType="PieChart"
                    data={securityService}
                    // data={[
                    //   ["Service", "Growth"],
                    //   ["Cybersecurity", 28],
                    //   ["Cloud Security", 15],
                    //   ["Network Security", 10],
                    //   ["Incident Response", 5],
                    //   ["Management (IAM)", 42],
                    // ]}
                    options={{
                      is3D: true,
                      pieStartAngle: 0,
                      chartArea: {
                        top: 30,
                        width: "100%",
                        height: "91%",
                      },
                      legend: {
                        position: "top",
                        textStyle: {
                          color: "#222",
                          fontSize: 11,
                        },
                      },
                      colors: [
                        "#CE2605",
                        "#FF6143",
                        "#FF7A60",
                        "#FFA998",
                        "#EB2701",
                        "#F93A15",
                      ],
                    }}
                    width={"100%"}
                    height={"100%"}
                  />
                }
                containerClassName={classNames(
                  styles["graph-container"],
                  "flex-1"
                )}
                titleClassName={styles["sub-heading"]}
                title="Investment Allocation in Security Services"
              />

              <GraphWrapper
                graphComonent={
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="100%"
                    data={securityChallenges}
                    options={barChartOptions}
                  />
                }
                containerClassName={classNames(
                  styles["graph-container"],
                  "flex-1"
                )}
                titleClassName={styles["sub-heading"]}
                title="Top Security Challenges for Enterprises"
              />
            </div>
            <div className="flex flex-col gap-[10px] flex-[.2]">
              <div className="flex flex-col flex-1 gap-[10px] p-[20px] bg-[#FFF6EB] rounded-[10px] border-[1px] border-[#EB8D01]">
                <TextElement className="text-[#EB2701] font-inter font-semibold text-[20px] leading-[28px]">
                  {" "}
                  Top Industries
                </TextElement>
                <TextElement className="text-black font-inter font-bold text-[24px] leading-[32px]">
                  {/* {securityNetworkInsights?.industryName || ""} */}
                  {(securityNetworkInsights &&
                    securityNetworkInsights[0]?.industryName) ||
                    " "}
                </TextElement>
              </div>
              <div className="flex flex-col flex-1 gap-[10px] p-[20px] bg-[#FFF6EB] rounded-[10px] border-[1px] border-[#EB8D01]">
                <TextElement className="text-[#EB2701] font-inter font-semibold text-[20px] leading-[28px]">
                  {" "}
                  Key Insights
                </TextElement>
                <TextElement>{securityNetwork}</TextElement>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Security & Network */}

      {/* IOT */}
      {(selectServices === "IOT with GIS" || !selectServices) && (
        <div className={styles["channel"]}>
          <TextElement className={styles["channel-title"]}>
            IoT with GIS
          </TextElement>
          <div className={classNames(styles["findings"], "py-2")}>
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Total Addressable Market Size (TAM)"
              // value={"$32B"}
              value={`$${formatNumberStandard(
                roundOff(iotGisFindings.avgPotentialTargetOrganizations || 0, 2)
              )}`}
              className={styles["white-metric"]}
              icon={
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.0001 7.28062C10.1566 7.28062 11.9032 5.8626 11.9032 4.11187C11.9032 2.36113 10.1566 0.943115 8.0001 0.943115C5.84362 0.943115 4.09697 2.36113 4.09697 4.11187C4.09697 5.8626 5.84362 7.28062 8.0001 7.28062ZM8.0001 8.86499C5.39476 8.86499 0.193848 9.92652 0.193848 12.0337V13.6181H15.8063V12.0337C15.8063 9.92652 10.6054 8.86499 8.0001 8.86499Z"
                    fill="#EB2701"
                  />
                </svg>
              }
            />
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Compound Annual Growth Rate (CAGR)"
              // value={"12.0%"}
              value={`${roundOff(
                iotGisFindings.avgCompoundAnnualGrowthRate || 0,
                2
              )}%`}
              className={styles["white-metric"]}
              icon={
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15.2805" r="15" fill="white" />
                  <path
                    d="M24.7436 14.2506C24.9145 14.4429 25 14.6714 25 14.9359C25 15.2004 24.9145 15.4288 24.7436 15.6211L21.4336 19.0115C21.2626 19.2039 21.0451 19.3001 20.7809 19.3001C20.5167 19.3001 20.2991 19.2039 20.1282 19.0115L18.7995 17.665C18.6131 17.4726 18.5198 17.2442 18.5198 16.9797C18.5198 16.7152 18.6131 16.4868 18.7995 16.2944C18.986 16.1181 19.2075 16.0299 19.4639 16.0299C19.7203 16.0299 19.9417 16.1181 20.1282 16.2944L20.7809 16.9677L23.4149 14.2506C23.6014 14.0742 23.8228 13.9861 24.0793 13.9861C24.3357 13.9861 24.5571 14.0742 24.7436 14.2506ZM13.6946 23.7725L11.3636 21.5844C10.2448 20.5424 9.28516 19.6127 8.48485 18.7951C7.68454 17.9776 7.02409 17.2081 6.5035 16.4868C5.98291 15.7654 5.60218 15.0681 5.36131 14.3948C5.12044 13.7216 5 13.0162 5 12.2789C5 10.772 5.48951 9.51765 6.46853 8.51577C7.44755 7.51388 8.66744 7.01294 10.1282 7.01294C10.9363 7.01294 11.7055 7.18526 12.4359 7.52991C13.1663 7.87456 13.7956 8.36749 14.324 9.0087C14.8524 8.36749 15.4817 7.87456 16.2121 7.52991C16.9425 7.18526 17.7117 7.01294 18.5198 7.01294C19.8407 7.01294 20.9479 7.42572 21.8415 8.25127C22.735 9.07682 23.2906 10.0426 23.5082 11.1487C23.1974 11.0205 22.871 10.9403 22.5291 10.9083C22.1873 10.8762 21.8376 10.8602 21.4802 10.8602C20.1593 10.8602 18.9472 11.4092 17.8438 12.5073C16.7405 13.6053 16.1888 14.9799 16.1888 16.631C16.1888 17.4005 16.352 18.182 16.6783 18.9755C17.0047 19.769 17.4631 20.4142 18.0536 20.9111C17.7584 21.1836 17.3737 21.5323 16.8998 21.9571C16.4258 22.3819 16.0179 22.7546 15.676 23.0752L14.9301 23.7725C14.7591 23.9328 14.5532 24.0129 14.3124 24.0129C14.0715 24.0129 13.8656 23.9328 13.6946 23.7725Z"
                    fill="#EB2701"
                  />
                </svg>
              }
            />
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Potential Target Organisations"
              // value={"24,500+"}
              value={formatNumberStandard(
                Math.round(iotGisFindings.avgPotentialTargetOrganizations || 0)
              )}
              className={styles["white-metric"]}
              icon={
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15.2805" r="15" fill="white" />
                  <path
                    d="M23 13.1558C23 12.4094 22.4 11.7987 21.6667 11.7987H14.76L15.64 7.48294L15.6667 7.1708C15.6667 6.75008 15.4933 6.37008 15.2267 6.08508L14.1733 5.01294L7.58667 11.7172C7.22667 12.0837 7 12.5994 7 13.1558V21.9772C7 23.1037 7.89333 24.0129 9 24.0129H18C18.8267 24.0129 19.5333 23.4972 19.84 22.7779L22.8533 15.5987C22.9467 15.3679 23 15.1101 23 14.8522V13.1558Z"
                    fill="#EB2701"
                  />
                </svg>
              }
            />
          </div>
          <div className="flex flex-row gap-[10px] w-full">
            <div className="flex flex-row gap-[10px] flex-[.8]">
              <GraphWrapper
                graphComonent={
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="100%"
                    data={verticalDemandsService || []}
                    options={barChartOptions}
                  />
                }
                containerClassName={classNames(
                  styles["graph-container"],
                  "flex-1"
                )}
                titleClassName={styles["sub-heading"]}
                title="Verticals Leading Demand"
              />

              <GraphWrapper
                graphComonent={
                  <Chart
                    chartType="BarChart"
                    data={iotGisUseCase}
                    options={{
                      ...barChartOptions,
                      hAxis: {
                        format: "short",
                      }
                    }}
                    width={"100%"}
                    height={"100%"}
                  />
                }
                containerClassName={classNames(
                  styles["graph-container"],
                  "flex-1"
                )}
                titleClassName={styles["sub-heading"]}
                title="IoT Use Case Segmentation (by Spending)"
              />
            </div>
            <div className="flex flex-col gap-[10px] flex-[.2]">
              <div className="flex flex-col flex-1 gap-[10px] p-[20px] bg-[#FFF6EB] rounded-[10px] border-[1px] border-[#EB8D01]">
                <TextElement className="text-[#EB2701] font-inter font-semibold text-[20px] leading-[28px]">
                  {" "}
                  Top Industries
                </TextElement>
                <TextElement className="text-black font-inter font-bold text-[24px] leading-[32px]">
                  {/* {iotGisInsights?.industryName} */}
                  {(iotGisInsights && iotGisInsights[0]?.industryName) || " "}
                </TextElement>
              </div>
              <div className="flex flex-col flex-1 gap-[10px] p-[20px] bg-[#FFF6EB] rounded-[10px] border-[1px] border-[#EB8D01]">
                <TextElement className="text-[#EB2701] font-inter font-semibold text-[20px] leading-[28px]">
                  {" "}
                  Key Insights
                </TextElement>
                <TextElement>{iotGis}</TextElement>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <TextElement className={classNames(styles["sub-heading"], "py-2")}>
        Customer Needs Breakdown (Insights Across Services)
      </TextElement>

      <div className="flex gap-[10px]">
        <div className="flex flex-col gap-[20px] rounded-[20px] bg-white shadow-[5px_5px_10px_0px_rgba(1,25,60,0.05)] p-[30px]">
          <div className="flex flex-row gap-[10px] items-top">
            <svg
              width="28"
              height="26"
              viewBox="0 0 28 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2 22.5025L7.7 14.8636L0 11.3914L7.7 7.91916L11.2 0.280273L14.7 7.91916L22.4 11.3914L14.7 14.8636L11.2 22.5025ZM22.4 25.2803L20.65 21.4608L16.8 19.7247L20.65 17.9886L22.4 14.1692L24.15 17.9886L28 19.7247L24.15 21.4608L22.4 25.2803Z"
                fill="#EB2701"
              />
            </svg>
            <TextElement className="text-[#02285E] font-inter font-bold text-[20px] leading-[28px]">
              Cloud Adoption Growth
            </TextElement>
          </div>
          <TextElement>
            82% cloud penetration due to hybrid environments and demand for
            operational agility by medium-to-large firms.
          </TextElement>
        </div>
        <div className="flex flex-col gap-[20px] rounded-[20px] bg-white shadow-[5px_5px_10px_0px_rgba(1,25,60,0.05)] p-[30px]">
          <div className="flex flex-row gap-[10px] items-top">
            <svg
              width="28"
              height="26"
              viewBox="0 0 28 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2 22.5025L7.7 14.8636L0 11.3914L7.7 7.91916L11.2 0.280273L14.7 7.91916L22.4 11.3914L14.7 14.8636L11.2 22.5025ZM22.4 25.2803L20.65 21.4608L16.8 19.7247L20.65 17.9886L22.4 14.1692L24.15 17.9886L28 19.7247L24.15 21.4608L22.4 25.2803Z"
                fill="#EB2701"
              />
            </svg>
            <TextElement className="text-[#02285E] font-inter font-bold text-[20px] leading-[28px]">
              Integrated Data Solutions
            </TextElement>
          </div>
          <TextElement>
            Organisations seek connected platforms to enhance ROI on existing
            tech stacks.
          </TextElement>
        </div>
        <div className="flex flex-col gap-[20px] rounded-[20px] bg-white shadow-[5px_5px_10px_0px_rgba(1,25,60,0.05)] p-[30px]">
          <div className="flex flex-row gap-[10px] items-top">
            <svg
              width="28"
              height="26"
              viewBox="0 0 28 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2 22.5025L7.7 14.8636L0 11.3914L7.7 7.91916L11.2 0.280273L14.7 7.91916L22.4 11.3914L14.7 14.8636L11.2 22.5025ZM22.4 25.2803L20.65 21.4608L16.8 19.7247L20.65 17.9886L22.4 14.1692L24.15 17.9886L28 19.7247L24.15 21.4608L22.4 25.2803Z"
                fill="#EB2701"
              />
            </svg>
            <TextElement className="text-[#02285E] font-inter font-bold text-[20px] leading-[28px]">
              Zero-Trust Compliance
            </TextElement>
          </div>
          <TextElement>
            Security budget increases due to regulatory environments in finance
            and healthcare (GDPR, PCI DSS compliance).
          </TextElement>
        </div>
        <div className="flex flex-col gap-[20px] rounded-[20px] bg-white shadow-[5px_5px_10px_0px_rgba(1,25,60,0.05)] p-[30px]">
          <div className="flex flex-row gap-[10px] items-top">
            <svg
              width="28"
              height="26"
              viewBox="0 0 28 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2 22.5025L7.7 14.8636L0 11.3914L7.7 7.91916L11.2 0.280273L14.7 7.91916L22.4 11.3914L14.7 14.8636L11.2 22.5025ZM22.4 25.2803L20.65 21.4608L16.8 19.7247L20.65 17.9886L22.4 14.1692L24.15 17.9886L28 19.7247L24.15 21.4608L22.4 25.2803Z"
                fill="#EB2701"
              />
            </svg>
            <TextElement className="text-[#02285E] font-inter font-bold text-[20px] leading-[28px]">
              Edge Computing for IoT
            </TextElement>
          </div>
          <TextElement>
            Adoption of edge devices for real-time processing in utility and
            agriculture sectors accelerates GIS-based applications.
          </TextElement>
        </div>
      </div> */}
    </div>
  );
};

export default AcquisitionDashboardReport;
