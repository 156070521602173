import React, { useEffect, useState } from "react";
import styles from "./MyBranding.module.scss";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { Button, TextElement } from "../../componenets";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import { UserRoleType } from "../../redux/authSlice";
import CMOViewLayout from "../../componenets/CMOViewLayout/CMOViewLayout";
import ApproverViewLayout from "../../componenets/ApproverViewLayout/ApproverViewLayout";
import ColorPicker from "../../componenets/ColorPicker/ColorPicker";
import BrandAssets from "../../componenets/BrandAssests/BrandAssests";
import { useMyBranding } from "./useMyBranding";

enum BrandingDropdownIds {
  IDENTITY = "identity",
  STYLE_GUIDE = "style-guide",
  ASSETS = "assets",
}
interface Props {
  roleFor: UserRoleType;
}

const MyBranding: React.FC<Props> = (props: any) => {
  const { roleFor } = props;
  const [isOpen, setIsOpen] = useState([false, false, false]);
  const navigate = useNavigate();
  const [basePath, setBasePath] = useState("");

  const { company, handleChangeCompanyFormData, handleSubmitCompanyData } =
    useMyBranding();

  useEffect(() => {
    if (roleFor === UserRoleType.MARKETING)
      setBasePath(ROUTE_DEFINITIONS.MY_BRANDING.path);
    else if (roleFor === UserRoleType.CMO)
      setBasePath(ROUTE_DEFINITIONS.CMO_BRANDING.path);
    else if (
      roleFor === UserRoleType.PRIMARY_APPROVER ||
      roleFor === UserRoleType.SECONDARY_APPROVER
    )
      setBasePath(ROUTE_DEFINITIONS.APPROVER_BRANDING.path);
  }, [roleFor]);

  useEffect(() => {
    const activeSection =
      new URLSearchParams(window.location.search).get("section") ||
      BrandingDropdownIds.IDENTITY;

    switch (activeSection) {
      case BrandingDropdownIds.IDENTITY:
        setIsOpen([true, false, false]);
        break;
      case BrandingDropdownIds.STYLE_GUIDE:
        setIsOpen([false, true, false]);
        break;
      case BrandingDropdownIds.ASSETS:
        setIsOpen([false, false, true]);
        break;
      default:
        setIsOpen([false, false, false]);
    }
  }, [window.location.search]);

  const toggleAccordion = (index: any) => {
    const newIsOpen = [...isOpen];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpen(newIsOpen);
    if (index === 0)
      navigate(`${basePath}?section=${BrandingDropdownIds.IDENTITY}`);
    if (index === 1)
      navigate(`${basePath}?section=${BrandingDropdownIds.STYLE_GUIDE}`);
    if (index === 2)
      navigate(`${basePath}?section=${BrandingDropdownIds.ASSETS}`);
  };

  const renderFormInput = (input: {
    label: string;
    placeholder: string;
    rows?: number; // Optional rows for textarea
    cols?: number; // Optional cols for textarea
    value: string; // Value for input/textarea
    onChange?: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void; // Shared handler for both
    name: string;
    readOnly?: boolean; // Toggle between read-only and editable
    type: "input" | "textarea"; // New prop to determine the element
  }) => {
    const {
      label,
      placeholder,
      rows = 10,
      cols = 10,
      value,
      onChange,
      name,
      readOnly = false, // Default to false
      type = "input", // Default to input
    } = input;

    return (
      <div className={styles.formGroup}>
        <label className={styles.formLabel}>{label}</label>
        {readOnly ? (
          <div className={styles.readOnlyText}>
            {value || placeholder} {/* Show placeholder if no value */}
          </div>
        ) : type === "textarea" ? (
          <textarea
            className={styles.formInput}
            placeholder={placeholder}
            rows={rows}
            cols={cols}
            value={value} // Bind the value prop
            onChange={onChange} // Bind the onChange handler if provided
            draggable={false}
            name={name}
            onClick={(event) => event.stopPropagation()}
          />
        ) : (
          <input
            className={styles.formInput}
            placeholder={placeholder}
            value={value} // Bind the value prop
            onChange={onChange} // Bind the onChange handler if provided
            draggable={false}
            name={name}
            onClick={(event) => event.stopPropagation()}
          />
        )}
      </div>
    );
  };

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    if (file.size < 100000) {
      // checks if file is less than 100KB
      // Process the file here
    } else {
      // Handle error: file too large
      alert("File size should be less than 100KB.");
    }
  };

  const layoutViewBasedOnRole = (children: React.ReactElement) => {
    switch (roleFor) {
      case UserRoleType.MARKETING:
        return (
          <MarkerterViewLayout
            header={
              <TextElement className={styles["header-text"]}>
                Company Profile and Branding
              </TextElement>
            }
          >
            {children}
          </MarkerterViewLayout>
        );
      case UserRoleType.CMO:
        return (
          <CMOViewLayout
            header={
              <TextElement className={styles["header-text"]}>
                Company Profile and Branding
              </TextElement>
            }
          >
            {children}
          </CMOViewLayout>
        );
      case UserRoleType.PRIMARY_APPROVER || UserRoleType.SECONDARY_APPROVER:
        return (
          <ApproverViewLayout
            header={
              <TextElement className={styles["header-text"]}>
                Company Profile and Branding
              </TextElement>
            }
          >
            {children}
          </ApproverViewLayout>
        );
      default:
        return null;
    }
  };

  return layoutViewBasedOnRole(
    <div
      className={styles.content}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {["Company Profile", "Style Guide", "Brand Assets"].map(
        (title, index) => (
          <div
            key={index}
            className={styles.accordion}
            style={{
              height: isOpen[index] ? "auto" : "60px",
              overflow: "hidden",
            }}
          >
            <div
              className={styles.accordionHeader}
              onClick={() => toggleAccordion(index)}
            >
              <div className={styles.accordionTitle}>{title}</div>
              <div className={styles.accordionDownArrow}>
                {isOpen[index] ? (
                  <MdKeyboardArrowUp />
                ) : (
                  <MdKeyboardArrowDown />
                )}
              </div>
            </div>
            {isOpen[index] && (
              <div
                className={styles.accordionContent}
                //
              >
                {title === "Company Profile" && (
                  <form onSubmit={handleSubmitCompanyData}>
                    {renderFormInput({
                      label: "Company Name",
                      placeholder:
                        "A brief description of the brand's purpose or mission",
                      rows: 5,
                      cols: 10,
                      value: company?.name,
                      onChange: handleChangeCompanyFormData,
                      name: "name",
                      type: "input",
                    })}
                    {renderFormInput({
                      label: "Industry",
                      placeholder:
                        "Core values that define your brand's personality and guide its behavior",
                      rows: 3,
                      cols: 10,
                      value: company?.industry,
                      onChange: handleChangeCompanyFormData,
                      name: "industry",
                      type: "input",
                    })}
                    {renderFormInput({
                      label: "Company Address",
                      placeholder:
                        "Adjectives that describe your brand's personality (e.g., adventurous, sophisticated, friendly).",
                      rows: 1,
                      cols: 10,
                      value: company?.address,
                      onChange: handleChangeCompanyFormData,
                      name: "address",
                      type: "textarea",
                    })}
                    {renderFormInput({
                      label: "Website",
                      placeholder:
                        "Tone and style of communication (e.g., formal, informal, playful)",

                      value: company?.website,
                      onChange: handleChangeCompanyFormData,
                      name: "website",
                      type: "input",
                    })}
                    {renderFormInput({
                      label: "About The Company",
                      placeholder:
                        "Tone and style of communication (e.g., formal, informal, playful)",
                      rows: 4,
                      cols: 10,
                      value: company?.aboutCompany,
                      onChange: handleChangeCompanyFormData,
                      name: "aboutCompany",
                      type: "textarea",
                    })}
                    {renderFormInput({
                      label: "Mission",
                      placeholder:
                        "Tone and style of communication (e.g., formal, informal, playful)",
                      rows: 2,
                      cols: 10,
                      value: company?.mission,
                      onChange: handleChangeCompanyFormData,
                      name: "mission",
                      type: "textarea",
                    })}
                    {renderFormInput({
                      label: "Vision",
                      placeholder:
                        "Tone and style of communication (e.g., formal, informal, playful)",
                      rows: 2,
                      cols: 10,
                      value: company?.vision,
                      onChange: handleChangeCompanyFormData,
                      name: "vision",
                      type: "textarea",
                    })}
                    {renderFormInput({
                      label: "Services",
                      placeholder:
                        "Tone and style of communication (e.g., formal, informal, playful)",
                      rows: 2,
                      cols: 10,
                      value: company.services,
                      onChange: handleChangeCompanyFormData,
                      name: "services",
                      type: "textarea",
                    })}
                    <div
                      className={styles.formSubmitButton}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Button type="submit">Save</Button>
                    </div>
                  </form>
                )}
                {title === "Style Guide" && (
                  <div
                    className={styles.styleGuideWrapper}
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <span className={styles.styleGuideLogoTitle}>Logo</span>
                      <div className={styles.styleGuideImageSvgSection}>
                        <div className={styles.styleGuideImageSvg}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="36"
                            viewBox="0 0 44 36"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.666672 4.99984C0.666672 2.6066 2.60677 0.666504 5 0.666504H39C41.3932 0.666504 43.3333 2.6066 43.3333 4.99984V31.6665C43.3333 34.0597 41.3932 35.9998 39 35.9998H5.00001C2.60677 35.9998 0.666672 34.0597 0.666672 31.6665V4.99984ZM5 2.6665C3.71134 2.6665 2.66667 3.71117 2.66667 4.99984V31.6665C2.66667 32.9552 3.71134 33.9998 5.00001 33.9998H39C40.2887 33.9998 41.3333 32.9552 41.3333 31.6665V4.99984C41.3333 3.71117 40.2887 2.6665 39 2.6665H5Z"
                              fill="#778BA8"
                            />
                            <ellipse
                              cx="10.6667"
                              cy="9.99984"
                              rx="4.33333"
                              ry="4.33333"
                              fill="#778BA8"
                            />
                            <path
                              d="M6.33334 28.333V26.3567C6.33334 25.9147 6.50894 25.4907 6.8215 25.1782L12.2096 19.7901C12.8387 19.161 13.851 19.1369 14.5092 19.7353L15.8406 20.9456C16.4922 21.538 17.4921 21.5212 18.1235 20.9074L27.8217 11.4786C28.4751 10.8433 29.5176 10.8506 30.162 11.495L37.8452 19.1782C38.1578 19.4907 38.3333 19.9147 38.3333 20.3567V28.333C38.3333 29.2535 37.5872 29.9997 36.6667 29.9997H8.00001C7.07954 29.9997 6.33334 29.2535 6.33334 28.333Z"
                              fill="#778BA8"
                            />
                          </svg>
                        </div>
                        <div className={styles.styleGuideImageContent}>
                          <p className={styles["directive-text"]}>
                            Please upload square image, size less than 100KB
                          </p>
                          <div className={styles["file-uploader-container"]}>
                            <input
                              type="file"
                              accept="image/*"
                              id="fileInput"
                              className={styles.styleGuideFileInput}
                              onChange={handleFileUpload}
                              onClick={(event) => event.stopPropagation()}
                            />
                            <label
                              htmlFor="fileInput"
                              className={styles.styleGuideFileInputLabel}
                              onClick={(event) => event.stopPropagation()}
                            >
                              Choose File
                            </label>
                            <p
                              className={styles.styleGuideChooseFile}
                              onClick={(event) => event.stopPropagation()}
                            >
                              No File Chosen
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles["branding-colors"]}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <TextElement className={styles["heading"]}>
                        Branding Colours
                      </TextElement>
                      <div
                        className={styles["color-box-container"]}
                        onClick={(event) => event.stopPropagation()}
                      >
                        <div
                          className={styles["color-box"]}
                          onClick={(event) => event.stopPropagation()}
                          style={{
                            backgroundColor: "red",
                          }}
                        ></div>
                        <div
                          className={styles["color-box"]}
                          onClick={(event) => event.stopPropagation()}
                          style={{
                            backgroundColor: "blue",
                          }}
                        ></div>
                        <div
                          className={styles["color-box"]}
                          onClick={(event) => event.stopPropagation()}
                          style={{
                            backgroundColor: "green",
                          }}
                        ></div>
                        <ColorPicker
                          value="#FF0000"
                          onChange={(color) => console.log(color)}
                        />
                      </div>
                      <Button className={styles["color-action-btn"]}>
                        Save
                      </Button>
                    </div>
                  </div>
                )}
                {title === "Brand Assets" && (
                  <div>
                    <BrandAssets />
                  </div>
                )}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default MyBranding;
