import React, { useState } from "react";
import styles from "./AddTriggerModal.module.scss";
import Modal from "../../../Modal/Modal";
import Button from "../../../Button/Button";
import TextElement from "../../../TextElement/TextElement";
import { TriggerData } from "../../types";

interface AddTriggerModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: TriggerData) => void;
}

const AddTriggerModal: React.FC<AddTriggerModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const handleOk = () => {};
  const [data, setData] = useState<TriggerData>({
    title: "",
  });

  return (
    <Modal
      isOpen={open}
      closeModal={onClose}
      className={styles["container"]}
      contentClassName={styles["content"]}
    >
      <TextElement className={styles["title"]}>Trigger</TextElement>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(data);
        }}
      >
        <TextElement className={styles["label"]}>Label</TextElement>
        <input
          className={styles["textarea"]}
          onChange={(e) => {
            setData({
              ...data,
              title: e.target.value,
            });
          }}
        />

        <div className={styles["modal-footer"]}>
          <Button type={"submit"} onClick={handleOk}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddTriggerModal;
