import { useState, useEffect } from "react";
import HttpService from "../../lib/api";
import { Opportunity } from "./types";
import { OpportunityDetails } from "./components/DetailCards/DetailCards";

export type OpportunityStage = "Consideration" | "Evaluation" | "Decision";

interface Props {
  stage?: OpportunityStage;
}

const useRecommendedActionsForSale = ({ stage }: Props) => {
  const httpService = new HttpService();

  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);

  const [metrics, setMetrics] = useState<any>({
    totalOpportunities: 120,
    estimatedPotentialRevenue: 350000,
    averageDaysInStage: 23,
    averageEstimatedRevenuePerOpportunity: 14000,
    winRateProjection: "10%",
    opportunitiesFlaggedForInactivity: "20%",

    estimatedPotentialRevenueConsideration: 35198,
    daysSinceLastActivity: 23,
    timeInPipeline: 0,
    engagementFrequency: 3,
    winRateProjectionConsideration: "0",
    closureReadiness: "50%",
  });

  console.log("Metrics>>>>>>", metrics);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedOpportunity, setSelectedOpportunity] =
    useState<Opportunity | null>(null);

  const [confidenceScore, setConfidenceScore] = useState<number>(0);
  const [stageDetails, setStageDetails] = useState<OpportunityDetails>();

  // const metrics = {
  //   totalOpportunities: 120,
  //   estimatedPotentialRevenue: 350000,
  //   averageDaysInStage: 23,
  //   averageEstimatedRevenuePerOpportunity: 14000,
  //   winRateProjection: "10%",
  //   opportunitiesFlaggedForInactivity: "20%",
  //   estimatedPotentialRevenueConsideration: 35198,
  //   daysSinceLastActivity: 23,
  //   timeInPipeline: 35,
  //   engagementFrequency: 3,
  //   winRateProjectionConsideration: "10%",
  //   closureReadiness: "50%",
  // };

  const executeRawQuery = async <T = any,>(query: string): Promise<T> => {
    try {
      const response = await httpService.post<T, { query: string }>(
        "/api/execute-raw-sql/",
        { query }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to execute raw query", error);
      return [] as T;
    }
  };

  const fetchConfidance = async (val: any) => {
    setLoading(true);
    setError(null);
    try {
      const response = await executeRawQuery<Opportunity[]>(
        `SELECT 
      opportunity_stage, 
      AVG(confidence_score) AS average_confidence_score
  FROM 
      opportunities
  WHERE 
      opportunity_stage = '${val}'
  GROUP BY 
      opportunity_stage;`
      );

      setConfidenceScore(
        Math.round(Number(response[0]?.averageConfidenceScore) || 0)
      );
    } catch (err: any) {
      console.log("Error>>>>", err);
      setError(err.response?.data?.message || "Failed to fetch opportunities");
    } finally {
      setLoading(false);
    }
  };

  const fetchOpportunitiesByStage = async (stage: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await executeRawQuery<Opportunity[]>(
        `SELECT * FROM opportunities WHERE opportunity_stage = '${stage}'`
      );
      console.log("Opportunities>>>>>>", response);
      setOpportunities(response);
    } catch (err: any) {
    } finally {
      setLoading(false);
    }
  };

  const fetchOppotunityDetails = async (
    opportunityId: string | null | undefined
  ) => {
    setLoading(true);
    setError(null);

    try {
      let detailPoints = "";
      let defaultValues: OpportunityDetails = {};

      // Determine the detail points and default values based on the stage
      if (stage === "Consideration") {
        detailPoints =
          "call_sentiment_analysis, external_factors, identified_pain_points, associated_activities";
        defaultValues = {
          callSentimentAnalysis:
            "Follow-ups have generally positive sentiment, indicating the client is open to discussions. However, escalations during meetings suggest underlying dissatisfaction. Building trust is crucial to keep momentum.",
          externalFactors:
            "Competitors appear to be influencing decision-making heavily. External industry shifts might also be at play, requiring proactive updates on product adaptability.",
          identifiedPainPoints:
            "The client has expressed concerns around implementation timelines and ongoing support during escalated discussions. These need to be addressed clearly in future interactions.",
          associatedActivities:
            "A mix of follow-ups, demos, and meetings shows engagement, but the demo escalation highlights a need for better tailoring to the client’s needs. Stronger preparation for demos is essential.",
        };
      } else if (stage === "Evaluation") {
        detailPoints =
          "proposal_quality, competitive_positioning, pricing_strategy";
        defaultValues = {
          proposalQuality:
            "Initial proposals lacked detail on how the solution addresses the client’s specific pain points. Future proposals should include tailored metrics and a roadmap to showcase benefits clearly.",
          competitivePositioning:
            "Competitors are being viewed favourably by the client. A strong focus on differentiators, such as superior post-implementation support or unique product features, is necessary.",
          pricingStrategy:
            "The client’s expected deal size suggests they might be price sensitive. Offering discounts or additional services as part of the package could improve appeal without reducing perceived value.",
        };
      } else if (stage === "Decision") {
        detailPoints = "slippage_risk, closure_readiness, deal_confidence";
        defaultValues = {
          slippageRisk:
            "Confidence in this deal is moderate. While positive responses from calls indicate a chance to win, unresolved escalations and competitor activity lower the probability of success.",
          closureReadiness:
            "Slippage risk is high, given that unresolved concerns could delay or derail closure. A proactive plan to tackle objections quickly will help mitigate this risk.",
          dealConfidence:
            "The deal is progressing toward closure, but readiness remains contingent on resolving escalations and demonstrating value over competitors. The timeline is at risk without swift action.",
        };
      }

      if (opportunityId) {
        // Fetch details from the database if opportunityId is provided
        let query = `SELECT ${detailPoints} FROM opportunities WHERE opportunity_id = '${opportunityId}' and opportunity_stage = '${stage}'`;
        const response = await executeRawQuery<OpportunityDetails[]>(query);

        // If response is valid, set the details
        if (response && response.length > 0) {
          setStageDetails(response[0]);
        } else {
          setStageDetails(defaultValues);
        }
      } else {
        // If opportunityId is falsy, set default values
        setStageDetails(defaultValues);
      }
    } catch (err: any) {
      setError(
        err.response?.data?.message || "Failed to fetch opportunity details"
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchMetrics = async (stage: string) => {
    setLoading(true);
    setError(null);

    try {
      const totalOpportunitiesresponse = await executeRawQuery<any>(
        `SELECT COUNT(*) AS total_opportunities
FROM opportunities
WHERE opportunity_stage = '${stage}';`
      );

      const estimatedPotentialRevenueres = await executeRawQuery<any>(
        `SELECT SUM(estimated_revenue) AS total_estimated_revenue
FROM opportunities
WHERE opportunity_stage =  '${stage}';`
      );

      const averageDaysInStageRes = await executeRawQuery<any>(
        `SELECT AVG(days_since_last_activity) AS average_days_in_consideration_stage
FROM opportunities
WHERE opportunity_stage = '${stage}';`
      );

      const averageEstimatedRevenuePerOpportunityRes =
        await executeRawQuery<any>(
          `SELECT AVG(estimated_revenue) AS average_estimated_revenue_in_consideration
FROM opportunities
WHERE opportunity_stage = '${stage}';`
        );

      const winRateProjectionRes = await executeRawQuery<any>(
        //         `SELECT
        //     COUNT(CASE WHEN win_loss_status = 'Closed Won' THEN 1 END) * 100.0 / COUNT(*) AS win_rate_percentage
        // FROM opportunities
        // WHERE opportunity_stage ='${stage}';`

        `SELECT 
    COUNT(CASE WHEN opportunity_stage = 'Closed Won' THEN 1 END) * 100.0 / NULLIF(COUNT(*), 0) AS win_rate_percentage,
    COUNT(*) AS total_opportunities,
    COUNT(CASE WHEN opportunity_stage = 'Closed Won' THEN 1 END) AS won_opportunities
FROM 
    opportunities
WHERE 
    opportunity_stage IN ('${stage}', 'Closed Won', 'Closed Lost');`
      );

      const opportunitiesFlaggedForInactivityRes = await executeRawQuery<any>(
        `SELECT 
    COUNT(CASE WHEN days_since_last_activity > 30 THEN 1 END) AS flagged_count,
    COUNT(*) AS total_count,
    COUNT(CASE WHEN days_since_last_activity > 30 THEN 1 END) * 100.0 / COUNT(*) AS percent_flagged_for_inactivity
FROM opportunities
WHERE opportunity_stage = '${stage}';`
      );

      console.log("winRateProjectionRes: 1", winRateProjectionRes);

      setMetrics((prev: any) => ({
        ...prev,
        totalOpportunities: Math.round(
          totalOpportunitiesresponse[0].totalOpportunities
        ),
        estimatedPotentialRevenue: Math.round(
          estimatedPotentialRevenueres[0].totalEstimatedRevenue
        ),
        averageDaysInStage: Math.round(
          averageDaysInStageRes[0].averageDaysInConsiderationStage
        ),
        averageEstimatedRevenuePerOpportunity: Math.round(
          averageEstimatedRevenuePerOpportunityRes[0]
            .averageEstimatedRevenueInConsideration
        ),
        winRateProjectionConsideration: Math.round(
          winRateProjectionRes[0].winRatePercentage
        ),
        opportunitiesFlaggedForInactivity: Math.round(
          opportunitiesFlaggedForInactivityRes[0].percentFlaggedForInactivity
        ),

        // estimatedPotentialRevenueConsideration: 35198,
        // daysSinceLastActivity: 23,
        // timeInPipeline: 35,
        // engagementFrequency: 3,
        // winRateProjectionConsideration: "10%",
        // closureReadiness: "50%",
      }));
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to fetch opportunities");
    } finally {
      setLoading(false);
    }
  };

  const fetchMetricsByID = async (stage: string) => {
    setLoading(true);
    setError(null);
    try {
      const totalOpportunitiesresponse = await executeRawQuery<any>(
        `SELECT SUM(estimated_revenue) AS total_estimated_revenue
FROM opportunities
WHERE opportunity_stage = '${stage}'
AND account_id = '${selectedOpportunity?.opportunityId}';`
      );

      const estimatedPotentialRevenueres = await executeRawQuery<any>(
        `SELECT 
    account_id,
    EXTRACT(day FROM NOW() - last_activity_date) AS days_since_last_activity FROM opportunities WHERE 
    opportunity_stage = '${stage}' 
    AND account_id = '${selectedOpportunity?.opportunityId}';`
      );

      const averageDaysInStageRes = await executeRawQuery<any>(
        `SELECT 
    account_id,
    opportunity_id,
    opportunity_name,
    created_date,
    last_activity_date,
    ROUND(EXTRACT(EPOCH FROM NOW() - created_date) / 86400, 0) AS days_in_pipeline
FROM 
    opportunities
WHERE 
    opportunity_id = '${selectedOpportunity?.opportunityId}' 
    AND opportunity_stage = '${stage}' ;`
      );

      const averageEstimatedRevenuePerOpportunityRes =
        await executeRawQuery<any>(
          ` SELECT 
    account_id,
    COUNT(*) AS total_activities_logged
FROM 
    opportunities
WHERE 
    opportunity_id  = '${selectedOpportunity?.opportunityId}'  AND opportunity_stage = '${stage}'
GROUP BY 
    account_id;`
        );

      //       const winRateProjectionRes = await executeRawQuery<any>(
      //         `SELECT
      //     account_id,
      //     COUNT(CASE WHEN win_loss_status = 'Closed Won' THEN 1 ELSE NULL END) AS wins,
      //     COUNT(*) AS total_opportunities,
      //     CASE
      //         WHEN COUNT(*) > 0 THEN (COUNT(CASE WHEN win_loss_status = 'Closed Won' THEN 1 ELSE NULL END) / COUNT(*)) * 100
      //         ELSE 0
      //     END AS win_rate_projection
      // FROM
      //     opportunities
      // WHERE
      //     opportunity_id  = '${selectedOpportunity?.opportunityId}'
      //     AND opportunity_stage = '${stage}'
      // GROUP BY
      //     account_id;`
      //       );

      const winRateProjectionRes = await executeRawQuery<any>(
        `SELECT 
    COUNT(CASE WHEN opportunity_stage = 'Closed Won' THEN 1 END) * 100.0 / NULLIF(COUNT(*), 0) AS win_rate_percentage,
    COUNT(*) AS total_opportunities,
    COUNT(CASE WHEN opportunity_stage = 'Closed Won' THEN 1 END) AS won_opportunities
FROM 
    opportunities
WHERE 
    opportunity_stage IN ('${stage}', 'Closed Won', 'Closed Lost') 
    AND opportunity_id = '${selectedOpportunity?.opportunityId}'; `
      );

      const opportunitiesFlaggedForInactivityRes = await executeRawQuery<any>(
        `SELECT 
    COUNT(CASE WHEN days_since_last_activity > 30 THEN 1 END) AS flagged_count,
    COUNT(*) AS total_count,
    COUNT(CASE WHEN days_since_last_activity > 30 THEN 1 END) * 100.0 / COUNT(*) AS percent_flagged_for_inactivity
FROM opportunities
WHERE opportunity_stage = '${stage}';`
      );

      setMetrics((prevMetrics: any) => ({
        ...prevMetrics,
        estimatedPotentialRevenueConsideration: Math.round(
          totalOpportunitiesresponse[0]?.totalOpportunities
        ),
        daysSinceLastActivity: Math.round(
          estimatedPotentialRevenueres[0]?.totalEstimatedRevenue
        ),
        timeInPipeline: Math.round(averageDaysInStageRes[0]?.daysInPipeline),
        engagementFrequency: Math.round(
          averageEstimatedRevenuePerOpportunityRes[0]?.totalActivitiesLogged ||
            0
        ),
        winRateProjectionConsideration: Math.round(
          winRateProjectionRes[0]?.winRateProjection || 0
        ),
        closureReadiness: Math.round(
          opportunitiesFlaggedForInactivityRes[0]
            ?.percentFlaggedForInactivity || 0
        ),
      }));
      console.log("winRateProjectionRes: ", winRateProjectionRes);
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to fetch opportunities");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stage) {
      fetchOpportunitiesByStage(stage);
      fetchOppotunityDetails(selectedOpportunity?.opportunityId);
      if (stage === "Consideration") {
        fetchConfidance("Consideration");
      } else if (stage === "Evaluation") {
        fetchConfidance("Evaluation");
      } else if (stage === "Decision") {
        // setConfidenceScore(75);
        fetchConfidance("Decision");
      }
    }
  }, [stage]);

  useEffect(() => {
    if (selectedOpportunity) {
      fetchOppotunityDetails(selectedOpportunity.opportunityId);
    }
  }, [selectedOpportunity]);

  useEffect(() => {
    if (stage) {
      if (selectedOpportunity?.opportunityId) {
        fetchMetricsByID(stage);
      } else {
        fetchMetrics(stage);
      }
    }
  }, [stage, selectedOpportunity?.opportunityId]);

  return {
    opportunities,
    setSelectedOpportunity,
    selectedOpportunity,
    stageDetails,
    confidenceScore,
    metrics,
  };
};

export default useRecommendedActionsForSale;
