import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  KeyFindings,
  Product,
  Campaign,
  ScatterChartData,
  DateCapturedValues,
  salesForceFindings,
} from "./types";
import HttpService from "../../lib/api";
import { valid } from "chroma-js";
import { generateOptionalConditions } from "../../lib/queryHelpers";

const Month_Names = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const services = [
  { label: "Salesforce", value: "Salesforce" },
  { label: "Data Analytics", value: "Data Analytics" },
  { label: "Security and Networking", value: "Security and Networking" },
  { label: "IOT with GIS", value: "IOT with GIS" },
];

const useMarketingDashboardReport = (
  product: string,
  campaign: string,
  dateCaptured: DateCapturedValues
) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  const [industrySegmentList, setIndustrySegmentList] = useState<
    {
      industrySegmentId: number;
      industrySegmentName: string;
    }[]
  >([]);

  const [selectedIndustry, setSelectedIndustry] = useState<any>("");

  const [salesInsights, setSalesInsights] = useState<any>();
  const [dataAnalyticsInsights, setDataAnalyticsInsights] = useState<any>();

  const [selectServices, setSelectServices] = useState<any>("");

  const [dashboardData, setDashboardData] = useState<any>(null);

  const dateCapturedOptions: { label: string; value: DateCapturedValues }[] = [
    { label: "Date Captured", value: "" },
    { label: "Last Month", value: "lastMonth" },
    { label: "Last 2 Months", value: "last2Months" },
    { label: "Last 3 Months", value: "last3Months" },
    { label: "Last 4 Months", value: "last4Months" },
    { label: "Last 6 Months", value: "last6Months" },
  ];

  const [keyFindings, setKeyFindings] = useState<KeyFindings>({
    tam: 0,
    cagr: 0,
    targetOrganizations: 0,
    smbsContribution: 0,
    expectedInvestment: 0,
    cloudUptake: 0,

    averageEngagementScore: 0,
    averageEmailOpenRate: 0,
    averageEmailClickRate: 0,
    landingPageConversionRate: 0,
    totalEmailsOpened: 0,
    totalEmailsSent: 0,
    totalLandingPageVisits: 0,
    averageLandingPageBounceRate: 0,
    totalLinkedInClicks: 0,
    totalLinkedInImpressions: 0,
    totalContacts: 0,
    idleContactsPercentage: 0,
    totalMqls: 0,
    dormantMqlsPercentage: 0,
    leadDropOffRate: 0,
    likelyToCloseOpportunities: 0,
  });

  const [organisationSize, setOrganisationSize] = useState<
    (string | number)[][]
  >([]);
  const [dataAnalyticsUseCases, setDataAnalyticsUseCases] = useState<
    (string | number)[][]
  >([]);

  const [salesforcePG, setSalesforcePG] = useState<(string | number)[][]>([]);

  //
  const [salesforceDemand, setSalesforceDemand] = useState<
    (string | number)[][]
  >([]);
  const [dataAnalyticsYoyBudgetAllocation, setDataAnalyticsYoyBudgetAllocation] =
    useState<(string | number)[][]>([]);

  // Security Services
  const [securityService, setSecurityService] = useState<(string | number)[][]>(
    []
  );

  const [verticalDemandsService, setVerticalDemandsService] = useState<
    (string | number)[][]
  >([]);
  //

  const [dataAnalyticsPG, setDataAnalyticsPG] = useState<(string | number)[][]>(
    []
  );

  const [securityChallenges, setSecurityChallenges] = useState<
    (string | number)[][]
  >([]);

  const [technologyTrends, setTechnologyTrends] = useState<
    (string | number)[][]
  >([]);

  const [salesForceFinding, setsSalesForceFinding] =
    useState<salesForceFindings>({
      avgCompoundAnnualGrowthRate: 0,
      avgPotentialTargetOrganizations: 0,
      avgTotalAddressableMarketSize: 0,
      serviceName: "",
    });

  const [dataAnalyticsFindings, setsDataAnalyticsFindings] =
    useState<salesForceFindings>({
      avgCompoundAnnualGrowthRate: 0,
      avgPotentialTargetOrganizations: 0,
      avgTotalAddressableMarketSize: 0,
      serviceName: "",
    });

  const [securityNetworkFindings, setSecurityNetworkFindings] =
    useState<salesForceFindings>({
      avgCompoundAnnualGrowthRate: 0,
      avgPotentialTargetOrganizations: 0,
      avgTotalAddressableMarketSize: 0,
      serviceName: "",
    });

  const [securityNetworkPG, setSecurityNetworkPG] = useState<
    (string | number)[][]
  >([]);

  const [securityNetworkInsights, setSecurityNetworkInsights] = useState<any>();

  //
  const [iotGisFindings, setIotGisFindings] = useState<salesForceFindings>({
    avgCompoundAnnualGrowthRate: 0,
    avgPotentialTargetOrganizations: 0,
    avgTotalAddressableMarketSize: 0,
    serviceName: "",
  });

  const [iotGisPG, setIotGisPG] = useState<(string | number)[][]>([]);

  const [iotGisUseCase, setIotGisUseCase] = useState<(string | number)[][]>([]);

  const [iotGisInsights, setIotGisInsights] = useState<any>();
  //

  const [graphicImpact, setGraphicImpact] = useState<any>([]);

  const [costEngagementScatterData, setCostEngagementScatterData] = useState<
    (string | number)[][]
  >([]);

  const [contactsTrendData, setContactsTrendData] = useState<
    (string | number)[][]
  >([]);

  const [performanceAcrossKeyMetrics, setPerformanceAcrossKeyMetrics] =
    useState<(string | number)[][]>([]);

  const [emailPerformanceData, setEmailPerformanceData] = useState<
    (string | number)[][]
  >([]);

  const [landingPagePerformanceData, setLandingPagePerformanceData] = useState<
    (string | number)[][]
  >([]);

  const [linkedInEngagementData, setLinkedInEngagementData] = useState<
    (string | number)[][]
  >([]);

  const httpService = new HttpService();

  const executeRawQuery = async <T = any>(query: string): Promise<T> => {
    try {
      const response = await httpService.post<
        T,
        {
          query: string;
        }
      >("/api/execute-raw-sql/", { query });
      return response.data;
    } catch (error) {
      console.error("Failed to execute raw query", error);
      toast.error("Failed to execute raw query");
      return [] as T;
    }
  };

  const fetchIndusrtySegment = async () => {
    const response = await executeRawQuery<
      { industrySegmentId: number; industrySegmentName: string }[]
    >(`
      SELECT 
      industry_segment_name ,industry_segment_id 
      FROM 
      industry_segment;
      `);

    const industrySegments = response.map((item: any) => ({
      industrySegmentId: item.industrySegmentName,
      industrySegmentName: item.industrySegmentName,
    }));

    setIndustrySegmentList(industrySegments);
  };

  useEffect(() => {
    fetchIndusrtySegment();
  }, []);

  const fetchDashBoardData = async (input: any) => {
    try {
      const httpService = new HttpService(
        process.env.REACT_APP_AQUISITION_API_URL
      );

      const response = await httpService.post(
        "https://dev-user-aquisition.eriko.ai/acquisition_dashboard/generate_dashboard",
        input
      );

      console.log("response: ", response);
      // Return the response data
      return response.data;
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      throw error; // Re-throw error for caller to handle
    }
  };

  // useEffect(() => {
  //   if (selectedIndustry) {
  //     const fetchData = async () => {
  //       try {
  //         const input = {
  //           input: {
  //             service_name: selectServices,
  //             industry_name: selectedIndustry,
  //           },
  //         };

  //         const data = await fetchDashBoardData(input);

  //         setDashboardData(data);
  //       } catch (error) {
  //         console.error("Failed to fetch dashboard data:", error);
  //       }
  //     };

  //     fetchData();
  //   }
  // }, [selectedIndustry]);

  // TODO: Remove (unused)
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsReponse = await executeRawQuery<Product[]>(
          "SELECT * FROM public.product;"
        );
        setProducts(productsReponse);
      } catch (error) {
        console.error("Error fetching products", error);
        toast.error("Error fetching products");
      }
    };

    const fetchCampaigns = async () => {
      try {
        let query = "SELECT * FROM public.campaign;";
        if (product) {
          query = `SELECT * FROM public.campaign WHERE product_id = ${product};`;
        }
        const campaignsResponse = await executeRawQuery<Campaign[]>(query);
        setCampaigns(campaignsResponse);
      } catch (error) {
        console.error("Error fetching campaigns", error);
        toast.error("Error fetching campaigns");
      }
    };

    // fetchProducts();
    // fetchCampaigns();
  }, [product, campaign]);

  // TODO: Remove (unused)
  useEffect(() => {
    const joinClause = `
        JOIN campaign c ON ca.campaign_id = c.campaign_id
        JOIN product p ON ca.product_id = p.product_id
    `;

    const generateWhereClause = (
      campaign?: string,
      product?: string
    ): string => {
      let whereClause = "";

      if (campaign && product) {
        whereClause = `WHERE c.campaign_id = '${campaign}' AND p.product_id = '${product}'`;
      } else if (campaign) {
        whereClause = `WHERE c.campaign_id = '${campaign}'`;
      } else if (product) {
        whereClause = `WHERE p.product_id = '${product}'`;
      }

      return whereClause;
    };

    const addDateCondition = (
      whereClause: string,
      dateCaptured?: string
    ): string => {
      let dateCondition = "";
      switch (dateCaptured) {
        case "lastMonth":
          dateCondition =
            "month >= date_trunc('month', now()) - interval '1 month' AND month < date_trunc('month', now())";
          break;
        case "last2Months":
          dateCondition =
            "month >= date_trunc('month', now()) - interval '2 months' AND month < date_trunc('month', now())";
          break;
        case "last3Months":
          dateCondition =
            "month >= date_trunc('month', now()) - interval '3 months' AND month < date_trunc('month', now())";
          break;
        case "last4Months":
          dateCondition =
            "month >= date_trunc('month', now()) - interval '4 months' AND month < date_trunc('month', now())";
          break;
        case "last6Months":
          dateCondition =
            "month >= date_trunc('month', now()) - interval '6 months' AND month < date_trunc('month', now())";
          break;
        default:
          dateCondition = "";
      }
      if (dateCondition) {
        whereClause += whereClause
          ? ` AND ${dateCondition}`
          : `WHERE ${dateCondition}`;
      }
      return whereClause;
    };

    // const fetchKeyFindings = async () => {
    //   try {
    //     // Start by applying the date condition
    //     let where = addDateCondition("", dateCaptured);

    //     // Add product filter if provided
    //     if (product) {
    //       // If where is not initialized yet, start with "WHERE"
    //       if (!where) {
    //         where = `WHERE product_id = ${product}`;
    //       } else {
    //         where += ` AND product_id = ${product}`;
    //       }
    //     }

    //     // Add campaign filter if provided
    //     if (campaign) {
    //       // If where is not initialized yet, start with "WHERE"
    //       if (!where) {
    //         where = `WHERE campaign_id = '${campaign}'`;
    //       } else {
    //         where += ` AND campaign_id = '${campaign}'`;
    //       }
    //     }

    //     // Key Findings Query with dynamic filters
    //     let keyFindingsQuery = `
    //       SELECT
    //         SUM(total_contacts) AS total_contacts,
    //         ROUND((SUM(idle_contacts) * 1.0 / SUM(total_contacts)) * 100, 2) AS idle_contacts_percentage,
    //         SUM(engaged_contacts) AS total_mqls,
    //         ROUND((SUM(idle_contacts) * 1.0 / SUM(engaged_contacts)) * 100, 2) AS dormant_mqls_percentage,
    //         ROUND(((SUM(total_contacts) - SUM(engaged_contacts)) * 1.0 / SUM(total_contacts)) * 100, 2) AS lead_drop_off_rate,
    //         '8%' AS likely_to_close_opportunities -- Placeholder: Calculate based on assumptions or other criteria
    //       FROM
    //         campaign_analysis ca
    //       ${where}
    //     `;

    //     // Metrics Query with dynamic filters
    //     let metricsQuery = `
    //       SELECT
    //         SUM(total_contacts) AS total_contacts_generated,
    //         SUM(engaged_contacts) AS total_engaged_contacts,
    //         SUM(engaged_contacts) * 1.0 / SUM(total_contacts) * 100 AS average_engagement_score,
    //         SUM(emails_opened) AS total_emails_opened,
    //         SUM(emails_sent) AS total_emails_sent,
    //         SUM(emails_opened) * 1.0 / SUM(emails_sent) * 100 AS average_email_open_rate,
    //         SUM(emails_clicked) * 1.0 / SUM(emails_sent) * 100 AS average_email_click_rate,
    //         SUM(landing_page_visits) AS total_landing_page_visits,
    //         SUM(landing_page_conversions) * 1.0 / SUM(landing_page_visits) * 100 AS landing_page_conversion_rate,
    //         SUM(landing_page_visits - landing_page_conversions) * 1.0 / SUM(landing_page_visits) * 100 AS average_landing_page_bounce_rate,
    //         SUM(linkedin_clicks) AS total_linkedin_clicks,
    //         SUM(linkedin_impressions) AS total_linkedin_impressions
    //       FROM campaign_analysis ca
    //       ${where}
    //     `;

    //     // Execute the queries
    //     const metrics = await executeRawQuery(metricsQuery);
    //     const keyFindingsResponse = await executeRawQuery<
    //       {
    //         totalContacts: number;
    //         idleContactsPercentage: number;
    //         totalMqls: number;
    //         dormantMqlsPercentage: number;
    //         leadDropOffRate: number;
    //         likelyToCloseOpportunities: number;
    //       }[]
    //     >(keyFindingsQuery);

    //     // Set the fetched data
    //     setKeyFindings({
    //       totalContacts: keyFindingsResponse[0].totalContacts || 0,
    //       idleContactsPercentage:
    //         keyFindingsResponse[0].idleContactsPercentage || 0,
    //       totalMqls: keyFindingsResponse[0].totalMqls || 0,
    //       dormantMqlsPercentage:
    //         keyFindingsResponse[0].dormantMqlsPercentage || 0,
    //       leadDropOffRate: keyFindingsResponse[0].leadDropOffRate || 0,
    //       likelyToCloseOpportunities:
    //         keyFindingsResponse[0].likelyToCloseOpportunities || 0,

    //       totalContactsGenerated: metrics[0].totalContactsGenerated,
    //       totalEngagedContacts: metrics[0].totalEngagedContacts,
    //       averageEngagementScore: metrics[0].averageEngagementScore,
    //       averageEmailOpenRate: metrics[0].averageEmailOpenRate,
    //       averageEmailClickRate: metrics[0].averageEmailClickRate,
    //       landingPageConversionRate: metrics[0].landingPageConversionRate,
    //       totalEmailsOpened: metrics[0].totalEmailsOpened,
    //       totalEmailsSent: metrics[0].totalEmailsSent,
    //       totalLandingPageVisits: metrics[0].totalLandingPageVisits,
    //       averageLandingPageBounceRate: metrics[0].averageLandingPageBounceRate,
    //       totalLinkedInClicks: metrics[0].totalLinkedinClicks,
    //       totalLinkedInImpressions: metrics[0].totalLinkedinImpressions,
    //     });
    //   } catch (error) {
    //     console.error("Error fetching key findings", error);
    //     toast.error("Error fetching key findings");
    //   }
    // };

    const fetchEngagementData = async () => {
      try {
        let query = `
          SELECT 
            to_char(month, 'YYYY-MM') AS month, 
            AVG(monthly_cost / NULLIF(engaged_contacts, 0)) AS cost_per_engagement, 
            AVG(engaged_contacts * 1.0 / NULLIF(total_contacts, 0) * 100) AS engagement_rate 
          FROM campaign_analysis ca
          ${joinClause}
        `;

        let whereClause = generateWhereClause(campaign, product);
        whereClause = addDateCondition(whereClause, dateCaptured);

        query += whereClause;
        query += `
          GROUP BY to_char(month, 'YYYY-MM')
          ORDER BY to_char(month, 'YYYY-MM');
        `;

        const response = await executeRawQuery<
          {
            month: string;
            costPerEngagement: number;
            engagementRate: number;
          }[]
        >(query);

        setCostEngagementScatterData([
          ["Month", "Cost per Engagement", "Engagement Rate"],
          ...response.map(
            (row) =>
              [
                Month_Names[new Date(row.month)?.getMonth()],
                row.costPerEngagement,
                row.engagementRate,
              ] as [string, number, number]
          ),
        ]);
      } catch (error) {
        console.error("Error fetching scatter chart data", error);
        toast.error("Error fetching scatter chart data");
      }
    };

    const fetchContactsTrendData = async () => {
      try {
        let query = `
          SELECT 
            to_char(month, 'YYYY-MM') AS month,
            SUM(total_contacts) AS total_contacts,
            SUM(engaged_contacts) AS engaged_contacts,
            SUM(idle_contacts) AS idle_contacts
          FROM campaign_analysis ca
          ${joinClause}
        `;

        let whereClause = generateWhereClause(campaign, product);
        whereClause = addDateCondition(whereClause, dateCaptured);

        query += whereClause;
        query += `
          GROUP BY to_char(month, 'YYYY-MM')
          ORDER BY to_char(month, 'YYYY-MM');
        `;

        const response = await executeRawQuery<
          {
            month: string;
            totalContacts: number;
            engagedContacts: number;
            idleContacts: number;
          }[]
        >(query);

        setContactsTrendData([
          ["Month", "Converted", "Engaged", "Idle"],
          ...response.map(
            (row) =>
              [
                Month_Names[new Date(row.month)?.getMonth()],
                row.totalContacts,
                row.engagedContacts,
                row.idleContacts,
              ] as [string, number, number, number]
          ),
        ]);
      } catch (error) {
        console.error("Error fetching contacts trend data", error);
        toast.error("Error fetching contacts trend data");
      }
    };

    const fetchPerformanceAcrossKeyMetrics = async () => {
      try {
        let query = `
          SELECT 
            to_char(month, 'YYYY-MM') AS month, 
            AVG(email_open_rate) AS open_rate,
            AVG(landing_page_bounce_rate) AS bounce_rate, 
            AVG(landing_page_conversion_rate) AS conversion_rate
          FROM campaign_analysis ca
          ${joinClause}
        `;

        let whereClause = generateWhereClause(campaign, product);
        whereClause = addDateCondition(whereClause, dateCaptured);

        query += whereClause;
        query += `
          GROUP BY to_char(month, 'YYYY-MM')
          ORDER BY to_char(month, 'YYYY-MM');
        `;

        const response = await executeRawQuery<
          {
            month: string;
            openRate: number;
            bounceRate: number;
            conversionRate: number;
          }[]
        >(query);

        setPerformanceAcrossKeyMetrics([
          ["Month", "Open Rate", "Conversion Rate", "Bounce Rate"],
          ...response.map(
            (row) =>
              [
                Month_Names[new Date(row.month)?.getMonth()],
                row.openRate,
                row.conversionRate,
                row.bounceRate,
              ] as [string, number, number, number]
          ),
        ]);
      } catch (error) {
        console.error("Error fetching performance metrics", error);
        toast.error("Error fetching performance metrics");
      }
    };

    const fetchEmailPerformanceData = async () => {
      try {
        let query = `
          SELECT 
            to_char(month, 'YYYY-MM') AS month,
            SUM(emails_opened) AS emails_opened,
            SUM(emails_clicked) AS emails_clicked,
            SUM(emails_sent) AS emails_sent
          FROM 
            campaign_analysis ca
          ${joinClause}
        `;

        let whereClause = generateWhereClause(campaign, product);
        whereClause = addDateCondition(whereClause, dateCaptured);

        query += whereClause;
        query += `
          GROUP BY 
            to_char(month, 'YYYY-MM')
          ORDER BY 
            to_char(month, 'YYYY-MM');
        `;

        const result = await executeRawQuery<
          {
            month: string;
            emailsOpened: string;
            emailsClicked: string;
            emailsSent: string;
          }[]
        >(query);

        setEmailPerformanceData([
          ["Month", "Emails Opened", "Emails Clicked", "Emails Sent"],
          ...result.map(
            (row) =>
              [
                Month_Names[new Date(row.month)?.getMonth()],
                parseInt(row.emailsOpened),
                parseInt(row.emailsClicked),
                parseInt(row.emailsSent),
              ] as [string, number, number, number]
          ),
        ]);
      } catch (error) {
        console.error("Error fetching email performance data", error);
        toast.error("Error fetching email performance data");
      }
    };

    const fetchLandingPagePerformanceData = async () => {
      try {
        let query = `
          SELECT 
            to_char(month, 'YYYY-MM') AS month,
            COALESCE(AVG(landing_page_conversions * 1.0 / NULLIF(landing_page_visits, 0)), 0) AS landing_page_conversion_rate,
            COALESCE(AVG((landing_page_visits - landing_page_conversions) * 1.0 / NULLIF(landing_page_visits, 0)), 0) AS landing_page_bounce_rate
          FROM 
            campaign_analysis ca
          ${joinClause}
        `;

        let whereClause = generateWhereClause(campaign, product);
        whereClause = addDateCondition(whereClause, dateCaptured);

        query += whereClause;
        query += `
          GROUP BY 
            to_char(month, 'YYYY-MM')
          ORDER BY 
            to_char(month, 'YYYY-MM');
        `;

        const result = await executeRawQuery<
          {
            month: string;
            landingPageConversionRate: string;
            landingPageBounceRate: string;
          }[]
        >(query);

        const conversionRate = result.map((row) =>
          parseFloat(row.landingPageConversionRate)
        );
        const bounceRate = result.map((row) =>
          parseFloat(row.landingPageBounceRate)
        );

        setLandingPagePerformanceData([
          ["Month", "Conversion Rate", "Bounce Rate"],
          ...result.map(
            (row) =>
              [
                Month_Names[new Date(row.month)?.getMonth()],
                parseFloat(row.landingPageConversionRate),
                parseFloat(row.landingPageBounceRate),
              ] as [string, number, number]
          ),
        ]);
      } catch (error) {
        console.error("Error fetching landing page performance data", error);
        toast.error("Error fetching landing page performance data");
      }
    };

    const fetchLinkedInEngagementData = async () => {
      try {
        let query = `
          SELECT 
            to_char(month, 'YYYY-MM') AS month,
            SUM(linkedin_impressions) AS impressions,
            SUM(linkedin_clicks) AS clicks
          FROM 
            campaign_analysis ca
          ${joinClause}
        `;

        let whereClause = generateWhereClause(campaign, product);
        whereClause = addDateCondition(whereClause, dateCaptured);

        query += whereClause;
        query += `
          GROUP BY 
            to_char(month, 'YYYY-MM')
          ORDER BY 
            to_char(month, 'YYYY-MM');
        `;

        const result = await executeRawQuery<
          {
            month: string;
            impressions: string;
            clicks: string;
          }[]
        >(query);

        setLinkedInEngagementData([
          ["Month", "Impressions", "Clicks"],
          ...result.map(
            (row) =>
              [
                Month_Names[new Date(row.month)?.getMonth()],
                parseInt(row.impressions),
                parseInt(row.clicks),
              ] as [string, number, number]
          ),
        ]);
      } catch (error) {
        console.error("Error fetching LinkedIn engagement data", error);
        toast.error("Error fetching LinkedIn engagement data");
      }
    };

    // fetchContactsTrendData();
    // fetchEngagementData();
    // // fetchKeyFindings();
    // fetchPerformanceAcrossKeyMetrics();
    // fetchEmailPerformanceData();
    // fetchLandingPagePerformanceData();
    // fetchLinkedInEngagementData();
  }, [product, campaign, dateCaptured]);

  const fetchKeyFindings = async () => {
    const condition = generateOptionalConditions({
      industry_name: selectedIndustry,
      service_name: selectServices,
    });
    const response = await executeRawQuery<any>(`
    SELECT 
    AVG(CAST(REPLACE(REPLACE(REPLACE(TAM, '$', ''), 'B', ''), ',', '') AS FLOAT)) AS avg_TAM,  -- Remove '$', 'B', and ',' from TAM
    SUM(CAST(REPLACE(REPLACE(organizations_num, '+', ''), ',', '') AS FLOAT)) AS avg_target_organizations,  -- Remove '+' and ',' from organizations_num
    AVG(CAST(REPLACE(CAGR, '%', '') AS FLOAT)) AS avg_CAGR,  -- Remove '%' from CAGR
    AVG(CAST(REPLACE(SMBs_contribution, '%', '') AS FLOAT)) AS avg_SMBs_contribution,  -- Remove '%' from SMBs Contribution
    AVG(CAST(
      REPLACE(
        REPLACE(
          REPLACE(
            expected_investment, '$', ''
          ),
          'M', '000000'
        ),
      ',', ''
    ) AS FLOAT)) AS avg_expected_investment,  -- Remove '$', 'M', and ',' from Expected Investment
    AVG(CAST(REPLACE(cloud_adoption, '%', '') AS FLOAT)) AS avg_cloud_uptake  -- Remove '%' from Cloud Adoption
    FROM 
    AcquisitionDashboardMetrics
    ${condition}
    ;`);

    if (response) {
      setKeyFindings({
        ...keyFindings,
        tam: response[0]?.avgTam || 0,

        cagr: response[0]?.avgCagr || 0,
        targetOrganizations: response[0]?.avgTargetOrganizations || 0,
        smbsContribution: response[0]?.avgSmbsContribution || 0,
        expectedInvestment: response[0]?.avgExpectedInvestment || 0,
        cloudUptake: response[0]?.avgCloudUptake || 0,
      });
    }
  };

  const fetchOrganisationSize = async () => {
    const condition = generateOptionalConditions({
      industry_name: selectedIndustry,
      service_name: selectServices,
    })
    const query = `
      SELECT 
        j.key AS organization_size,
        SUM((j.value)::FLOAT) AS total_investment
      FROM 
        AcquisitionDashboardMetrics,
        LATERAL jsonb_each(investment_share_by_size) AS j(key, value)
        ${condition}
      GROUP BY 
        j.key
      ORDER BY 
        total_investment DESC;
    `;

    try {
      const response = await executeRawQuery(query);

      if (response?.length) {
        // Format data: Ensure numeric values for investments
        const formattedData = [["Organisations", "Total Investment"]];
        response?.forEach((item: any) => {
          formattedData.push([
            item.organizationSize,
            Number(item.totalInvestment),
          ]);
        });

        setOrganisationSize(formattedData); // Update state with formatted data
      }
    } catch (error) {
      console.error("Error fetching organization size data:", error);
    }
  };

  const fetchDataAnalyticsKeyUseCases = async () => {
    console.log("selectedIndustry>>>", selectedIndustry, selectServices);

    const whereClause =
      selectedIndustry && selectServices
        ? `WHERE service_name = '${selectServices}' AND industry_name = '${selectedIndustry}'`
        : "";

    const query = `
      SELECT 
        INITCAP(j.key) AS industry,
        SUM((j.value)::FLOAT) AS use_cases
      FROM 
        AcquisitionDashboardMetrics,
        LATERAL jsonb_each(use_cases_number) AS j(key, value)
        ${whereClause}
      GROUP BY 
        INITCAP(j.key)
      HAVING
        SUM((j.value)::FLOAT) > 0
      ORDER BY
        use_cases DESC
      LIMIT 15;
    `;

    try {
      const response = await executeRawQuery(query);
      console.log("key use cases response: ", response);

      if (response?.length) {
        const formattedData = [["Industries", "Use Cases"]];
        response?.forEach((item: any) => {
          formattedData.push([item.industry, Number(item.useCases)]);
        });

        setDataAnalyticsUseCases(formattedData);
      }
    } catch (error) {
      console.error("Error fetching key use cases:", error);
    }
  };

  const fetchTechnologyTrends = async () => {
    const condition = generateOptionalConditions({
      industry_name: selectedIndustry,
      service_name: selectServices,
    });
    const response = await executeRawQuery<any>(`
      SELECT 
        j.key AS technology,
        SUM((j.value)::FLOAT) AS total_trend_percentage
      FROM 
        AcquisitionDashboardMetrics,
        LATERAL jsonb_each(technologies_trend) AS j(key, value)
        ${condition}
      GROUP BY 
        j.key
      ORDER BY 
        total_trend_percentage DESC;
      `);

    if (response?.length) {
      const formattedData = [["Technology", "Total Trend Percentage"]];
      response.forEach((item: any) => {
        formattedData.push([
          item.technology,
          Number(item.totalTrendPercentage),
        ]);
      });

      setTechnologyTrends(formattedData);
    }
  };

  const fetchGeographicImpact = async () => {
    const condition = generateOptionalConditions({
      industry_name: selectedIndustry,
      service_name: selectServices,
    });
    const response = await executeRawQuery<any>(`
      SELECT 
        j.key AS region,
        SUM((j.value)::FLOAT) AS total_impact_percentage
      FROM 
        AcquisitionDashboardMetrics,
        LATERAL jsonb_each(region_impact) AS j(key, value)
        ${condition}
      GROUP BY 
        j.key
      ORDER BY 
        total_impact_percentage DESC;
    `);

    if (response?.length) {
      const formattedData = [["Technology", "Total Trend Percentage"]];
      response.forEach((item: any) => {
        formattedData.push([item.region, Number(item.totalImpactPercentage)]);
      });

      setGraphicImpact(formattedData);
    }
  };

  // Sales
  const fetchSalesFinding = async () => {
    const response = await executeRawQuery<any>(`
      SELECT 
        service_name,
        AVG(CAST(REGEXP_REPLACE(TAM, '[^0-9.]', '', 'g') AS NUMERIC)) AS avg_total_addressable_market_size,
        AVG(CAST(REGEXP_REPLACE(CAGR, '[^0-9.]', '', 'g') AS NUMERIC)) AS avg_compound_annual_growth_rate,
        AVG(CAST(REGEXP_REPLACE(organizations_num, '[^0-9.]', '', 'g') AS NUMERIC)) AS avg_potential_target_organizations
      FROM 
        AcquisitionDashboardMetrics
      WHERE 
        service_name = 'Salesforce'
      GROUP BY 
        service_name;
    `);

    if (response) {
      setsSalesForceFinding({
        ...salesForceFinding,
        avgCompoundAnnualGrowthRate: response[0]?.avgCompoundAnnualGrowthRate,
        avgPotentialTargetOrganizations:
          response[0]?.avgPotentialTargetOrganizations,
        avgTotalAddressableMarketSize:
          response[0]?.avgTotalAddressableMarketSize,
        serviceName: response[0]?.serviceName,
      });
      console.log("fetchSalesFinding: ", response);
    }
  };

  const fetchSalesIndustries = async () => {
    const response = await executeRawQuery<any>(`
SELECT DISTINCT 
    industry_name, 
    REPLACE(REPLACE(organizations_num, '+', ''), ',', '') AS number_of_organisations
FROM 
    acquisitiondashboardmetrics
WHERE 
    service_name = 'Salesforce'
ORDER BY 
    industry_name, 
    number_of_organisations;

`);

    console.log("response>>>>>>> ", response);
    if (response?.length) {
      const formattedData = [["Industries", "Organisations"]];
      response.forEach((item: any) => {
        formattedData?.push([item.industryName, Number(item.numberOfOrganisations)]);
      });

      setSalesforceDemand(formattedData);
    }
  };

  const fetchSalesPG = async () => {
    const response = await executeRawQuery<any>(`
SELECT
    role,
    AVG(CAST(value AS INTEGER)) AS avg_professionals
FROM
    acquisitiondashboardmetrics,
    jsonb_each_text(salesforce_professionals) AS role_data(role, value)
WHERE
    service_name = 'Salesforce'
GROUP BY
    role
ORDER BY
    avg_professionals DESC;
`);

    if (response?.length) {
      const formattedData = [["Professionals", "Growth"]];
      response.forEach((item: any) => {
        formattedData?.push([item.role, Number(item.avgProfessionals)]);
      });

      setSalesforcePG(formattedData);
    }
  };

  const fetchSalesTopInsights = async () => {
    const response = await executeRawQuery<any>(`
   SELECT 
    industry_name, 
    insights
FROM 
    acquisitiondashboardmetrics
WHERE 
    service_name = 'Salesforce'
ORDER BY 
    CAST(REGEXP_REPLACE(organizations_num, '[^0-9]', '', 'g') AS INTEGER) DESC
LIMIT 1;
    `);

    if (response) {
      setSalesInsights(response);
    }
  };

  // Data Analytics
  const fetchDataAnalyticsFinding = async () => {
    const response = await executeRawQuery<any>(`
      SELECT 
        service_name,
        AVG(CAST(REGEXP_REPLACE(TAM, '[^0-9.]', '', 'g') AS NUMERIC)) AS avg_total_addressable_market_size,
        AVG(CAST(REGEXP_REPLACE(CAGR, '[^0-9.]', '', 'g') AS NUMERIC)) AS avg_compound_annual_growth_rate,
        AVG(CAST(REGEXP_REPLACE(organizations_num, '[^0-9.]', '', 'g') AS NUMERIC)) AS avg_potential_target_organizations
      FROM 
        AcquisitionDashboardMetrics
      WHERE 
        service_name = 'Data Analytics'
      GROUP BY 
        service_name;
    `);
    console.log("fetchDataAnalyticsFinding:1 ", response);
    if (response) {
      setsDataAnalyticsFindings({
        ...salesForceFinding,
        avgCompoundAnnualGrowthRate: response[0]?.avgCompoundAnnualGrowthRate,
        avgPotentialTargetOrganizations:
          response[0]?.avgPotentialTargetOrganizations,
        avgTotalAddressableMarketSize:
          response[0]?.avgTotalAddressableMarketSize,
        serviceName: response[0]?.serviceName,
      });
      console.log("fetchDataAnalyticsFinding: ", response);
    }
  };
  const fetchDataAnalyticsPG = async () => {
    const response = await executeRawQuery<any>(`
SELECT
    role,
    AVG(CAST(value AS INTEGER)) AS avg_professionals
FROM
    acquisitiondashboardmetrics,
    jsonb_each_text(salesforce_professionals) AS role_data(role, value)
WHERE
    service_name = 'Data Analytics'
GROUP BY
    role
ORDER BY
    avg_professionals DESC;
`);

    if (response?.length) {
      const formattedData = [["year", "Budget Increase Percentage"]];
      response.forEach((item: any) => {
        formattedData?.push([item.role, Number(item.avgProfessionals)]);
      });

      setDataAnalyticsPG(formattedData);
    }
  };
  const fetchDataAnalyticsProjectedYoyBudget = async () => {
    const condition = generateOptionalConditions({
      industry_name: selectedIndustry,
      service_name: selectServices,
    });
    const response = await executeRawQuery<any>(`
      SELECT 
        j.key AS year,
        SUM((j.value)::FLOAT) AS projected_budget_increase
      FROM 
        AcquisitionDashboardMetrics,
        LATERAL jsonb_each(yoy_budget_increase) AS j(key, value)
      WHERE
        service_name = 'Data Analytics'
      GROUP BY 
        j.key
      ORDER BY 
        year ASC;
    `);

    if (response?.length) {
      const formattedData = [["Year", "Projected Budget Increase"]];
      response.forEach((item: any) => {
        formattedData.push([item.year, Number(item.projectedBudgetIncrease)]);
      });

      setDataAnalyticsYoyBudgetAllocation(formattedData);
    }
  };
  const fetchDataAnalyticsTopInsights = async () => {
    const response = await executeRawQuery<any>(`
   SELECT 
    industry_name, 
    insights
FROM 
    acquisitiondashboardmetrics
WHERE 
    service_name = 'Data Analytics'
ORDER BY 
    CAST(REGEXP_REPLACE(organizations_num, '[^0-9]', '', 'g') AS INTEGER) DESC
LIMIT 1;
    `);

    if (response) {
      setDataAnalyticsInsights(response);
    }
  };

  //   Security and Network
  const fetchSecurityNetworkFinding = async () => {
    const response = await executeRawQuery<any>(`
      SELECT 
        service_name,
        AVG(CAST(REGEXP_REPLACE(TAM, '[^0-9.]', '', 'g') AS NUMERIC)) AS avg_total_addressable_market_size,
        AVG(CAST(REGEXP_REPLACE(CAGR, '[^0-9.]', '', 'g') AS NUMERIC)) AS avg_compound_annual_growth_rate,
        AVG(CAST(REGEXP_REPLACE(organizations_num, '[^0-9.]', '', 'g') AS NUMERIC)) AS avg_potential_target_organizations
      FROM 
        AcquisitionDashboardMetrics
      WHERE 
        service_name = 'Security and Networking'
      GROUP BY 
        service_name;
    `);
    console.log("SecurityNetwork:1 ", response);
    if (response) {
      setSecurityNetworkFindings({
        ...salesForceFinding,
        avgCompoundAnnualGrowthRate: response[0]?.avgCompoundAnnualGrowthRate,
        avgPotentialTargetOrganizations:
          response[0]?.avgPotentialTargetOrganizations,
        avgTotalAddressableMarketSize:
          response[0]?.avgTotalAddressableMarketSize,
        serviceName: response[0]?.serviceName,
      });
      console.log("fetchDataAnalyticsFinding: ", response);
    }
  };
  const fetchSecurityNetworkPG = async () => {
    const response = await executeRawQuery<any>(`
SELECT
    role,
    AVG(CAST(value AS INTEGER)) AS avg_professionals
FROM
    acquisitiondashboardmetrics,
    jsonb_each_text(salesforce_professionals) AS role_data(role, value)
WHERE
       service_name = 'Security and Networking'
GROUP BY
    role
ORDER BY
    avg_professionals DESC;
`);

    if (response?.length) {
      const formattedData = [
        ["Organisations", "Projected Percentage of Organisations"],
      ];
      response.forEach((item: any) => {
        formattedData?.push([item.role, Number(item.avgProfessionals)]);
      });

      setSecurityNetworkPG(formattedData);
    }
  };
  const fetchSecurityChallenges = async () => {
    const response = await executeRawQuery<any>(`
      SELECT 
        j.key AS security_challenges,
        SUM((j.value)::FLOAT) AS occurences
      FROM 
        AcquisitionDashboardMetrics,
        LATERAL jsonb_each(security_challenges) AS j(key, value)
      WHERE
        service_name = 'Security and Networking'
      GROUP BY
        j.key
      ORDER BY
        occurences DESC;
    `);

    if (response?.length) {
      const formattedData = [["Security Challenges", "Occurences"]];
      response.forEach((item: any) => {
        formattedData.push([item.securityChallenges, Number(item.occurences)]);
      });

      setSecurityChallenges(formattedData);
    }
  };
  const fetchSecurityNetworkTopInsights = async () => {
    const response = await executeRawQuery<any>(`
      SELECT 
      industry_name, 
      insights
      FROM 
    acquisitiondashboardmetrics
WHERE 
service_name = 'Security and Networking'
ORDER BY 
CAST(REGEXP_REPLACE(organizations_num, '[^0-9]', '', 'g') AS INTEGER) DESC
LIMIT 1;
`);

    if (response) {
      setSecurityNetworkInsights(response);
    }
  };

  const fetchSecurityServices = async () => {
    const response = await executeRawQuery<any>(`
WITH keys AS (
    SELECT DISTINCT jsonb_object_keys(security_investments) AS key
    FROM AcquisitionDashboardMetrics
),
aggregated_data AS (
    SELECT
        key,
        SUM((security_investments->>key)::NUMERIC) AS total_value
    FROM AcquisitionDashboardMetrics, keys
    WHERE security_investments ? key
    GROUP BY key
)
SELECT key, total_value
FROM aggregated_data
ORDER BY total_value DESC;
`);

    if (response?.length) {
      const formattedData = [["Service", "Growth"]];
      response.forEach((item: any) => {
        formattedData?.push([item.key, Number(item.totalValue)]);
      });

      setSecurityService(formattedData);
    }
  };

  //   IOT GIS
  const fetchIOTGISFinding = async () => {
    const response = await executeRawQuery<any>(`
      SELECT 
        service_name,
        AVG(CAST(REGEXP_REPLACE(TAM, '[^0-9.]', '', 'g') AS NUMERIC)) AS avg_total_addressable_market_size,
        AVG(CAST(REGEXP_REPLACE(CAGR, '[^0-9.]', '', 'g') AS NUMERIC)) AS avg_compound_annual_growth_rate,
        AVG(CAST(REGEXP_REPLACE(organizations_num, '[^0-9.]', '', 'g') AS NUMERIC)) AS avg_potential_target_organizations
      FROM 
        AcquisitionDashboardMetrics
      WHERE 
         service_name = 'IOT with GIS'
      GROUP BY 
        service_name;
    `);

    if (response) {
      setIotGisFindings({
        ...salesForceFinding,
        avgCompoundAnnualGrowthRate: response[0]?.avgCompoundAnnualGrowthRate,
        avgPotentialTargetOrganizations:
          response[0]?.avgPotentialTargetOrganizations,
        avgTotalAddressableMarketSize:
          response[0]?.avgTotalAddressableMarketSize,
        serviceName: response[0]?.serviceName,
      });
      console.log("fetchDataAnalyticsFinding: ", response);
    }
  };
  const fetchIOTGISPG = async () => {
    const response = await executeRawQuery<any>(`
SELECT
    role,
    AVG(CAST(value AS INTEGER)) AS avg_professionals
FROM
    acquisitiondashboardmetrics,
    jsonb_each_text(salesforce_professionals) AS role_data(role, value)
WHERE
       service_name = 'IOT with GIS'
GROUP BY
    role
ORDER BY
    avg_professionals DESC;
`);

    if (response?.length) {
      const formattedData = [["Segmentation", "Spending"]];
      response.forEach((item: any) => {
        formattedData?.push([item.role, Number(item.avgProfessionals)]);
      });

      setIotGisPG(formattedData);
    }
  };
  const fetchIOTGISUseCase = async () => {
    const response = await executeRawQuery<any>(`
      SELECT 
        INITCAP(j.key) AS segmentation,
        SUM((j.value)::FLOAT) AS spendings
      FROM 
        AcquisitionDashboardMetrics,
        LATERAL jsonb_each(use_cases_spending) AS j(key, value)
      WHERE
        service_name = 'IOT with GIS'
      GROUP BY
        INITCAP(j.key)
      ORDER BY
        spendings DESC
      LIMIT 15;
    `);

    if (response?.length) {
      const formattedData = [["Segmentation", "Spendings"]];
      response.forEach((item: any) => {
        formattedData.push([item.segmentation, Number(item.spendings)]);
      });

      setIotGisUseCase(formattedData);
    }
  };
  const fetchIOTGISTopInsights = async () => {
    const response = await executeRawQuery<any>(`
      SELECT 
      industry_name, 
      insights
      FROM 
    acquisitiondashboardmetrics
WHERE 
  service_name = 'IOT with GIS'
ORDER BY 
CAST(REGEXP_REPLACE(organizations_num, '[^0-9]', '', 'g') AS INTEGER) DESC
LIMIT 1;
`);

    if (response) {
      setIotGisInsights(response);
    }
  };

  // ! Verticals Leading Demand Graph
  const fetchLeadingDemand = async () => {
    const response = await executeRawQuery<any>(`
WITH keys AS (
    SELECT DISTINCT jsonb_object_keys(security_investments) AS key
    FROM AcquisitionDashboardMetrics
),
aggregated_data AS (
    SELECT
        key,
        SUM((security_investments->>key)::NUMERIC) AS total_value
    FROM AcquisitionDashboardMetrics, keys
    WHERE security_investments ? key
    AND service_name = 'IOT with GIS'  -- Replace with the service name you want to filter by
    GROUP BY key
)
SELECT key, total_value
FROM aggregated_data
ORDER BY total_value DESC;
`);

    if (response?.length) {
      const formattedData = [["Verticals", "Organisations"]];
      response.forEach((item: any) => {
        formattedData?.push([item.key, Number(item.totalValue)]);
      });

      setVerticalDemandsService(formattedData);
    }
  };

  useEffect(() => {
    //
    fetchKeyFindings();
    fetchOrganisationSize();
    fetchTechnologyTrends();
    fetchGeographicImpact();
    //
    if (selectServices === "Salesforce" || !selectServices) {
      fetchSalesFinding();
      fetchSalesPG();
      fetchSalesTopInsights();
      fetchSalesIndustries();
    }
    //
    if (selectServices === "Data Analytics" || !selectServices) {
      fetchDataAnalyticsFinding();
      fetchDataAnalyticsKeyUseCases();
      fetchDataAnalyticsProjectedYoyBudget();
      fetchDataAnalyticsTopInsights();
    }
    //
    if (selectServices === "Security and Networking" || !selectServices) {
      fetchSecurityNetworkFinding();
      fetchSecurityChallenges();
      fetchSecurityNetworkTopInsights();
      fetchSecurityServices();
    }
    //
    if (selectServices === "IOT with GIS" || !selectServices) {
      fetchIOTGISFinding();
      fetchIOTGISUseCase();
      fetchIOTGISTopInsights();
      fetchLeadingDemand();
    }
  }, [selectedIndustry, selectServices]);

  return {
    emailPerformanceData,
    landingPagePerformanceData,
    linkedInEngagementData,
    keyFindings,
    products,
    campaigns,
    costEngagementScatterData,
    contactsTrendData,
    performanceAcrossKeyMetrics,
    dateCapturedOptions,
    industrySegmentList,
    setIndustrySegmentList,
    selectedIndustry,
    setSelectedIndustry,
    dashboardData,
    organisationSize,
    technologyTrends,
    setTechnologyTrends,
    graphicImpact,
    // services
    services,

    setGraphicImpact,
    //
    salesForceFinding,
    setsSalesForceFinding,
    salesforcePG,
    setSalesforcePG,
    salesInsights,
    setSalesInsights,
    salesforceDemand,
    setSalesforceDemand,
    //
    dataAnalyticsFindings,
    setsDataAnalyticsFindings,
    dataAnalyticsUseCases,
    dataAnalyticsYoyBudgetAllocation,
    dataAnalyticsInsights,
    //
    securityNetworkFindings,
    securityChallenges,
    securityNetworkInsights,
    securityService,
    setSecurityService,
    //
    iotGisFindings,
    iotGisUseCase,
    iotGisInsights,
    selectServices,
    setSelectServices,
    verticalDemandsService,
  };
};

export default useMarketingDashboardReport;
