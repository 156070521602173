import React, { useState, useEffect } from "react";

import styles from "./JourneyManagment.module.scss";
import useJourneyManagement from "./useJourneyManagment";
import { useLocation, useParams } from "react-router-dom";
import ReactFlowContainer from "../../componenets/ReactFlowContainer/ReactFlowContainer";
import JourneyTreeHeader from "./JourneyTreeHeader/JourneyTreeHeader";
import { useTestJourney } from "./useTestJourney";

const initialNodes = [
  { id: "1", position: { x: 0, y: 0 }, data: { label: "1" } },
  { id: "2", position: { x: 0, y: 100 }, data: { label: "2" } },
];
const initialEdges = [{ id: "e1-2", source: "1", target: "2" }];

const JourneyTreeView: React.FC = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const stage = query.get("id");

  const { fetchJourneyById, selectedJourney } = useJourneyManagement();

  useEffect(() => {
    if (stage) fetchJourneyById(parseInt(stage));
  }, []);

  return (
    <div className={styles["journey-tree-view"]}>
      <JourneyTreeHeader defaultCampaignName={selectedJourney?.title as string} />
      <ReactFlowContainer
        journeyId={stage ? parseInt(stage) : 0}
        updatedJourneyBlueprint={(nodes, edges) => {}}
      />
    </div>
  );
};

export default JourneyTreeView;
