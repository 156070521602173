import React, { useState } from "react";
import styles from "./ShowMoreLess.module.scss";
import classNames from "classnames";

interface ShowMoreLessProps {
  text: string;
  maxWords: number;
  className?: string;
}

const ShowMoreLess: React.FC<ShowMoreLessProps> = ({
  text = "",
  maxWords,
  className,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const words = text.split(" ");
  const shouldShowMore = words.length > maxWords;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded ? text : words.slice(0, maxWords).join(" ");

  return (
    <p className={classNames(styles["show-more-less"], className)}>
      {displayText}
      {shouldShowMore && (
        <button
          onClick={toggleExpand}
          className={styles["show-more-less-button"]}
        >
          {isExpanded ? (
            <span>&nbsp;...show less</span>
          ) : (
            <span>&nbsp;...show more</span>
          )}
        </button>
      )}
    </p>
  );
};

export default ShowMoreLess;
