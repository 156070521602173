import React, { useState, useEffect } from "react";
import JourneySidebar from "./JourneySidebar/JourneySidebar";
import useJourneyManagement, { JourneyTypes } from "./useJourneyManagment";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";

const JourneyManagement: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const {
    journeys,
    selectedJourney,
    setSelectedJourney,
    handleSaveJourney,
    handleDeleteJourney,
    selectedType,
    setSelectedType,
    fetchJourneys,
  } = useJourneyManagement();

  useEffect(() => {
    fetchJourneys(selectedType as string);
  }, [selectedType]);

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-start p-6 bg-gray-100 min-h-screen">
      <div className="w-full max-w-4xl mx-auto bg-white p-6 rounded shadow-md">
        <button
          onClick={() => {
            setSelectedJourney(null);
            setSidebarOpen(true);
          }}
          className="mb-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Add New Journey
        </button>

        <div className="mb-4">
          <label htmlFor="type-filter" className="block text-gray-700 mb-1">
            Filter by Type:
          </label>
          <select
            id="type-filter"
            value={selectedType as string}
            onChange={(e) => setSelectedType(e.target.value as JourneyTypes)}
            className="w-full border border-gray-300 py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All</option>
            <option value="onboarding">Onboarding</option>
            <option value="nurture-journey-by-product">
              Nurture Journey by Product
            </option>
            <option value="retention">Retention</option>
            <option value="upsell-and-cross-sell">Upsell and Cross-Sell</option>
          </select>
        </div>

        <ul className="space-y-4">
          {journeys.map((journey) => (
            <li
              key={journey.id}
              className="p-4 bg-gray-50 border border-gray-200 rounded shadow"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      setSelectedJourney(journey);
                      setSidebarOpen(true);
                    }}
                    className="bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-600"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteJourney(journey.id)}
                    className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() =>
                      navigate(
                        `${ROUTE_DEFINITIONS.CAMPAIGN_TREE_VIEW.path}?id=${journey.id}`
                      )
                    }
                    className="bg-green-500 text-white py-1 px-3 rounded hover:bg-green-600"
                  >
                    Journey Tree View
                  </button>
                </div>
              </div>
              <p className="text-gray-600">{journey.description}</p>
            </li>
          ))}
        </ul>
      </div>
      {isSidebarOpen && (
        <JourneySidebar
          journey={selectedJourney}
          onClose={() => setSidebarOpen(false)}
          onSave={(j) => {
            handleSaveJourney(j, selectedType as string);
          }}
        />
      )}
    </div>
  );
};

export default JourneyManagement;
